//this file contains a list of us college names
//currently used for the auto_complete list in collegeList tab --> Search_autocomplete.js

export default function getCollegeNamesLocal() {
  addKey()
  return collegeNamesLocal
}

function addKey() {
  for (let i = 0; i < collegeNamesLocal.length; i++) {
    collegeNamesLocal[i]['key'] = i
  }
}

let collegeNamesLocal = [
  {
    institution: 'Alabama A & M University',
  },
  {
    institution: 'University of Alabama at Birmingham',
  },
  {
    institution: 'Amridge University',
  },
  {
    institution: 'University of Alabama in Huntsville',
  },
  {
    institution: 'Alabama State University',
  },
  {
    institution: 'University of Alabama System Office',
  },
  {
    institution: 'The University of Alabama',
  },
  {
    institution: 'Central Alabama Community College',
  },
  {
    institution: 'Athens State University',
  },
  {
    institution: 'Auburn University at Montgomery',
  },
  {
    institution: 'Auburn University',
  },
  {
    institution: 'Birmingham Southern College',
  },
  {
    institution: 'Chattahoochee Valley Community College',
  },
  {
    institution: 'Concordia College Alabama',
  },
  {
    institution: 'South University-Montgomery',
  },
  {
    institution: 'Enterprise State Community College',
  },
  {
    institution: 'James H Faulkner State Community College',
  },
  {
    institution: 'Faulkner University',
  },
  {
    institution: 'Gadsden State Community College',
  },
  {
    institution: 'New Beginning College of Cosmetology',
  },
  {
    institution: 'George C Wallace State Community College-Dothan',
  },
  {
    institution: 'George C Wallace State Community College-Hanceville',
  },
  {
    institution: 'George C Wallace State Community College-Selma',
  },
  {
    institution: 'Herzing University-Birmingham',
  },
  {
    institution: 'Huntingdon College',
  },
  {
    institution: 'Heritage Christian University',
  },
  {
    institution: 'J F Drake State Community and Technical College',
  },
  {
    institution: 'J F Ingram State Technical College',
  },
  {
    institution: 'Jacksonville State University',
  },
  {
    institution: 'Jefferson Davis Community College',
  },
  {
    institution: 'Jefferson State Community College',
  },
  {
    institution: 'John C Calhoun State Community College',
  },
  {
    institution: 'Judson College',
  },
  {
    institution: 'Lawson State Community College-Birmingham Campus',
  },
  {
    institution: 'University of West Alabama',
  },
  {
    institution: 'Lurleen B Wallace Community College',
  },
  {
    institution: 'Marion Military Institute',
  },
  {
    institution: 'Miles College',
  },
  {
    institution: 'University of Mobile',
  },
  {
    institution: 'University of Montevallo',
  },
  {
    institution: 'Northwest-Shoals Community College',
  },
  {
    institution: 'University of North Alabama',
  },
  {
    institution: 'Northeast Alabama Community College',
  },
  {
    institution: 'Oakwood University',
  },
  {
    institution: 'Alabama Southern Community College',
  },
  {
    institution: 'Prince Institute-Southeast',
  },
  {
    institution: 'Reid State Technical College',
  },
  {
    institution: 'Bishop State Community College',
  },
  {
    institution: 'Samford University',
  },
  {
    institution: 'Selma University',
  },
  {
    institution: 'Shelton State Community College',
  },
  {
    institution: 'Snead State Community College',
  },
  {
    institution: 'University of South Alabama',
  },
  {
    institution: 'Spring Hill College',
  },
  {
    institution: 'Southeastern Bible College',
  },
  {
    institution: 'Stillman College',
  },
  {
    institution: 'Talladega College',
  },
  {
    institution: 'H Councill Trenholm State Technical College',
  },
  {
    institution: 'Troy University',
  },
  {
    institution: 'Tuskegee University',
  },
  {
    institution: 'United States Sports Academy',
  },
  {
    institution: 'Bevill State Community College',
  },
  {
    institution: 'University of Alaska Anchorage',
  },
  {
    institution: 'Alaska Bible College',
  },
  {
    institution: 'University of Alaska Fairbanks',
  },
  {
    institution: 'University of Alaska Southeast',
  },
  {
    institution: 'Alaska Pacific University',
  },
  {
    institution: "AVTEC-Alaska's Institute of Technology",
  },
  {
    institution: 'Charter College-Anchorage',
  },
  {
    institution: 'Prince William Sound Community College',
  },
  {
    institution: 'Alaska Career College',
  },
  {
    institution: 'University of Alaska System of Higher Education',
  },
  {
    institution: 'Everest College-Phoenix',
  },
  {
    institution: 'Collins College',
  },
  {
    institution: 'Empire Beauty School-Paradise Valley',
  },
  {
    institution: 'Empire Beauty School-Tucson',
  },
  {
    institution: 'Thunderbird School of Global Management',
  },
  {
    institution: 'American Indian College of the Assemblies of God Inc',
  },
  {
    institution: 'American Institute of Technology',
  },
  {
    institution: 'Carrington College-Phoenix',
  },
  {
    institution: 'Carrington College-Mesa',
  },
  {
    institution: 'Carrington College-Tucson',
  },
  {
    institution: 'College America-Flagstaff',
  },
  {
    institution: 'Arizona Academy of Beauty-East',
  },
  {
    institution: 'Arizona Automotive Institute',
  },
  {
    institution: 'Brookline College-Phoenix',
  },
  {
    institution: 'Arizona State University-Tempe',
  },
  {
    institution: 'Arizona Western College',
  },
  {
    institution: 'University of Arizona',
  },
  {
    institution: 'Southwest University of Visual Arts-Tucson',
  },
  {
    institution: 'Brillare Hairdressing Academy',
  },
  {
    institution: 'Central Arizona College',
  },
  {
    institution: 'Brown Mackie College-Tucson',
  },
  {
    institution: 'Charles of Italy Beauty College',
  },
  {
    institution: 'Cochise College',
  },
  {
    institution: 'Empire Beauty School-Flagstaff',
  },
  {
    institution: 'Empire Beauty School-Chandler',
  },
  {
    institution: 'Cortiva Institute-Tucson',
  },
  {
    institution: 'DeVry University-Arizona',
  },
  {
    institution: 'Avalon School of Cosmetology-Mesa',
  },
  {
    institution: 'Eastern Arizona College',
  },
  {
    institution: 'Embry-Riddle Aeronautical University-Prescott',
  },
  {
    institution: 'Frank Lloyd Wright School of Architecture',
  },
  {
    institution: 'Glendale Community College',
  },
  {
    institution: 'Grand Canyon University',
  },
  {
    institution: 'Anthem College-Phoenix',
  },
  {
    institution: 'International Academy of Hair Design',
  },
  {
    institution: 'Kaplan College-Phoenix',
  },
  {
    institution: 'Maricopa Community College System Office',
  },
  {
    institution: 'GateWay Community College',
  },
  {
    institution: 'Mesa Community College',
  },
  {
    institution: 'ITT Technical Institute-Tucson',
  },
  {
    institution: 'ITT Technical Institute-Tempe',
  },
  {
    institution: 'Mohave Community College',
  },
  {
    institution:
      'Universal Technical Institute of Arizona Inc-Motorcycle Mechanics Institute Division',
  },
  {
    institution: 'Dine College',
  },
  {
    institution: 'Northern Arizona University',
  },
  {
    institution: 'Northland Pioneer College',
  },
  {
    institution: 'Ottawa University-Phoenix',
  },
  {
    institution: 'Phoenix College',
  },
  {
    institution: 'Cortiva Institute-Scottsdale',
  },
  {
    institution: 'University of Phoenix-Phoenix Campus',
  },
  {
    institution: 'Pima Community College',
  },
  {
    institution: 'Pima Medical Institute-Tucson',
  },
  {
    institution: 'Pima Medical Institute-Albuquerque',
  },
  {
    institution: 'Prescott College',
  },
  {
    institution: 'Refrigeration School Inc',
  },
  {
    institution: 'Rio Salado College',
  },
  {
    institution: 'Roberto-Venn School of Luthiery',
  },
  {
    institution: 'Hair Academy of Safford',
  },
  {
    institution: 'Scottsdale Community College',
  },
  {
    institution: 'South Mountain Community College',
  },
  {
    institution: 'Arizona Christian University',
  },
  {
    institution: 'Tucson College',
  },
  {
    institution: 'Universal Technical Institute of Arizona Inc',
  },
  {
    institution: 'Western International University',
  },
  {
    institution: 'Empire Beauty School-NW Phoenix',
  },
  {
    institution: 'Yavapai College',
  },
  {
    institution: 'University of Arkansas at Little Rock',
  },
  {
    institution: 'University of Arkansas for Medical Sciences',
  },
  {
    institution: 'ABC Beauty College Inc',
  },
  {
    institution: 'Arkansas Baptist College',
  },
  {
    institution: 'Arkansas Beauty School',
  },
  {
    institution: 'Arkansas Beauty College',
  },
  {
    institution: 'Lyon College',
  },
  {
    institution: 'Arkansas College of Barbering and Hair Design',
  },
  {
    institution: "Arthur's Beauty College Inc-Fort Smith",
  },
  {
    institution: 'University of Arkansas',
  },
  {
    institution: 'University of Arkansas at Pine Bluff',
  },
  {
    institution: 'Arkansas State University-Beebe',
  },
  {
    institution: 'Arkansas State University-Main Campus',
  },
  {
    institution: 'Arkansas Tech University',
  },
  {
    institution: 'University of Arkansas at Monticello',
  },
  {
    institution: "Arthur's Beauty College Inc-Jacksonville",
  },
  {
    institution: 'Baptist Health Schools-Little Rock',
  },
  {
    institution: 'Black River Technical College',
  },
  {
    institution: 'University of Central Arkansas',
  },
  {
    institution: 'Central Baptist College',
  },
  {
    institution: 'Cossatot Community College of the University of Arkansas',
  },
  {
    institution: "Crowley's Ridge College",
  },
  {
    institution: "Crowley's Ridge Technical Institute",
  },
  {
    institution: 'East Arkansas Community College',
  },
  {
    institution: 'Eastern College of Health Vocations-Little Rock',
  },
  {
    institution: 'Imagine-Paul Mitchell Partner School',
  },
  {
    institution: 'National Park Community College',
  },
  {
    institution: 'University of Arkansas Community College-Batesville',
  },
  {
    institution: 'Harding University',
  },
  {
    institution: 'Henderson State University',
  },
  {
    institution: 'Hendrix College',
  },
  {
    institution: 'Hot Springs Beauty College',
  },
  {
    institution: 'Jefferson Regional Medical Center School of Nursing',
  },
  {
    institution: 'John Brown University',
  },
  {
    institution: 'Lees School of Cosmetology',
  },
  {
    institution: 'Career Academy of Hair Design',
  },
  {
    institution: 'Marsha Kay Beauty College',
  },
  {
    institution: 'Academy of Salon and Spa',
  },
  {
    institution: 'Mid-South Community College',
  },
  {
    institution: 'Arkansas Northeastern College',
  },
  {
    institution: "Margaret's Hair Academy Inc",
  },
  {
    institution: 'New Tyler Barber College Inc',
  },
  {
    institution: 'North Arkansas College',
  },
  {
    institution: 'Northwest Technical Institute',
  },
  {
    institution: 'Ouachita Baptist University',
  },
  {
    institution: 'College of the Ouachitas',
  },
  {
    institution: 'Ozarka College',
  },
  {
    institution: 'University of the Ozarks',
  },
  {
    institution: 'University of Arkansas Community College-Morrilton',
  },
  {
    institution: 'Philander Smith College',
  },
  {
    institution: 'Phillips Community College of the University of Arkansas',
  },
  {
    institution: 'Southeast Arkansas College',
  },
  {
    institution: 'Professional Cosmetology Education Center',
  },
  {
    institution: 'Pulaski Technical College',
  },
  {
    institution: 'University of Arkansas Community College-Hope',
  },
  {
    institution: 'Rich Mountain Community College',
  },
  {
    institution: 'Paul Mitchell the School-Arkansas',
  },
  {
    institution: 'Searcy Beauty College Inc',
  },
  {
    institution: 'Shorter College',
  },
  {
    institution: 'Williams Baptist College',
  },
  {
    institution: 'South Arkansas Community College',
  },
  {
    institution: 'Southern Arkansas University Main Campus',
  },
  {
    institution: 'Southern Arkansas University Tech',
  },
  {
    institution: 'University of Arkansas System Office',
  },
  {
    institution: 'Velvatex College of Beauty Culture',
  },
  {
    institution: 'University of Arkansas-Fort Smith',
  },
  {
    institution: 'Academy of Art University',
  },
  {
    institution: 'ITT Technical Institute-Rancho Cordova',
  },
  {
    institution: 'Academy of Chinese Culture and Health Sciences',
  },
  {
    institution: 'The Academy of Radio and TV Broadcasting',
  },
  {
    institution: 'Avalon School of Cosmetology-Alameda',
  },
  {
    institution: 'College of Alameda',
  },
  {
    institution: 'Allan Hancock College',
  },
  {
    institution: 'American Academy of Dramatic Arts-Los Angeles',
  },
  {
    institution: 'American Baptist Seminary of the West',
  },
  {
    institution: 'American Film Institute Conservatory',
  },
  {
    institution: 'American Beauty College',
  },
  {
    institution: 'American Career College-Los Angeles',
  },
  {
    institution: 'American Conservatory Theater',
  },
  {
    institution: 'American River College',
  },
  {
    institution: 'Everest College-Hayward',
  },
  {
    institution: 'Antelope Valley College',
  },
  {
    institution: 'Art Center College of Design',
  },
  {
    institution: 'Associated Technical College-Los Angeles',
  },
  {
    institution: 'Associated Technical College-San Diego',
  },
  {
    institution: 'Azusa Pacific University',
  },
  {
    institution: 'Bakersfield College',
  },
  {
    institution: 'Barstow Community College',
  },
  {
    institution: 'Bellus Academy-National City',
  },
  {
    institution: 'Bethesda University of California',
  },
  {
    institution: 'Biola University',
  },
  {
    institution: 'Brooks Institute',
  },
  {
    institution: 'Brownson Technical School',
  },
  {
    institution: 'Bryan University',
  },
  {
    institution: 'Butte College',
  },
  {
    institution: 'Phillips Graduate Institute',
  },
  {
    institution: 'California Institute of Integral Studies',
  },
  {
    institution: 'Cabrillo College',
  },
  {
    institution: 'California Baptist University',
  },
  {
    institution: 'California College of the Arts',
  },
  {
    institution: 'University of California-Hastings College of Law',
  },
  {
    institution: 'California Institute of Technology',
  },
  {
    institution: 'California Lutheran University',
  },
  {
    institution: 'California Polytechnic State University-San Luis Obispo',
  },
  {
    institution: 'Alliant International University',
  },
  {
    institution: 'California State University-Bakersfield',
  },
  {
    institution: 'California State University-Stanislaus',
  },
  {
    institution: 'California State University-Chancellors Office',
  },
  {
    institution: 'California State University-San Bernardino',
  },
  {
    institution: 'California State Polytechnic University-Pomona',
  },
  {
    institution: 'California State University-Chico',
  },
  {
    institution: 'California State University-Dominguez Hills',
  },
  {
    institution: 'California State University-Fresno',
  },
  {
    institution: 'California State University-Fullerton',
  },
  {
    institution: 'California State University-East Bay',
  },
  {
    institution: 'California State University-Long Beach',
  },
  {
    institution: 'California State University-Los Angeles',
  },
  {
    institution: 'California State University-Northridge',
  },
  {
    institution: 'California State University-Sacramento',
  },
  {
    institution: 'University of California-Berkeley',
  },
  {
    institution: 'University of California-Davis',
  },
  {
    institution: 'University of California-Irvine',
  },
  {
    institution: 'University of California-Los Angeles',
  },
  {
    institution: 'University of California-Riverside',
  },
  {
    institution: 'University of California-San Diego',
  },
  {
    institution: 'University of California-San Francisco',
  },
  {
    institution: 'University of California-Santa Barbara',
  },
  {
    institution: 'University of California-Santa Cruz',
  },
  {
    institution: 'Sofia University',
  },
  {
    institution: 'California Beauty School',
  },
  {
    institution: 'California Christian College',
  },
  {
    institution: 'California College San Diego',
  },
  {
    institution: 'Le Cordon Bleu College of Culinary Arts-San Francisco',
  },
  {
    institution: 'California Hair Design Academy',
  },
  {
    institution: 'California Institute of the Arts',
  },
  {
    institution: 'California Maritime Academy',
  },
  {
    institution: 'California Western School of Law',
  },
  {
    institution: 'Canada College',
  },
  {
    institution: 'College of the Canyons',
  },
  {
    institution: 'Career Academy of Beauty',
  },
  {
    institution: 'Casa Loma College-Van Nuys',
  },
  {
    institution: 'CET-San Diego',
  },
  {
    institution: 'CET-Sobrato',
  },
  {
    institution: 'CET-El Centro',
  },
  {
    institution: 'CET-San Bernardino',
  },
  {
    institution: 'CET-Watsonville',
  },
  {
    institution: 'CET-Gilroy',
  },
  {
    institution: 'CET-Salinas',
  },
  {
    institution: 'Cerritos College',
  },
  {
    institution: 'Cerro Coso Community College',
  },
  {
    institution: 'Chabot College',
  },
  {
    institution: 'Chaffey College',
  },
  {
    institution: 'Chapman University',
  },
  {
    institution: 'Charles R Drew University of Medicine and Science',
  },
  {
    institution: 'Concordia University-Irvine',
  },
  {
    institution: 'San Diego Christian College',
  },
  {
    institution: 'Church Divinity School of the Pacific',
  },
  {
    institution: 'Citrus College',
  },
  {
    institution: 'Citrus Heights Beauty College',
  },
  {
    institution: 'City College of San Francisco',
  },
  {
    institution: 'Claremont Graduate University',
  },
  {
    institution: 'Claremont McKenna College',
  },
  {
    institution: 'Milan Institute-Visalia',
  },
  {
    institution: 'Clovis Adult Education',
  },
  {
    institution: 'Coast Community College District Office',
  },
  {
    institution: 'Coastline Community College',
  },
  {
    institution: 'Cogswell College',
  },
  {
    institution: 'Coleman University',
  },
  {
    institution: 'COBA Academy',
  },
  {
    institution: 'Western University of Health Sciences',
  },
  {
    institution: 'Columbia College',
  },
  {
    institution: 'Columbia College-Hollywood',
  },
  {
    institution: 'El Camino College-Compton Center',
  },
  {
    institution: 'Western Beauty Institute',
  },
  {
    institution: 'Contra Costa Community College District Office',
  },
  {
    institution: 'Contra Costa College',
  },
  {
    institution: 'Cosumnes River College',
  },
  {
    institution: 'Crafton Hills College',
  },
  {
    institution: 'Cuesta College',
  },
  {
    institution: 'Cuyamaca College',
  },
  {
    institution: 'Cypress College',
  },
  {
    institution: 'De Anza College',
  },
  {
    institution: 'Marinello Schools of Beauty-Hemet',
  },
  {
    institution: 'International School of Beauty Inc',
  },
  {
    institution: "Dell'Arte International School of Physical Theatre",
  },
  {
    institution: 'College of the Desert',
  },
  {
    institution: 'Design Institute of San Diego',
  },
  {
    institution: 'DeVry University-California',
  },
  {
    institution: 'Diablo Valley College',
  },
  {
    institution: 'Dominican University of California',
  },
  {
    institution: 'Dominican School of Philosophy & Theology',
  },
  {
    institution: 'East Los Angeles College',
  },
  {
    institution: 'Marinello Schools of Beauty-Santa Clara',
  },
  {
    institution: 'San Diego Continuing Education',
  },
  {
    institution: 'El Camino Community College District',
  },
  {
    institution: 'Elegance International',
  },
  {
    institution: 'Marinello Schools of Beauty-Burbank',
  },
  {
    institution: 'Marinello Schools of Beauty-Moreno Valley',
  },
  {
    institution: "Emperor's College of Traditional Oriental Medicine",
  },
  {
    institution: 'Empire College School of Business',
  },
  {
    institution: 'Evergreen Valley College',
  },
  {
    institution: 'Fashion Institute of Design & Merchandising-Los Angeles',
  },
  {
    institution: 'Fashion Careers College',
  },
  {
    institution: 'Fashion Institute of Design & Merchandising-San Francisco',
  },
  {
    institution: 'Fashion Institute of Design & Merchandising-Orange County',
  },
  {
    institution: 'Feather River Community College District',
  },
  {
    institution: 'Federico Beauty Institute',
  },
  {
    institution: 'Fielding Graduate University',
  },
  {
    institution: 'Five Branches University',
  },
  {
    institution: 'Hair California Beauty Academy',
  },
  {
    institution: 'Foothill College',
  },
  {
    institution: 'Franciscan School of Theology',
  },
  {
    institution: 'Fredrick and Charles Beauty College',
  },
  {
    institution: 'Fresno City College',
  },
  {
    institution: 'Fresno Pacific University',
  },
  {
    institution: 'Foothill-De Anza Community College District',
  },
  {
    institution: 'Fuller Theological Seminary in California',
  },
  {
    institution: 'Fullerton College',
  },
  {
    institution: 'Galen College of Medical and Dental Assistants',
  },
  {
    institution: 'Gavilan College',
  },
  {
    institution: 'Gemological Institute of America-Carlsbad',
  },
  {
    institution: 'Glendale Community College',
  },
  {
    institution: 'Glendale Career College',
  },
  {
    institution: 'Golden Gate University-San Francisco',
  },
  {
    institution: 'Golden West College',
  },
  {
    institution: 'Graduate Theological Union',
  },
  {
    institution: 'Grossmont-Cuyamaca Community College District',
  },
  {
    institution: 'Grossmont College',
  },
  {
    institution: 'Salon Success Academy-San Bernardino',
  },
  {
    institution: 'Hartnell College',
  },
  {
    institution: 'Harvey Mudd College',
  },
  {
    institution: 'Heald College-Rancho Cordova',
  },
  {
    institution: 'Heald College-Fresno',
  },
  {
    institution: 'Heald College-San Jose',
  },
  {
    institution: 'Heald College-San Francisco',
  },
  {
    institution: 'Heald College-Concord',
  },
  {
    institution: 'Hilltop Beauty School',
  },
  {
    institution: 'Holy Names University',
  },
  {
    institution: 'Pacifica Graduate Institute',
  },
  {
    institution: 'Humboldt State University',
  },
  {
    institution: 'Humphreys College-Stockton and Modesto Campuses',
  },
  {
    institution: 'Imperial Valley College',
  },
  {
    institution: 'Institute for Business and Technology',
  },
  {
    institution: 'LA College International',
  },
  {
    institution: 'Interior Designers Institute',
  },
  {
    institution: 'Toni & Guy Hairdressing Academy-Santa Monica',
  },
  {
    institution: 'Irvine Valley College',
  },
  {
    institution: 'ITT Technical Institute-National City',
  },
  {
    institution: 'ITT Technical Institute-San Dimas',
  },
  {
    institution: 'ITT Technical Institute-Orange',
  },
  {
    institution: 'Bellus Academy-El Cajon',
  },
  {
    institution: 'Joe Blasco Makeup Artist Training Center',
  },
  {
    institution: 'John F Kennedy University',
  },
  {
    institution: 'American Jewish University',
  },
  {
    institution: 'Reedley College',
  },
  {
    institution: 'Life Pacific College',
  },
  {
    institution: 'The Art Institute of California-Argosy University San Diego',
  },
  {
    institution: 'University of La Verne',
  },
  {
    institution: 'Laguna College of Art and Design',
  },
  {
    institution: 'Lake Tahoe Community College',
  },
  {
    institution: 'Lancaster Beauty School',
  },
  {
    institution: 'Laney College',
  },
  {
    institution: 'Lassen Community College',
  },
  {
    institution: 'Life Chiropractic College West',
  },
  {
    institution: 'Lincoln University',
  },
  {
    institution: 'Southern California Seminary',
  },
  {
    institution: 'La Sierra University',
  },
  {
    institution: 'Loma Linda University',
  },
  {
    institution: 'Long Beach City College',
  },
  {
    institution: 'Southern California University of Health Sciences',
  },
  {
    institution: 'Los Angeles Community College District Office',
  },
  {
    institution: 'Los Angeles Harbor College',
  },
  {
    institution: 'Los Angeles Pierce College',
  },
  {
    institution: 'Los Angeles Southwest College',
  },
  {
    institution: 'Los Angeles Trade Technical College',
  },
  {
    institution: 'Los Angeles Valley College',
  },
  {
    institution: "The Master's College and Seminary",
  },
  {
    institution: 'Los Angeles City College',
  },
  {
    institution: 'Los Angeles County College of Nursing and Allied Health',
  },
  {
    institution: 'Los Angeles Mission College',
  },
  {
    institution: 'Los Medanos College',
  },
  {
    institution: 'Los Rios Community College District Office',
  },
  {
    institution:
      'The Art Institute of California-Argosy University San Francisco',
  },
  {
    institution: 'Loyola Marymount University',
  },
  {
    institution: 'Lu Ross Academy',
  },
  {
    institution: 'Manchester Beauty College',
  },
  {
    institution: 'Tulare Beauty College',
  },
  {
    institution: 'Lyles Bakersfield College of Beauty',
  },
  {
    institution: 'Lyles Fresno College of Beauty',
  },
  {
    institution: 'Lytles Redwood Empire Beauty College Inc',
  },
  {
    institution: 'MTI Business College Inc',
  },
  {
    institution: 'MTI College',
  },
  {
    institution: 'Kaplan College-Sacramento',
  },
  {
    institution: 'Kaplan College-San Diego',
  },
  {
    institution: 'Kaplan College-Vista',
  },
  {
    institution: 'College of Marin',
  },
  {
    institution: 'Marinello Schools of Beauty-Los Angeles',
  },
  {
    institution: 'Marymount California University',
  },
  {
    institution: 'Mendocino College',
  },
  {
    institution: 'Menlo College',
  },
  {
    institution: 'Merced College',
  },
  {
    institution: 'Merritt College',
  },
  {
    institution: 'Mills College',
  },
  {
    institution: 'MiraCosta College',
  },
  {
    institution: 'Marinello Schools of Beauty-San Francisco',
  },
  {
    institution: 'Mission College',
  },
  {
    institution: 'Modern Beauty Academy',
  },
  {
    institution: 'Kaplan College-North Hollywood',
  },
  {
    institution: 'Modesto Junior College',
  },
  {
    institution: 'Moler Barber College',
  },
  {
    institution: 'Monterey Institute of International Studies',
  },
  {
    institution: 'Monterey Peninsula College',
  },
  {
    institution: 'Moorpark College',
  },
  {
    institution: 'Mt San Antonio College',
  },
  {
    institution: "Mount St Mary's College",
  },
  {
    institution: 'Mt San Jacinto Community College District',
  },
  {
    institution: 'Mueller College',
  },
  {
    institution: 'Musicians Institute',
  },
  {
    institution: 'Napa Valley College',
  },
  {
    institution: 'Everest College-San Francisco',
  },
  {
    institution: 'Everest College-Reseda',
  },
  {
    institution: 'Everest College-LA Wilshire',
  },
  {
    institution: 'Everest College-San Jose',
  },
  {
    institution: 'Everest College-Gardena',
  },
  {
    institution: 'Everest College-Alhambra',
  },
  {
    institution: 'Everest College-San Bernardino',
  },
  {
    institution: 'The National Hispanic University',
  },
  {
    institution: 'National Holistic Institute',
  },
  {
    institution: 'National University',
  },
  {
    institution: 'Naval Postgraduate School',
  },
  {
    institution: 'Newschool of Architecture and Design',
  },
  {
    institution: 'Newberry School of Beauty',
  },
  {
    institution: "Adrian's College of Beauty Modesto",
  },
  {
    institution: 'North Orange County Community College District',
  },
  {
    institution: 'North-West College-Pomona',
  },
  {
    institution: 'North-West College-West Covina',
  },
  {
    institution: 'North-West College-Pasadena',
  },
  {
    institution: 'Northwestern Polytechnic University',
  },
  {
    institution: 'Notre Dame de Namur University',
  },
  {
    institution: 'Occidental College',
  },
  {
    institution: 'Oceanside College of Beauty',
  },
  {
    institution: 'Ohlone College',
  },
  {
    institution: 'Orange Coast College',
  },
  {
    institution: 'Otis College of Art and Design',
  },
  {
    institution: 'Oxnard College',
  },
  {
    institution: 'Hope International University',
  },
  {
    institution: 'Concorde Career College-San Diego',
  },
  {
    institution: 'Palo Alto University',
  },
  {
    institution: 'Pacific Lutheran Theological Seminary',
  },
  {
    institution: 'Pacific Oaks College',
  },
  {
    institution: 'Pacific School of Religion',
  },
  {
    institution: 'Pacific States University',
  },
  {
    institution: 'Pacific Union College',
  },
  {
    institution: 'University of the Pacific',
  },
  {
    institution: 'Palo Verde College',
  },
  {
    institution: 'Palomar College',
  },
  {
    institution: 'Paris Beauty College',
  },
  {
    institution: 'Pasadena City College',
  },
  {
    institution: 'Pepperdine University',
  },
  {
    institution: 'Peralta Community College System Office',
  },
  {
    institution: 'Pitzer College',
  },
  {
    institution: 'Platt College-San Diego',
  },
  {
    institution: 'Point Loma Nazarene University',
  },
  {
    institution: 'Pomona College',
  },
  {
    institution: 'Porterville College',
  },
  {
    institution: 'Westwood College-South Bay',
  },
  {
    institution: 'Professional Institute of Beauty',
  },
  {
    institution: 'Santa Ana College',
  },
  {
    institution: 'Pardee RAND Graduate School',
  },
  {
    institution: 'Shasta School of Cosmetology',
  },
  {
    institution: 'University of Redlands',
  },
  {
    institution: 'College of the Redwoods',
  },
  {
    institution: 'Salon Success Academy-Upland',
  },
  {
    institution: 'Salon Success Academy-Corona',
  },
  {
    institution: 'Rio Hondo College',
  },
  {
    institution: 'Riverside City College',
  },
  {
    institution: 'Argosy University-San Francisco Bay Area',
  },
  {
    institution: 'Rosemead Beauty School',
  },
  {
    institution: 'Dongguk University-Los Angeles',
  },
  {
    institution: 'Sacramento City College',
  },
  {
    institution: 'Saddleback College',
  },
  {
    institution: "Saint Patrick's Seminary and University",
  },
  {
    institution: 'Samuel Merritt University',
  },
  {
    institution: 'San Diego Community College District-District Office',
  },
  {
    institution: 'San Diego City College',
  },
  {
    institution: 'Golf Academy of America-Carlsbad',
  },
  {
    institution: 'San Diego Mesa College',
  },
  {
    institution: 'San Diego Miramar College',
  },
  {
    institution: 'San Diego State University',
  },
  {
    institution: 'University of San Diego',
  },
  {
    institution: 'San Francisco Art Institute',
  },
  {
    institution: 'San Francisco Conservatory of Music',
  },
  {
    institution: 'San Francisco State University',
  },
  {
    institution: 'San Francisco Theological Seminary',
  },
  {
    institution: 'University of San Francisco',
  },
  {
    institution: 'San Joaquin College of Law',
  },
  {
    institution: 'San Joaquin Delta College',
  },
  {
    institution: 'San Joaquin Valley College-Visalia',
  },
  {
    institution: 'San Joaquin Valley College-Bakersfield',
  },
  {
    institution: 'William Jessup University',
  },
  {
    institution: 'San Jose-Evergreen Community College District',
  },
  {
    institution: 'San Jose City College',
  },
  {
    institution: 'San Jose State University',
  },
  {
    institution: 'San Mateo County Community College District Office',
  },
  {
    institution: 'College of San Mateo',
  },
  {
    institution: 'Santa Barbara Business College-Bakersfield',
  },
  {
    institution: 'Westwood College-Los Angeles',
  },
  {
    institution: 'Santa Barbara Business College-Santa Maria',
  },
  {
    institution: 'Santa Barbara City College',
  },
  {
    institution: 'Santa Clara University',
  },
  {
    institution: 'Santa Monica College',
  },
  {
    institution: 'Santa Rosa Junior College',
  },
  {
    institution: 'Saybrook University',
  },
  {
    institution: 'Scripps College',
  },
  {
    institution: 'Wyotech-Fremont',
  },
  {
    institution: 'College of the Sequoias',
  },
  {
    institution: 'Shasta Bible College and Graduate School',
  },
  {
    institution: 'Shasta College',
  },
  {
    institution: 'Sierra College',
  },
  {
    institution: 'Sierra College of Beauty',
  },
  {
    institution: 'Sierra Valley College of Court Reporting',
  },
  {
    institution: 'Trinity Law School',
  },
  {
    institution: 'Simpson University',
  },
  {
    institution: 'College of the Siskiyous',
  },
  {
    institution: 'Charles A Jones Career and Education Center',
  },
  {
    institution: 'Skyline College',
  },
  {
    institution: 'San Bernardino Valley College',
  },
  {
    institution: "Saint Mary's College of California",
  },
  {
    institution: 'Solano Community College',
  },
  {
    institution: 'Sonoma State University',
  },
  {
    institution: 'Pinnacle College',
  },
  {
    institution: 'South Baylo University',
  },
  {
    institution: 'South Coast College',
  },
  {
    institution: 'Vanguard University of Southern California',
  },
  {
    institution: 'Concorde Career College-Garden Grove',
  },
  {
    institution: 'Southwestern College',
  },
  {
    institution: "St John's Seminary",
  },
  {
    institution: 'Starr King School for Ministry',
  },
  {
    institution: 'State Center Community College District',
  },
  {
    institution: 'Marshall B Ketchum University',
  },
  {
    institution: 'Southern California Institute of Architecture',
  },
  {
    institution: 'University of Southern California',
  },
  {
    institution: 'Southwestern Law School',
  },
  {
    institution: 'Taft College',
  },
  {
    institution: 'Claremont School of Theology',
  },
  {
    institution: 'Thomas Aquinas College',
  },
  {
    institution: 'Marinello Schools of Beauty-Lake Forest',
  },
  {
    institution: 'Epic Bible College',
  },
  {
    institution:
      'University of California-System Administration Central Office',
  },
  {
    institution: 'United Education Institute-Huntington Park Campus',
  },
  {
    institution: 'Concorde Career College-San Bernardino',
  },
  {
    institution: 'Universal College of Beauty Inc-Los Angeles 1',
  },
  {
    institution: 'Concorde Career College-North Hollywood',
  },
  {
    institution: 'Ventura County Community College System Office',
  },
  {
    institution: 'Ventura College',
  },
  {
    institution: 'The Santa Barbara and Ventura Colleges of Law-Ventura',
  },
  {
    institution: 'Victor Valley Beauty College Inc',
  },
  {
    institution: 'Victor Valley College',
  },
  {
    institution: 'Berkeley City College',
  },
  {
    institution: 'West Valley Mission Community College District Office',
  },
  {
    institution: 'Walden University',
  },
  {
    institution: 'Waynes College of Beauty',
  },
  {
    institution: 'West Hills College-Coalinga',
  },
  {
    institution: 'West Los Angeles College',
  },
  {
    institution: 'West Valley College',
  },
  {
    institution: 'Carrington College California-Sacramento',
  },
  {
    institution: 'Westminster Theological Seminary in California',
  },
  {
    institution: 'Westmont College',
  },
  {
    institution: 'Whittier College',
  },
  {
    institution: 'Woodbury University',
  },
  {
    institution: 'The Wright Institute',
  },
  {
    institution: 'Western State College of Law at Argosy University',
  },
  {
    institution: 'Thomas Jefferson School of Law',
  },
  {
    institution: 'Yeshiva Ohr Elchonon Chabad West Coast Talmudical Seminary',
  },
  {
    institution: 'Yosemite Community College District Office',
  },
  {
    institution: 'Yuba College',
  },
  {
    institution: 'The Salon Professional Academy-Grand Junction',
  },
  {
    institution: 'Adams State University',
  },
  {
    institution: 'Aims Community College',
  },
  {
    institution: 'Arapahoe Community College',
  },
  {
    institution: 'Bel-Rea Institute of Animal Technology',
  },
  {
    institution: 'Everest College-Colorado Springs',
  },
  {
    institution: 'Boulder College of Massage Therapy',
  },
  {
    institution: 'University of Colorado Denver',
  },
  {
    institution: 'University of Colorado Colorado Springs',
  },
  {
    institution: 'Redstone College',
  },
  {
    institution: 'University of Colorado Boulder',
  },
  {
    institution: 'Colorado Christian University',
  },
  {
    institution: 'Colorado College',
  },
  {
    institution: 'Concorde Career College-Aurora',
  },
  {
    institution: 'The Art Institute of Colorado',
  },
  {
    institution: 'Colorado Mountain College',
  },
  {
    institution: 'Colorado Northwestern Community College',
  },
  {
    institution: 'Colorado School of Mines',
  },
  {
    institution: 'Colorado School of Trades',
  },
  {
    institution: 'Colorado State University-Fort Collins',
  },
  {
    institution: 'Colorado Technical University-Colorado Springs',
  },
  {
    institution: 'Empire Beauty School-Lakewood',
  },
  {
    institution: 'Community College of Aurora',
  },
  {
    institution: 'College America-Denver',
  },
  {
    institution: 'Delta Montrose Technical College',
  },
  {
    institution: 'Prince Institute-Rocky Mountains',
  },
  {
    institution: 'Community College of Denver',
  },
  {
    institution: 'Lincoln College of Technology-Denver',
  },
  {
    institution: 'Denver Seminary',
  },
  {
    institution: 'Westwood College-Denver North',
  },
  {
    institution: 'University of Denver',
  },
  {
    institution: 'Emily Griffith Technical College',
  },
  {
    institution: 'Fort Lewis College',
  },
  {
    institution: 'Front Range Community College',
  },
  {
    institution: 'Glenwood Beauty Academy',
  },
  {
    institution: 'Hair Dynamics Education Center',
  },
  {
    institution: 'Iliff School of Theology',
  },
  {
    institution: 'International Salon and Spa Academy',
  },
  {
    institution: 'Lamar Community College',
  },
  {
    institution: 'Colorado Mesa University',
  },
  {
    institution: 'Metropolitan State University of Denver',
  },
  {
    institution: 'Morgan Community College',
  },
  {
    institution: 'Naropa University',
  },
  {
    institution: 'National American University-Colorado Springs',
  },
  {
    institution: 'National American University-Denver',
  },
  {
    institution: 'Nazarene Bible College',
  },
  {
    institution: 'Northeastern Junior College',
  },
  {
    institution: 'University of Northern Colorado',
  },
  {
    institution: 'Otero Junior College',
  },
  {
    institution: 'Everest College-Thornton',
  },
  {
    institution: 'Pikes Peak Community College',
  },
  {
    institution: 'Intellitec Medical Institute',
  },
  {
    institution: 'Pueblo Community College',
  },
  {
    institution: 'Red Rocks Community College',
  },
  {
    institution: 'Regis University',
  },
  {
    institution: 'Rocky Mountain College of Art and Design',
  },
  {
    institution: 'Montessori Education Center of the Rockies',
  },
  {
    institution: 'Colorado State University-Pueblo',
  },
  {
    institution: 'Pickens Technical College',
  },
  {
    institution: 'Intellitec College-Colorado Springs',
  },
  {
    institution: 'Intellitec College-Grand Junction',
  },
  {
    institution: 'Trinidad State Junior College',
  },
  {
    institution: 'University of Colorado System Office',
  },
  {
    institution: 'United States Air Force Academy',
  },
  {
    institution: 'Empire Beauty School-Thornton',
  },
  {
    institution: 'Western State Colorado University',
  },
  {
    institution: 'Yeshiva Toras Chaim Talmudical Seminary',
  },
  {
    institution: 'Albertus Magnus College',
  },
  {
    institution: 'Paul Mitchell the School-Danbury',
  },
  {
    institution: 'Asnuntuck Community College',
  },
  {
    institution: 'Bais Binyomin Academy',
  },
  {
    institution: 'Branford Hall Career Institute-Branford Campus',
  },
  {
    institution: 'Lincoln College of New England-Southington',
  },
  {
    institution: 'Bridgeport Hospital School of Nursing',
  },
  {
    institution: 'University of Bridgeport',
  },
  {
    institution: 'Central Connecticut State University',
  },
  {
    institution: 'Charter Oak State College',
  },
  {
    institution: 'Connecticut Center for Massage Therapy-Newington',
  },
  {
    institution: 'Connecticut College',
  },
  {
    institution: 'Marinello Schools of Beauty-East Hartford',
  },
  {
    institution: 'Porter and Chester Institute of Branford',
  },
  {
    institution: 'University of Connecticut',
  },
  {
    institution: 'Goodwin College',
  },
  {
    institution: 'Lincoln Technical Institute-East Windsor',
  },
  {
    institution: 'Eastern Connecticut State University',
  },
  {
    institution: 'Fairfield University',
  },
  {
    institution: 'Northhaven Academy',
  },
  {
    institution: 'Capital Community College',
  },
  {
    institution: 'Rensselaer Hartford Graduate Center Inc',
  },
  {
    institution: 'Fox Institute of Business-West Hartford',
  },
  {
    institution: 'Hartford Seminary',
  },
  {
    institution: 'University of Hartford',
  },
  {
    institution: 'Holy Apostles College and Seminary',
  },
  {
    institution: 'Housatonic Community College',
  },
  {
    institution: 'Sanford-Brown College-Farmington',
  },
  {
    institution: 'The Leon Institute of Hair Design',
  },
  {
    institution: 'Lyme Academy College of Fine Arts',
  },
  {
    institution: 'Manchester Community College',
  },
  {
    institution: 'Naugatuck Valley Community College',
  },
  {
    institution: 'Middlesex Community College',
  },
  {
    institution: 'Mitchell College',
  },
  {
    institution: 'Three Rivers Community College',
  },
  {
    institution: 'Lincoln Technical Institute-New Britain',
  },
  {
    institution: 'New England Tractor Trailer Training School of Connecticut',
  },
  {
    institution: 'University of New Haven',
  },
  {
    institution: 'Ridley-Lowell Business & Technical Institute-New London',
  },
  {
    institution: 'Norwalk Community College',
  },
  {
    institution: 'Northwestern Connecticut Community College',
  },
  {
    institution: 'Paier College of Art Inc',
  },
  {
    institution: 'Marinello Schools of Beauty-Meriden',
  },
  {
    institution: 'Porter and Chester Institute of Stratford',
  },
  {
    institution: 'Post University',
  },
  {
    institution: 'Quinebaug Valley Community College',
  },
  {
    institution: 'Quinnipiac University',
  },
  {
    institution: 'Sacred Heart University',
  },
  {
    institution: 'University of Saint Joseph',
  },
  {
    institution: 'Gateway Community College',
  },
  {
    institution: "St Vincent's College",
  },
  {
    institution: 'Southern Connecticut State University',
  },
  {
    institution: 'Stone Academy-Hamden',
  },
  {
    institution: 'Tri-State College of Acupuncture',
  },
  {
    institution: 'Trinity College',
  },
  {
    institution: 'Tunxis Community College',
  },
  {
    institution: 'United States Coast Guard Academy',
  },
  {
    institution: 'Wesleyan University',
  },
  {
    institution: 'Western Connecticut State University',
  },
  {
    institution: 'Yale-New Haven Hospital Dietetic Internship',
  },
  {
    institution: 'Yale University',
  },
  {
    institution: 'Beebe Medical Center Margaret H Rollins School of Nursing',
  },
  {
    institution: 'Dawn Career Institute Inc',
  },
  {
    institution: 'Delaware Technical Community College-Central Office',
  },
  {
    institution: 'Delaware Technical Community College-Owens',
  },
  {
    institution: 'Delaware Technical Community College-Terry',
  },
  {
    institution: 'Delaware Technical Community College-Stanton/Wilmington',
  },
  {
    institution: 'Delaware State University',
  },
  {
    institution: 'University of Delaware',
  },
  {
    institution: 'Goldey-Beacom College',
  },
  {
    institution: 'Schilling-Douglas School of Hair Design',
  },
  {
    institution: 'Wesley College',
  },
  {
    institution: 'Wilmington University',
  },
  {
    institution: 'American University',
  },
  {
    institution: 'Catholic University of America',
  },
  {
    institution: 'Corcoran College of Art and Design',
  },
  {
    institution: 'University of the District of Columbia',
  },
  {
    institution:
      'Pontifical Faculty of the Immaculate Conception at the Dominican House of Studies',
  },
  {
    institution: 'Gallaudet University',
  },
  {
    institution: 'George Washington University',
  },
  {
    institution: 'Georgetown University',
  },
  {
    institution: 'Howard University',
  },
  {
    institution: 'Medtech Institute',
  },
  {
    institution: 'Strayer University-District of Columbia',
  },
  {
    institution: 'National Conservatory of Dramatic Arts',
  },
  {
    institution: 'Trinity Washington University',
  },
  {
    institution: 'Wesley Theological Seminary',
  },
  {
    institution: 'Wyotech-Daytona',
  },
  {
    institution: 'The Art Institute of Fort Lauderdale',
  },
  {
    institution: 'Atlantic Technical Center',
  },
  {
    institution: 'The Baptist College of Florida',
  },
  {
    institution: 'Barry University',
  },
  {
    institution: 'Gooding Institute of Nurse Anesthesia',
  },
  {
    institution: 'Bethune-Cookman University',
  },
  {
    institution: 'Lynn University',
  },
  {
    institution: 'GUTI The Premier Beauty & Wellness Academy',
  },
  {
    institution: 'Bradford-Union Technical Center',
  },
  {
    institution: 'Eastern Florida State College',
  },
  {
    institution: 'Broward College',
  },
  {
    institution: 'Fortis College-Winter Park',
  },
  {
    institution: 'Carlos Albizu University-Miami',
  },
  {
    institution: 'College of Central Florida',
  },
  {
    institution: 'Johnson University Florida',
  },
  {
    institution: 'University of Central Florida',
  },
  {
    institution: 'Charlotte Technical Center',
  },
  {
    institution: 'Chipola College',
  },
  {
    institution: 'Clearwater Christian College',
  },
  {
    institution: 'Lorenzo Walker Institute of Technology',
  },
  {
    institution: 'Daytona State College',
  },
  {
    institution: 'South University-West Palm Beach',
  },
  {
    institution: 'Eckerd College',
  },
  {
    institution: 'Edison State College',
  },
  {
    institution: 'Edward Waters College',
  },
  {
    institution: 'Embry-Riddle Aeronautical University-Daytona Beach',
  },
  {
    institution: 'Florida Agricultural and Mechanical University',
  },
  {
    institution: 'Florida Atlantic University',
  },
  {
    institution: 'Florida State College at Jacksonville',
  },
  {
    institution: 'Flagler College-St Augustine',
  },
  {
    institution: 'Florida College',
  },
  {
    institution: 'Concorde Career Institute-Jacksonville',
  },
  {
    institution: 'Concorde Career Institute-Miramar',
  },
  {
    institution: 'Concorde Career Institute-Tampa',
  },
  {
    institution: 'Adventist University of Health Sciences',
  },
  {
    institution: 'Florida Institute of Technology',
  },
  {
    institution: 'Florida Institute of Ultrasound Inc',
  },
  {
    institution: 'Florida International University',
  },
  {
    institution: 'Florida Keys Community College',
  },
  {
    institution: 'Florida Memorial University',
  },
  {
    institution: 'Florida Career College-Miami',
  },
  {
    institution: 'Florida School of Massage',
  },
  {
    institution: 'Florida Southern College',
  },
  {
    institution: 'Florida State University',
  },
  {
    institution: 'Florida Technical College',
  },
  {
    institution: 'University of Southernmost Florida',
  },
  {
    institution: 'University of Florida',
  },
  {
    institution: 'Everest University-Pompano Beach',
  },
  {
    institution: 'Fort Pierce Beauty Academy',
  },
  {
    institution: 'Full Sail University',
  },
  {
    institution: 'George Stone Technical Center',
  },
  {
    institution: 'George T Baker Aviation School',
  },
  {
    institution: 'Gulf Coast State College',
  },
  {
    institution: 'Key College',
  },
  {
    institution: 'Hillsborough Community College',
  },
  {
    institution: 'Hobe Sound Bible College',
  },
  {
    institution: 'Hollywood Institute of Beauty Careers',
  },
  {
    institution: 'Cortiva Institute-Florida',
  },
  {
    institution: 'Indian River State College',
  },
  {
    institution: 'International Academy of Design and Technology-Tampa',
  },
  {
    institution: 'International Academy',
  },
  {
    institution: 'AI Miami International University of Art and Design',
  },
  {
    institution: 'ITT Technical Institute-Tampa',
  },
  {
    institution: 'Jacksonville University',
  },
  {
    institution: 'Jones College-Jacksonville',
  },
  {
    institution: 'Keiser University-Ft Lauderdale',
  },
  {
    institution: 'Heritage Institute-Ft Myers',
  },
  {
    institution: 'La Belle Beauty School',
  },
  {
    institution: 'Florida Gateway College',
  },
  {
    institution: 'Lake Technical Center',
  },
  {
    institution: 'Lake-Sumter State College',
  },
  {
    institution: 'Fort Myers Institute of Technology',
  },
  {
    institution: 'Lively Technical Center',
  },
  {
    institution: 'Lindsey Hopkins Technical Education Center',
  },
  {
    institution: 'Loraines Academy Inc',
  },
  {
    institution: 'Luther Rice University & Seminary',
  },
  {
    institution: 'State College of Florida-Manatee-Sarasota',
  },
  {
    institution: 'Manatee Technical Institute',
  },
  {
    institution: 'Manhattan Hairstyling Academy',
  },
  {
    institution: 'Traviss Career Center',
  },
  {
    institution: 'Trinity International University-Florida',
  },
  {
    institution: 'Miami Lakes Educational Center',
  },
  {
    institution: 'Miami Dade College',
  },
  {
    institution: 'University of Miami',
  },
  {
    institution: 'Mid Florida Tech',
  },
  {
    institution: 'Beauty Schools of America-Miami',
  },
  {
    institution: 'Remington College-Tampa Campus',
  },
  {
    institution: 'Everest Institute-North Miami',
  },
  {
    institution: 'Everest Institute-Hialeah',
  },
  {
    institution: 'Academy of Healing Arts Massage & Facial Skin Care',
  },
  {
    institution: 'Lincoln College of Technology-West Palm Beach',
  },
  {
    institution: 'Fortis College-Tampa',
  },
  {
    institution: 'Paul Mitchell the School-Jacksonville',
  },
  {
    institution: 'North Florida Community College',
  },
  {
    institution: 'University of North Florida',
  },
  {
    institution: 'Northwood University-Florida',
  },
  {
    institution: 'Nova Southeastern University',
  },
  {
    institution: 'Northwest Florida State College',
  },
  {
    institution: 'Orange Park International School of Beauty',
  },
  {
    institution: 'Everest University-North Orlando',
  },
  {
    institution: 'Orlando Tech',
  },
  {
    institution: 'Palm Beach Atlantic University',
  },
  {
    institution: 'Palm Beach State College',
  },
  {
    institution: 'Pasco-Hernando Community College',
  },
  {
    institution: 'Pensacola State College',
  },
  {
    institution: 'Pinellas Technical Education Center-Clearwater',
  },
  {
    institution: 'Polk State College',
  },
  {
    institution: 'Radford M Locklin Technical Center',
  },
  {
    institution: 'Saint Vincent de Paul Regional Seminary',
  },
  {
    institution: 'Ridge Career Center',
  },
  {
    institution: 'Ringling College of Art and Design',
  },
  {
    institution: 'Toni & Guy Hairdressing Academy-Jacksonville',
  },
  {
    institution: 'Robert Morgan Educational Center',
  },
  {
    institution: 'Rollins College',
  },
  {
    institution: 'First Coast Technical College',
  },
  {
    institution: 'Saint Leo University',
  },
  {
    institution: 'St Petersburg College',
  },
  {
    institution: 'Pinellas Technical Education Center-St Petersburg',
  },
  {
    institution: 'Santa Fe College',
  },
  {
    institution: 'Sarasota County Technical Institute',
  },
  {
    institution: 'Argosy University-Sarasota',
  },
  {
    institution: 'Seminole State College of Florida',
  },
  {
    institution: 'Sheridan Technical Center',
  },
  {
    institution: 'Saint John Vianney College Seminary',
  },
  {
    institution: 'Saint Johns River State College',
  },
  {
    institution: 'South Florida State College',
  },
  {
    institution: 'University of South Florida-Main Campus',
  },
  {
    institution: 'St Thomas University',
  },
  {
    institution: 'Stenotype Institute of Jacksonville Inc-Jacksonville',
  },
  {
    institution: 'Stetson University',
  },
  {
    institution: 'Southeastern University',
  },
  {
    institution: 'Benes International School of Beauty',
  },
  {
    institution: 'Suwannee-Hamilton Technical Center',
  },
  {
    institution: 'Tallahassee Community College',
  },
  {
    institution: 'Talmudic College of Florida',
  },
  {
    institution: 'Everest University-Tampa',
  },
  {
    institution: 'Everest University-Largo',
  },
  {
    institution: 'The University of Tampa',
  },
  {
    institution: 'Taylor Technical Institute',
  },
  {
    institution: 'Tom P Haney Technical Center',
  },
  {
    institution: 'Trinity Baptist College',
  },
  {
    institution: 'Trinity College of Florida',
  },
  {
    institution: 'Fashion Focus Hair Academy',
  },
  {
    institution: 'Valencia College',
  },
  {
    institution: 'Warner University',
  },
  {
    institution: 'Washington-Holmes Technical Center',
  },
  {
    institution: 'Webber International University',
  },
  {
    institution: 'Rasmussen College-Florida',
  },
  {
    institution: 'The University of West Florida',
  },
  {
    institution: 'Westside Tech',
  },
  {
    institution: 'William T McFatter Technical Center',
  },
  {
    institution: 'Winter Park Tech',
  },
  {
    institution: 'Withlacoochee Technical Institute',
  },
  {
    institution: 'Abraham Baldwin Agricultural College',
  },
  {
    institution: 'Agnes Scott College',
  },
  {
    institution: 'Interactive College of Technology-Chamblee',
  },
  {
    institution: 'Interactive College of Technology-Morrow',
  },
  {
    institution: 'Albany Technical College',
  },
  {
    institution: 'Darton State College',
  },
  {
    institution: 'Albany State University',
  },
  {
    institution: 'Andrew College',
  },
  {
    institution: 'Armstrong Atlantic State University',
  },
  {
    institution: 'The Art Institute of Atlanta',
  },
  {
    institution: 'Georgia Career Institute',
  },
  {
    institution: 'Atlanta Technical College',
  },
  {
    institution: 'Point University',
  },
  {
    institution: 'Atlanta Metropolitan State College',
  },
  {
    institution: "Atlanta's John Marshall Law School",
  },
  {
    institution: 'Atlanta School of Massage',
  },
  {
    institution: 'Clark Atlanta University',
  },
  {
    institution: 'Augusta Technical College',
  },
  {
    institution: 'Augusta State University',
  },
  {
    institution: 'Bainbridge State College',
  },
  {
    institution: 'Bauder College',
  },
  {
    institution: 'Beauty College of America',
  },
  {
    institution: 'Berry College',
  },
  {
    institution: 'Beulah Heights University',
  },
  {
    institution: 'Brenau University',
  },
  {
    institution: 'Brewton-Parker College',
  },
  {
    institution: 'Brown College of Court Reporting',
  },
  {
    institution: 'College of Coastal Georgia',
  },
  {
    institution: 'West Georgia Technical College',
  },
  {
    institution: 'Carver Bible College',
  },
  {
    institution: 'Clayton  State University',
  },
  {
    institution: 'Columbia Theological Seminary',
  },
  {
    institution: 'Columbus Technical College',
  },
  {
    institution: 'Columbus State University',
  },
  {
    institution: 'Georgia Northwestern Technical College',
  },
  {
    institution: 'Covenant College',
  },
  {
    institution: 'Dalton State College',
  },
  {
    institution: 'Pro Way Hair School',
  },
  {
    institution: 'DeVry University-Georgia',
  },
  {
    institution: 'South University-Savannah',
  },
  {
    institution: 'East Georgia State College',
  },
  {
    institution: 'Emmanuel College',
  },
  {
    institution: 'Emory University',
  },
  {
    institution: 'Georgia Highlands College',
  },
  {
    institution: 'Fort Valley State University',
  },
  {
    institution: 'Grady Health System Professional Schools',
  },
  {
    institution: 'Georgia Institute of Technology-Main Campus',
  },
  {
    institution: 'Georgia Southwestern State University',
  },
  {
    institution: 'Gainesville State College',
  },
  {
    institution: 'Georgia College and State University',
  },
  {
    institution: 'Georgia Military College',
  },
  {
    institution: 'Georgia Southern University',
  },
  {
    institution: 'Georgia State University',
  },
  {
    institution: 'University of Georgia',
  },
  {
    institution: 'Gordon State College',
  },
  {
    institution: 'Southern Crescent Technical College',
  },
  {
    institution: 'Gupton Jones College of Funeral Service',
  },
  {
    institution: 'Gwinnett College-Lilburn',
  },
  {
    institution: 'Gwinnett Technical College',
  },
  {
    institution: 'Middle Georgia Technical College',
  },
  {
    institution: 'Interdenominational Theological Center',
  },
  {
    institution: 'Kennesaw State University',
  },
  {
    institution: 'LaGrange College',
  },
  {
    institution: 'Lanier Technical College',
  },
  {
    institution: 'Life University',
  },
  {
    institution: 'Central Georgia Technical College',
  },
  {
    institution: 'Macon State College',
  },
  {
    institution: 'Chattahoochee Technical College',
  },
  {
    institution: 'Herzing University-Atlanta',
  },
  {
    institution: 'Georgia Health Sciences University',
  },
  {
    institution: 'Mercer University',
  },
  {
    institution: 'Middle Georgia College',
  },
  {
    institution: 'Morehouse College',
  },
  {
    institution: 'Morehouse School of Medicine',
  },
  {
    institution: 'Moultrie Technical College',
  },
  {
    institution: 'University of North Georgia',
  },
  {
    institution: 'North Georgia Technical College',
  },
  {
    institution: 'Oglethorpe University',
  },
  {
    institution: 'Paine College',
  },
  {
    institution: 'Piedmont College',
  },
  {
    institution: 'Portfolio Center',
  },
  {
    institution: 'Reinhardt University',
  },
  {
    institution: 'Savannah Technical College',
  },
  {
    institution: 'Savannah College of Art and Design',
  },
  {
    institution: 'Savannah State University',
  },
  {
    institution: 'Shorter University',
  },
  {
    institution: 'South Georgia College',
  },
  {
    institution: 'South Georgia Technical College',
  },
  {
    institution: 'The Creative Circus',
  },
  {
    institution: 'Spelman College',
  },
  {
    institution: 'Southern Polytechnic State University',
  },
  {
    institution: 'Southwest Georgia Technical College',
  },
  {
    institution: 'Thomas University',
  },
  {
    institution: 'Toccoa Falls College',
  },
  {
    institution: 'Truett-McConnell College',
  },
  {
    institution: 'Wiregrass Georgia Technical College',
  },
  {
    institution: 'Valdosta State University',
  },
  {
    institution: 'Waycross College',
  },
  {
    institution: 'Wesleyan College',
  },
  {
    institution: 'University of West Georgia',
  },
  {
    institution: 'Young Harris College',
  },
  {
    institution: 'Heald College-Honolulu',
  },
  {
    institution: 'Chaminade University of Honolulu',
  },
  {
    institution: 'University of Hawaii at Hilo',
  },
  {
    institution: 'University of Hawaii at Manoa',
  },
  {
    institution: 'Hawaii Institute of Hair Design',
  },
  {
    institution: 'Hawaii Pacific University',
  },
  {
    institution: 'Honolulu Community College',
  },
  {
    institution: 'Kapiolani Community College',
  },
  {
    institution: 'Kauai Community College',
  },
  {
    institution: 'Leeward Community College',
  },
  {
    institution: 'University of Hawaii Maui College',
  },
  {
    institution: 'Med-Assist School of Hawaii Inc',
  },
  {
    institution: 'World Medicine Institute',
  },
  {
    institution: 'Travel Institute of the Pacific',
  },
  {
    institution: 'University of Hawaii System Office',
  },
  {
    institution: 'University of Hawaii-West Oahu',
  },
  {
    institution: 'Windward Community College',
  },
  {
    institution: 'Carrington College-Boise',
  },
  {
    institution: 'Boise Bible College',
  },
  {
    institution: 'Boise State University',
  },
  {
    institution: 'Eastern Idaho Technical College',
  },
  {
    institution: 'Idaho State University',
  },
  {
    institution: 'University of Idaho',
  },
  {
    institution: 'The College of Idaho',
  },
  {
    institution: 'Lewis-Clark State College',
  },
  {
    institution: 'ITT Technical Institute-Boise',
  },
  {
    institution: 'Joseph Charles Institute of Cosmetology',
  },
  {
    institution: "Mr Leon's School of Hair Design-Moscow",
  },
  {
    institution: 'North Idaho College',
  },
  {
    institution: 'Northwest Nazarene University',
  },
  {
    institution: 'The School of Hairstyling',
  },
  {
    institution: 'Brigham Young University-Idaho',
  },
  {
    institution: 'College of Southern Idaho',
  },
  {
    institution: 'Adler School of Professional Psychology',
  },
  {
    institution: 'Alvareitas College of Cosmetology-Edwardsville',
  },
  {
    institution: 'Alvareitas College of Cosmetology-Godfrey',
  },
  {
    institution: 'American Academy of Art',
  },
  {
    institution: 'School of the Art Institute of Chicago',
  },
  {
    institution: 'The College of Office Technology',
  },
  {
    institution: 'Augustana College',
  },
  {
    institution: 'Aurora University',
  },
  {
    institution: 'Concept College of Cosmetology',
  },
  {
    institution: 'Southwestern Illinois College',
  },
  {
    institution: 'Bethany Theological Seminary',
  },
  {
    institution: 'Black Hawk College',
  },
  {
    institution: 'Blackburn College',
  },
  {
    institution: 'Blessing Rieman College of Nursing',
  },
  {
    institution: 'Blessing Hospital School of Radiologic Technology',
  },
  {
    institution: 'Bradley University',
  },
  {
    institution: 'Paul Mitchell the School-Bradley',
  },
  {
    institution: 'Cameo Beauty Academy',
  },
  {
    institution: 'Cannella School of Hair Design-Villa Park',
  },
  {
    institution: 'Cannella School of Hair Design-Chicago',
  },
  {
    institution: 'Cannella School of Hair Design-Chicago',
  },
  {
    institution: 'Cannella School of Hair Design-Elgin',
  },
  {
    institution: 'Capri Beauty College',
  },
  {
    institution: 'Empire Beauty School-Hanover Park',
  },
  {
    institution: 'Capri Beauty College',
  },
  {
    institution: 'Carl Sandburg College',
  },
  {
    institution: 'Catholic Theological Union at Chicago',
  },
  {
    institution: 'La James International College-East Moline',
  },
  {
    institution: 'Midwestern University-Downers Grove',
  },
  {
    institution: 'The Chicago School of Professional Psychology at Chicago',
  },
  {
    institution: 'Chicago State University',
  },
  {
    institution: 'Chicago Theological Seminary',
  },
  {
    institution: 'University of Chicago',
  },
  {
    institution: 'City Colleges of Chicago-Kennedy-King College',
  },
  {
    institution: 'City Colleges of Chicago-Malcolm X College',
  },
  {
    institution: 'City Colleges of Chicago-Olive-Harvey College',
  },
  {
    institution: 'City Colleges of Chicago-Harry S Truman College',
  },
  {
    institution: 'City Colleges of Chicago-Richard J Daley College',
  },
  {
    institution: 'City Colleges of Chicago-Harold Washington College',
  },
  {
    institution: 'City Colleges of Chicago-Wilbur Wright College',
  },
  {
    institution: 'Columbia College-Chicago',
  },
  {
    institution: 'Concordia University-Chicago',
  },
  {
    institution: 'Le Cordon Bleu College of Culinary Arts-Chicago',
  },
  {
    institution: 'Coyne College',
  },
  {
    institution: 'City Colleges of Chicago-District Office',
  },
  {
    institution: 'Danville Area Community College',
  },
  {
    institution: 'Concept College of Cosmetology',
  },
  {
    institution: 'DePaul University',
  },
  {
    institution: 'DeVry University-Illinois',
  },
  {
    institution: 'DeVry University-Administrative Office',
  },
  {
    institution: 'Cosmetology & Spa Academy',
  },
  {
    institution: 'College of DuPage',
  },
  {
    institution: 'East-West University',
  },
  {
    institution: 'Eastern Illinois University',
  },
  {
    institution: 'Elgin Community College',
  },
  {
    institution: 'Elmhurst College',
  },
  {
    institution: 'Eureka College',
  },
  {
    institution: 'First Institute Inc',
  },
  {
    institution: 'Fox College',
  },
  {
    institution: 'Garrett-Evangelical Theological Seminary',
  },
  {
    institution: 'Gem City College',
  },
  {
    institution: 'Governors State University',
  },
  {
    institution: 'Graham Hospital School of Nursing',
  },
  {
    institution: 'Greenville College',
  },
  {
    institution: 'Hair Professionals Academy of Cosmetology',
  },
  {
    institution: 'Hair Professionals Career College',
  },
  {
    institution: 'Hair Professionals School of Cosmetology',
  },
  {
    institution: 'Harrington College of Design',
  },
  {
    institution: 'Hebrew Theological College',
  },
  {
    institution: 'Highland Community College',
  },
  {
    institution: 'Rosalind Franklin University of Medicine and Science',
  },
  {
    institution: 'University of Illinois at Chicago',
  },
  {
    institution: 'Benedictine University',
  },
  {
    institution: 'Illinois College of Optometry',
  },
  {
    institution: 'University of Illinois at Urbana-Champaign',
  },
  {
    institution: 'Illinois Wesleyan University',
  },
  {
    institution: 'Illinois Central College',
  },
  {
    institution: 'Illinois College',
  },
  {
    institution: 'Olney Central College',
  },
  {
    institution: 'Illinois Institute of Technology',
  },
  {
    institution: 'Argosy University-Chicago',
  },
  {
    institution: 'Illinois State University',
  },
  {
    institution: 'Illinois Valley Community College',
  },
  {
    institution: 'Institute for Clinical Social Work',
  },
  {
    institution: 'International Academy of Design and Technology-Chicago',
  },
  {
    institution: 'John A Logan College',
  },
  {
    institution: 'The John Marshall Law School',
  },
  {
    institution: 'John Wood Community College',
  },
  {
    institution: 'Joliet Junior College',
  },
  {
    institution: 'Judson University',
  },
  {
    institution: 'Kankakee Community College',
  },
  {
    institution: 'Kaskaskia College',
  },
  {
    institution:
      "DeVry University's Keller Graduate School of Management-Illinois",
  },
  {
    institution: 'Kendall College',
  },
  {
    institution: 'Kishwaukee College',
  },
  {
    institution: 'Knox College',
  },
  {
    institution: 'Educators of Beauty College of Cosmetology-La Salle',
  },
  {
    institution: 'College of Lake County',
  },
  {
    institution: 'Lake Forest College',
  },
  {
    institution: 'Lake Forest Graduate School of Management',
  },
  {
    institution: 'Lake Land College',
  },
  {
    institution: 'Lakeview College of Nursing',
  },
  {
    institution: 'Lewis and Clark Community College',
  },
  {
    institution: 'Lewis University',
  },
  {
    institution: 'Lexington College',
  },
  {
    institution: 'Lincoln Christian University',
  },
  {
    institution: 'Lincoln College',
  },
  {
    institution: 'Lincoln Land Community College',
  },
  {
    institution: 'Lincoln College of Technology-Melrose Park',
  },
  {
    institution: 'Loyola University Chicago',
  },
  {
    institution: 'Lutheran School of Theology at Chicago',
  },
  {
    institution: 'Trinity College of Nursing & Health Sciences',
  },
  {
    institution: 'CET-Chicago',
  },
  {
    institution: 'Mac Daniels Beauty School',
  },
  {
    institution: 'MacCormac College',
  },
  {
    institution: 'MacMurray College',
  },
  {
    institution: 'McCormick Theological Seminary',
  },
  {
    institution: 'McHenry County College',
  },
  {
    institution: 'McKendree University',
  },
  {
    institution: 'Meadville Lombard Theological School',
  },
  {
    institution: 'Methodist College',
  },
  {
    institution: 'Midstate College',
  },
  {
    institution: 'Millikin University',
  },
  {
    institution: 'Monmouth College',
  },
  {
    institution: 'Moody Bible Institute',
  },
  {
    institution: 'Moraine Valley Community College',
  },
  {
    institution: 'Morrison Institute of Technology',
  },
  {
    institution: 'Morton College',
  },
  {
    institution: "Mr John's School of Cosmetology Esthetics & Nails-Decatur",
  },
  {
    institution: 'National Louis University',
  },
  {
    institution: 'National University of Health Sciences',
  },
  {
    institution: 'Niles School of Cosmetology',
  },
  {
    institution: 'North Central College',
  },
  {
    institution: 'North Park University',
  },
  {
    institution: 'Northern Baptist Theological Seminary',
  },
  {
    institution: 'Northern Illinois University',
  },
  {
    institution: 'Northwestern College-Chicago Campus',
  },
  {
    institution: 'Northwestern University',
  },
  {
    institution: 'Northeastern Illinois University',
  },
  {
    institution: 'Oakton Community College',
  },
  {
    institution: 'Oehrlein School of Cosmetology',
  },
  {
    institution: 'Olivet Nazarene University',
  },
  {
    institution: 'Parkland College',
  },
  {
    institution: 'Pivot Point Academy-Evanston',
  },
  {
    institution: 'Prairie State College',
  },
  {
    institution: 'Principia College',
  },
  {
    institution: "Professional's Choice Hair Design Academy",
  },
  {
    institution: 'Quincy University',
  },
  {
    institution: 'Vatterott College-Quincy',
  },
  {
    institution: 'The Illinois Institute of Art-Chicago',
  },
  {
    institution: 'Rend Lake College',
  },
  {
    institution: 'Richland Community College',
  },
  {
    institution: 'Robert Morris University Illinois',
  },
  {
    institution: 'Rock Valley College',
  },
  {
    institution: 'Madison Media Institute - Rockford Career College',
  },
  {
    institution: 'Rockford University',
  },
  {
    institution: 'Roosevelt University',
  },
  {
    institution: 'Dominican University',
  },
  {
    institution: 'Rush University',
  },
  {
    institution: 'Saint Francis Medical Center College of Nursing',
  },
  {
    institution: 'University of St Francis',
  },
  {
    institution: "St John's College of Nursing",
  },
  {
    institution: 'Saint Xavier University',
  },
  {
    institution: 'University of Illinois at Springfield',
  },
  {
    institution: 'Sauk Valley Community College',
  },
  {
    institution: 'Seabury-Western Theological Seminary',
  },
  {
    institution: 'Shawnee Community College',
  },
  {
    institution: 'Shimer College',
  },
  {
    institution: 'Saint Augustine College',
  },
  {
    institution: 'University of Saint Mary of the Lake',
  },
  {
    institution: 'Southeastern Illinois College',
  },
  {
    institution: 'National Latino Education Institute',
  },
  {
    institution: 'Spertus College',
  },
  {
    institution: 'Spoon River College',
  },
  {
    institution: 'Saint Anthony College of Nursing',
  },
  {
    institution: 'Educators of Beauty College of Cosmetology-Sterling',
  },
  {
    institution: 'Southern Illinois University-Carbondale',
  },
  {
    institution: 'Southern Illinois University-Edwardsville',
  },
  {
    institution: 'Southern Illinois University-System Office',
  },
  {
    institution: 'Taylor Business Institute',
  },
  {
    institution: 'Telshe Yeshiva-Chicago',
  },
  {
    institution: 'South Suburban College',
  },
  {
    institution: 'Tri-County Beauty Academy',
  },
  {
    institution: 'Trinity Christian College',
  },
  {
    institution: 'Trinity International University-Illinois',
  },
  {
    institution: 'Triton College',
  },
  {
    institution: 'University of Spa & Cosmetology Arts',
  },
  {
    institution: 'University of Illinois University Administration',
  },
  {
    institution: 'VanderCook College of Music',
  },
  {
    institution: 'Waubonsee Community College',
  },
  {
    institution: 'Resurrection University',
  },
  {
    institution: 'Western Illinois University',
  },
  {
    institution: 'Wheaton College',
  },
  {
    institution: 'Harper College',
  },
  {
    institution: 'Ancilla College',
  },
  {
    institution: 'Anderson University',
  },
  {
    institution: 'Apex Academy of Hair Design Inc',
  },
  {
    institution: 'Ball State University',
  },
  {
    institution: 'Bethel College-Indiana',
  },
  {
    institution: 'Butler University',
  },
  {
    institution: 'Calumet College of Saint Joseph',
  },
  {
    institution: 'Christian Theological Seminary',
  },
  {
    institution: 'College of Court Reporting Inc',
  },
  {
    institution: 'Concordia Theological Seminary',
  },
  {
    institution: 'Tricoci University of Beauty Culture-Highland',
  },
  {
    institution: 'DePauw University',
  },
  {
    institution: 'Don Roberts School of Hair Design',
  },
  {
    institution: 'Don Roberts Beauty School',
  },
  {
    institution: 'Earlham College',
  },
  {
    institution: 'University of Evansville',
  },
  {
    institution: 'Franklin College',
  },
  {
    institution: 'Good Samaritan Hospital School of Radiologic Technology',
  },
  {
    institution: 'Goshen College',
  },
  {
    institution: 'Grace College and Theological Seminary',
  },
  {
    institution: 'Hair Fashions By Kaye Beauty College-Noblesville',
  },
  {
    institution: 'Hair Fashions By Kaye Beauty College-Indianapolis',
  },
  {
    institution: 'Hanover College',
  },
  {
    institution: 'J Michael Harrold Beauty Academy',
  },
  {
    institution: 'Holy Cross College',
  },
  {
    institution: "PJ's College of Cosmetology-Clarksville",
  },
  {
    institution: 'Huntington University',
  },
  {
    institution: 'Ivy Tech Community College',
  },
  {
    institution: 'Indiana University-Purdue University-Fort Wayne',
  },
  {
    institution: 'Indiana University-Purdue University-Indianapolis',
  },
  {
    institution: 'Harrison College-Indianapolis',
  },
  {
    institution: 'University of Indianapolis',
  },
  {
    institution: 'Indiana Institute of Technology',
  },
  {
    institution: 'University of Southern Indiana',
  },
  {
    institution: 'Indiana State University',
  },
  {
    institution: 'Indiana University-Kokomo',
  },
  {
    institution: 'Indiana University-South Bend',
  },
  {
    institution: 'Indiana University-Bloomington',
  },
  {
    institution: 'Indiana University-Northwest',
  },
  {
    institution: 'Indiana University-Southeast',
  },
  {
    institution: 'Indiana University-East',
  },
  {
    institution: 'International Business College-Fort Wayne',
  },
  {
    institution: 'International Business College-Indianapolis',
  },
  {
    institution: 'ITT Educational Services Inc-System Office',
  },
  {
    institution: 'ITT Technical Institute-Nashville',
  },
  {
    institution: 'ITT Technical Institute-Fort Wayne',
  },
  {
    institution: 'ITT Technical Institute-Indianapolis',
  },
  {
    institution: 'J Everett Light Career Center',
  },
  {
    institution: 'Knox Beauty College',
  },
  {
    institution: 'Lafayette Beauty Academy',
  },
  {
    institution: 'Brown Mackie College-Merrillville',
  },
  {
    institution: 'Brown Mackie College-Michigan City',
  },
  {
    institution: 'Empire Beauty School-Indianapolis',
  },
  {
    institution: 'Lincoln College of Technology-Indianapolis',
  },
  {
    institution: 'Manchester University',
  },
  {
    institution: 'Marian University',
  },
  {
    institution: 'Indiana Wesleyan University',
  },
  {
    institution: 'Martin University',
  },
  {
    institution: 'Masters of Cosmetology College',
  },
  {
    institution: 'Anabaptist Mennonite Biblical Seminary',
  },
  {
    institution: 'Merrillville Beauty College',
  },
  {
    institution: 'Brown Mackie College-South Bend',
  },
  {
    institution: 'Mid-America College of Funeral Service',
  },
  {
    institution: 'West Michigan College of Barbering and Beauty',
  },
  {
    institution: 'University of Notre Dame',
  },
  {
    institution: 'Oakland City University',
  },
  {
    institution: "PJ's College of Cosmetology-Richmond",
  },
  {
    institution: 'Kaplan College-Indianapolis',
  },
  {
    institution: 'Purdue University-Calumet Campus',
  },
  {
    institution: 'Purdue University-North Central Campus',
  },
  {
    institution: 'Ravenscroft Beauty College',
  },
  {
    institution: 'Rogers Academy of Hair Design',
  },
  {
    institution: 'Rose-Hulman Institute of Technology',
  },
  {
    institution: "Rudae's School of Beauty Culture-Kokomo",
  },
  {
    institution: 'University of Saint Francis-Fort Wayne',
  },
  {
    institution: 'Saint Josephs College',
  },
  {
    institution: 'Saint Mary-of-the-Woods College',
  },
  {
    institution: "Saint Mary's College",
  },
  {
    institution: 'Kaplan College-Hammond',
  },
  {
    institution: 'Kaplan College-Merrillville',
  },
  {
    institution: 'Saint Meinrad School of Theology',
  },
  {
    institution: 'Saint Elizabeth School of Nursing',
  },
  {
    institution: 'Taylor University',
  },
  {
    institution: 'Trine University',
  },
  {
    institution: 'Valparaiso University',
  },
  {
    institution: 'Vincennes Beauty College',
  },
  {
    institution: 'Vincennes University',
  },
  {
    institution: 'Wabash College',
  },
  {
    institution: 'Allen College',
  },
  {
    institution: 'AIB College of Business',
  },
  {
    institution: 'Briar Cliff University',
  },
  {
    institution: 'Buena Vista University',
  },
  {
    institution: 'Capri College-Dubuque',
  },
  {
    institution: 'Capri College-Cedar Rapids',
  },
  {
    institution: 'American College of Hairstyling-Cedar Rapids',
  },
  {
    institution: 'Central College',
  },
  {
    institution: 'Clarke University',
  },
  {
    institution: 'Coe College',
  },
  {
    institution: 'Cornell College',
  },
  {
    institution: 'Iowa School of Beauty-Marshalltown',
  },
  {
    institution: 'Des Moines Area Community College',
  },
  {
    institution: 'Divine Word College',
  },
  {
    institution: 'Dordt College',
  },
  {
    institution: 'Drake University',
  },
  {
    institution: 'University of Dubuque',
  },
  {
    institution: 'Ellsworth Community College',
  },
  {
    institution: 'Emmaus Bible College',
  },
  {
    institution: 'Eastern Iowa Community College District',
  },
  {
    institution: 'Faith Baptist Bible College and Theological Seminary',
  },
  {
    institution: 'Faust Institute of Cosmetology-Storm Lake',
  },
  {
    institution: 'Graceland University-Lamoni',
  },
  {
    institution: 'Grand View University',
  },
  {
    institution: 'Grinnell College',
  },
  {
    institution: 'Kaplan University-Mason City Campus',
  },
  {
    institution: 'Kaplan University-Cedar Rapids Campus',
  },
  {
    institution: 'Hamilton Technical College',
  },
  {
    institution: 'Hawkeye Community College',
  },
  {
    institution: 'American Hair Academy',
  },
  {
    institution: 'Indian Hills Community College',
  },
  {
    institution: 'Iowa Central Community College',
  },
  {
    institution: 'Iowa Lakes Community College',
  },
  {
    institution: 'UnityPoint Health-Des Moines School of Radiologic Technology',
  },
  {
    institution: 'American College of Hairstyling-Des Moines',
  },
  {
    institution: 'Iowa School of Beauty-Des Moines',
  },
  {
    institution: 'Iowa State University',
  },
  {
    institution: 'Iowa Wesleyan College',
  },
  {
    institution: 'Iowa Western Community College',
  },
  {
    institution: 'University of Iowa',
  },
  {
    institution: 'Kirkwood Community College',
  },
  {
    institution: 'La James International College-Cedar Falls',
  },
  {
    institution: 'La James International College-Johnston',
  },
  {
    institution: 'La James College of Hairstyling and Cosmetology',
  },
  {
    institution: 'La James International College-Ft Dodge',
  },
  {
    institution: 'Loras College',
  },
  {
    institution: 'Luther College',
  },
  {
    institution: 'Maharishi University of Management',
  },
  {
    institution: 'Marshalltown Community College',
  },
  {
    institution: 'Mercy College of Health Sciences',
  },
  {
    institution: 'Morningside College',
  },
  {
    institution: 'Mount Mercy University',
  },
  {
    institution: 'Ashford University',
  },
  {
    institution: 'North Iowa Area Community College',
  },
  {
    institution: 'University of Northern Iowa',
  },
  {
    institution: 'Northwestern College',
  },
  {
    institution: 'Northeast Iowa Community College-Calmar',
  },
  {
    institution: 'Northwest Iowa Community College',
  },
  {
    institution: 'Des Moines University-Osteopathic Medical Center',
  },
  {
    institution: 'Iowa School of Beauty-Ottumwa',
  },
  {
    institution: 'Palmer College of Chiropractic-Davenport',
  },
  {
    institution: 'PCI Academy-Ames',
  },
  {
    institution: 'Saint Ambrose University',
  },
  {
    institution: "St Luke's College",
  },
  {
    institution: 'La James International College-Davenport',
  },
  {
    institution: 'La James International College-Iowa City',
  },
  {
    institution: 'Simpson College',
  },
  {
    institution: 'Southeastern Community College',
  },
  {
    institution: 'Southwestern Community College',
  },
  {
    institution: 'Capri College-Davenport',
  },
  {
    institution: 'Iowa School of Beauty-Sioux City',
  },
  {
    institution: 'E Q School of Hair Design',
  },
  {
    institution: 'Upper Iowa University',
  },
  {
    institution: 'Waldorf College',
  },
  {
    institution: 'Wartburg College',
  },
  {
    institution: 'Wartburg Theological Seminary',
  },
  {
    institution: 'College of Hair Design',
  },
  {
    institution: 'Western Iowa Tech Community College',
  },
  {
    institution: 'William Penn University',
  },
  {
    institution: 'Allen County Community College',
  },
  {
    institution: 'Baker University',
  },
  {
    institution: 'Barton County Community College',
  },
  {
    institution: 'Benedictine College',
  },
  {
    institution: 'Bethany College',
  },
  {
    institution: 'Bethel College-North Newton',
  },
  {
    institution: 'Brown Mackie College-Kansas City',
  },
  {
    institution: 'Brown Mackie College-Salina',
  },
  {
    institution: 'Bryan University',
  },
  {
    institution: 'Butler Community College',
  },
  {
    institution: 'Central Baptist Theological Seminary',
  },
  {
    institution: 'Central Christian College of Kansas',
  },
  {
    institution: 'Cloud County Community College',
  },
  {
    institution: 'Coffeyville Community College',
  },
  {
    institution: 'Colby Community College',
  },
  {
    institution: 'Cowley County Community College',
  },
  {
    institution: 'Marinello Schools of Beauty-Manhattan',
  },
  {
    institution: 'Dodge City Community College',
  },
  {
    institution: 'Donnelly College',
  },
  {
    institution: 'Emporia State University',
  },
  {
    institution: 'Flint Hills Technical College',
  },
  {
    institution: 'Fort Hays State University',
  },
  {
    institution: 'Barclay College',
  },
  {
    institution: 'Friends University',
  },
  {
    institution: 'Fort Scott Community College',
  },
  {
    institution: 'Garden City Community College',
  },
  {
    institution: 'Haskell Indian Nations University',
  },
  {
    institution: 'Hays Academy of Hair Design',
  },
  {
    institution: 'Hesston College',
  },
  {
    institution: 'Highland Community College',
  },
  {
    institution: 'Hutchinson Community College',
  },
  {
    institution: 'Independence Community College',
  },
  {
    institution: 'Johnson County Community College',
  },
  {
    institution: 'Concorde Career Colleges',
  },
  {
    institution: 'Kansas City Kansas Community College',
  },
  {
    institution: 'University of Kansas',
  },
  {
    institution: 'Newman University',
  },
  {
    institution: 'Old Town Barber College-Wichita',
  },
  {
    institution: 'Kansas State University',
  },
  {
    institution: 'Kansas Wesleyan University',
  },
  {
    institution: 'Washburn Institute of Technology',
  },
  {
    institution: 'La Baron Hairdressing Academy-Overland Park',
  },
  {
    institution: 'Labette Community College',
  },
  {
    institution: 'Manhattan Area Technical College',
  },
  {
    institution: 'Manhattan Christian College',
  },
  {
    institution: 'McPherson College',
  },
  {
    institution: 'MidAmerica Nazarene University',
  },
  {
    institution: 'Neosho County Community College',
  },
  {
    institution: 'North Central Kansas Technical College',
  },
  {
    institution: 'Northwest Kansas Technical College',
  },
  {
    institution: 'Ottawa University-Ottawa',
  },
  {
    institution: 'Ottawa University-Kansas City',
  },
  {
    institution: 'Pittsburg State University',
  },
  {
    institution: 'Pratt Community College',
  },
  {
    institution: 'University of Saint Mary',
  },
  {
    institution: 'Salina Area Technical College',
  },
  {
    institution: 'Seward County Community College and Area Technical School',
  },
  {
    institution: 'Sidneys Hair Dressing College',
  },
  {
    institution: 'Southwestern College',
  },
  {
    institution: 'Sterling College',
  },
  {
    institution: 'Tabor College',
  },
  {
    institution: 'Washburn University',
  },
  {
    institution: 'Wichita Area Technical College',
  },
  {
    institution: 'Wichita State University',
  },
  {
    institution: 'Wichita Technical Institute',
  },
  {
    institution: 'Alice Lloyd College',
  },
  {
    institution: 'Asbury University',
  },
  {
    institution: 'Asbury Theological Seminary',
  },
  {
    institution: 'Ashland Community and Technical College',
  },
  {
    institution: 'Barrett and Company School of Hair Design',
  },
  {
    institution: 'Bellarmine University',
  },
  {
    institution: 'Berea College',
  },
  {
    institution: "PJ's College of Cosmetology-Bowling Green",
  },
  {
    institution: 'Southcentral Kentucky Community and Technical College',
  },
  {
    institution: 'Brescia University',
  },
  {
    institution: 'Campbellsville University',
  },
  {
    institution: 'Bluegrass Community and Technical College',
  },
  {
    institution: 'Centre College',
  },
  {
    institution: 'Clear Creek Baptist Bible College',
  },
  {
    institution: 'Paul Mitchell the School-Lexington',
  },
  {
    institution: 'Collins School of Cosmetology',
  },
  {
    institution: 'Galen College of Nursing-Louisville',
  },
  {
    institution: 'University of the Cumberlands',
  },
  {
    institution: 'Eastern Kentucky University',
  },
  {
    institution: 'Elizabethtown Community and Technical College',
  },
  {
    institution: "Ezell's Cosmetology School",
  },
  {
    institution: 'Frontier Nursing University',
  },
  {
    institution: 'Georgetown College',
  },
  {
    institution: "PJ's College of Cosmetology-Glasgow",
  },
  {
    institution: 'Hazard Community and Technical College',
  },
  {
    institution: 'Paul Mitchell the School-Louisville',
  },
  {
    institution: 'Henderson Community College',
  },
  {
    institution: 'Hopkinsville Community College',
  },
  {
    institution: 'Daymar College-Paducah Main',
  },
  {
    institution: 'Jefferson Community and Technical College',
  },
  {
    institution: 'Jenny Lea Academy of Cosmetology',
  },
  {
    institution: 'The Salon Professional Academy-Lexington',
  },
  {
    institution: 'National College-Lexington',
  },
  {
    institution: 'Kentucky Mountain Bible College',
  },
  {
    institution: 'Kentucky State University',
  },
  {
    institution: 'Kentucky Wesleyan College',
  },
  {
    institution: 'University of Kentucky',
  },
  {
    institution: 'Kentucky Christian University',
  },
  {
    institution: 'Lexington Theological Seminary',
  },
  {
    institution: 'Lindsey Wilson College',
  },
  {
    institution: 'Sullivan College of Technology and Design',
  },
  {
    institution: 'University of Louisville',
  },
  {
    institution: 'Louisville Presbyterian Theological Seminary',
  },
  {
    institution: 'Madisonville Community College',
  },
  {
    institution: 'Maysville Community and Technical College',
  },
  {
    institution: 'Mid-Continent University',
  },
  {
    institution: 'Midway College',
  },
  {
    institution: 'Morehead State University',
  },
  {
    institution: 'Murray State University',
  },
  {
    institution: 'Gateway Community and Technical College',
  },
  {
    institution: 'Northern Kentucky University',
  },
  {
    institution: 'Nutek Academy of Beauty Inc',
  },
  {
    institution: 'Daymar College-Owensboro',
  },
  {
    institution: 'West Kentucky Community and Technical College',
  },
  {
    institution: "Pat Wilson's Beauty College",
  },
  {
    institution: 'University of Pikeville',
  },
  {
    institution: 'Big Sandy Community and Technical College',
  },
  {
    institution: 'Brown Mackie College-Louisville',
  },
  {
    institution: 'Empire Beauty School-Chenoweth',
  },
  {
    institution: 'Saint Catharine College',
  },
  {
    institution: 'Empire Beauty School-Elizabethtown',
  },
  {
    institution: 'Empire Beauty School-Dixie',
  },
  {
    institution: 'Empire Beauty School-Florence',
  },
  {
    institution: 'Brown Mackie College-Northern Kentucky',
  },
  {
    institution: 'Somerset Community College',
  },
  {
    institution: 'Southeast Kentucky Community and Technical College',
  },
  {
    institution: 'The Southern Baptist Theological Seminary',
  },
  {
    institution: 'Spalding University',
  },
  {
    institution: 'Spencerian College-Louisville',
  },
  {
    institution: 'Sullivan University',
  },
  {
    institution: 'Thomas More College',
  },
  {
    institution: 'Transylvania University',
  },
  {
    institution: "Trend Setters' Academy of Beauty Culture-Louisville",
  },
  {
    institution: 'Kentucky Community and Technical College System',
  },
  {
    institution: 'Union College',
  },
  {
    institution: 'Western Kentucky University',
  },
  {
    institution: 'Central Louisiana Technical Community College',
  },
  {
    institution: 'American School of Business',
  },
  {
    institution: 'Ayers Career College',
  },
  {
    institution: 'Baton Rouge General Medical Center-School of Nursing',
  },
  {
    institution: 'Baton Rouge General Medical Center-Radiologic Technology',
  },
  {
    institution: 'Baton Rouge School of Computers',
  },
  {
    institution: 'Capital Area Technical College',
  },
  {
    institution: 'Bossier Parish Community College',
  },
  {
    institution: 'Cameron College',
  },
  {
    institution: 'Centenary College of Louisiana',
  },
  {
    institution: "Cloyd's Beauty School 1 Inc",
  },
  {
    institution: 'Crescent City Bartending School',
  },
  {
    institution: "D'Jay's School of Beauty Arts and Sciences",
  },
  {
    institution: 'Delgado Community College',
  },
  {
    institution: 'Delta School of Business and Technology',
  },
  {
    institution: 'Northeast Louisiana Technical College',
  },
  {
    institution: 'Demmons School of Beauty',
  },
  {
    institution: 'Denham Springs Beauty School',
  },
  {
    institution: 'Dillard University',
  },
  {
    institution: 'Nunez Community College',
  },
  {
    institution: 'Grambling State University',
  },
  {
    institution: "Guy's Shreveport Academy of Cosmetology Inc",
  },
  {
    institution: 'ITI Technical College',
  },
  {
    institution: 'John Jay Beauty College',
  },
  {
    institution: 'John Jay Beauty College',
  },
  {
    institution:
      'Louisiana State University Health Sciences Center-New Orleans',
  },
  {
    institution: 'Louisiana State University-Alexandria',
  },
  {
    institution:
      'Louisiana State University and Agricultural & Mechanical College',
  },
  {
    institution: 'Louisiana State University-Eunice',
  },
  {
    institution: 'Louisiana State University-Shreveport',
  },
  {
    institution: 'Acadiana Technical College-Lafayette Campus',
  },
  {
    institution: 'Aveda Institute-Baton Rouge',
  },
  {
    institution: 'Aveda Institute-Lafayette',
  },
  {
    institution: 'Louisiana Academy of Beauty',
  },
  {
    institution: 'Louisiana College',
  },
  {
    institution: 'Opelousas School of Cosmetology Inc',
  },
  {
    institution: 'Louisiana State University-System Office',
  },
  {
    institution: 'Louisiana Tech University',
  },
  {
    institution: 'Loyola University New Orleans',
  },
  {
    institution: 'McNeese State University',
  },
  {
    institution: 'University of New Orleans',
  },
  {
    institution: 'New Orleans Baptist Theological Seminary',
  },
  {
    institution: 'Nicholls State University',
  },
  {
    institution: 'University of Louisiana at Monroe',
  },
  {
    institution: 'Northwest Louisiana Technical College',
  },
  {
    institution: 'Notre Dame Seminary Graduate School of Theology',
  },
  {
    institution: 'Northwestern State University of Louisiana',
  },
  {
    institution: 'Our Lady of Holy Cross College',
  },
  {
    institution: 'Our Lady of the Lake College',
  },
  {
    institution: 'Pat Goins Benton Road Beauty School',
  },
  {
    institution: 'Pat Goins Monroe Beauty School',
  },
  {
    institution: 'Pat Goins Ruston Beauty School',
  },
  {
    institution: 'Pineville Beauty School',
  },
  {
    institution: 'Aveda Institute-Covington',
  },
  {
    institution: 'Saint Joseph Seminary College',
  },
  {
    institution: 'Vanguard College of Cosmetology-Slidell',
  },
  {
    institution: 'South Louisiana Beauty College',
  },
  {
    institution: 'L E Fletcher Technical Community College',
  },
  {
    institution: 'Remington College-Lafayette Campus',
  },
  {
    institution: 'Southern University-Board and System',
  },
  {
    institution: 'SOWELA Technical Community College',
  },
  {
    institution: 'Stevensons Academy of Hair Design',
  },
  {
    institution: 'Southeastern Louisiana University',
  },
  {
    institution: 'Southern University and A & M College',
  },
  {
    institution: 'Southern University at New Orleans',
  },
  {
    institution: 'Southern University at Shreveport',
  },
  {
    institution: 'University of Louisiana at Lafayette',
  },
  {
    institution: 'Northshore Technical Community College',
  },
  {
    institution: 'Tulane University of Louisiana',
  },
  {
    institution: 'Xavier University of Louisiana',
  },
  {
    institution:
      'South Central Louisiana Technical College-Young Memorial Campus',
  },
  {
    institution: 'Kaplan University-Maine Campus',
  },
  {
    institution: 'College of the Atlantic',
  },
  {
    institution: 'Bangor Theological Seminary',
  },
  {
    institution: 'Bates College',
  },
  {
    institution: 'Beal College',
  },
  {
    institution: 'Bowdoin College',
  },
  {
    institution:
      'Central Maine Medical Center College of Nursing and Health Professions',
  },
  {
    institution: 'Central Maine Community College',
  },
  {
    institution: 'Colby College',
  },
  {
    institution: 'Eastern Maine Community College',
  },
  {
    institution: 'Husson University',
  },
  {
    institution: 'Kennebec Valley Community College',
  },
  {
    institution: 'The Landing School',
  },
  {
    institution: 'University of Maine at Augusta',
  },
  {
    institution: 'University of Maine at Farmington',
  },
  {
    institution: 'University of Maine at Fort Kent',
  },
  {
    institution: 'University of Maine at Machias',
  },
  {
    institution: 'University of Maine',
  },
  {
    institution: 'University of Maine-System Central Office',
  },
  {
    institution: 'Maine Maritime Academy',
  },
  {
    institution: 'University of Maine at Presque Isle',
  },
  {
    institution: 'University of New England',
  },
  {
    institution: 'Northern Maine Community College',
  },
  {
    institution: 'Empire Beauty School-Maine',
  },
  {
    institution: 'Maine College of Art',
  },
  {
    institution: "Saint Joseph's College of Maine",
  },
  {
    institution: 'Spa Tech Institute-Westbrook',
  },
  {
    institution: 'Southern Maine Community College',
  },
  {
    institution: 'University of Southern Maine',
  },
  {
    institution: 'Thomas College',
  },
  {
    institution: 'Unity College',
  },
  {
    institution: 'Washington County Community College',
  },
  {
    institution: "Aaron's Academy of Beauty",
  },
  {
    institution: 'Aesthetics Institute of Cosmetology',
  },
  {
    institution: 'Allegany College of Maryland',
  },
  {
    institution: 'Anne Arundel Community College',
  },
  {
    institution: 'TESST College of Technology-Towson',
  },
  {
    institution: 'Award Beauty School',
  },
  {
    institution: 'Baltimore Studio of Hair Design',
  },
  {
    institution: 'Baltimore City Community College',
  },
  {
    institution: 'University of Baltimore',
  },
  {
    institution: 'Bowie State University',
  },
  {
    institution: 'Capitol College',
  },
  {
    institution: 'Cecil College',
  },
  {
    institution: 'College of Southern Maryland',
  },
  {
    institution: 'Chesapeake College',
  },
  {
    institution: 'Washington Adventist University',
  },
  {
    institution: 'Coppin State University',
  },
  {
    institution: 'Delmarva Beauty Academy',
  },
  {
    institution: 'North American Trade Schools',
  },
  {
    institution: 'Frederick Community College',
  },
  {
    institution: 'Frostburg State University',
  },
  {
    institution: 'Garrett College',
  },
  {
    institution: 'Goucher College',
  },
  {
    institution: 'Kaplan University-Hagerstown Campus',
  },
  {
    institution: 'Hagerstown Community College',
  },
  {
    institution: 'Harford Community College',
  },
  {
    institution: 'Hood College',
  },
  {
    institution: 'Howard Community College',
  },
  {
    institution: 'The Colorlab Academy of Hair',
  },
  {
    institution: 'Johns Hopkins University',
  },
  {
    institution: "L'Academie de Cuisine",
  },
  {
    institution: 'Lincoln College of Technology-Columbia',
  },
  {
    institution: 'Loyola University Maryland',
  },
  {
    institution: 'Maryland Beauty Academy of Reisterstown',
  },
  {
    institution: 'University of Maryland-University College',
  },
  {
    institution: 'University of Maryland-Baltimore',
  },
  {
    institution: 'University of Maryland-Baltimore County',
  },
  {
    institution: 'University of Maryland-College Park',
  },
  {
    institution: 'Maryland Institute College of Art',
  },
  {
    institution: 'University of Maryland Eastern Shore',
  },
  {
    institution: 'Fortis Institute-Towson',
  },
  {
    institution: 'Montgomery Beauty School',
  },
  {
    institution: 'Montgomery College',
  },
  {
    institution: 'Morgan State University',
  },
  {
    institution: "Mount St Mary's University",
  },
  {
    institution: 'Ner Israel Rabbinical College',
  },
  {
    institution: 'Hair Academy Inc-New Carrollton',
  },
  {
    institution: 'Notre Dame of Maryland University',
  },
  {
    institution: "Prince George's Community College",
  },
  {
    institution: 'TESST College of Technology-Baltimore',
  },
  {
    institution: 'Robert Paul Academy of Cosmetology Arts & Sciences',
  },
  {
    institution: 'Maryland Beauty Academy of Essex',
  },
  {
    institution: "St Mary's Seminary & University",
  },
  {
    institution: 'Salisbury University',
  },
  {
    institution: "St Mary's College of Maryland",
  },
  {
    institution: 'Sojourner-Douglass College',
  },
  {
    institution: "St John's College",
  },
  {
    institution: 'TESST College of Technology-Beltsville',
  },
  {
    institution: 'Towson University',
  },
  {
    institution: 'Maryland University of Integrative Health',
  },
  {
    institution: 'International Beauty School',
  },
  {
    institution: 'University System of Maryland',
  },
  {
    institution: 'United States Naval Academy',
  },
  {
    institution: 'Stevenson University',
  },
  {
    institution: 'Washington Bible College-Capital Bible Seminary',
  },
  {
    institution: 'Washington College',
  },
  {
    institution: 'Washington Theological Union',
  },
  {
    institution: 'McDaniel College',
  },
  {
    institution: 'Wor-Wic Community College',
  },
  {
    institution: 'Hult International Business School',
  },
  {
    institution: 'New England College of Business and Finance',
  },
  {
    institution: 'American International College',
  },
  {
    institution: 'Amherst College',
  },
  {
    institution: 'Andover Newton Theological School',
  },
  {
    institution: 'Anna Maria College',
  },
  {
    institution: 'Assabet Valley Regional Technical School',
  },
  {
    institution: 'Assumption College',
  },
  {
    institution: 'Babson College',
  },
  {
    institution: 'Bancroft School of Massage Therapy',
  },
  {
    institution: 'Boston Baptist College',
  },
  {
    institution: 'Bay Path College',
  },
  {
    institution: 'Bay State College',
  },
  {
    institution: 'Bay State School of Technology',
  },
  {
    institution: 'Becker College',
  },
  {
    institution: 'Bentley University',
  },
  {
    institution: 'Berklee College of Music',
  },
  {
    institution: 'Berkshire Community College',
  },
  {
    institution: 'Empire Beauty School-Boston',
  },
  {
    institution: 'Boston Architectural College',
  },
  {
    institution: 'Boston Graduate School of Psychoanalysis Inc',
  },
  {
    institution: 'Boston College',
  },
  {
    institution: 'The Boston Conservatory',
  },
  {
    institution: 'Boston University',
  },
  {
    institution: 'Brandeis University',
  },
  {
    institution: 'Bridgewater State University',
  },
  {
    institution: 'Bristol Community College',
  },
  {
    institution: 'Signature Healthcare Brockton Hospital School of Nursing',
  },
  {
    institution: 'Bunker Hill Community College',
  },
  {
    institution: 'Cambridge College',
  },
  {
    institution: 'Cape Cod Community College',
  },
  {
    institution: 'Catherine Hinds Institute of Esthetics',
  },
  {
    institution: 'Laboure College',
  },
  {
    institution: 'Clark University',
  },
  {
    institution: 'Conway School of Landscape Design',
  },
  {
    institution: 'Curry College',
  },
  {
    institution: 'Lincoln Technical Institute-Somerville',
  },
  {
    institution: 'Dean College',
  },
  {
    institution: 'Diman Regional Technical Institute',
  },
  {
    institution: 'National Aviation Academy of New England',
  },
  {
    institution: 'Eastern Nazarene College',
  },
  {
    institution: 'Emerson College',
  },
  {
    institution: 'Emmanuel College',
  },
  {
    institution: 'Endicott College',
  },
  {
    institution: 'Episcopal Divinity School',
  },
  {
    institution: 'Rob Roy Academy-Fall River',
  },
  {
    institution: 'Fisher College',
  },
  {
    institution: 'Fitchburg State University',
  },
  {
    institution: 'Framingham State University',
  },
  {
    institution: 'Benjamin Franklin Institute of Technology',
  },
  {
    institution: 'Gordon College',
  },
  {
    institution: 'Gordon-Conwell Theological Seminary',
  },
  {
    institution: 'Greenfield Community College',
  },
  {
    institution: 'Hallmark Institute of Photography',
  },
  {
    institution: 'Hampshire College',
  },
  {
    institution: 'Harvard University',
  },
  {
    institution: 'Hebrew College',
  },
  {
    institution:
      'Hellenic College-Holy Cross Greek Orthodox School of Theology',
  },
  {
    institution: 'Henris School of Hair Design',
  },
  {
    institution: 'College of the Holy Cross',
  },
  {
    institution: 'Holyoke Community College',
  },
  {
    institution: 'Sanford-Brown College-Boston',
  },
  {
    institution: 'Kay Harvey Academy of Hair Design',
  },
  {
    institution: 'La Baron Hairdressing Academy-Brockton',
  },
  {
    institution: 'La Baron Hairdressing Academy-New Bedford',
  },
  {
    institution: 'Lasell College',
  },
  {
    institution: 'Lawrence Memorial Hospital School of Nursing',
  },
  {
    institution: 'Lesley University',
  },
  {
    institution: 'Longy School of Music of Bard College',
  },
  {
    institution: 'Lowell Academy Hairstyling Institute',
  },
  {
    institution: 'University of Massachusetts-Lowell',
  },
  {
    institution: 'Mansfield Beauty Schools-Springfield',
  },
  {
    institution: 'Mansfield Beauty Schools-Quincy',
  },
  {
    institution: 'Marian Court College',
  },
  {
    institution: 'University of Massachusetts-Amherst',
  },
  {
    institution: 'University of Massachusetts-Boston',
  },
  {
    institution: 'Massachusetts Bay Community College',
  },
  {
    institution: 'MCPHS University',
  },
  {
    institution: 'University of Massachusetts-Central Office',
  },
  {
    institution: 'Massachusetts College of Art and Design',
  },
  {
    institution: 'Massachusetts Institute of Technology',
  },
  {
    institution: 'Massachusetts Maritime Academy',
  },
  {
    institution: 'University of Massachusetts Medical School Worcester',
  },
  {
    institution: 'Massachusetts School of Professional Psychology',
  },
  {
    institution: 'Massachusetts School of Barbering',
  },
  {
    institution: 'Massasoit Community College',
  },
  {
    institution: 'Merrimack College',
  },
  {
    institution: 'MGH Institute of Health Professions',
  },
  {
    institution: 'Middlesex Community College',
  },
  {
    institution: 'Montserrat College of Art',
  },
  {
    institution: 'Mount Holyoke College',
  },
  {
    institution: 'Mount Ida College',
  },
  {
    institution: 'Mount Wachusett Community College',
  },
  {
    institution: 'Cortiva Institute-Boston',
  },
  {
    institution: 'School of the Museum of Fine Arts-Boston',
  },
  {
    institution: 'Everest Institute-Brighton',
  },
  {
    institution: 'Rob Roy Academy-New Bedford',
  },
  {
    institution: 'The New England Conservatory of Music',
  },
  {
    institution: 'New England College of Optometry',
  },
  {
    institution: 'New England School of Acupuncture',
  },
  {
    institution: 'New England School of Law',
  },
  {
    institution: 'New England School of Photography',
  },
  {
    institution: 'Newbury College',
  },
  {
    institution: 'Nichols College',
  },
  {
    institution: 'Massachusetts College of Liberal Arts',
  },
  {
    institution: 'North Bennet Street School',
  },
  {
    institution: 'North Shore Community College',
  },
  {
    institution: 'The New England Institute of Art',
  },
  {
    institution: 'Northeastern University',
  },
  {
    institution: 'Northern Essex Community College',
  },
  {
    institution: 'College of Our Lady of the Elms',
  },
  {
    institution: 'Pine Manor College',
  },
  {
    institution: 'Blessed John XXIII National Seminary',
  },
  {
    institution: 'Quincy College',
  },
  {
    institution: 'Quinsigamond Community College',
  },
  {
    institution: 'Kaplan Career Institute-Charlestown',
  },
  {
    institution: 'Regis College',
  },
  {
    institution: 'Roxbury Community College',
  },
  {
    institution: "Saint John's Seminary",
  },
  {
    institution: 'Salem State University',
  },
  {
    institution: 'Salter College-West Boylston',
  },
  {
    institution: 'Simmons College',
  },
  {
    institution: "Bard College at Simon's Rock",
  },
  {
    institution: 'Smith College',
  },
  {
    institution: 'Southeastern Technical Institute',
  },
  {
    institution: 'Springfield College',
  },
  {
    institution: 'Springfield Technical Community College',
  },
  {
    institution: 'University of Massachusetts-Dartmouth',
  },
  {
    institution: 'Stonehill College',
  },
  {
    institution: 'Suffolk University',
  },
  {
    institution: 'Rob Roy Academy-Taunton',
  },
  {
    institution: 'Tufts University',
  },
  {
    institution: 'Wellesley College',
  },
  {
    institution: 'Wentworth Institute of Technology',
  },
  {
    institution: 'Western New England University',
  },
  {
    institution: 'Westfield State University',
  },
  {
    institution: 'Wheaton College',
  },
  {
    institution: 'Wheelock College',
  },
  {
    institution: 'Williams College',
  },
  {
    institution: 'Worcester Polytechnic Institute',
  },
  {
    institution: 'Worcester State University',
  },
  {
    institution: 'Adrian College',
  },
  {
    institution: 'Albion College',
  },
  {
    institution: 'Hillsdale Beauty College',
  },
  {
    institution: 'Northwestern Technological Institute',
  },
  {
    institution: 'Alma College',
  },
  {
    institution: 'Alpena Community College',
  },
  {
    institution: 'Andrews University',
  },
  {
    institution: 'Aquinas College',
  },
  {
    institution: 'Baker College of Owosso',
  },
  {
    institution: 'Baker College of Flint',
  },
  {
    institution: 'Bay de Noc Community College',
  },
  {
    institution: 'Bayshire Academy of Beauty Craft Inc',
  },
  {
    institution: 'Cadillac Institute of Cosmetology',
  },
  {
    institution: 'Calvin College',
  },
  {
    institution: 'Calvin Theological Seminary',
  },
  {
    institution: 'Carnegie Institute',
  },
  {
    institution: 'Michigan School of Professional Psychology',
  },
  {
    institution: 'Central Michigan University',
  },
  {
    institution: 'Mott Community College',
  },
  {
    institution: 'Empire Beauty School-Michigan',
  },
  {
    institution: 'Cleary University',
  },
  {
    institution: 'Concordia University-Ann Arbor',
  },
  {
    institution: 'Cranbrook Academy of Art',
  },
  {
    institution: 'College for Creative Studies',
  },
  {
    institution: 'Davenport University',
  },
  {
    institution: 'David Pressley School of Cosmetology',
  },
  {
    institution: 'Delta College',
  },
  {
    institution: 'Detroit Business Institute-Downriver',
  },
  {
    institution: 'Michigan State University-College of Law',
  },
  {
    institution: 'MIAT College of Technology',
  },
  {
    institution: 'University of Detroit Mercy',
  },
  {
    institution: 'Dorsey Business Schools-Madison Heights',
  },
  {
    institution: 'Dorsey Business Schools-Southgate',
  },
  {
    institution: 'Eastern Michigan University',
  },
  {
    institution: 'Ferris State University',
  },
  {
    institution: 'Flint Institute of Barbering Inc',
  },
  {
    institution: 'Glen Oaks Community College',
  },
  {
    institution: 'Kettering University',
  },
  {
    institution: 'Gogebic Community College',
  },
  {
    institution: 'Grace Bible College',
  },
  {
    institution: 'Cornerstone University',
  },
  {
    institution: 'Everest Institute-Grand Rapids',
  },
  {
    institution: 'Grand Rapids Community College',
  },
  {
    institution: 'Grand Valley State University',
  },
  {
    institution: 'Great Lakes Christian College',
  },
  {
    institution: 'Henry Ford Community College',
  },
  {
    institution: 'Hope College',
  },
  {
    institution: 'ITT Technical Institute-Wyoming',
  },
  {
    institution: 'Jackson College',
  },
  {
    institution: 'Wright Beauty Academy',
  },
  {
    institution: 'Kalamazoo College',
  },
  {
    institution: 'Kalamazoo Valley Community College',
  },
  {
    institution: 'Kellogg Community College',
  },
  {
    institution: 'Kirtland Community College',
  },
  {
    institution: 'Michigan College of Beauty-Monroe',
  },
  {
    institution: 'Lake Michigan College',
  },
  {
    institution: 'Lake Superior State University',
  },
  {
    institution: 'Lansing Community College',
  },
  {
    institution: 'Lawrence Technological University',
  },
  {
    institution: 'M J Murphy Beauty College of Mount Pleasant',
  },
  {
    institution: 'Macomb Community College',
  },
  {
    institution: 'Madonna University',
  },
  {
    institution: 'Marygrove College',
  },
  {
    institution: 'Rochester College',
  },
  {
    institution: 'University of Michigan-Ann Arbor',
  },
  {
    institution: 'Michigan Barber School Inc',
  },
  {
    institution: 'Michigan State University',
  },
  {
    institution: 'Michigan Technological University',
  },
  {
    institution: 'University of Michigan-Dearborn',
  },
  {
    institution: 'University of Michigan-Flint',
  },
  {
    institution: 'Mid Michigan Community College',
  },
  {
    institution: 'Monroe County Community College',
  },
  {
    institution: 'Montcalm Community College',
  },
  {
    institution: "Mr Bela's School of Cosmetology Inc",
  },
  {
    institution: 'Baker College of Muskegon',
  },
  {
    institution: 'Muskegon Community College',
  },
  {
    institution: 'Everest College-Skokie',
  },
  {
    institution: 'Everest Institute-Southfield',
  },
  {
    institution: 'North Central Michigan College',
  },
  {
    institution: 'Northern Michigan University',
  },
  {
    institution: 'Paul Mitchell the School-Escanaba',
  },
  {
    institution: 'Northwestern Michigan College',
  },
  {
    institution: 'Northwood University-Michigan',
  },
  {
    institution: 'Oakland Community College',
  },
  {
    institution: 'Oakland University',
  },
  {
    institution: 'Olivet College',
  },
  {
    institution: 'Port Huron Cosmetology College',
  },
  {
    institution: 'Kuyper College',
  },
  {
    institution: 'Ross Medical Education Center-Lansing',
  },
  {
    institution: 'Ross Medical Education Center-Madison Heights',
  },
  {
    institution: 'Ross Medical Education Center-Flint',
  },
  {
    institution: 'Sacred Heart Major Seminary',
  },
  {
    institution: 'Saginaw Valley State University',
  },
  {
    institution: 'Schoolcraft College',
  },
  {
    institution: 'Siena Heights University',
  },
  {
    institution: 'St Clair County Community College',
  },
  {
    institution: 'Southwestern Michigan College',
  },
  {
    institution: 'Specs Howard School of Media Arts',
  },
  {
    institution: 'Spring Arbor University',
  },
  {
    institution: 'Michigan Career and Technical Institute',
  },
  {
    institution: 'Finlandia University',
  },
  {
    institution: 'Thomas M Cooley Law Schoo',
  },
  {
    institution: 'Twin City Beauty College',
  },
  {
    institution: 'Walsh College of Accountancy and Business Administration',
  },
  {
    institution: 'Washtenaw Community College',
  },
  {
    institution: 'Wayne County Community College District',
  },
  {
    institution: 'Wayne State University',
  },
  {
    institution: 'West Shore Community College',
  },
  {
    institution: 'Western Michigan University',
  },
  {
    institution: 'Western Theological Seminary',
  },
  {
    institution: 'Wright Beauty Academy',
  },
  {
    institution: 'Academy College',
  },
  {
    institution: 'Alexandria Technical & Community College',
  },
  {
    institution: 'American Indian OIC Inc',
  },
  {
    institution: 'Anoka Technical College',
  },
  {
    institution: 'Anoka-Ramsey Community College',
  },
  {
    institution: 'Apostolic Bible Institute Inc',
  },
  {
    institution: 'Augsburg College',
  },
  {
    institution: 'Riverland Community College',
  },
  {
    institution: 'Northwest Technical College',
  },
  {
    institution: 'Bemidji State University',
  },
  {
    institution: 'Bethany Lutheran College',
  },
  {
    institution: 'Bethel University',
  },
  {
    institution: 'Bethel Seminary-St Paul',
  },
  {
    institution: 'Central Lakes College-Brainerd',
  },
  {
    institution: 'Carleton College',
  },
  {
    institution: 'Concordia College at Moorhead',
  },
  {
    institution: 'Concordia University-Saint Paul',
  },
  {
    institution: 'Dakota County Technical College',
  },
  {
    institution: 'Martin Luther College',
  },
  {
    institution: 'Lake Superior College',
  },
  {
    institution: 'Cosmetology Careers Unlimited College of Hair Skin and Nails',
  },
  {
    institution: 'Duluth Business University',
  },
  {
    institution: 'Minnesota State Community and Technical College',
  },
  {
    institution: 'Globe University-Woodbury',
  },
  {
    institution: 'Minnesota West Community and Technical College',
  },
  {
    institution: 'Gustavus Adolphus College',
  },
  {
    institution: 'Hamline University',
  },
  {
    institution: 'Hazelden Graduate School of Addiction Studies',
  },
  {
    institution: 'Hennepin Technical College',
  },
  {
    institution: 'Cosmetology Careers Unlimited College of Hair Skin and Nails',
  },
  {
    institution: 'Hibbing Community College',
  },
  {
    institution: 'Aveda Institute-Minneapolis',
  },
  {
    institution: 'Inver Hills Community College',
  },
  {
    institution: 'Itasca Community College',
  },
  {
    institution: 'The Art Institutes International-Minnesota',
  },
  {
    institution: 'Luther Seminary',
  },
  {
    institution: 'Macalester College',
  },
  {
    institution: 'South Central College',
  },
  {
    institution: 'Minnesota State University-Mankato',
  },
  {
    institution: 'Regency Beauty Institute-Blaine',
  },
  {
    institution: 'Mayo Medical School',
  },
  {
    institution: 'Mayo School of Health Sciences',
  },
  {
    institution: 'Argosy University-Twin Cities',
  },
  {
    institution: 'Mesabi Range Community and Technical College',
  },
  {
    institution: 'Metropolitan State University',
  },
  {
    institution: 'University of Minnesota-Twin Cities',
  },
  {
    institution: 'University of Minnesota-Crookston',
  },
  {
    institution: 'Minneapolis Business College',
  },
  {
    institution: 'Minneapolis College of Art and Design',
  },
  {
    institution: 'Minneapolis Community and Technical College',
  },
  {
    institution: 'Herzing University-Minneapolis',
  },
  {
    institution: 'Minnesota School of Cosmetology-Woodbury Campus',
  },
  {
    institution: 'Crossroads College',
  },
  {
    institution: 'University of Minnesota-Duluth',
  },
  {
    institution: 'University of Minnesota-Morris',
  },
  {
    institution: 'Minnesota School of Business-Richfield',
  },
  {
    institution: 'Model College of Hair Design',
  },
  {
    institution: 'Minnesota State University-Moorhead',
  },
  {
    institution: 'North Hennepin Community College',
  },
  {
    institution: 'National American University-Roseville',
  },
  {
    institution: 'Brown College-Mendota Heights',
  },
  {
    institution: 'Normandale Community College',
  },
  {
    institution: 'North Central University',
  },
  {
    institution: 'Northland Community and Technical College',
  },
  {
    institution: 'Northwest Technical Institute',
  },
  {
    institution: 'University of Northwestern-St Paul',
  },
  {
    institution: 'Northwestern Health Sciences University',
  },
  {
    institution: 'Oak Hills Christian College',
  },
  {
    institution: 'Regency Beauty Institute-Burnsville',
  },
  {
    institution: 'Pine Technical College',
  },
  {
    institution: 'Rainy River Community College',
  },
  {
    institution: 'Rochester Community and Technical College',
  },
  {
    institution: 'College of Saint Benedict',
  },
  {
    institution: 'St Cloud Technical and Community College',
  },
  {
    institution: 'Regency Beauty Institute-St Cloud',
  },
  {
    institution: 'Saint Cloud State University',
  },
  {
    institution: 'Saint Johns University',
  },
  {
    institution: "Saint Mary's University of Minnesota",
  },
  {
    institution: 'St Olaf College',
  },
  {
    institution: 'Crown College',
  },
  {
    institution: 'The College of Saint Scholastica',
  },
  {
    institution: 'University of St Thomas',
  },
  {
    institution: 'College of Visual Arts',
  },
  {
    institution: 'Empire Beauty School-Bloomington',
  },
  {
    institution: 'St Catherine University',
  },
  {
    institution: 'Rasmussen College-Minnesota',
  },
  {
    institution: 'Saint Paul College',
  },
  {
    institution: 'Southwest Minnesota State University',
  },
  {
    institution: 'Summit Academy Opportunities Industrialization Center',
  },
  {
    institution: 'United Theological Seminary of the Twin Cities',
  },
  {
    institution: 'Vermilion Community College',
  },
  {
    institution: 'Dunwoody College of Technology',
  },
  {
    institution: 'Ridgewater College',
  },
  {
    institution: 'Minnesota State College-Southeast Technical',
  },
  {
    institution: 'Winona State University',
  },
  {
    institution: 'William Mitchell College of Law',
  },
  {
    institution: 'Century College',
  },
  {
    institution: 'Alcorn State University',
  },
  {
    institution: 'Belhaven University',
  },
  {
    institution: 'Blue Mountain College',
  },
  {
    institution: 'Chris Beauty College',
  },
  {
    institution: 'Coahoma Community College',
  },
  {
    institution: 'Antonelli College-Jackson',
  },
  {
    institution: 'Copiah-Lincoln Community College',
  },
  {
    institution: 'Creations College of Cosmetology',
  },
  {
    institution: 'Delta Beauty College',
  },
  {
    institution: 'Delta State University',
  },
  {
    institution: 'East Central Community College',
  },
  {
    institution: 'East Mississippi Community College',
  },
  {
    institution: 'Fosters Cosmetology College',
  },
  {
    institution: 'Academy of Hair Design-Grenada',
  },
  {
    institution: 'Hinds Community College',
  },
  {
    institution: 'Holmes Community College',
  },
  {
    institution: 'Itawamba Community College',
  },
  {
    institution: 'Jackson State University',
  },
  {
    institution: 'Jones County Junior College',
  },
  {
    institution: 'Meridian Community College',
  },
  {
    institution: 'Millsaps College',
  },
  {
    institution:
      'Board of Trustees-Mississippi State Institutions of Higher Learning',
  },
  {
    institution: 'Mississippi Delta Community College',
  },
  {
    institution: 'University of Mississippi',
  },
  {
    institution: 'University of Mississippi Medical Center',
  },
  {
    institution: 'Mississippi University for Women',
  },
  {
    institution: 'Mississippi Valley State University',
  },
  {
    institution: 'Mississippi College',
  },
  {
    institution: 'Mississippi College of Beauty Culture',
  },
  {
    institution: 'Mississippi Gulf Coast Community College',
  },
  {
    institution: 'Mississippi State University',
  },
  {
    institution: 'Northeast Mississippi Community College',
  },
  {
    institution: 'Northwest Mississippi Community College',
  },
  {
    institution: 'Pearl River Community College',
  },
  {
    institution: 'Rust College',
  },
  {
    institution: 'Southeastern Baptist College',
  },
  {
    institution: 'Southwest Mississippi Community College',
  },
  {
    institution: 'University of Southern Mississippi',
  },
  {
    institution: 'Magnolia College of Cosmetology',
  },
  {
    institution: 'Tougaloo College',
  },
  {
    institution: 'Wesley Biblical Seminary',
  },
  {
    institution: 'William Carey University',
  },
  {
    institution: 'Anthem College-Maryland Heights',
  },
  {
    institution: 'Aquinas Institute of Theology',
  },
  {
    institution: 'Assemblies of God Theological Seminary',
  },
  {
    institution: 'Avila University',
  },
  {
    institution: 'ITT Technical Institute-Earth City',
  },
  {
    institution: 'Baptist Bible College',
  },
  {
    institution: 'House of Heavilin Beauty College-Blue Springs',
  },
  {
    institution: 'Boonslick Technical Education Center',
  },
  {
    institution: 'Cox College',
  },
  {
    institution: 'Calvary Bible College and Theological Seminary',
  },
  {
    institution: 'Cape Girardeau Career and Technology Center',
  },
  {
    institution: 'Central Christian College of the Bible',
  },
  {
    institution: 'Central Bible College',
  },
  {
    institution:
      'Central Methodist University-College of Liberal Arts and Sciences',
  },
  {
    institution: 'University of Central Missouri',
  },
  {
    institution: 'Grand River Technical School',
  },
  {
    institution: 'Chillicothe Beauty Academy Inc',
  },
  {
    institution: 'Cleveland University-Kansas City',
  },
  {
    institution: 'Columbia Area Career Center',
  },
  {
    institution: 'Columbia College',
  },
  {
    institution: 'Conception Seminary College',
  },
  {
    institution: 'Concordia Seminary',
  },
  {
    institution: 'Cottey College',
  },
  {
    institution: 'Covenant Theological Seminary',
  },
  {
    institution: 'Crowder College',
  },
  {
    institution: 'Culver-Stockton College',
  },
  {
    institution: 'DeVry University-Missouri',
  },
  {
    institution: 'Drury University',
  },
  {
    institution: 'East Central College',
  },
  {
    institution: 'Eden Theological Seminary',
  },
  {
    institution: 'Elaine Steven Beauty College',
  },
  {
    institution: 'Pinnacle Career Institute-South Kansas City',
  },
  {
    institution: 'Evangel University',
  },
  {
    institution: 'Fontbonne University',
  },
  {
    institution: 'Forest Institute of Professional Psychology',
  },
  {
    institution: 'Four Rivers Career Center',
  },
  {
    institution: 'Ozarks Technical Community College',
  },
  {
    institution: 'Hannibal-LaGrange University',
  },
  {
    institution: 'Harris-Stowe State University',
  },
  {
    institution: 'Hickey College',
  },
  {
    institution: 'House of Heavilin Beauty College-Kansas City',
  },
  {
    institution: 'Independence College of Cosmetology',
  },
  {
    institution: 'Jefferson College',
  },
  {
    institution: 'Cosmetology Concepts Institute',
  },
  {
    institution: 'Barnes-Jewish College Goldfarb School of Nursing',
  },
  {
    institution: 'Kansas City Art Institute',
  },
  {
    institution: 'Kennett Career and Technology Center',
  },
  {
    institution: 'Kenrick Glennon Seminary',
  },
  {
    institution: 'A T Still University of Health Sciences',
  },
  {
    institution: 'Lake Career and Technical Center',
  },
  {
    institution: 'Lex La-Ray Technical Center',
  },
  {
    institution: 'Lincoln University',
  },
  {
    institution: 'Lindenwood University',
  },
  {
    institution: 'Linn State Technical College',
  },
  {
    institution: 'Logan University',
  },
  {
    institution: 'Metropolitan Community College-Kansas City',
  },
  {
    institution: 'Lutheran School of Nursing',
  },
  {
    institution: 'Maryville University of Saint Louis',
  },
  {
    institution: 'Merrell University of Beauty Arts and Science',
  },
  {
    institution: 'Metro Business College-Cape Girardeau',
  },
  {
    institution: 'Metropolitan Community College-Kansas City',
  },
  {
    institution: 'Midwest Institute',
  },
  {
    institution: 'Midwestern Baptist Theological Seminary',
  },
  {
    institution: 'Mineral Area College',
  },
  {
    institution: "Westwood College-O'Hare Airport",
  },
  {
    institution: 'Missouri Baptist University',
  },
  {
    institution: 'National Academy of Beauty Arts-Farmington',
  },
  {
    institution: 'Missouri College',
  },
  {
    institution: 'Missouri School of Barbering & Hairstyling',
  },
  {
    institution: 'Missouri Southern State University',
  },
  {
    institution: 'Missouri Tech',
  },
  {
    institution: 'Missouri Valley College',
  },
  {
    institution: 'Missouri Western State University',
  },
  {
    institution: 'University of Missouri-Columbia',
  },
  {
    institution: 'University of Missouri-Kansas City',
  },
  {
    institution: 'Missouri University of Science and Technology',
  },
  {
    institution: 'University of Missouri-St Louis',
  },
  {
    institution: 'University of Missouri-System Office',
  },
  {
    institution: 'Moberly Area Community College',
  },
  {
    institution: 'Hillyard Technical Center',
  },
  {
    institution: 'National Academy of Beauty Arts-St Louis',
  },
  {
    institution: 'Nazarene Theological Seminary',
  },
  {
    institution: 'Neosho Beauty College',
  },
  {
    institution: 'Nichols Career Center',
  },
  {
    institution: 'Northwest Technical School',
  },
  {
    institution: 'Truman State University',
  },
  {
    institution: 'Northwest Missouri State University',
  },
  {
    institution: 'Ozark Christian College',
  },
  {
    institution: 'College of the Ozarks',
  },
  {
    institution: 'Park University',
  },
  {
    institution: 'Stevens Institute of Business & Arts',
  },
  {
    institution: 'Poplar Bluff Technical Career Center',
  },
  {
    institution: 'Ranken Technical College',
  },
  {
    institution: 'Research College of Nursing',
  },
  {
    institution: 'Rockhurst University',
  },
  {
    institution: 'Rolla Technical Institute',
  },
  {
    institution: 'Everest College-Springfield',
  },
  {
    institution: 'Saint Louis University',
  },
  {
    institution: 'Salem College of Hairstyling',
  },
  {
    institution: 'Sanford-Brown College-Fenton',
  },
  {
    institution: 'American College of Hair Design Inc',
  },
  {
    institution: 'Sikeston Career and Technology Center',
  },
  {
    institution: 'Saint Louis Christian College',
  },
  {
    institution: 'St Louis College of Pharmacy',
  },
  {
    institution: 'Saint Louis Community College',
  },
  {
    institution: 'Saint Paul School of Theology',
  },
  {
    institution: 'Southwest Baptist University',
  },
  {
    institution: 'Missouri State University-West Plains',
  },
  {
    institution: 'Mercy Hospital Springfield-School of Radiologic Technology',
  },
  {
    institution: "Saint Luke's College of Health Sciences",
  },
  {
    institution: 'St Louis College of Health Careers-St Louis',
  },
  {
    institution: 'State Fair Community College',
  },
  {
    institution: 'Stephens College',
  },
  {
    institution: 'Southeast Missouri State University',
  },
  {
    institution: 'Missouri State University-Springfield',
  },
  {
    institution: 'Three Rivers Community College',
  },
  {
    institution: 'North Central Missouri College',
  },
  {
    institution: 'Eldon Career Center',
  },
  {
    institution: 'Truman Medical Center School of Nurse Anesthesia',
  },
  {
    institution: 'Kansas City University of Medicine and Biosciences',
  },
  {
    institution: 'Washington University in St Louis',
  },
  {
    institution: 'Webster University',
  },
  {
    institution: 'Wentworth Military Academy & Junior College',
  },
  {
    institution: 'Westminster College',
  },
  {
    institution: 'William Jewell College',
  },
  {
    institution: 'William Woods University',
  },
  {
    institution: 'Academy of Cosmetology',
  },
  {
    institution: 'Blackfeet Community College',
  },
  {
    institution: 'Butte Academy of Beauty Culture',
  },
  {
    institution: 'Highlands College of Montana Tech',
  },
  {
    institution: "Crevier's School of Cosmetology",
  },
  {
    institution: 'Carroll College',
  },
  {
    institution: 'Dawson Community College',
  },
  {
    institution: 'Chief Dull Knife College',
  },
  {
    institution: 'Montana State University-Billings',
  },
  {
    institution: 'Flathead Valley Community College',
  },
  {
    institution: 'Aaniiih Nakoda College',
  },
  {
    institution: 'Fort Peck Community College',
  },
  {
    institution: 'Great Falls College Montana State University',
  },
  {
    institution: 'University of Great Falls',
  },
  {
    institution: 'Helena College University of Montana',
  },
  {
    institution: 'Little Big Horn College',
  },
  {
    institution: 'Miles Community College',
  },
  {
    institution: 'Modern Beauty School Inc',
  },
  {
    institution: 'Montana Tech of the University of Montana',
  },
  {
    institution: 'Montana State University',
  },
  {
    institution: 'The University of Montana',
  },
  {
    institution: 'Montana State University-Northern',
  },
  {
    institution: 'Rocky Mountain College',
  },
  {
    institution: 'Salish Kootenai College',
  },
  {
    institution: 'The University of Montana-Western',
  },
  {
    institution: "La'James International College",
  },
  {
    institution: 'Bellevue University',
  },
  {
    institution: 'Clarkson College',
  },
  {
    institution: 'Bryan College of Health Sciences',
  },
  {
    institution: 'Central Community College',
  },
  {
    institution: 'Chadron State College',
  },
  {
    institution: 'College of Hair Design-Downtown',
  },
  {
    institution: 'Concordia University-Nebraska',
  },
  {
    institution: 'Creighton University',
  },
  {
    institution: 'Doane College-Crete',
  },
  {
    institution: 'Grace University',
  },
  {
    institution: 'Hastings College',
  },
  {
    institution: 'Fullen School of Hair Design',
  },
  {
    institution: 'Alegent Creighton Health School of Radiologic Technology',
  },
  {
    institution: "Joseph's College",
  },
  {
    institution: 'University of Nebraska at Kearney',
  },
  {
    institution: 'Kaplan University-Lincoln Campus',
  },
  {
    institution: 'Nebraska Methodist College of Nursing & Allied Health',
  },
  {
    institution: 'Metropolitan Community College Area',
  },
  {
    institution: 'Mid-Plains Community College',
  },
  {
    institution: 'Midland University',
  },
  {
    institution: 'Nebraska Christian College',
  },
  {
    institution: 'University of Nebraska at Omaha',
  },
  {
    institution: 'Kaplan University-Omaha Campus',
  },
  {
    institution: 'Nebraska Indian Community College',
  },
  {
    institution: 'University of Nebraska Medical Center',
  },
  {
    institution: 'Nebraska Wesleyan University',
  },
  {
    institution: 'University of Nebraska-Lincoln',
  },
  {
    institution: 'Northeast Community College',
  },
  {
    institution: 'Peru State College',
  },
  {
    institution: 'Summit Christian College',
  },
  {
    institution: 'College of Saint Mary',
  },
  {
    institution: 'Southeast Community College Area',
  },
  {
    institution: 'Union College',
  },
  {
    institution: 'University of Nebraska-Central Administration System Office',
  },
  {
    institution: 'Vatterott College-Spring Valley',
  },
  {
    institution: 'Nebraska College of Technical Agriculture',
  },
  {
    institution: 'Wayne State College',
  },
  {
    institution: 'Regional West Medical Center School of Radiologic Technology',
  },
  {
    institution: 'Western Nebraska Community College',
  },
  {
    institution: 'York College',
  },
  {
    institution: 'Academy of Hair Design-Las Vegas',
  },
  {
    institution: 'Career College of Northern Nevada',
  },
  {
    institution: 'College of Southern Nevada',
  },
  {
    institution: 'The Art Institute of Las Vegas',
  },
  {
    institution: 'Everest College-Henderson',
  },
  {
    institution: 'Marinello Schools of Beauty-Las Vegas',
  },
  {
    institution: 'University of Nevada-Las Vegas',
  },
  {
    institution: 'University of Nevada-Reno',
  },
  {
    institution: 'Great Basin College',
  },
  {
    institution: 'Marinello Schools of Beauty-Reno',
  },
  {
    institution: 'Morrison University',
  },
  {
    institution: 'Sierra Nevada College',
  },
  {
    institution: 'Truckee Meadows Community College',
  },
  {
    institution: 'Nevada System of Higher Education-System Office',
  },
  {
    institution: 'Western Nevada College',
  },
  {
    institution: 'Colby-Sawyer College',
  },
  {
    institution: 'Continental Academie of Hair Design-Hudson',
  },
  {
    institution: 'Daniel Webster College',
  },
  {
    institution: 'Dartmouth College',
  },
  {
    institution: 'Empire Beauty School-Laconia',
  },
  {
    institution: 'Empire Beauty School-Somersworth',
  },
  {
    institution: 'Franklin Pierce University',
  },
  {
    institution: 'University of New Hampshire-School of Law',
  },
  {
    institution: 'Mount Washington College',
  },
  {
    institution: 'Keene Beauty Academy',
  },
  {
    institution: 'Lebanon College',
  },
  {
    institution: 'College of St Mary Magdalen',
  },
  {
    institution:
      'Michaels School of Hair Design and Esthetics-Paul Mitchell Partner School',
  },
  {
    institution: 'New England College',
  },
  {
    institution: 'New England School of Hair Design Inc',
  },
  {
    institution: 'Southern New Hampshire University',
  },
  {
    institution: 'New Hampshire Institute for Therapeutic Arts',
  },
  {
    institution: 'University of New Hampshire-Main Campus',
  },
  {
    institution: 'Keene State College',
  },
  {
    institution: 'University of New Hampshire at Manchester',
  },
  {
    institution: 'Plymouth State University',
  },
  {
    institution: "NHTI-Concord's Community College",
  },
  {
    institution: 'White Mountains Community College',
  },
  {
    institution: 'River Valley Community College',
  },
  {
    institution: 'Lakes Region Community College',
  },
  {
    institution: 'Manchester Community College',
  },
  {
    institution: 'Nashua Community College',
  },
  {
    institution: 'Great Bay Community College',
  },
  {
    institution: 'Paul Mitchell the School-Portsmouth',
  },
  {
    institution: 'Rivier University',
  },
  {
    institution: 'Saint Anselm College',
  },
  {
    institution: 'St Joseph School of Nursing',
  },
  {
    institution: 'Granite State College',
  },
  {
    institution: 'Thomas More College of Liberal Arts',
  },
  {
    institution: 'University System of New Hampshire System Office',
  },
  {
    institution: 'Eastwick College-Hackensack',
  },
  {
    institution: 'Assumption College for Sisters',
  },
  {
    institution: 'Atlantic Cape Community College',
  },
  {
    institution: 'Fortis Institute-Wayne',
  },
  {
    institution: 'Bergen Community College',
  },
  {
    institution: 'Berkeley College-Woodland Park',
  },
  {
    institution: 'Beth Medrash Govoha',
  },
  {
    institution: 'Bloomfield College',
  },
  {
    institution: 'Brookdale Community College',
  },
  {
    institution: 'Burlington County College',
  },
  {
    institution: 'Caldwell College',
  },
  {
    institution: 'Camden County College',
  },
  {
    institution: 'Centenary College',
  },
  {
    institution: 'Adult and Continuing Education-BCTS',
  },
  {
    institution: 'Anthem Institute-Parsippany',
  },
  {
    institution: 'Lincoln Technical Institute-Edison',
  },
  {
    institution: 'Fox Institute of Business-Clifton',
  },
  {
    institution: 'Dover Business College',
  },
  {
    institution: 'Empire Beauty School-Ocean',
  },
  {
    institution: 'Cooper Health System Center for Allied Health Education',
  },
  {
    institution: 'County College of Morris',
  },
  {
    institution: 'Cumberland County College',
  },
  {
    institution: 'DeVry University-New Jersey',
  },
  {
    institution: 'Divers Academy International',
  },
  {
    institution: 'Drake College of Business-Elizabeth',
  },
  {
    institution: 'Drew University',
  },
  {
    institution: 'duCret School of Arts',
  },
  {
    institution: 'Lincoln Technical Institute-South Plainfield',
  },
  {
    institution: 'Essex County College',
  },
  {
    institution: 'Empire Beauty School-Union',
  },
  {
    institution: 'Fairleigh Dickinson University-Metropolitan Campus',
  },
  {
    institution: 'Felician College',
  },
  {
    institution: 'Fairleigh Dickinson University-College at Florham',
  },
  {
    institution: 'Hohokus School of Trade and Technical Sciences',
  },
  {
    institution: 'Georgian Court University',
  },
  {
    institution: 'Rowan University',
  },
  {
    institution: 'Gloucester County College',
  },
  {
    institution: 'Empire Beauty School-Cherry Hill',
  },
  {
    institution: 'Harris School of Business-Cherry Hill Campus',
  },
  {
    institution: 'Eastwick College-Ramsey',
  },
  {
    institution: 'Holy Name Medical Center School of Nursing',
  },
  {
    institution: 'Hudson County Community College',
  },
  {
    institution: 'New Jersey City University',
  },
  {
    institution: 'Joe Kubert School of Cartoon and Graphic Art',
  },
  {
    institution: 'Kean University',
  },
  {
    institution: 'Harris School of Business-Voorhees Campus',
  },
  {
    institution: 'Lincoln Technical Institute-Union',
  },
  {
    institution: 'Rizzieri Aveda School for Beauty and Wellness',
  },
  {
    institution: 'Mercer County Community College',
  },
  {
    institution: 'Middlesex County College',
  },
  {
    institution: 'Monmouth University',
  },
  {
    institution: 'Montclair State University',
  },
  {
    institution: 'Mountainside School of Nursing',
  },
  {
    institution:
      'Muhlenberg Harold B and Dorothy A Snyder Schools-School of Imaging',
  },
  {
    institution:
      'Muhlenberg Harold B and Dorothy A Snyder Schools-School of Nursing',
  },
  {
    institution: 'Hohokus School-RETS Nutley',
  },
  {
    institution: 'Empire Beauty School-Jersey City',
  },
  {
    institution: 'New Brunswick Theological Seminary',
  },
  {
    institution: 'Robert Fiance Beauty Schools-West New York',
  },
  {
    institution: 'New Jersey Institute of Technology',
  },
  {
    institution: 'Ocean County College',
  },
  {
    institution: 'Omega Institute',
  },
  {
    institution: 'P B Cosmetology Education Center',
  },
  {
    institution: 'Parisian Beauty School',
  },
  {
    institution: 'Passaic County Community College',
  },
  {
    institution: 'Pennco Tech-Blackwood',
  },
  {
    institution: 'Princeton Theological Seminary',
  },
  {
    institution: 'Princeton University',
  },
  {
    institution: 'Rabbinical College of America',
  },
  {
    institution: 'Ramapo College of New Jersey',
  },
  {
    institution: 'Robert Fiance Beauty Schools-Perth Amboy',
  },
  {
    institution: 'Rider University',
  },
  {
    institution: 'Roman Academy of Beauty Culture',
  },
  {
    institution: 'Rutgers University-Camden',
  },
  {
    institution: 'Rutgers University-New Brunswick',
  },
  {
    institution: 'Rutgers University-Newark',
  },
  {
    institution: 'St Francis Medical Center-School of Radiologic Technology',
  },
  {
    institution: 'Saint Francis Medical Center School of Nursing',
  },
  {
    institution: "Saint Peter's University",
  },
  {
    institution: 'Salem Community College',
  },
  {
    institution: 'Seton Hall University',
  },
  {
    institution: 'Atlantic Beauty & Spa Academy LLC',
  },
  {
    institution: 'College of Saint Elizabeth',
  },
  {
    institution: 'Raritan Valley Community College',
  },
  {
    institution: 'Stevens Institute of Technology',
  },
  {
    institution: 'The Richard Stockton College of New Jersey',
  },
  {
    institution: 'Talmudical Academy-New Jersey',
  },
  {
    institution: 'Teterboro School of Aeronautics',
  },
  {
    institution: 'Thomas Edison State College',
  },
  {
    institution: 'The College of New Jersey',
  },
  {
    institution: 'Sanford-Brown Institute-Iselin',
  },
  {
    institution: 'Union County College',
  },
  {
    institution: 'University of Medicine and Dentistry of New Jersey',
  },
  {
    institution: 'William Paterson University of New Jersey',
  },
  {
    institution: 'Olympian University of Cosmetology',
  },
  {
    institution: 'Central New Mexico Community College',
  },
  {
    institution: 'Navajo Technical University',
  },
  {
    institution: 'De Wolff College Hair Styling and Cosmetology',
  },
  {
    institution: 'New Mexico State University-Dona Ana',
  },
  {
    institution: 'Clovis Community College',
  },
  {
    institution: 'Eastern New Mexico University-Main Campus',
  },
  {
    institution: 'Eastern New Mexico University-Roswell Campus',
  },
  {
    institution: 'Institute of American Indian and Alaska Native Culture',
  },
  {
    institution: 'New Mexico Highlands University',
  },
  {
    institution: 'New Mexico Junior College',
  },
  {
    institution: 'New Mexico Military Institute',
  },
  {
    institution: 'University of New Mexico-Gallup Campus',
  },
  {
    institution: 'New Mexico Institute of Mining and Technology',
  },
  {
    institution: 'University of New Mexico-Los Alamos Campus',
  },
  {
    institution: 'University of New Mexico-Main Campus',
  },
  {
    institution: 'New Mexico State University-Alamogordo',
  },
  {
    institution: 'New Mexico State University-Carlsbad',
  },
  {
    institution: 'New Mexico State University-Grants',
  },
  {
    institution: 'New Mexico State University-Main Campus',
  },
  {
    institution: 'University of New Mexico-Valencia County Campus',
  },
  {
    institution: 'Northern New Mexico College',
  },
  {
    institution: 'San Juan College',
  },
  {
    institution: 'Santa Fe Community College',
  },
  {
    institution: 'Santa Fe University of Art and Design',
  },
  {
    institution: 'University of the Southwest',
  },
  {
    institution: 'Southwestern College',
  },
  {
    institution: 'Southwestern Indian Polytechnic Institute',
  },
  {
    institution: 'University of New Mexico-Taos Campus',
  },
  {
    institution: 'Mesalands Community College',
  },
  {
    institution: 'Vogue College of Cosmetology-Santa Fe',
  },
  {
    institution: 'Western New Mexico University',
  },
  {
    institution: 'Vaughn College of Aeronautics and Technology',
  },
  {
    institution: 'Adelphi University',
  },
  {
    institution: 'Adirondack Community College',
  },
  {
    institution: 'Globe Institute of Technology',
  },
  {
    institution: 'Bryant & Stratton College-Albany',
  },
  {
    institution: 'Albany College of Pharmacy and Health Sciences',
  },
  {
    institution: 'Albany Law School',
  },
  {
    institution: 'Albany Medical College',
  },
  {
    institution: 'Alfred University',
  },
  {
    institution: 'Allen School-Brooklyn',
  },
  {
    institution: 'The Ailey School',
  },
  {
    institution: 'American Academy of Dramatic Arts-New York',
  },
  {
    institution: 'American Academy McAllister Institute of Funeral Service',
  },
  {
    institution: 'Joffrey Ballet School',
  },
  {
    institution: 'American Musical and Dramatic Academy',
  },
  {
    institution: 'Apex Technical School',
  },
  {
    institution: 'Arnot Ogden Medical Center',
  },
  {
    institution: 'Associated Beth Rivkah Schools',
  },
  {
    institution: "Austin's School of Spa Technology",
  },
  {
    institution: 'Bank Street College of Education',
  },
  {
    institution: 'Bard College',
  },
  {
    institution: 'Barnard College',
  },
  {
    institution: 'Berk Trade and Business School',
  },
  {
    institution: 'Berkeley College-New York',
  },
  {
    institution: 'Beth Hatalmud Rabbinical College',
  },
  {
    institution: 'Beth Hamedrash Shaarei Yosher Institute',
  },
  {
    institution: 'Phillips Beth Israel School of Nursing',
  },
  {
    institution: 'Hair Design Institute at Fifth Avenue-Brooklyn',
  },
  {
    institution: 'Boricua College',
  },
  {
    institution: 'Bramson ORT College',
  },
  {
    institution: 'Briarcliffe College',
  },
  {
    institution: 'Brittany Beauty School',
  },
  {
    institution: 'American Beauty School',
  },
  {
    institution: 'Brooklyn Law School',
  },
  {
    institution: 'SUNY Broome Community College',
  },
  {
    institution: 'Bryant & Stratton College-Amherst',
  },
  {
    institution: 'Bryant & Stratton College-Syracuse North',
  },
  {
    institution: 'Bryant & Stratton College-Syracuse',
  },
  {
    institution: 'Bryant & Stratton College-Buffalo',
  },
  {
    institution: 'Bryant & Stratton College-Greece',
  },
  {
    institution: 'Business Informatics Center Inc',
  },
  {
    institution: 'Canisius College',
  },
  {
    institution: 'Capri School of Hair Design',
  },
  {
    institution: "St Paul's School of Nursing-Queens",
  },
  {
    institution: 'Cayuga County Community College',
  },
  {
    institution: 'Cazenovia College',
  },
  {
    institution: 'Central Yeshiva Tomchei Tmimim Lubavitz',
  },
  {
    institution: 'Cheryl Fells School of Business',
  },
  {
    institution: 'Christ the King Seminary',
  },
  {
    institution: 'Circle in the Square Theater School',
  },
  {
    institution: 'CUNY System Office',
  },
  {
    institution: 'Clarkson University',
  },
  {
    institution: 'Clinton Community College',
  },
  {
    institution: 'Cochran School of Nursing',
  },
  {
    institution: 'Colgate Rochester Crozer Divinity School',
  },
  {
    institution: 'Colgate University',
  },
  {
    institution: 'Metropolitan College of New York',
  },
  {
    institution: 'Columbia University in the City of New York',
  },
  {
    institution: 'Columbia-Greene Community College',
  },
  {
    institution: 'Empire Beauty School-Queens',
  },
  {
    institution: 'Concordia College-New York',
  },
  {
    institution: 'Continental School of Beauty Culture-Buffalo',
  },
  {
    institution: 'Continental School of Beauty Culture-Batavia',
  },
  {
    institution: 'Continental School of Beauty Culture-Rochester',
  },
  {
    institution: 'Cooper Union for the Advancement of Science and Art',
  },
  {
    institution: 'Cornell University',
  },
  {
    institution: 'Weill Cornell Medical College',
  },
  {
    institution: 'Corning Community College',
  },
  {
    institution: 'Crouse Hospital College of Nursing',
  },
  {
    institution: 'Culinary Institute of America',
  },
  {
    institution: 'CUNY Bernard M Baruch College',
  },
  {
    institution: 'CUNY Borough of Manhattan Community College',
  },
  {
    institution: 'CUNY Bronx Community College',
  },
  {
    institution: 'CUNY Brooklyn College',
  },
  {
    institution: 'College of Staten Island CUNY',
  },
  {
    institution: 'CUNY City College',
  },
  {
    institution: 'CUNY Graduate School and University Center',
  },
  {
    institution: 'CUNY Hostos Community College',
  },
  {
    institution: 'CUNY Hunter College',
  },
  {
    institution: 'CUNY John Jay College of Criminal Justice',
  },
  {
    institution: 'CUNY Kingsborough Community College',
  },
  {
    institution: 'CUNY LaGuardia Community College',
  },
  {
    institution: 'CUNY Lehman College',
  },
  {
    institution: 'CUNY Medgar Evers College',
  },
  {
    institution: 'CUNY New York City College of Technology',
  },
  {
    institution: 'CUNY Queens College',
  },
  {
    institution: 'CUNY Queensborough Community College',
  },
  {
    institution: 'CUNY School of Law at Queens College',
  },
  {
    institution: 'CUNY York College',
  },
  {
    institution: 'CVPH Medical Center School of Radiologic Technology',
  },
  {
    institution: "D'Youville College",
  },
  {
    institution: 'Daemen College',
  },
  {
    institution: 'Yeshiva of Far Rockaway Derech Ayson Rabbinical Seminary',
  },
  {
    institution: 'Dominican College of Blauvelt',
  },
  {
    institution: 'Dowling College',
  },
  {
    institution: 'Dutchess Community College',
  },
  {
    institution: 'Belanger School of Nursing',
  },
  {
    institution: 'Elmira Business Institute',
  },
  {
    institution: 'Elmira College',
  },
  {
    institution: 'Erie Community College',
  },
  {
    institution: 'Fashion Institute of Technology',
  },
  {
    institution: 'Finger Lakes Community College',
  },
  {
    institution: 'Five Towns College',
  },
  {
    institution: 'Fordham University',
  },
  {
    institution: 'The International Culinary Center',
  },
  {
    institution: 'Fulton-Montgomery Community College',
  },
  {
    institution: 'Gemological Institute of America-New York',
  },
  {
    institution: 'The General Theological Seminary',
  },
  {
    institution: 'Genesee Community College',
  },
  {
    institution: 'Global Business Institute',
  },
  {
    institution: 'Global Business Institute',
  },
  {
    institution: 'Hamilton College',
  },
  {
    institution: 'Hartwick College',
  },
  {
    institution: 'Helene Fuld College of Nursing',
  },
  {
    institution: 'Herkimer County Community College',
  },
  {
    institution: 'Hilbert College',
  },
  {
    institution: 'Hobart William Smith Colleges',
  },
  {
    institution: 'Hofstra University',
  },
  {
    institution: 'Houghton College',
  },
  {
    institution: 'Hudson Valley Community College',
  },
  {
    institution: 'Hunter Business School',
  },
  {
    institution: 'Institute of Design and Construction',
  },
  {
    institution: 'Institute of Audio Research',
  },
  {
    institution: 'Iona College',
  },
  {
    institution: 'Isabella Graham Hart School of Practical Nursing',
  },
  {
    institution: 'Island Drafting and Technical Institute',
  },
  {
    institution: 'Ithaca College',
  },
  {
    institution: 'Jamestown Community College',
  },
  {
    institution: 'Jamestown Business College',
  },
  {
    institution: 'Jefferson Community College',
  },
  {
    institution: 'Jewish Theological Seminary of America',
  },
  {
    institution: 'The Juilliard School',
  },
  {
    institution: 'SBI Campus-An Affiliate of Sanford-Brown',
  },
  {
    institution: 'Kehilath Yakov Rabbinical Seminary',
  },
  {
    institution: 'Keuka College',
  },
  {
    institution: 'LIM College',
  },
  {
    institution: 'Le Moyne College',
  },
  {
    institution: 'LIU Brooklyn',
  },
  {
    institution: 'LIU Post',
  },
  {
    institution: 'LIU-University Center Campus',
  },
  {
    institution: 'Long Island Beauty School-Hempstead',
  },
  {
    institution: 'Long Island Business Institute',
  },
  {
    institution: 'LIU Hudson at Rockland',
  },
  {
    institution: 'LIU Brentwood',
  },
  {
    institution: 'Machzikei Hadath Rabbinical College',
  },
  {
    institution: 'Mandl School-The College of Allied Health',
  },
  {
    institution: 'Manhattan College',
  },
  {
    institution: 'Manhattan School of Music',
  },
  {
    institution: 'Manhattanville College',
  },
  {
    institution: 'Maria College of Albany',
  },
  {
    institution: 'Marion S Whelan School of Nursing of Geneva General Hospital',
  },
  {
    institution: 'Marist College',
  },
  {
    institution: 'MarJon School of Beauty ltd-Tonawanda',
  },
  {
    institution: 'Marymount Manhattan College',
  },
  {
    institution: 'Medaille College',
  },
  {
    institution: 'Memorial School of Nursing',
  },
  {
    institution: 'Memorial Hospital School of Radiation Therapy Technology',
  },
  {
    institution: 'Mercy College',
  },
  {
    institution: 'Mesivta Torah Vodaath Rabbinical Seminary',
  },
  {
    institution: 'Mesivta of Eastern Parkway-Yeshiva Zichron Meilech',
  },
  {
    institution: 'Mesivtha Tifereth Jerusalem of America',
  },
  {
    institution: 'Southern Westchester BOCES-Practical Nursing Program',
  },
  {
    institution: 'Midway Paris Beauty School',
  },
  {
    institution: 'Mildred Elley School-Albany Campus',
  },
  {
    institution: 'Mirrer Yeshiva Cent Institute',
  },
  {
    institution: 'Modern Welding School',
  },
  {
    institution: 'Mohawk Valley Community College',
  },
  {
    institution: 'Molloy College',
  },
  {
    institution: 'Monroe College',
  },
  {
    institution: 'Monroe Community College',
  },
  {
    institution: 'Mount Saint Mary College',
  },
  {
    institution: 'Dorothea Hopfer School of Nursing-Mt Vernon Hospital',
  },
  {
    institution: 'College of Mount Saint Vincent',
  },
  {
    institution: 'Icahn School of Medicine at Mount Sinai',
  },
  {
    institution: 'Nassau Community College',
  },
  {
    institution: 'Nazareth College',
  },
  {
    institution: 'Neighborhood Playhouse School of the Theater',
  },
  {
    institution: 'The College of New Rochelle',
  },
  {
    institution: 'The New School',
  },
  {
    institution: 'New School of Radio and Television',
  },
  {
    institution: 'New York Chiropractic College',
  },
  {
    institution: 'Star Career Academy-New York',
  },
  {
    institution: 'New York Institute of English and Business',
  },
  {
    institution: 'New York Law School',
  },
  {
    institution: 'New York Medical College',
  },
  {
    institution: 'New York School for Medical and Dental Assistants',
  },
  {
    institution: 'New York Theological Seminary',
  },
  {
    institution: 'New York University',
  },
  {
    institution: 'Niagara County Community College',
  },
  {
    institution: 'Niagara University',
  },
  {
    institution: 'Empire Beauty School-Peekskill',
  },
  {
    institution: 'North Country Community College',
  },
  {
    institution: 'New York College of Podiatric Medicine',
  },
  {
    institution: 'New York Institute of Technology',
  },
  {
    institution: 'New York School of Interior Design',
  },
  {
    institution: 'Nyack College',
  },
  {
    institution: 'Ohr Hameir Theological Seminary',
  },
  {
    institution: 'Olean Business Institute',
  },
  {
    institution: 'Onondaga Community College',
  },
  {
    institution: 'Orange County Community College',
  },
  {
    institution: 'Otsego Area BOCES-Practical Nursing Program',
  },
  {
    institution: 'Pace University-New York',
  },
  {
    institution: 'Paul Smiths College of Arts and Science',
  },
  {
    institution: 'Continental School of Beauty Culture-West Seneca',
  },
  {
    institution: 'Plaza College',
  },
  {
    institution: 'Polytechnic Institute of New York University',
  },
  {
    institution: 'Davis College',
  },
  {
    institution: 'Pratt Institute-Main',
  },
  {
    institution: 'Professional Business College',
  },
  {
    institution: 'Rabbinical Academy Mesivta Rabbi Chaim Berlin',
  },
  {
    institution: 'Rabbinical College Bobover Yeshiva Bnei Zion',
  },
  {
    institution: "Rabbinical College of Ch'san Sofer New York",
  },
  {
    institution: 'Rabbinical College Beth Shraga',
  },
  {
    institution: "Rabbinical Seminary M'kor Chaim",
  },
  {
    institution: 'Rabbinical College of Long Island',
  },
  {
    institution: 'Rabbinical Seminary of America',
  },
  {
    institution: 'Rensselaer Polytechnic Institute',
  },
  {
    institution: 'Ridley-Lowell Business & Technical Institute-Binghamton',
  },
  {
    institution: 'Roberts Wesleyan College',
  },
  {
    institution: 'Everest Institute-Rochester',
  },
  {
    institution: 'Rochester Institute of Technology',
  },
  {
    institution: 'University of Rochester',
  },
  {
    institution: 'Rockefeller University',
  },
  {
    institution: 'Rockland Community College',
  },
  {
    institution: 'Empire Beauty School-Manhattan',
  },
  {
    institution: 'The Sage Colleges',
  },
  {
    institution: "St Bernard's School of Theology and Ministry",
  },
  {
    institution: 'St Bonaventure University',
  },
  {
    institution: 'St Francis College',
  },
  {
    institution:
      "St Joseph's College of Nursing at St Joseph's Hospital Health Center",
  },
  {
    institution: 'St Lawrence University',
  },
  {
    institution: 'The College of Saint Rose',
  },
  {
    institution: 'St Thomas Aquinas College',
  },
  {
    institution: 'Samaritan Hospital School of Nursing',
  },
  {
    institution: 'Sarah Lawrence College',
  },
  {
    institution: 'Schenectady County Community College',
  },
  {
    institution: "Sh'or Yoshuv Rabbinical College",
  },
  {
    institution: 'Siena College',
  },
  {
    institution: 'Simmons Institute of Funeral Service Inc',
  },
  {
    institution: 'Skidmore College',
  },
  {
    institution: "Saint Joseph's College-New York",
  },
  {
    institution: 'Saint Vladimirs Orthodox Theological Seminary',
  },
  {
    institution: 'Spanish-American Institute',
  },
  {
    institution: "Faxton-St Luke's Healthcare School of Radiologic Technology",
  },
  {
    institution: 'Saint Elizabeth College of Nursing',
  },
  {
    institution: 'Saint Elizabeth Medical Center School of Radiography',
  },
  {
    institution: 'Saint John Fisher College',
  },
  {
    institution: "St Paul's School of Nursing-Staten Island",
  },
  {
    institution: 'St James Mercy Hospital School of Radiologic Science',
  },
  {
    institution: "St John's University-New York",
  },
  {
    institution: 'SUNY-System Office',
  },
  {
    institution: 'New York Career Institute',
  },
  {
    institution: 'Suburban Technical School',
  },
  {
    institution: 'Branford Hall Career Institute-Bohemia Campus',
  },
  {
    institution: 'Sullivan County Community College',
  },
  {
    institution: 'SUNY College of Technology at Alfred',
  },
  {
    institution: 'SUNY College of Technology at Canton',
  },
  {
    institution: 'SUNY College of Technology at Delhi',
  },
  {
    institution: 'SUNY College of Agriculture and Technology at Cobleskill',
  },
  {
    institution: 'Farmingdale State College',
  },
  {
    institution: 'Morrisville State College',
  },
  {
    institution: 'SUNY at Albany',
  },
  {
    institution: 'SUNY at Binghamton',
  },
  {
    institution: 'University at Buffalo',
  },
  {
    institution: 'Stony Brook University',
  },
  {
    institution: 'SUNY College of Environmental Science and Forestry',
  },
  {
    institution: 'SUNY Institute of Technology at Utica-Rome',
  },
  {
    institution: 'SUNY College at Brockport',
  },
  {
    institution: 'Buffalo State SUNY',
  },
  {
    institution: 'SUNY College at Cortland',
  },
  {
    institution: 'SUNY at Fredonia',
  },
  {
    institution: 'SUNY College at Geneseo',
  },
  {
    institution: 'State University of New York at New Paltz',
  },
  {
    institution: 'SUNY Oneonta',
  },
  {
    institution: 'SUNY College at Oswego',
  },
  {
    institution: 'SUNY College at Potsdam',
  },
  {
    institution: 'SUNY at Purchase College',
  },
  {
    institution: 'SUNY College of Optometry',
  },
  {
    institution: 'SUNY College at Old Westbury',
  },
  {
    institution: 'SUNY College at Plattsburgh',
  },
  {
    institution: 'SUNY Downstate Medical Center',
  },
  {
    institution: 'SUNY Empire State College',
  },
  {
    institution: 'SUNY Maritime College',
  },
  {
    institution: 'Upstate Medical University',
  },
  {
    institution: 'Swedish Institute a College of Health Sciences',
  },
  {
    institution: 'Syracuse University',
  },
  {
    institution: 'Talmudical Seminary Oholei Torah',
  },
  {
    institution: 'Talmudical Institute of Upstate New York',
  },
  {
    institution: 'Teachers College at Columbia University',
  },
  {
    institution: 'Technical Career Institutes',
  },
  {
    institution: 'Tompkins Cortland Community College',
  },
  {
    institution: 'Torah Temimah Talmudical Seminary',
  },
  {
    institution: 'Touro College',
  },
  {
    institution: 'Trocaire College',
  },
  {
    institution: 'Excelsior College',
  },
  {
    institution: 'Ulster County Community College',
  },
  {
    institution: 'Sanford-Brown Institute-New York',
  },
  {
    institution: 'Union College',
  },
  {
    institution: 'Union Theological Seminary in the City of New York',
  },
  {
    institution: 'United Talmudical Seminary',
  },
  {
    institution: 'United States Merchant Marine Academy',
  },
  {
    institution: 'United States Military Academy',
  },
  {
    institution: 'Utica College',
  },
  {
    institution: 'Utica School of Commerce',
  },
  {
    institution: 'Vassar College',
  },
  {
    institution: 'Villa Maria College',
  },
  {
    institution: 'School of Visual Arts',
  },
  {
    institution: 'Wagner College',
  },
  {
    institution: 'Webb Institute',
  },
  {
    institution: 'Wells College',
  },
  {
    institution: 'The College of Westchester',
  },
  {
    institution: 'SUNY Westchester Community College',
  },
  {
    institution: 'Westchester School of Beauty Culture',
  },
  {
    institution: 'Wood Tobe-Coburn School',
  },
  {
    institution: 'Word of Life Bible Institute',
  },
  {
    institution: 'Yeshiva Karlin Stolin',
  },
  {
    institution: 'Yeshivat Mikdash Melech',
  },
  {
    institution: 'Yeshiva Derech Chaim',
  },
  {
    institution: 'Yeshiva of Nitra Rabbinical College',
  },
  {
    institution: 'Yeshiva Shaar Hatorah',
  },
  {
    institution: 'Yeshiva University',
  },
  {
    institution: 'Yeshivath Viznitz',
  },
  {
    institution: 'Yeshivath Zichron Moshe',
  },
  {
    institution: 'College of the Albemarle',
  },
  {
    institution: 'The Art Institute of Charlotte',
  },
  {
    institution: 'South Piedmont Community College',
  },
  {
    institution: 'Appalachian State University',
  },
  {
    institution: 'Asheville-Buncombe Technical Community College',
  },
  {
    institution: 'Barton College',
  },
  {
    institution: 'Beaufort County Community College',
  },
  {
    institution: 'Belmont Abbey College',
  },
  {
    institution: 'Bennett College',
  },
  {
    institution: 'Bladen Community College',
  },
  {
    institution: 'Blue Ridge Community College',
  },
  {
    institution: 'Brevard College',
  },
  {
    institution: 'Brookstone College-Charlotte',
  },
  {
    institution: 'Brunswick Community College',
  },
  {
    institution: 'Cabarrus College of Health Sciences',
  },
  {
    institution: 'Caldwell Community College and Technical Institute',
  },
  {
    institution: 'Campbell University',
  },
  {
    institution: 'Cape Fear Community College',
  },
  {
    institution: 'Carolina School of Broadcasting',
  },
  {
    institution: 'Carteret Community College',
  },
  {
    institution: 'Catawba College',
  },
  {
    institution: 'Catawba Valley Community College',
  },
  {
    institution: 'South College-Asheville',
  },
  {
    institution: 'Central Carolina Community College',
  },
  {
    institution: 'Central Piedmont Community College',
  },
  {
    institution: 'Chowan University',
  },
  {
    institution: 'Cleveland Community College',
  },
  {
    institution: 'Coastal Carolina Community College',
  },
  {
    institution: 'Craven Community College',
  },
  {
    institution: 'Davidson County Community College',
  },
  {
    institution: 'Davidson College',
  },
  {
    institution: 'Duke University',
  },
  {
    institution: 'Durham Technical Community College',
  },
  {
    institution: 'East Carolina University',
  },
  {
    institution: 'Edgecombe Community College',
  },
  {
    institution: 'Elizabeth City State University',
  },
  {
    institution: 'Elon University',
  },
  {
    institution: 'Fayetteville Beauty College',
  },
  {
    institution: 'Fayetteville Technical Community College',
  },
  {
    institution: 'Fayetteville State University',
  },
  {
    institution: 'Forsyth Technical Community College',
  },
  {
    institution: 'Gardner-Webb University',
  },
  {
    institution: 'Gaston College',
  },
  {
    institution: 'Greensboro College',
  },
  {
    institution: 'Guilford College',
  },
  {
    institution: 'Guilford Technical Community College',
  },
  {
    institution: 'Halifax Community College',
  },
  {
    institution: 'Haywood Community College',
  },
  {
    institution: 'Heritage Bible College',
  },
  {
    institution: 'High Point University',
  },
  {
    institution: 'Isothermal Community College',
  },
  {
    institution: 'James Sprunt Community College',
  },
  {
    institution: 'Laurel University',
  },
  {
    institution: 'Johnson C Smith University',
  },
  {
    institution: 'Johnston Community College',
  },
  {
    institution: 'Lees-McRae College',
  },
  {
    institution: 'Lenoir Community College',
  },
  {
    institution: 'Lenoir-Rhyne University',
  },
  {
    institution: 'Leons Beauty School Inc',
  },
  {
    institution: 'Livingstone College',
  },
  {
    institution: 'Louisburg College',
  },
  {
    institution: 'Mars Hill University',
  },
  {
    institution: 'Martin Community College',
  },
  {
    institution: 'Mayland Community College',
  },
  {
    institution: 'McDowell Technical Community College',
  },
  {
    institution: 'Mercy School of Nursing',
  },
  {
    institution: 'Meredith College',
  },
  {
    institution: 'Methodist University',
  },
  {
    institution: 'Miller-Motte College-Wilmington',
  },
  {
    institution: 'Mitchell Community College',
  },
  {
    institution: 'Mitchells Hairstyling Academy-Greenville',
  },
  {
    institution: 'Mitchells Hairstyling Academy-Wilson',
  },
  {
    institution: "Regina's College of Beauty-Monroe",
  },
  {
    institution: 'Montgomery Community College',
  },
  {
    institution: 'Montreat College',
  },
  {
    institution: 'Mount Olive College',
  },
  {
    institution: 'College of Wilmington',
  },
  {
    institution: 'Nash Community College',
  },
  {
    institution: 'North Carolina A & T State University',
  },
  {
    institution: 'University of North Carolina at Asheville',
  },
  {
    institution: 'University of North Carolina at Chapel Hill',
  },
  {
    institution: 'University of North Carolina at Charlotte',
  },
  {
    institution: 'University of North Carolina at Greensboro',
  },
  {
    institution: 'North Carolina Central University',
  },
  {
    institution: 'University of North Carolina System Office',
  },
  {
    institution: 'University of North Carolina School of the Arts',
  },
  {
    institution: 'North Carolina State University at Raleigh',
  },
  {
    institution: 'North Carolina Wesleyan College',
  },
  {
    institution: 'University of North Carolina Wilmington',
  },
  {
    institution: 'Pamlico Community College',
  },
  {
    institution: 'William Peace University',
  },
  {
    institution: 'University of North Carolina at Pembroke',
  },
  {
    institution: 'Pfeiffer University',
  },
  {
    institution: 'Piedmont International University',
  },
  {
    institution: 'Piedmont Community College',
  },
  {
    institution: 'Pitt Community College',
  },
  {
    institution: 'Queens University of Charlotte',
  },
  {
    institution: 'Randolph Community College',
  },
  {
    institution: 'Richmond Community College',
  },
  {
    institution: 'Mid-Atlantic Christian University',
  },
  {
    institution: 'Roanoke-Chowan Community College',
  },
  {
    institution: 'Robeson Community College',
  },
  {
    institution: 'Rockingham Community College',
  },
  {
    institution: 'Rowan-Cabarrus Community College',
  },
  {
    institution: "Saint Augustine's University",
  },
  {
    institution: 'Salem College',
  },
  {
    institution: 'Sampson Community College',
  },
  {
    institution: 'Sandhills Community College',
  },
  {
    institution: 'Shaw University',
  },
  {
    institution: 'Paul Mitchell the School-Fayetteville',
  },
  {
    institution: 'St Andrews University',
  },
  {
    institution: 'Empire Beauty School-Matthews',
  },
  {
    institution: 'Southeastern Community College',
  },
  {
    institution: 'Southwestern Community College',
  },
  {
    institution: 'Stanly Community College',
  },
  {
    institution: 'Southeastern Baptist Theological Seminary',
  },
  {
    institution: 'Surry Community College',
  },
  {
    institution: 'Alamance Community College',
  },
  {
    institution: 'Tri-County Community College',
  },
  {
    institution: 'Vance-Granville Community College',
  },
  {
    institution: 'Wake Forest University',
  },
  {
    institution: 'Wake Technical Community College',
  },
  {
    institution: 'Warren Wilson College',
  },
  {
    institution: 'Watts School of Nursing',
  },
  {
    institution: 'Wayne Community College',
  },
  {
    institution: 'Western Piedmont Community College',
  },
  {
    institution: 'Wilkes Community College',
  },
  {
    institution: 'Wilson Community College',
  },
  {
    institution: 'Wingate University',
  },
  {
    institution: 'Carolina Christian College',
  },
  {
    institution: 'Winston Salem Barber School',
  },
  {
    institution: 'Winston-Salem State University',
  },
  {
    institution: 'Western Carolina University',
  },
  {
    institution: 'Rasmussen College-North Dakota',
  },
  {
    institution: 'Bismarck State College',
  },
  {
    institution: 'Dickinson State University',
  },
  {
    institution: 'Fort Berthold Community College',
  },
  {
    institution: "Josef's School of Hair Design Inc-Grand Forks",
  },
  {
    institution: 'Headquarters Academy of Hair Design Inc',
  },
  {
    institution: 'University of Jamestown',
  },
  {
    institution: "Josef's School of Hair Design Inc-Fargo Downtown",
  },
  {
    institution: 'Lake Region State College',
  },
  {
    institution: 'Cankdeska Cikana Community College',
  },
  {
    institution: 'University of Mary',
  },
  {
    institution: 'Mayville State University',
  },
  {
    institution: 'Sanford College of Nursing',
  },
  {
    institution: 'Minot State University',
  },
  {
    institution: 'Moler Barber College',
  },
  {
    institution: 'University of North Dakota',
  },
  {
    institution: 'North Dakota State College of Science',
  },
  {
    institution: 'Dakota College at Bottineau',
  },
  {
    institution: 'North Dakota State University-Main Campus',
  },
  {
    institution: 'Williston State College',
  },
  {
    institution: 'JZ Trend Academy Paul Mitchell Partner School',
  },
  {
    institution: 'Sitting Bull College',
  },
  {
    institution: 'Trinity Bible College',
  },
  {
    institution: 'Turtle Mountain Community College',
  },
  {
    institution: 'United Tribes Technical College',
  },
  {
    institution: 'Valley City State University',
  },
  {
    institution: 'ETI Technical College',
  },
  {
    institution: 'The Art Institute of Cincinnati',
  },
  {
    institution: 'Miami-Jacobs Career College-Independence',
  },
  {
    institution:
      'Air Force Institute of Technology-Graduate School of Engineering & Management',
  },
  {
    institution: 'Akron Institute of Herzing University',
  },
  {
    institution: 'University of Akron Main Campus',
  },
  {
    institution: 'Akron School of Practical Nursing',
  },
  {
    institution: 'University of Akron Wayne College',
  },
  {
    institution: 'Allegheny Wesleyan College',
  },
  {
    institution: 'Antonelli College-Cincinnati',
  },
  {
    institution: 'Apollo Career Center',
  },
  {
    institution: 'Art Academy of Cincinnati',
  },
  {
    institution: 'Ashland County-West Holmes Career Center',
  },
  {
    institution: 'Ashland University',
  },
  {
    institution: 'Ashtabula County Technical and Career Campus',
  },
  {
    institution: 'Athenaeum of Ohio',
  },
  {
    institution: 'Auburn Career Center',
  },
  {
    institution: 'Aultman College of Nursing and Health Sciences',
  },
  {
    institution: 'Baldwin Wallace University',
  },
  {
    institution: 'Belmont College',
  },
  {
    institution: 'Bluffton University',
  },
  {
    institution: 'Fortis College-Ravenna',
  },
  {
    institution: 'Bowling Green State University-Firelands',
  },
  {
    institution: 'Bowling Green State University-Main Campus',
  },
  {
    institution: 'Bryant & Stratton College-Parma',
  },
  {
    institution: 'Buckeye Hills Career Center',
  },
  {
    institution: 'Butler Tech-D Russel Lee Career Center',
  },
  {
    institution: 'Canton City Schools Adult Career and Technical Education',
  },
  {
    institution: 'Capital University',
  },
  {
    institution: 'Valley Beauty School',
  },
  {
    institution: 'Carousel Beauty College-Middletown',
  },
  {
    institution: 'Carousel Beauty College-Dayton',
  },
  {
    institution: 'Carousel of Miami Valley Beauty College',
  },
  {
    institution: 'Case Western Reserve University',
  },
  {
    institution: 'Cedarville University',
  },
  {
    institution: 'Central Ohio Technical College',
  },
  {
    institution: 'Central School of Practical Nursing',
  },
  {
    institution: 'Central State University',
  },
  {
    institution: 'Chatfield College',
  },
  {
    institution: 'Choffin Career  and Technical Center',
  },
  {
    institution: 'The Christ College of Nursing and Health Sciences',
  },
  {
    institution: 'Cincinnati Christian University',
  },
  {
    institution: 'Cincinnati College of Mortuary Science',
  },
  {
    institution: 'University of Cincinnati-Main Campus',
  },
  {
    institution: 'Cincinnati State Technical and Community College',
  },
  {
    institution: 'University of Cincinnati-Clermont College',
  },
  {
    institution: 'University of Cincinnati-Blue Ash College',
  },
  {
    institution: 'Ohio Christian University',
  },
  {
    institution: 'Clark State Community College',
  },
  {
    institution: 'Scott College of Cosmetology',
  },
  {
    institution: 'Cleveland Institute of Art',
  },
  {
    institution: 'Cleveland Institute of Dental-Medical Assistants-Cleveland',
  },
  {
    institution: 'Cleveland Institute of Music',
  },
  {
    institution: 'Cleveland State University',
  },
  {
    institution: 'Columbiana County Career and Technical Center',
  },
  {
    institution: 'Bradford School',
  },
  {
    institution: 'Columbus College of Art and Design',
  },
  {
    institution: 'Kaplan College-Columbus',
  },
  {
    institution: 'Columbus State Community College',
  },
  {
    institution: 'Cuyahoga Community College District',
  },
  {
    institution: 'Davis College',
  },
  {
    institution: 'Dayton Barber College',
  },
  {
    institution: 'University of Dayton',
  },
  {
    institution: 'Defiance College',
  },
  {
    institution: 'Denison University',
  },
  {
    institution: 'DeVry University-Ohio',
  },
  {
    institution: 'Chancellor University',
  },
  {
    institution: 'Eastern Hills Academy of Hair Design',
  },
  {
    institution: 'Edison State Community College',
  },
  {
    institution: 'EHOVE Career Center',
  },
  {
    institution: 'Bryant & Stratton College-Cleveland',
  },
  {
    institution: 'Regency Beauty Institute-Cleveland',
  },
  {
    institution: 'The University of Findlay',
  },
  {
    institution: 'Franklin University',
  },
  {
    institution: 'Gerbers Akron Beauty School',
  },
  {
    institution: 'Gods Bible School and College',
  },
  {
    institution: 'Good Samaritan College of Nursing and Health Science',
  },
  {
    institution: 'Great Oaks Institute of Technology and Career Development',
  },
  {
    institution: 'Hannah E Mullins School of Practical Nursing',
  },
  {
    institution: 'Adult and Community Education-Hudson',
  },
  {
    institution: 'Hebrew Union College-Jewish Institute of Religion',
  },
  {
    institution: 'Heidelberg University',
  },
  {
    institution: 'Hiram College',
  },
  {
    institution: 'Hobart Institute of Welding Technology',
  },
  {
    institution: 'Hocking College',
  },
  {
    institution: 'American School of Technology',
  },
  {
    institution: 'Ross Medical Education Center-Cincinnati',
  },
  {
    institution: 'International College of Broadcasting',
  },
  {
    institution: 'ITT Technical Institute-Dayton',
  },
  {
    institution: 'Eastern Gateway Community College',
  },
  {
    institution: 'John Carroll University',
  },
  {
    institution: 'Hondros College',
  },
  {
    institution: 'Casal Aveda Institute',
  },
  {
    institution: 'Kent State University at Ashtabula',
  },
  {
    institution: 'Kent State University at East Liverpool',
  },
  {
    institution: 'Kent State University at Stark',
  },
  {
    institution: 'Kent State University at Trumbull',
  },
  {
    institution: 'Kent State University at Tuscarawas',
  },
  {
    institution: 'Kent State University at Salem',
  },
  {
    institution: 'Kent State University at Kent',
  },
  {
    institution: 'Kent State University at Geauga',
  },
  {
    institution: 'Kenyon College',
  },
  {
    institution: 'Kettering College',
  },
  {
    institution: 'Lake Erie College',
  },
  {
    institution: 'Lakeland Community College',
  },
  {
    institution: 'O C Collins Career Center',
  },
  {
    institution: 'James A Rhodes State College',
  },
  {
    institution: 'Ohio Business College-Sheffield',
  },
  {
    institution: 'Ohio Business College-Sandusky',
  },
  {
    institution: 'Lorain County Community College',
  },
  {
    institution: 'Lourdes University',
  },
  {
    institution: 'Malone University',
  },
  {
    institution: 'Marietta College',
  },
  {
    institution: 'Marion Technical College',
  },
  {
    institution: 'Medina County Career Center',
  },
  {
    institution: 'Mercy College of Ohio',
  },
  {
    institution: 'Methodist Theological School in Ohio',
  },
  {
    institution: 'Miami University-Hamilton',
  },
  {
    institution: 'Miami University-Middletown',
  },
  {
    institution: 'Miami University-Oxford',
  },
  {
    institution: 'Miami-Jacobs Career College-Dayton',
  },
  {
    institution: 'Moler Hollywood Beauty Academy',
  },
  {
    institution: 'Miami Valley Career Technology Center',
  },
  {
    institution: 'Mount Carmel College of Nursing',
  },
  {
    institution: 'University of Mount Union',
  },
  {
    institution: 'Mount Vernon Nazarene University',
  },
  {
    institution: 'College of Mount St Joseph',
  },
  {
    institution: 'Zane State College',
  },
  {
    institution: 'Muskingum University',
  },
  {
    institution: 'Fortis College-Cuyahoga Falls',
  },
  {
    institution: 'Brown Mackie College-North Canton',
  },
  {
    institution: 'National Beauty College',
  },
  {
    institution: 'North Central State College',
  },
  {
    institution: 'Northern Institute of Cosmetology',
  },
  {
    institution: 'Northwest State Community College',
  },
  {
    institution: 'Toledo Public Schools Adult and Continuing Education',
  },
  {
    institution: 'Notre Dame College',
  },
  {
    institution: 'Northeast Ohio Medical University',
  },
  {
    institution: 'University of Northwestern Ohio',
  },
  {
    institution: 'Oberlin College',
  },
  {
    institution: 'Ohio College of Massotherapy Inc',
  },
  {
    institution: 'Ohio Technical College',
  },
  {
    institution: 'Ohio Dominican University',
  },
  {
    institution: 'Kaplan College-Dayton',
  },
  {
    institution: 'Ohio Northern University',
  },
  {
    institution: 'Ohio State University Agricultural Technical Institute',
  },
  {
    institution: 'Ohio State University-Lima Campus',
  },
  {
    institution: 'Ohio State University-Mansfield Campus',
  },
  {
    institution: 'Ohio State University-Marion Campus',
  },
  {
    institution: 'Ohio State University-Newark Campus',
  },
  {
    institution: 'Ohio State Beauty Academy',
  },
  {
    institution: 'Ohio State College of Barber Styling',
  },
  {
    institution: 'Ohio State School of Cosmetology & Experts Barber School',
  },
  {
    institution: 'The Spa School',
  },
  {
    institution: 'Ohio State School of Cosmetology-Canal Winchester',
  },
  {
    institution: 'Ohio State University-Main Campus',
  },
  {
    institution: 'Ohio University-Eastern Campus',
  },
  {
    institution: 'Ohio University-Chillicothe Campus',
  },
  {
    institution: 'Ohio University-Southern Campus',
  },
  {
    institution: 'Ohio University-Lancaster Campus',
  },
  {
    institution: 'Ohio University-Main Campus',
  },
  {
    institution: 'Ohio University-Zanesville Campus',
  },
  {
    institution: 'Ohio Valley College of Technology',
  },
  {
    institution: 'Trinity Health System School of Nursing',
  },
  {
    institution: 'Ohio Wesleyan University',
  },
  {
    institution: 'Otterbein University',
  },
  {
    institution: 'Owens Community College',
  },
  {
    institution: 'Paramount Beauty Academy',
  },
  {
    institution: 'Cuyahoga Valley Career Center',
  },
  {
    institution: 'Payne Theological Seminary',
  },
  {
    institution: 'Pontifical College Josephinum',
  },
  {
    institution: 'Professional Skills Institute',
  },
  {
    institution: 'Firelands Regional Medical Center School of Nursing',
  },
  {
    institution: 'Rabbinical College Telshe',
  },
  {
    institution: "Raphael's School of Beauty Culture Inc-Niles",
  },
  {
    institution: 'Fortis College-Centerville',
  },
  {
    institution: 'University of Rio Grande',
  },
  {
    institution:
      'Advertising Art Educational Services DBA School of Advertising Art',
  },
  {
    institution: 'Shawnee State University',
  },
  {
    institution: 'Sinclair Community College',
  },
  {
    institution: 'Gallipolis Career College',
  },
  {
    institution: 'Daymar College-New Boston',
  },
  {
    institution: 'Daymar College-Jackson',
  },
  {
    institution: 'Daymar College-Lancaster',
  },
  {
    institution: 'Daymar College-Chillicothe',
  },
  {
    institution: 'Brown Mackie College-Cincinnati',
  },
  {
    institution: 'Brown Mackie College-Akron',
  },
  {
    institution: 'Lincoln College of Technology-Franklin LCT',
  },
  {
    institution: 'Lincoln College of Technology-Tri-County',
  },
  {
    institution: 'Lincoln College of Technology-Vine Street',
  },
  {
    institution: 'Lincoln College of Technology-Dayton',
  },
  {
    institution: 'Stark State College',
  },
  {
    institution: 'Stautzenberger College-Maumee',
  },
  {
    institution: 'Franciscan University of Steubenville',
  },
  {
    institution: 'Southern State Community College',
  },
  {
    institution: 'Ohio Mid-Western College',
  },
  {
    institution: 'Terra State Community College',
  },
  {
    institution: 'Tiffin Academy of Hair Design',
  },
  {
    institution: 'Tiffin University',
  },
  {
    institution: 'University of Toledo',
  },
  {
    institution: 'Kaplan Career Institute-Cleveland',
  },
  {
    institution: 'Tri-State Bible College',
  },
  {
    institution: 'Tri-County Adult Career Center',
  },
  {
    institution: 'Tri-Rivers Career Center',
  },
  {
    institution: 'Trinity Lutheran Seminary',
  },
  {
    institution: 'Trumbull Business College',
  },
  {
    institution: 'Union Institute & University',
  },
  {
    institution: 'United Theological Seminary',
  },
  {
    institution: 'Upper Valley Career Center',
  },
  {
    institution: 'Urbana University',
  },
  {
    institution: 'Ursuline College',
  },
  {
    institution: 'Valley Beauty School',
  },
  {
    institution: 'Virginia Marti College of Art and Design',
  },
  {
    institution: 'Cut Beauty School',
  },
  {
    institution: 'W Howard Nicol School of Practical Nursing',
  },
  {
    institution: 'Walsh University',
  },
  {
    institution: 'Washington State Community College',
  },
  {
    institution: 'Western Hills School of Beauty and Hair Design',
  },
  {
    institution: 'Wilberforce University',
  },
  {
    institution: 'Wilmington College',
  },
  {
    institution: 'Winebrenner Theological Seminary',
  },
  {
    institution: 'Wittenberg University',
  },
  {
    institution: 'The College of Wooster',
  },
  {
    institution: 'Wright State University-Main Campus',
  },
  {
    institution: 'Wright State University-Lake Campus',
  },
  {
    institution: 'Xavier University',
  },
  {
    institution: 'ITT Technical Institute-Youngstown',
  },
  {
    institution: 'Youngstown State University',
  },
  {
    institution: 'American Broadcasting School-Oklahoma City',
  },
  {
    institution: 'Bacone College',
  },
  {
    institution: 'Oklahoma Wesleyan University',
  },
  {
    institution: 'Southern Nazarene University',
  },
  {
    institution: 'Broken Arrow Beauty College-Broken Arrow',
  },
  {
    institution: 'Pontotoc Technology Center',
  },
  {
    institution: 'Cameron University',
  },
  {
    institution: 'Carl Albert State College',
  },
  {
    institution: 'Central State Beauty Academy',
  },
  {
    institution: 'University of Central Oklahoma',
  },
  {
    institution: 'Claremore Beauty College',
  },
  {
    institution: 'Connors State College',
  },
  {
    institution: 'East Central University',
  },
  {
    institution: 'Eastern Oklahoma State College',
  },
  {
    institution: 'Redlands Community College',
  },
  {
    institution: 'Enid Beauty College',
  },
  {
    institution: 'Eves College of Hairstyling',
  },
  {
    institution: 'Hillsdale Free Will Baptist College',
  },
  {
    institution: 'Hollywood Cosmetology Center',
  },
  {
    institution: 'Langston University',
  },
  {
    institution: 'Murray State College',
  },
  {
    institution: 'Spartan College of Aeronautics and Technology',
  },
  {
    institution: 'Northeastern State University',
  },
  {
    institution: 'Beauty Technical College Inc',
  },
  {
    institution: 'Northern Oklahoma College',
  },
  {
    institution: 'Northeastern Oklahoma A&M College',
  },
  {
    institution: 'Northwestern Oklahoma State University',
  },
  {
    institution: 'Oklahoma State University Center for Health Sciences',
  },
  {
    institution: 'Oklahoma Christian University',
  },
  {
    institution: 'University of Oklahoma-Health Sciences Center',
  },
  {
    institution: 'Oklahoma Panhandle State University',
  },
  {
    institution: 'Oklahoma State University-Main Campus',
  },
  {
    institution: 'Oklahoma State University-Oklahoma City',
  },
  {
    institution: 'Oklahoma Baptist University',
  },
  {
    institution: 'Oklahoma City Community College',
  },
  {
    institution: 'Oklahoma City University',
  },
  {
    institution: 'University of Oklahoma-Norman Campus',
  },
  {
    institution: 'Oklahoma School of Photography',
  },
  {
    institution: 'Oklahoma State University Institute of Technology',
  },
  {
    institution: 'Oral Roberts University',
  },
  {
    institution: 'Tulsa Technology Center-Peoria Campus',
  },
  {
    institution: 'Platt College-Central OKC',
  },
  {
    institution: 'Rogers State University',
  },
  {
    institution: 'Rose State College',
  },
  {
    institution: "Saint Gregory's University",
  },
  {
    institution: 'Standard Beauty College of Oklahoma',
  },
  {
    institution: 'University of Science and Arts of Oklahoma',
  },
  {
    institution: 'Seminole State College',
  },
  {
    institution: 'Southern School of Beauty Inc',
  },
  {
    institution: 'Southeastern Oklahoma State University',
  },
  {
    institution: 'Southwestern Christian University',
  },
  {
    institution: 'Southwestern Oklahoma State University',
  },
  {
    institution: 'Tulsa Community College',
  },
  {
    institution: 'Tulsa Welding School-Tulsa',
  },
  {
    institution: 'University of Tulsa',
  },
  {
    institution: "Virgil's Beauty College",
  },
  {
    institution: 'Indian Capital Technology Center-Tahlequah',
  },
  {
    institution: 'Western Oklahoma State College',
  },
  {
    institution: 'Woodward Beauty College',
  },
  {
    institution: 'High Plains Technology Center',
  },
  {
    institution: 'Academy of Hair Design-Salem',
  },
  {
    institution: 'Abdill Career College Inc',
  },
  {
    institution: 'Paul Mitchell the School-Portland',
  },
  {
    institution: 'The Art Institute of Portland',
  },
  {
    institution: 'Beau Monde College of Hair Design',
  },
  {
    institution: 'Blue Mountain Community College',
  },
  {
    institution: 'Central Oregon Community College',
  },
  {
    institution: 'Chemeketa Community College',
  },
  {
    institution: 'Clackamas Community College',
  },
  {
    institution: 'Clatsop Community College',
  },
  {
    institution: 'College of Hair Design Careers',
  },
  {
    institution: 'Concorde Career College-Portland',
  },
  {
    institution: 'Concordia University-Portland',
  },
  {
    institution: 'Sumner College',
  },
  {
    institution: 'East West College of the Healing Arts',
  },
  {
    institution: 'Eastern Oregon University',
  },
  {
    institution: 'Phagans School of Hair Design-Portland',
  },
  {
    institution: 'New Hope Christian College-Eugene',
  },
  {
    institution: 'George Fox University',
  },
  {
    institution: 'Phagans Grants Pass College of Beauty',
  },
  {
    institution: 'ITT Technical Institute-Portland',
  },
  {
    institution: 'Lane Community College',
  },
  {
    institution: 'Lewis & Clark College',
  },
  {
    institution: 'Linfield College-McMinnville Campus',
  },
  {
    institution: 'Linn-Benton Community College',
  },
  {
    institution: 'Northwest College-Beaverton',
  },
  {
    institution: 'Marylhurst University',
  },
  {
    institution: 'Phagans Medford Beauty School',
  },
  {
    institution: 'Northwest College-Clackamas',
  },
  {
    institution: 'Mount Angel Seminary',
  },
  {
    institution: 'Mt Hood Community College',
  },
  {
    institution: 'Multnomah University',
  },
  {
    institution: 'National College of Natural Medicine',
  },
  {
    institution: 'Northwest Nannies Institute',
  },
  {
    institution: 'Northwest Christian University',
  },
  {
    institution: 'Oregon University System',
  },
  {
    institution: 'Oregon Health & Science University',
  },
  {
    institution: 'Oregon Institute of Technology',
  },
  {
    institution: 'Oregon College of Art and Craft',
  },
  {
    institution: 'Oregon State University',
  },
  {
    institution: 'University of Oregon',
  },
  {
    institution: 'Pacific Northwest College of Art',
  },
  {
    institution: 'Pacific University',
  },
  {
    institution: 'Phagans Beauty College',
  },
  {
    institution: 'Phagans Central Oregon Beauty College',
  },
  {
    institution: 'Phagans School of Beauty',
  },
  {
    institution: 'Phagans School of Hair Design',
  },
  {
    institution: 'Phagans Tigard Beauty School',
  },
  {
    institution: 'Portland Community College',
  },
  {
    institution: 'Portland State University',
  },
  {
    institution: 'University of Portland',
  },
  {
    institution: 'Reed College',
  },
  {
    institution: 'Rogue Community College',
  },
  {
    institution: 'Roseburg Beauty College',
  },
  {
    institution: 'Pioneer Pacific College',
  },
  {
    institution: 'Springfield College of Beauty',
  },
  {
    institution: 'Southern Oregon University',
  },
  {
    institution: 'Southwestern Oregon Community College',
  },
  {
    institution: 'Treasure Valley Community College',
  },
  {
    institution: 'Umpqua Community College',
  },
  {
    institution: 'Warner Pacific College',
  },
  {
    institution: 'Corban University',
  },
  {
    institution: 'College of Cosmetology',
  },
  {
    institution: 'Everest College-Portland',
  },
  {
    institution: 'Western Seminary',
  },
  {
    institution: 'Willamette University',
  },
  {
    institution: 'Western Oregon University',
  },
  {
    institution: 'University of Western States',
  },
  {
    institution: 'Abington Memorial Hospital Dixon School of Nursing',
  },
  {
    institution: 'Jolie Hair and Beauty Academy-Hazleton',
  },
  {
    institution: 'Keystone Technical Institute',
  },
  {
    institution: 'Bryn Athyn College of the New Church',
  },
  {
    institution: 'Academy of Vocal Arts',
  },
  {
    institution: 'Albright College',
  },
  {
    institution: 'All-State Career School-Lester',
  },
  {
    institution: 'Community College of Allegheny County',
  },
  {
    institution: 'Allegheny College',
  },
  {
    institution: 'The Vision Academy',
  },
  {
    institution: 'DeSales University',
  },
  {
    institution: 'Altoona Beauty School Inc',
  },
  {
    institution: 'Alvernia University',
  },
  {
    institution: 'Magnolia School',
  },
  {
    institution: 'American College of Financial Services',
  },
  {
    institution: 'Antonelli Institute',
  },
  {
    institution: 'The Art Institutes of York-PA',
  },
  {
    institution: 'The Art Institute of Philadelphia',
  },
  {
    institution: 'Career Training Academy-New Kensington',
  },
  {
    institution: 'The Art Institute of Pittsburgh',
  },
  {
    institution: 'Automotive Training Center-Exton',
  },
  {
    institution: 'Baptist Bible College & Seminary of Pennsylvania',
  },
  {
    institution: 'Community College of Beaver County',
  },
  {
    institution: 'Arcadia University',
  },
  {
    institution: 'Biblical Theological Seminary',
  },
  {
    institution: 'Bidwell Training Center Inc',
  },
  {
    institution: 'Bloomsburg University of Pennsylvania',
  },
  {
    institution: 'Bradford School',
  },
  {
    institution: 'Bryn Mawr College',
  },
  {
    institution: 'Bucknell University',
  },
  {
    institution: 'Bucks County Community College',
  },
  {
    institution: "Buck's County School of Beauty Culture Inc",
  },
  {
    institution: 'Butler Beauty Academy',
  },
  {
    institution: 'Butler County Community College',
  },
  {
    institution: 'Cabrini College',
  },
  {
    institution: 'California University of Pennsylvania',
  },
  {
    institution: 'Calvary Baptist Theological Seminary',
  },
  {
    institution: 'Cambria-Rowe Business College-Johnstown',
  },
  {
    institution: 'Carlow University',
  },
  {
    institution: 'Carnegie Mellon University',
  },
  {
    institution: 'Cedar Crest College',
  },
  {
    institution: 'Central Penn College',
  },
  {
    institution: 'Chatham University',
  },
  {
    institution: 'Chestnut Hill College',
  },
  {
    institution: 'Cheyney University of Pennsylvania',
  },
  {
    institution: 'Kaplan Career Institute-Franklin Mills',
  },
  {
    institution: 'Citizens School of Nursing',
  },
  {
    institution: 'Clarion University of Pennsylvania',
  },
  {
    institution: 'YTI Career Institute-Capital Region',
  },
  {
    institution: 'Conemaugh Valley Memorial Hospital',
  },
  {
    institution: 'Consolidated School of Business-York',
  },
  {
    institution: 'Curtis Institute of Music',
  },
  {
    institution: 'Dean Institute of Technology',
  },
  {
    institution: 'Delaware County Community College',
  },
  {
    institution: 'Delaware Valley College',
  },
  {
    institution: 'Dickinson College',
  },
  {
    institution:
      'The Dickinson School of Law of the Pennsylvania State University',
  },
  {
    institution: 'Douglas Education Center',
  },
  {
    institution: 'Drexel University',
  },
  {
    institution: 'Du Bois Business College-Du Bois',
  },
  {
    institution: 'Everest Institute-Pittsburgh',
  },
  {
    institution: 'Duquesne University',
  },
  {
    institution: 'East Stroudsburg University of Pennsylvania',
  },
  {
    institution: 'Eastern University',
  },
  {
    institution: 'Edinboro University of Pennsylvania',
  },
  {
    institution: 'Elizabethtown College',
  },
  {
    institution: 'Empire Beauty School-State College',
  },
  {
    institution: 'Empire Beauty School-Lebanon',
  },
  {
    institution: 'Empire Beauty School-York',
  },
  {
    institution: 'Empire Beauty School-Lancaster',
  },
  {
    institution: 'Empire Beauty School-Williamsport',
  },
  {
    institution: 'Empire Beauty School-Shamokin Dam',
  },
  {
    institution: 'Empire Beauty School-Pottsville',
  },
  {
    institution: 'Empire Beauty  School-Lehigh Valley',
  },
  {
    institution: 'Empire Beauty School-Gwinnett',
  },
  {
    institution: 'Empire Beauty School-Wyoming Valley',
  },
  {
    institution: 'Empire Beauty School-Warminster',
  },
  {
    institution: 'Empire Beauty School-Harrisburg',
  },
  {
    institution: 'Empire Beauty School-Reading',
  },
  {
    institution: 'Erie Business Center-Erie',
  },
  {
    institution: 'Erie Institute of Technology Inc',
  },
  {
    institution: 'Evangelical Theological Seminary',
  },
  {
    institution: 'Faith Theological Seminary',
  },
  {
    institution: 'Aria Health School of Nursing',
  },
  {
    institution: 'Franklin and Marshall College',
  },
  {
    institution: 'Gannon University',
  },
  {
    institution: 'Geneva College',
  },
  {
    institution: 'Gettysburg College',
  },
  {
    institution: 'Empire Beauty School-Exton',
  },
  {
    institution: 'Empire Beauty School-Laurel Springs',
  },
  {
    institution: 'Empire Beauty School-NE Philadelphia',
  },
  {
    institution: 'Gratz College',
  },
  {
    institution: 'Grove City College',
  },
  {
    institution: 'Gwynedd Mercy University',
  },
  {
    institution: 'Harcum College',
  },
  {
    institution: 'Harrisburg Area Community College-Harrisburg',
  },
  {
    institution: 'Haverford College',
  },
  {
    institution: 'Commonwealth Technical Institute',
  },
  {
    institution: 'Holy Family University',
  },
  {
    institution: 'Hussian School of Art',
  },
  {
    institution: 'Kaplan Career Institute-Pittsburgh',
  },
  {
    institution: 'Immaculata University',
  },
  {
    institution: 'Indiana University of Pennsylvania-Main Campus',
  },
  {
    institution: 'Pennsylvania School of Business',
  },
  {
    institution: 'Jameson Health System',
  },
  {
    institution: 'Great Lakes Institute of Technology',
  },
  {
    institution: 'Jolie Hair and Beauty Academy-Wilkes-Barre',
  },
  {
    institution: 'Johnson College',
  },
  {
    institution: 'Juniata College',
  },
  {
    institution: 'Keystone College',
  },
  {
    institution: 'Anthem Institute-Springfield',
  },
  {
    institution: "King's College",
  },
  {
    institution: 'Kittanning Beauty School',
  },
  {
    institution: 'Kutztown University of Pennsylvania',
  },
  {
    institution: 'La Roche College',
  },
  {
    institution: 'La Salle University',
  },
  {
    institution: 'Lackawanna College',
  },
  {
    institution: 'Lafayette College',
  },
  {
    institution: 'Lancaster Bible College',
  },
  {
    institution: 'Lancaster Theological Seminary',
  },
  {
    institution: 'Lancaster School of Cosmetology',
  },
  {
    institution: 'Lansdale School of Business',
  },
  {
    institution: 'Lansdale School of Cosmetology Inc',
  },
  {
    institution: 'Lebanon Valley College',
  },
  {
    institution: 'Lehigh Carbon Community College',
  },
  {
    institution: 'Berks Technical Institute',
  },
  {
    institution: 'Lehigh University',
  },
  {
    institution: 'Levittown Beauty Academy',
  },
  {
    institution: 'Lincoln Technical Institute-Allentown',
  },
  {
    institution: 'Lincoln Technical Institute-Philadelphia',
  },
  {
    institution: 'Lincoln University of Pennsylvania',
  },
  {
    institution: 'Lock Haven University',
  },
  {
    institution: 'St Margaret School of Nursing',
  },
  {
    institution: 'Lutheran Theological Seminary at Gettysburg',
  },
  {
    institution: 'Lutheran Theological Seminary at Philadelphia',
  },
  {
    institution: 'Luzerne County Community College',
  },
  {
    institution: 'Lycoming College',
  },
  {
    institution: 'Erie Business Center-New Castle',
  },
  {
    institution: 'Manor College',
  },
  {
    institution: 'Mansfield University of Pennsylvania',
  },
  {
    institution: 'Marywood University',
  },
  {
    institution: 'Vet Tech Institute',
  },
  {
    institution: 'Roxborough Memorial Hospital School of Nursing',
  },
  {
    institution: 'Mercyhurst University',
  },
  {
    institution: 'Messiah College',
  },
  {
    institution: 'Metropolitan Career Center Computer Technology Institute',
  },
  {
    institution: 'Millersville University of Pennsylvania',
  },
  {
    institution: 'Misericordia University',
  },
  {
    institution: 'Montgomery County Community College',
  },
  {
    institution: 'Moore College of Art and Design',
  },
  {
    institution: 'Moravian College',
  },
  {
    institution: 'Mount Aloysius College',
  },
  {
    institution: 'Muhlenberg College',
  },
  {
    institution: 'Kaplan Career Institute-Philadelphia',
  },
  {
    institution: 'Neumann University',
  },
  {
    institution: 'New Castle School of Trades',
  },
  {
    institution: 'Newport Business Institute-Lower Burrell',
  },
  {
    institution: 'Northampton County Area Community College',
  },
  {
    institution: "Pittsburgh's Ohio Valley Hospital School of Nursing",
  },
  {
    institution: 'Orleans Technical Institute',
  },
  {
    institution:
      'Pennsylvania Academy of Cosmetology Arts and Sciences-Johnstown',
  },
  {
    institution: 'Salus University',
  },
  {
    institution: 'Pennsylvania Institute of Technology',
  },
  {
    institution:
      'Pennsylvania State University-Penn State Erie-Behrend College',
  },
  {
    institution: 'Pennsylvania State University-Penn State Great Valley',
  },
  {
    institution: 'Pennsylvania State University-College of Medicine',
  },
  {
    institution: 'Pennsylvania State University-Penn State New Kensington',
  },
  {
    institution: 'Pennsylvania State University-Penn State Shenango',
  },
  {
    institution: 'Pennsylvania State University-Penn State Wilkes-Barre',
  },
  {
    institution:
      'Pennsylvania State University-Penn State Worthington Scranton',
  },
  {
    institution: 'Pennsylvania State System of Higher Education-Central Office',
  },
  {
    institution: 'Pennsylvania State University-Penn State Lehigh Valley',
  },
  {
    institution: 'Pennsylvania State University-Penn State Altoona',
  },
  {
    institution: 'Pennsylvania State University-Penn State Beaver',
  },
  {
    institution: 'Pennsylvania State University-Penn State Berks',
  },
  {
    institution: 'Pennsylvania State University-Penn State Harrisburg',
  },
  {
    institution: 'Pennsylvania State University-Penn State Brandywine',
  },
  {
    institution: 'Pennsylvania State University-Penn State Dubois',
  },
  {
    institution: 'Pennsylvania State University-Penn State Fayette- Eberly',
  },
  {
    institution: 'Pennsylvania State University-Penn State Hazleton',
  },
  {
    institution: 'Pennsylvania State University-Main Campus',
  },
  {
    institution: 'Pennsylvania State University-Penn State Greater Allegheny',
  },
  {
    institution: 'Pennsylvania State University-Penn State Mont Alto',
  },
  {
    institution: 'Pennsylvania State University-Penn State Abington',
  },
  {
    institution: 'Pennsylvania State University-Penn State Schuylkill',
  },
  {
    institution: 'Pennsylvania State University-Penn State York',
  },
  {
    institution: 'Pace Institute',
  },
  {
    institution: 'Peirce College',
  },
  {
    institution: 'Penn Commercial Business/Technical School',
  },
  {
    institution: 'Pennco Tech-Bristol',
  },
  {
    institution:
      'Pennsylvania Academy of Cosmetology Arts and Sciences-Du Bois',
  },
  {
    institution: 'Pennsylvania Academy of the Fine Arts',
  },
  {
    institution: 'Pennsylvania Gunsmith School',
  },
  {
    institution: 'Pennsylvania Institute of Taxidermy Inc',
  },
  {
    institution: 'Cortiva Institute-Pennsylvania School of Muscle Therapy',
  },
  {
    institution: 'Pennsylvania College of Art and Design',
  },
  {
    institution: 'University of Pennsylvania',
  },
  {
    institution: 'Philadelphia University',
  },
  {
    institution: 'The University of the Arts',
  },
  {
    institution: 'Cairn University-Langhorne',
  },
  {
    institution: 'Philadelphia College of Osteopathic Medicine',
  },
  {
    institution: 'University of the Sciences',
  },
  {
    institution: 'Community College of Philadelphia',
  },
  {
    institution: 'University of Pittsburgh-Bradford',
  },
  {
    institution: 'University of Pittsburgh-Greensburg',
  },
  {
    institution: 'University of Pittsburgh-Johnstown',
  },
  {
    institution: 'University of Pittsburgh-Pittsburgh Campus',
  },
  {
    institution: 'University of Pittsburgh-Titusville',
  },
  {
    institution: 'Beaver Falls Beauty Academy',
  },
  {
    institution: 'Pittsburgh Institute of Aeronautics',
  },
  {
    institution: 'Pittsburgh Institute of Mortuary Science Inc',
  },
  {
    institution: 'Pittsburgh Technical Institute',
  },
  {
    institution: 'Pittsburgh Theological Seminary',
  },
  {
    institution: 'Prism Career Institute-Upper Darby',
  },
  {
    institution: 'Point Park University',
  },
  {
    institution: 'Joseph F McCloskey School of Nursing at Schuylkill Health',
  },
  {
    institution: 'The Salon Professional Academy-Altoona',
  },
  {
    institution: 'Reading Area Community College',
  },
  {
    institution: 'Reading Hospital School of Health Sciences',
  },
  {
    institution: 'Reconstructionist Rabbinical College',
  },
  {
    institution: 'Reformed Presbyterian Theological Seminary',
  },
  {
    institution: 'The Restaurant School at Walnut Hill College',
  },
  {
    institution: 'Kaplan Career Institute-Broomall',
  },
  {
    institution: 'Robert Morris University',
  },
  {
    institution: 'Rosedale Technical Institute',
  },
  {
    institution: 'Rosemont College',
  },
  {
    institution: 'Saint Francis University',
  },
  {
    institution: "Saint Joseph's University",
  },
  {
    institution: 'Saint Vincent College',
  },
  {
    institution: 'Saint Vincent Seminary',
  },
  {
    institution: 'Schuylkill Technology Center',
  },
  {
    institution: 'University of Scranton',
  },
  {
    institution: 'Seton Hill University',
  },
  {
    institution: 'Heritage Valley Sewickley School of Nursing',
  },
  {
    institution:
      'University of Pittsburgh Medical Center-Shadyside School of Nursing',
  },
  {
    institution: 'Sharon Regional Health System School of Nursing',
  },
  {
    institution: 'Laurel Technical Institute',
  },
  {
    institution: 'Shippensburg University of Pennsylvania',
  },
  {
    institution: 'Slippery Rock University of Pennsylvania',
  },
  {
    institution: 'Saint Charles Borromeo Seminary-Overbrook',
  },
  {
    institution: 'South Hills Beauty Academy Inc',
  },
  {
    institution: 'South Hills School of Business & Technology',
  },
  {
    institution: 'St Lukes Hospital School of Nursing',
  },
  {
    institution: 'Stroudsburg School of Cosmetology',
  },
  {
    institution: 'Susquehanna University',
  },
  {
    institution: 'Swarthmore College',
  },
  {
    institution: 'Thaddeus Stevens College of Technology',
  },
  {
    institution: 'Talmudical Yeshiva of Philadelphia',
  },
  {
    institution: 'Temple University',
  },
  {
    institution: 'Theological Seminary of the Reformed Episcopal Church',
  },
  {
    institution: 'Thiel College',
  },
  {
    institution: 'Thomas Jefferson University',
  },
  {
    institution: 'Fortis Institute-Erie',
  },
  {
    institution: 'Triangle Tech Inc-Erie',
  },
  {
    institution: 'Triangle Tech Inc-Pittsburgh',
  },
  {
    institution: 'Triangle Tech Inc-Greensburg',
  },
  {
    institution: 'Triangle Tech Inc-Dubois',
  },
  {
    institution: 'Trinity Episcopal School for Ministry',
  },
  {
    institution: 'Ursinus College',
  },
  {
    institution: 'Valley Forge Christian College',
  },
  {
    institution: 'Valley Forge Military College',
  },
  {
    institution: 'Venus Beauty Academy',
  },
  {
    institution: 'Villanova University',
  },
  {
    institution: 'Washington Hospital School of Nursing',
  },
  {
    institution: 'Washington Hospital School of Radiography',
  },
  {
    institution: 'Washington & Jefferson College',
  },
  {
    institution: 'Waynesburg University',
  },
  {
    institution: 'Welder Training and Testing Institute',
  },
  {
    institution: 'West Chester University of Pennsylvania',
  },
  {
    institution: 'Western Pennsylvania Hospital School of Nursing',
  },
  {
    institution: 'Sanford-Brown Institute-Pittsburgh',
  },
  {
    institution: 'Westminster College',
  },
  {
    institution: 'Westminster Theological Seminary',
  },
  {
    institution: 'Westmoreland County Community College',
  },
  {
    institution: 'Widener University-Main Campus',
  },
  {
    institution: 'Wilkes University',
  },
  {
    institution: 'Newport Business Institute-Williamsport',
  },
  {
    institution: 'Wilson College',
  },
  {
    institution: 'Yeshivath Beth Moshe',
  },
  {
    institution: 'York College Pennsylvania',
  },
  {
    institution: 'YTI Career Institute-York',
  },
  {
    institution: 'Yorktowne Business Institute',
  },
  {
    institution: 'Empire Beauty School-Providence',
  },
  {
    institution: 'Brown University',
  },
  {
    institution: 'Bryant University',
  },
  {
    institution: 'Johnson & Wales University-Providence',
  },
  {
    institution: 'Sanford-Brown Institute-Cranston',
  },
  {
    institution: 'New England Institute of Technology',
  },
  {
    institution: 'New England Tractor Trailer Training School of Rhode Island',
  },
  {
    institution: 'Newport School of Hairdressing-Main Campus',
  },
  {
    institution: 'Providence College',
  },
  {
    institution: 'Rhode Island College',
  },
  {
    institution: 'Community College of Rhode Island',
  },
  {
    institution: 'University of Rhode Island',
  },
  {
    institution: 'Rhode Island School of Design',
  },
  {
    institution: 'Roger Williams University',
  },
  {
    institution: 'Salve Regina University',
  },
  {
    institution: 'St Joseph School of Nursing',
  },
  {
    institution: 'Empire Beauty School-Warwick',
  },
  {
    institution: 'Northpoint Bible College',
  },
  {
    institution: 'Aiken Technical College',
  },
  {
    institution: 'Allen University',
  },
  {
    institution: 'Anderson University',
  },
  {
    institution: 'Charleston Southern University',
  },
  {
    institution: 'Technical College of the Lowcountry',
  },
  {
    institution: 'Benedict College',
  },
  {
    institution: 'Bob Jones University',
  },
  {
    institution: 'Southern Wesleyan University',
  },
  {
    institution: 'Charleston Cosmetology Institute',
  },
  {
    institution: 'College of Charleston',
  },
  {
    institution: 'Charzanne Beauty College',
  },
  {
    institution: 'Northeastern Technical College',
  },
  {
    institution: 'Citadel Military College of South Carolina',
  },
  {
    institution: 'Claflin University',
  },
  {
    institution: 'Clemson University',
  },
  {
    institution: 'Clinton College',
  },
  {
    institution: 'Coker College',
  },
  {
    institution: 'Columbia International University',
  },
  {
    institution: 'Columbia College',
  },
  {
    institution: 'Converse College',
  },
  {
    institution: 'Denmark Technical College',
  },
  {
    institution: 'Erskine College',
  },
  {
    institution: 'Florence-Darlington Technical College',
  },
  {
    institution: 'Forrest College',
  },
  {
    institution: 'Francis Marion University',
  },
  {
    institution: 'Furman University',
  },
  {
    institution: 'Cathedral Bible College',
  },
  {
    institution: 'Greenville Technical College',
  },
  {
    institution: 'Horry-Georgetown Technical College',
  },
  {
    institution: 'Kenneth Shuler School of Cosmetology-North Augusta',
  },
  {
    institution: 'Kenneth Shuler School of Cosmetology and Nails-Columbia',
  },
  {
    institution: 'Lander University',
  },
  {
    institution: 'Limestone College',
  },
  {
    institution:
      'Lenoir-Rhyne University-Lutheran Theological Southern Seminary',
  },
  {
    institution: 'Medical University of South Carolina',
  },
  {
    institution: 'Midlands Technical College',
  },
  {
    institution: 'Morris College',
  },
  {
    institution: 'Newberry College',
  },
  {
    institution: 'North Greenville University',
  },
  {
    institution: 'Orangeburg Calhoun Technical College',
  },
  {
    institution: 'Piedmont Technical College',
  },
  {
    institution: 'Presbyterian College',
  },
  {
    institution: 'University of South Carolina-Aiken',
  },
  {
    institution: 'University of South Carolina-Beaufort',
  },
  {
    institution: 'University of South Carolina-Columbia',
  },
  {
    institution: 'University of South Carolina-Lancaster',
  },
  {
    institution: 'University of South Carolina-Salkehatchie',
  },
  {
    institution: 'University of South Carolina-Sumter',
  },
  {
    institution: 'University of South Carolina-Union',
  },
  {
    institution: 'Coastal Carolina University',
  },
  {
    institution: 'South Carolina State University',
  },
  {
    institution: 'University of South Carolina-Upstate',
  },
  {
    institution: 'Sherman College of Straight Chiropractic',
  },
  {
    institution: 'Spartanburg Methodist College',
  },
  {
    institution: 'Spartanburg Community College',
  },
  {
    institution: 'Central Carolina Technical College',
  },
  {
    institution: 'Sumter Beauty College',
  },
  {
    institution: 'Tri-County Technical College',
  },
  {
    institution: 'Trident Technical College',
  },
  {
    institution: 'Voorhees College',
  },
  {
    institution: 'Williamsburg Technical College',
  },
  {
    institution: 'Winthrop University',
  },
  {
    institution: 'Wofford College',
  },
  {
    institution: 'York Technical College',
  },
  {
    institution: 'Augustana College',
  },
  {
    institution: 'Black Hills Beauty College',
  },
  {
    institution: 'Black Hills State University',
  },
  {
    institution: 'Kilian Community College',
  },
  {
    institution: 'Dakota State University',
  },
  {
    institution: 'Dakota Wesleyan University',
  },
  {
    institution: 'Headlines Academy Inc',
  },
  {
    institution: 'Lake Area Technical Institute',
  },
  {
    institution: 'Avera McKennan Hospital School of Radiologic Technology',
  },
  {
    institution: 'Mitchell Technical Institute',
  },
  {
    institution: 'Mount Marty College',
  },
  {
    institution: 'National American University-Rapid City',
  },
  {
    institution: 'National American University-Sioux Falls',
  },
  {
    institution: 'Sioux Falls Seminary',
  },
  {
    institution: 'Northern State University',
  },
  {
    institution: 'Oglala Lakota College',
  },
  {
    institution: 'Presentation College',
  },
  {
    institution: 'Avera Sacred Heart Hospital',
  },
  {
    institution: 'South Dakota School of Mines and Technology',
  },
  {
    institution: 'South Dakota State University',
  },
  {
    institution: 'Sinte Gleska University',
  },
  {
    institution: 'University of Sioux Falls',
  },
  {
    institution: 'Sanford Medical Center',
  },
  {
    institution: 'Sisseton Wahpeton College',
  },
  {
    institution: 'Southeast Technical Institute',
  },
  {
    institution: 'Stewart School',
  },
  {
    institution: 'University of South Dakota',
  },
  {
    institution: 'Western Dakota Technical Institute',
  },
  {
    institution: 'American Baptist College',
  },
  {
    institution: 'Aquinas College',
  },
  {
    institution: 'Arnolds Beauty School',
  },
  {
    institution: 'Tennessee College of Applied Technology-Athens',
  },
  {
    institution: 'Austin Peay State University',
  },
  {
    institution: 'Baptist Memorial College of Health Sciences',
  },
  {
    institution: 'Belmont University',
  },
  {
    institution: 'Bethel University',
  },
  {
    institution: 'Bryan College-Dayton',
  },
  {
    institution: 'Carson-Newman University',
  },
  {
    institution: 'Chattanooga State Community College',
  },
  {
    institution: 'Christian Brothers University',
  },
  {
    institution: 'Pentecostal Theological Seminary',
  },
  {
    institution: 'Cleveland State Community College',
  },
  {
    institution: 'Columbia State Community College',
  },
  {
    institution: 'Concorde Career College-Memphis',
  },
  {
    institution: 'Tennessee College of Applied Technology-Covington',
  },
  {
    institution: 'Cumberland University',
  },
  {
    institution: 'Lipscomb University',
  },
  {
    institution: 'Tennessee College of Applied Technology-Dickson',
  },
  {
    institution: 'Daymar Institute-Nashville',
  },
  {
    institution: 'Dyersburg State Community College',
  },
  {
    institution: 'East Tennessee State University',
  },
  {
    institution: 'Chattanooga College Medical Dental and Technical Careers',
  },
  {
    institution: 'Tennessee College of Applied Technology-Elizabethton',
  },
  {
    institution: 'Emmanuel Christian Seminary',
  },
  {
    institution: 'Fayetteville College of Cosmetology Arts and Sciences',
  },
  {
    institution: 'Fisk University',
  },
  {
    institution: 'Welch College',
  },
  {
    institution: 'Freed-Hardeman University',
  },
  {
    institution: 'Tennessee College of Applied Technology-Harriman',
  },
  {
    institution: 'Tennessee College of Applied Technology-Hartsville',
  },
  {
    institution: 'Hiwassee College',
  },
  {
    institution: 'Tennessee College of Applied Technology-Hohenwald',
  },
  {
    institution: 'Tennessee College of Applied Technology-Jacksboro',
  },
  {
    institution: 'Jackson State Community College',
  },
  {
    institution: 'John A Gupton College',
  },
  {
    institution: 'Johnson University',
  },
  {
    institution: 'Paul Mitchell the School-Nashville',
  },
  {
    institution: 'King University',
  },
  {
    institution: 'South College',
  },
  {
    institution: 'Knoxville Institute of Hair Design',
  },
  {
    institution: 'Lane College',
  },
  {
    institution: 'Le Moyne-Owen College',
  },
  {
    institution: 'Lee University',
  },
  {
    institution: 'Lincoln Memorial University',
  },
  {
    institution: 'Tennessee College of Applied Technology-Livingston',
  },
  {
    institution: 'Martin Methodist College',
  },
  {
    institution: 'Maryville College',
  },
  {
    institution: 'Tennessee College of Applied Technology-McKenzie',
  },
  {
    institution: 'Genesis Career College-Cookeville',
  },
  {
    institution: 'Meharry Medical College',
  },
  {
    institution: 'Memphis College of Art',
  },
  {
    institution: 'Tennessee College of Applied Technology-Memphis',
  },
  {
    institution: 'University of Memphis',
  },
  {
    institution: 'Memphis Theological Seminary',
  },
  {
    institution: 'Victory University',
  },
  {
    institution: 'Middle Tennessee State University',
  },
  {
    institution: 'Middle Tennessee School of Anesthesia Inc',
  },
  {
    institution: 'Milligan College',
  },
  {
    institution: 'Tennessee College of Applied Technology-Morristown',
  },
  {
    institution: 'Motlow State Community College',
  },
  {
    institution: 'Tennessee College of Applied Technology-Murfreesboro',
  },
  {
    institution: 'Lincoln College of Technology-Nashville',
  },
  {
    institution: 'Nashville College of Medical Careers',
  },
  {
    institution: 'Nashville State Community College',
  },
  {
    institution: 'Tennessee College of Applied Technology-Newbern',
  },
  {
    institution: "O'More College of Design",
  },
  {
    institution: 'Tennessee College of Applied Technology-Paris',
  },
  {
    institution: 'Plaza Beauty School',
  },
  {
    institution: 'Tennessee College of Applied Technology-Pulaski',
  },
  {
    institution: 'Rhodes College',
  },
  {
    institution: 'Tennessee College of Applied Technology-Ripley',
  },
  {
    institution: 'Roane State Community College',
  },
  {
    institution: 'Tennessee College of Applied Technology-Crump',
  },
  {
    institution: 'Southwest Tennessee Community College',
  },
  {
    institution: 'Tennessee College of Applied Technology-Shelbyville',
  },
  {
    institution: 'Sewanee-The University of the South',
  },
  {
    institution: 'Tennessee College of Applied Technology-Oneida-Huntsville',
  },
  {
    institution: 'Tennessee College of Applied Technology-Crossville',
  },
  {
    institution: 'Tennessee College of Applied Technology-McMinnville',
  },
  {
    institution: 'Tennessee College of Applied Technology-Jackson',
  },
  {
    institution: 'Tennessee College of Applied Technology-Knoxville',
  },
  {
    institution: 'Tennessee College of Applied Technology-Whiteville',
  },
  {
    institution: 'Pellissippi State Community College',
  },
  {
    institution: 'Southern Adventist University',
  },
  {
    institution: 'Southern College of Optometry',
  },
  {
    institution: 'Tennessee Wesleyan College',
  },
  {
    institution: 'The University of Tennessee-Chattanooga',
  },
  {
    institution: 'The University of Tennessee-Knoxville',
  },
  {
    institution: 'The University of Tennessee-Martin',
  },
  {
    institution: 'Fountainhead College of Technology',
  },
  {
    institution: 'Tennessee School of Beauty of Knoxville Inc',
  },
  {
    institution: 'Tennessee State University',
  },
  {
    institution: 'Tennessee Technological University',
  },
  {
    institution: 'Tennessee Temple University',
  },
  {
    institution: 'Trevecca Nazarene University',
  },
  {
    institution: 'Northeast State Community College',
  },
  {
    institution: 'Tusculum College',
  },
  {
    institution: 'Union University',
  },
  {
    institution: 'New Concepts School of Cosmetology',
  },
  {
    institution: 'Vanderbilt University',
  },
  {
    institution: 'Volunteer Beauty Academy-Dyersburg',
  },
  {
    institution: 'Volunteer State Community College',
  },
  {
    institution: 'Walters State Community College',
  },
  {
    institution: 'West Tennessee Business College',
  },
  {
    institution: 'William Moore College of Technology',
  },
  {
    institution: 'Abilene Christian University',
  },
  {
    institution: 'Alamo Community College District Central Office',
  },
  {
    institution: 'Alvin Community College',
  },
  {
    institution: 'Amarillo College',
  },
  {
    institution: 'Amberton University',
  },
  {
    institution: 'American Commercial College-San Angelo',
  },
  {
    institution: 'American Commercial College-Abilene',
  },
  {
    institution: 'American Commercial College-Odessa',
  },
  {
    institution: 'Angelina College',
  },
  {
    institution: 'Angelo State University',
  },
  {
    institution: 'Arlington Baptist College',
  },
  {
    institution: 'Arlington Career Institute',
  },
  {
    institution: 'The Art Institute of Houston',
  },
  {
    institution: 'Austin College',
  },
  {
    institution: 'Austin Community College District',
  },
  {
    institution: 'Austin Presbyterian Theological Seminary',
  },
  {
    institution: 'Baldwin Beauty School-South Austin',
  },
  {
    institution: 'Baptist Health System School of Health Professions',
  },
  {
    institution: 'Baptist Missionary Association Theological Seminary',
  },
  {
    institution: 'Texas A & M University Health Science Center',
  },
  {
    institution: 'Baylor College of Medicine',
  },
  {
    institution: 'Baylor University',
  },
  {
    institution: 'Coastal Bend College',
  },
  {
    institution: 'Blinn College',
  },
  {
    institution: 'Remington College-Dallas Campus',
  },
  {
    institution: 'Vet Tech Institute of Houston',
  },
  {
    institution: 'Brazosport College',
  },
  {
    institution: 'Brookhaven College',
  },
  {
    institution: 'Capitol City Trade and Technical School',
  },
  {
    institution: 'Capitol City Careers',
  },
  {
    institution: 'Cedar Valley College',
  },
  {
    institution: 'Central Texas College',
  },
  {
    institution: 'Charles and Sues School of Hair Design',
  },
  {
    institution: 'Cisco College',
  },
  {
    institution: 'Clarendon College',
  },
  {
    institution: 'Concordia University-Texas',
  },
  {
    institution: "Conlee's College of Cosmetology",
  },
  {
    institution: 'North Central Texas College',
  },
  {
    institution: 'Texas A & M University-Corpus Christi',
  },
  {
    institution: 'Cosmetology Career Center LLC',
  },
  {
    institution: 'Court Reporting Institute of Dallas',
  },
  {
    institution: 'Dallas Baptist University',
  },
  {
    institution: 'Dallas Christian College',
  },
  {
    institution: 'Dallas County Community College District',
  },
  {
    institution: 'Dallas Institute of Funeral Service',
  },
  {
    institution: 'Dallas Theological Seminary',
  },
  {
    institution: 'University of Dallas',
  },
  {
    institution: 'Del Mar College',
  },
  {
    institution: 'DeVry University-Texas',
  },
  {
    institution: 'Career Point College',
  },
  {
    institution: 'East Texas Baptist University',
  },
  {
    institution: 'Texas A & M University-Texarkana',
  },
  {
    institution: 'Texas A & M University-Commerce',
  },
  {
    institution: 'Eastfield College',
  },
  {
    institution: 'El Centro College',
  },
  {
    institution: 'El Paso Community College',
  },
  {
    institution: 'Western Technical College',
  },
  {
    institution: 'Western Technical College',
  },
  {
    institution: 'Episcopal Theological Seminary of the Southwest',
  },
  {
    institution: 'Exposito School of Hair Design',
  },
  {
    institution: 'South University-The Art Institute of Dallas',
  },
  {
    institution: 'Fort Worth Beauty School',
  },
  {
    institution: 'Frank Phillips College',
  },
  {
    institution: 'Franklin Beauty School',
  },
  {
    institution: 'Galveston College',
  },
  {
    institution: 'Grayson College',
  },
  {
    institution: 'Hallmark College',
  },
  {
    institution: 'Hardin-Simmons University',
  },
  {
    institution: 'Trinity Valley Community College',
  },
  {
    institution: 'Hill College',
  },
  {
    institution: 'Houston Baptist University',
  },
  {
    institution: 'University of Houston-Clear Lake',
  },
  {
    institution: 'Houston Community College',
  },
  {
    institution: 'University of Houston-Downtown',
  },
  {
    institution: 'University of Houston-Victoria',
  },
  {
    institution: 'University of Houston',
  },
  {
    institution: 'Howard College',
  },
  {
    institution: 'Howard Payne University',
  },
  {
    institution: 'Huston-Tillotson University',
  },
  {
    institution: 'University of the Incarnate Word',
  },
  {
    institution: 'International Business College-El Paso',
  },
  {
    institution: 'International Business College-El Paso',
  },
  {
    institution: 'International Business College-El Paso',
  },
  {
    institution: 'ITT Technical Institute-Arlington',
  },
  {
    institution: 'ITT Technical Institute-Houston West',
  },
  {
    institution: 'Jacksonville College-Main Campus',
  },
  {
    institution: 'Jarvis Christian College',
  },
  {
    institution: 'KD College Conservatory of Film and Dramatic Arts',
  },
  {
    institution: 'Kilgore College',
  },
  {
    institution: 'Lamar University',
  },
  {
    institution: 'Lamar State College-Orange',
  },
  {
    institution: 'Lamar State College-Port Arthur',
  },
  {
    institution: 'Laredo Beauty College Inc',
  },
  {
    institution: 'Laredo Community College',
  },
  {
    institution: 'Texas A & M International University',
  },
  {
    institution: 'Lee College',
  },
  {
    institution: 'LeTourneau University',
  },
  {
    institution: 'Lincoln College of Technology-Grand Prairie',
  },
  {
    institution: 'Lubbock Hair Academy',
  },
  {
    institution: 'Lubbock Christian University',
  },
  {
    institution: 'College of the Mainland',
  },
  {
    institution: 'University of Mary Hardin-Baylor',
  },
  {
    institution: 'McLennan Community College',
  },
  {
    institution: 'McMurry University',
  },
  {
    institution: 'Covenant School of Nursing and Allied Health',
  },
  {
    institution: 'Metroplex Beauty School',
  },
  {
    institution: 'Midland College',
  },
  {
    institution: 'Midwestern State University',
  },
  {
    institution: 'Mims Classic Beauty College',
  },
  {
    institution: 'Wade College',
  },
  {
    institution: 'Southern Careers Institute-Austin',
  },
  {
    institution: 'Mountain View College',
  },
  {
    institution: 'Everest Institute-San Antonio',
  },
  {
    institution: 'Tint School of Makeup and Cosmetology-Irving',
  },
  {
    institution: 'Navarro College',
  },
  {
    institution: 'Neilson Beauty College',
  },
  {
    institution: 'Lone Star College System',
  },
  {
    institution: 'North Lake College',
  },
  {
    institution: 'University of North Texas',
  },
  {
    institution: 'Northeast Texas Community College',
  },
  {
    institution: 'Northwood University-Texas',
  },
  {
    institution: 'Oblate School of Theology',
  },
  {
    institution: 'Ocean Corporation',
  },
  {
    institution: 'Odessa College',
  },
  {
    institution: 'Ogle School Hair Skin Nails-Arlington',
  },
  {
    institution: 'Ogle School Hair Skin Nails-Ft Worth',
  },
  {
    institution: 'Our Lady of the Lake University',
  },
  {
    institution: 'The University of Texas-Pan American',
  },
  {
    institution: 'The University of Texas at Brownsville',
  },
  {
    institution: 'Panola College',
  },
  {
    institution: 'Paris Junior College',
  },
  {
    institution: 'Paul Quinn College',
  },
  {
    institution: 'Prairie View A & M University',
  },
  {
    institution: 'Ranger College',
  },
  {
    institution: 'Aviation Institute of Maintenance-Houston',
  },
  {
    institution: 'Rice University',
  },
  {
    institution: 'Richland College',
  },
  {
    institution: 'Kaplan College-McAllen',
  },
  {
    institution: "Saint Edward's University",
  },
  {
    institution: "St Philip's College",
  },
  {
    institution: 'University of St Thomas',
  },
  {
    institution: 'Sam Houston State University',
  },
  {
    institution: 'San Antonio College',
  },
  {
    institution: 'San Jacinto Community College',
  },
  {
    institution: 'Schreiner University',
  },
  {
    institution: 'Seguin Beauty School-Seguin',
  },
  {
    institution: "St Mary's University",
  },
  {
    institution: 'South Plains College',
  },
  {
    institution: 'South Texas Barber College Inc',
  },
  {
    institution: 'South Texas College of Law',
  },
  {
    institution: 'South Texas Vo-Tech Institute',
  },
  {
    institution: 'South Texas Vocational Technical Institute-McAllen',
  },
  {
    institution: 'Southern Methodist University',
  },
  {
    institution:
      'Southwest School of Business and Technical Careers-San Antonio',
  },
  {
    institution: 'Southwest Institute of Technology',
  },
  {
    institution: 'Southwest Texas Junior College',
  },
  {
    institution: 'Southwestern Assemblies of God University',
  },
  {
    institution: 'Southwestern University',
  },
  {
    institution: 'Stephen F Austin State University',
  },
  {
    institution: 'Texas State University',
  },
  {
    institution: 'Southwestern Adventist University',
  },
  {
    institution: 'Southwestern Christian College',
  },
  {
    institution: 'Sul Ross State University',
  },
  {
    institution: 'Tarleton State University',
  },
  {
    institution: 'Tarrant County College District',
  },
  {
    institution: 'Temple College',
  },
  {
    institution: 'University of Texas Southwestern Medical Center',
  },
  {
    institution: 'The University of Texas Health Science Center at San Antonio',
  },
  {
    institution: 'The University of Texas Medical Branch',
  },
  {
    institution: 'Texas State Technical College-System',
  },
  {
    institution: 'Texas State Technical College-Waco',
  },
  {
    institution: 'Texarkana College',
  },
  {
    institution: 'Texas A & M University-Kingsville',
  },
  {
    institution: 'Texas A & M University-Galveston',
  },
  {
    institution: 'Texas A & M University-College Station',
  },
  {
    institution: 'Texas A & M University-System Office',
  },
  {
    institution: 'The University of Texas at Arlington',
  },
  {
    institution: 'The University of Texas at Austin',
  },
  {
    institution: 'The University of Texas at Dallas',
  },
  {
    institution: 'The University of Texas at El Paso',
  },
  {
    institution: 'The University of Texas at Tyler',
  },
  {
    institution: 'Texas Chiropractic College Foundation Inc',
  },
  {
    institution: 'Texas Christian University',
  },
  {
    institution: 'Texas College',
  },
  {
    institution: 'University of North Texas Health Science Center',
  },
  {
    institution: 'Texas Lutheran University',
  },
  {
    institution: 'The University of Texas of the Permian Basin',
  },
  {
    institution: 'The University of Texas at San Antonio',
  },
  {
    institution: 'Texas School of Business-North Campus',
  },
  {
    institution: 'Texas Southern University',
  },
  {
    institution: 'The University of Texas System Office',
  },
  {
    institution: 'Texas Tech University',
  },
  {
    institution: 'Texas Vocational Schools Inc',
  },
  {
    institution: 'Texas Wesleyan University',
  },
  {
    institution: "Texas Woman's University",
  },
  {
    institution: 'Trinity University',
  },
  {
    institution: 'The University of Texas Health Science Center at Houston',
  },
  {
    institution: 'Texas State Technical College-Harlingen',
  },
  {
    institution: 'Texas State Technical College-West Texas',
  },
  {
    institution: 'Texas Tech University Health Sciences Center',
  },
  {
    institution: 'Tyler Junior College',
  },
  {
    institution: 'University of Houston-System Administration',
  },
  {
    institution: 'Universal Technical Institute of Texas Inc.',
  },
  {
    institution: 'University of Cosmetology Arts & Sciences-McAllen',
  },
  {
    institution: 'Vernon College',
  },
  {
    institution: 'Victoria Beauty College Inc',
  },
  {
    institution: 'Victoria College',
  },
  {
    institution: 'Vogue College of Cosmetology-McAllen',
  },
  {
    institution: 'Wayland Baptist University',
  },
  {
    institution: 'Weatherford College',
  },
  {
    institution: 'West Texas A & M University',
  },
  {
    institution: 'Western Texas College',
  },
  {
    institution: 'Wharton County Junior College',
  },
  {
    institution: 'Wiley College',
  },
  {
    institution: 'AmeriTech College-Provo',
  },
  {
    institution: 'Bridgerland Applied Technology College',
  },
  {
    institution: 'Brigham Young University-Provo',
  },
  {
    institution: 'Brigham Young University-Hawaii',
  },
  {
    institution: 'Broadview University-West Jordan',
  },
  {
    institution: 'Cameo College of Essential Beauty',
  },
  {
    institution: 'Sherman Kendall Academy-Salt Lake City',
  },
  {
    institution: 'Sherman Kendall Academy-Midvale',
  },
  {
    institution: 'Davis Applied Technology College',
  },
  {
    institution: 'Dixie State University',
  },
  {
    institution: 'Evans Hairstyling College-Cedar City',
  },
  {
    institution: 'Evans Hairstyling College-St George',
  },
  {
    institution: 'Paul Mitchell the School-Salt Lake City',
  },
  {
    institution: 'Eagle Gate College-Murray',
  },
  {
    institution: 'ITT Technical Institute-Murray',
  },
  {
    institution: 'Latter-day Saints Business College',
  },
  {
    institution: 'Everest College-Salt Lake City',
  },
  {
    institution: 'Ogden-Weber Applied Technology College',
  },
  {
    institution: 'Snow College',
  },
  {
    institution: 'Southern Utah University',
  },
  {
    institution: 'Stevens-Henager College-Ogden',
  },
  {
    institution: 'Stevens-Henager College of Business-Provo',
  },
  {
    institution: 'Uintah Basin Applied Technology College',
  },
  {
    institution: 'Utah State University',
  },
  {
    institution: 'Utah Valley University',
  },
  {
    institution: 'Salt Lake Community College',
  },
  {
    institution: 'University of Utah',
  },
  {
    institution: 'Weber State University',
  },
  {
    institution: 'Westminster College',
  },
  {
    institution: 'Bennington College',
  },
  {
    institution: 'Burlington College',
  },
  {
    institution: 'Castleton State College',
  },
  {
    institution: 'Champlain College',
  },
  {
    institution: 'Community College of Vermont',
  },
  {
    institution: 'Goddard College',
  },
  {
    institution: 'Green Mountain College',
  },
  {
    institution: 'Johnson State College',
  },
  {
    institution: 'Lyndon State College',
  },
  {
    institution: 'Marlboro College',
  },
  {
    institution: 'Middlebury College',
  },
  {
    institution: 'New England Culinary Institute',
  },
  {
    institution: 'Norwich University',
  },
  {
    institution: "O'Briens Aveda Institute",
  },
  {
    institution: "Saint Michael's College",
  },
  {
    institution: 'SIT Graduate Institute',
  },
  {
    institution: 'College of St Joseph',
  },
  {
    institution: 'Southern Vermont College',
  },
  {
    institution: 'Sterling College',
  },
  {
    institution: 'Vermont Law School',
  },
  {
    institution: 'Vermont State Colleges-Office of the Chancellor',
  },
  {
    institution: 'Vermont Technical College',
  },
  {
    institution: 'University of Vermont',
  },
  {
    institution: 'Bar Palma Beauty Careers Academy',
  },
  {
    institution: 'Advanced Technology Institute',
  },
  {
    institution: 'Averett University',
  },
  {
    institution: 'Blue Ridge Community College',
  },
  {
    institution: 'Bluefield College',
  },
  {
    institution: 'Bridgewater College',
  },
  {
    institution: 'College of William and Mary',
  },
  {
    institution: 'Regent University',
  },
  {
    institution: 'Central School of Practical Nursing',
  },
  {
    institution: 'Central Virginia Community College',
  },
  {
    institution: 'Christopher Newport University',
  },
  {
    institution: 'Suffolk Beauty Academy',
  },
  {
    institution: 'Bryant & Stratton College-Virginia Beach',
  },
  {
    institution: 'Bryant & Stratton College-Richmond',
  },
  {
    institution: 'Jefferson College of Health Sciences',
  },
  {
    institution: 'Dabney S Lancaster Community College',
  },
  {
    institution: 'Danville Community College',
  },
  {
    institution: 'Eastern Virginia Medical School',
  },
  {
    institution: 'Centura College-Virginia Beach',
  },
  {
    institution: 'Emory & Henry College',
  },
  {
    institution: 'Eastern Mennonite University',
  },
  {
    institution: 'Eastern Shore Community College',
  },
  {
    institution: 'Ferrum College',
  },
  {
    institution: 'George Mason University',
  },
  {
    institution: 'Germanna Community College',
  },
  {
    institution: 'Hampden-Sydney College',
  },
  {
    institution: 'Hampton University',
  },
  {
    institution: 'Hollins University',
  },
  {
    institution: 'J Sargeant Reynolds Community College',
  },
  {
    institution: 'James Madison University',
  },
  {
    institution: 'John Tyler Community College',
  },
  {
    institution: 'Everest College-Newport News',
  },
  {
    institution: 'Liberty University',
  },
  {
    institution: 'Longwood University',
  },
  {
    institution: 'Lord Fairfax Community College',
  },
  {
    institution: 'Lynchburg College',
  },
  {
    institution: 'Lynchburg General Hospital School of Nursing',
  },
  {
    institution: 'Mary Baldwin College',
  },
  {
    institution: 'University of Mary Washington',
  },
  {
    institution: 'Marymount University',
  },
  {
    institution:
      'Danville Regional Medical Center School of Health Professions',
  },
  {
    institution: 'Mountain Empire Community College',
  },
  {
    institution: 'American National University',
  },
  {
    institution: 'New River Community College',
  },
  {
    institution: 'Sentara College of Health Sciences',
  },
  {
    institution: 'Tidewater Tech-Trades',
  },
  {
    institution: 'Norfolk State University',
  },
  {
    institution: 'Northern Virginia Community College',
  },
  {
    institution: 'Old Dominion University',
  },
  {
    institution: 'Patrick Henry Community College',
  },
  {
    institution: 'Paul D Camp Community College',
  },
  {
    institution: 'Southside Regional Medical Center Professional Schools',
  },
  {
    institution: 'Miller-Motte Technical College-Lynchburg',
  },
  {
    institution: 'Piedmont Virginia Community College',
  },
  {
    institution: 'Radford University',
  },
  {
    institution: 'Sylvain Melloul International Hair Academy',
  },
  {
    institution: 'Randolph-Macon College',
  },
  {
    institution: 'Randolph College',
  },
  {
    institution: 'Rappahannock Community College',
  },
  {
    institution: 'Fortis College-Norfolk',
  },
  {
    institution: 'Richard Bland College of the College of William and Mary',
  },
  {
    institution: 'Bon Secours Memorial College of Nursing',
  },
  {
    institution: 'University of Richmond',
  },
  {
    institution: 'Riverside School of Health Careers',
  },
  {
    institution: 'Roanoke College',
  },
  {
    institution: "Bon Secours St Mary's Hospital School of Medical Imaging",
  },
  {
    institution: 'Saint Pauls College',
  },
  {
    institution: 'Shenandoah University',
  },
  {
    institution: 'Southern Virginia University',
  },
  {
    institution: 'Southside Virginia Community College',
  },
  {
    institution: 'Southwest Virginia Community College',
  },
  {
    institution: 'Springfield Beauty Academy',
  },
  {
    institution: 'Staunton School of Cosmetology',
  },
  {
    institution: 'Strayer University-Virginia',
  },
  {
    institution: 'Sweet Briar College',
  },
  {
    institution: 'Thomas Nelson Community College',
  },
  {
    institution: 'Tidewater Community College',
  },
  {
    institution: 'Union Presbyterian Seminary',
  },
  {
    institution: "The University of Virginia's College at Wise",
  },
  {
    institution: 'Virginia Highlands Community College',
  },
  {
    institution: 'Virginia Intermont College',
  },
  {
    institution: 'Virginia Polytechnic Institute and State University',
  },
  {
    institution: 'Virginia Western Community College',
  },
  {
    institution: 'Virginia Commonwealth University',
  },
  {
    institution: 'University of Virginia-Main Campus',
  },
  {
    institution: 'Virginia Military Institute',
  },
  {
    institution: 'Virginia School of Hair Design',
  },
  {
    institution: 'Virginia University of Lynchburg',
  },
  {
    institution: 'Virginia State University',
  },
  {
    institution: 'Virginia Union University',
  },
  {
    institution: 'Virginia Wesleyan College',
  },
  {
    institution: 'Wards Corner Beauty Academy-Norfolk',
  },
  {
    institution: 'Washington and Lee University',
  },
  {
    institution: 'Sanford-Brown College-Tysons Corner',
  },
  {
    institution: 'Washington County Adult Skill Center',
  },
  {
    institution: 'Woodrow Wilson Rehabilitation Center',
  },
  {
    institution: 'Wytheville Community College',
  },
  {
    institution: 'Beauty Academy',
  },
  {
    institution: 'The Art Institute of Seattle',
  },
  {
    institution: 'Evergreen Beauty and Barber College-Bellevue',
  },
  {
    institution: 'Bellevue College',
  },
  {
    institution: 'Bellingham Beauty School',
  },
  {
    institution: 'Bellingham Technical College',
  },
  {
    institution: 'Big Bend Community College',
  },
  {
    institution: 'Everest College-Bremerton',
  },
  {
    institution: 'Everest College-Renton',
  },
  {
    institution: 'Central Washington University',
  },
  {
    institution: 'Centralia College',
  },
  {
    institution: 'City University of Seattle',
  },
  {
    institution: 'Lucas Marc Academy',
  },
  {
    institution: 'Clark College',
  },
  {
    institution: 'Clover Park Technical College',
  },
  {
    institution: 'Columbia Basin College',
  },
  {
    institution: 'Cornish College of the Arts',
  },
  {
    institution: 'Divers Institute of Technology',
  },
  {
    institution: 'Eastern Washington University',
  },
  {
    institution: 'Edmonds Community College',
  },
  {
    institution: 'Everett Community College',
  },
  {
    institution: 'Paroba College of Cosmetology',
  },
  {
    institution: 'The Evergreen State College',
  },
  {
    institution: 'Pierce College at Fort Steilacoom',
  },
  {
    institution: 'Glen Dow Academy of Hair Design',
  },
  {
    institution: 'Gonzaga University',
  },
  {
    institution: 'Grays Harbor College',
  },
  {
    institution: 'Green River Community College',
  },
  {
    institution: 'Toni & Guy Hairdressing Academy-Shoreline',
  },
  {
    institution: 'Heritage University',
  },
  {
    institution: 'Highline Community College',
  },
  {
    institution: 'Interface College-Spokane',
  },
  {
    institution: 'International Air and Hospitality Academy',
  },
  {
    institution: 'ITT Technical Institute-Seattle',
  },
  {
    institution: 'ITT Technical Institute-Spokane Valley',
  },
  {
    institution: 'Bastyr University',
  },
  {
    institution: 'Gene Juarez Academy of Beauty-Mountlake Terrace',
  },
  {
    institution: 'Bates Technical College',
  },
  {
    institution: 'Lake Washington Institute of Technology',
  },
  {
    institution: 'Lower Columbia College',
  },
  {
    institution: 'Trinity Lutheran College',
  },
  {
    institution: 'Cascade Beauty College',
  },
  {
    institution: "BJ's Beauty & Barber College",
  },
  {
    institution: 'Northwest Hair Academy',
  },
  {
    institution: 'Seattle Community College-North Campus',
  },
  {
    institution: 'Northwest University',
  },
  {
    institution: 'Olympic College',
  },
  {
    institution: 'Perry Technical Institute',
  },
  {
    institution: 'Pacific Lutheran University',
  },
  {
    institution: 'Peninsula College',
  },
  {
    institution: 'University of Puget Sound',
  },
  {
    institution: 'Renton Technical College',
  },
  {
    institution: "Saint Martin's University",
  },
  {
    institution: 'Seattle Community College-South Campus',
  },
  {
    institution: 'Seattle Central College',
  },
  {
    institution: 'Everest College-Seattle',
  },
  {
    institution: 'Seattle Pacific University',
  },
  {
    institution: 'Seattle University',
  },
  {
    institution: 'Shoreline Community College',
  },
  {
    institution: 'Skagit Valley College',
  },
  {
    institution: 'South Puget Sound Community College',
  },
  {
    institution: 'Spokane Community College',
  },
  {
    institution: 'Spokane Falls Community College',
  },
  {
    institution: 'Stylemaster College of Hair Design',
  },
  {
    institution: 'Tacoma Community College',
  },
  {
    institution: 'The Hair School',
  },
  {
    institution: 'Walla Walla Community College',
  },
  {
    institution: 'Walla Walla University',
  },
  {
    institution: 'Washington State University',
  },
  {
    institution: 'University of Washington-Seattle Campus',
  },
  {
    institution: 'Wenatchee Valley College',
  },
  {
    institution: 'Everest College-Vancouver',
  },
  {
    institution: 'Western Washington University',
  },
  {
    institution: 'Whatcom Community College',
  },
  {
    institution: 'Whitman College',
  },
  {
    institution: 'Whitworth University',
  },
  {
    institution: 'Yakima Valley Community College',
  },
  {
    institution: 'Alderson Broaddus University',
  },
  {
    institution: 'Appalachian Beauty School',
  },
  {
    institution: 'Appalachian Bible College',
  },
  {
    institution: 'B M Spurr School of Practical Nursing',
  },
  {
    institution: 'Ben Franklin Career Center',
  },
  {
    institution: 'Bethany College',
  },
  {
    institution: 'Bluefield State College',
  },
  {
    institution: 'Cabell County Career Technology Center',
  },
  {
    institution: 'Charleston School of Beauty Culture',
  },
  {
    institution: 'University of Charleston',
  },
  {
    institution: 'Clarksburg Beauty Academy and School of Massage Therapy',
  },
  {
    institution: 'Concord University',
  },
  {
    institution: 'Davis & Elkins College',
  },
  {
    institution: 'Fairmont State University',
  },
  {
    institution: 'Glenville State College',
  },
  {
    institution: 'HRDE-Stanley Technical Institute-Clarksburg',
  },
  {
    institution: 'Huntington Junior College',
  },
  {
    institution: 'James Rumsey Technical Institute',
  },
  {
    institution: 'Ralph R Willis Career and Technical Center',
  },
  {
    institution: 'Human Resource Development and Employment Inc',
  },
  {
    institution: 'Marshall University',
  },
  {
    institution: 'Mercer County Technical Education Center',
  },
  {
    institution: 'Meredith Manor International Equestrian Center',
  },
  {
    institution: 'Monongalia County Technical Education Center',
  },
  {
    institution: 'Morgantown Beauty College Inc',
  },
  {
    institution: 'Mountain State College',
  },
  {
    institution: 'Everest Institute-Cross Lanes',
  },
  {
    institution: 'Opportunities Industrialization Center',
  },
  {
    institution: 'Ohio Valley University',
  },
  {
    institution: 'West Virginia University at Parkersburg',
  },
  {
    institution: 'Potomac State College of West Virginia University',
  },
  {
    institution: 'Academy of Careers and Technology',
  },
  {
    institution: 'Salem International University',
  },
  {
    institution: 'Shepherd University',
  },
  {
    institution: 'Southern West Virginia Community and Technical College',
  },
  {
    institution: 'Fred W Eberle Technical Center',
  },
  {
    institution: 'West Virginia School of Osteopathic Medicine',
  },
  {
    institution: 'West Virginia State University',
  },
  {
    institution:
      'West Virginia University Hospital Departments of Rad Tech and Nutrition',
  },
  {
    institution: 'West Liberty University',
  },
  {
    institution: 'West Virginia University Institute of Technology',
  },
  {
    institution: 'West Virginia Wesleyan College',
  },
  {
    institution: 'West Virginia Business College-Wheeling',
  },
  {
    institution: 'West Virginia Junior College-Charleston',
  },
  {
    institution: 'West Virginia Junior College-Morgantown',
  },
  {
    institution: 'International Beauty School 4',
  },
  {
    institution: 'West Virginia Northern Community College',
  },
  {
    institution: 'West Virginia University',
  },
  {
    institution: 'Wheeling Jesuit University',
  },
  {
    institution: 'Wood County School of Practical Nursing',
  },
  {
    institution: 'Advanced Institute of Hair Design-Glendale',
  },
  {
    institution: 'VICI Aveda Institute',
  },
  {
    institution: 'Alverno College',
  },
  {
    institution: 'Madison Area Technical College',
  },
  {
    institution: 'Bellin College',
  },
  {
    institution: 'Beloit College',
  },
  {
    institution: 'Blackhawk Technical College',
  },
  {
    institution: 'Cardinal Stritch University',
  },
  {
    institution: 'Carroll University',
  },
  {
    institution: 'Carthage College',
  },
  {
    institution: 'Columbia College of Nursing',
  },
  {
    institution: 'Concordia University-Wisconsin',
  },
  {
    institution: 'Edgewood College',
  },
  {
    institution: 'Fox Valley Technical College',
  },
  {
    institution: 'Gateway Technical College',
  },
  {
    institution: 'Gill-Tech Academy of Hair Design',
  },
  {
    institution: 'The Salon Professional Academy-Eau Claire',
  },
  {
    institution: 'ITT Technical Institute-Greenfield',
  },
  {
    institution: 'DeVry University-Wisconsin',
  },
  {
    institution: 'Lakeland College',
  },
  {
    institution: 'Lakeshore Technical College',
  },
  {
    institution: 'Lawrence University',
  },
  {
    institution: 'Maranatha Baptist University',
  },
  {
    institution: 'Marian University',
  },
  {
    institution: 'Marquette University',
  },
  {
    institution: 'Empire Beauty School-Madison',
  },
  {
    institution: 'Medical College of Wisconsin',
  },
  {
    institution: 'Mid-State Technical College',
  },
  {
    institution: 'Milwaukee Area Technical College',
  },
  {
    institution: 'Milwaukee Institute of Art & Design',
  },
  {
    institution: 'Milwaukee School of Engineering',
  },
  {
    institution: 'Moraine Park Technical College',
  },
  {
    institution: 'Mount Mary University',
  },
  {
    institution: 'Nashotah House',
  },
  {
    institution: 'Nicolet Area Technical College',
  },
  {
    institution: 'Northcentral Technical College',
  },
  {
    institution: 'Northeast Wisconsin Technical College',
  },
  {
    institution: 'Northland International University',
  },
  {
    institution: 'Northland College',
  },
  {
    institution: 'Ripon College',
  },
  {
    institution: 'Sacred Heart School of Theology',
  },
  {
    institution: 'Saint Norbert College',
  },
  {
    institution: 'Silver Lake College of the Holy Family',
  },
  {
    institution: 'State College of Beauty Culture Inc',
  },
  {
    institution: 'Southwest Wisconsin Technical College',
  },
  {
    institution: 'Bryant & Stratton College-Milwaukee',
  },
  {
    institution: 'University of Wisconsin Colleges',
  },
  {
    institution: 'Viterbo University',
  },
  {
    institution: 'Chippewa Valley Technical College',
  },
  {
    institution: 'Waukesha County Technical College',
  },
  {
    institution: 'Western Technical College',
  },
  {
    institution: 'University of Wisconsin-Whitewater',
  },
  {
    institution: 'Wisconsin Indianhead Technical College',
  },
  {
    institution: 'Wisconsin School of Professional Psychology',
  },
  {
    institution: 'Paul Mitchell the School-Green Bay',
  },
  {
    institution: 'University of Wisconsin-Eau Claire',
  },
  {
    institution: 'University of Wisconsin-Green Bay',
  },
  {
    institution: 'University of Wisconsin-La Crosse',
  },
  {
    institution: 'Wisconsin Lutheran College',
  },
  {
    institution: 'University of Wisconsin-Oshkosh',
  },
  {
    institution: 'University of Wisconsin-Parkside',
  },
  {
    institution: 'Herzing University-Madison',
  },
  {
    institution: 'University of Wisconsin-Stout',
  },
  {
    institution: 'University of Wisconsin-Superior',
  },
  {
    institution: 'University of Wisconsin-System Administration',
  },
  {
    institution: 'University of Wisconsin-Madison',
  },
  {
    institution: 'University of Wisconsin-Milwaukee',
  },
  {
    institution: 'University of Wisconsin-Platteville',
  },
  {
    institution: 'University of Wisconsin-River Falls',
  },
  {
    institution: 'University of Wisconsin-Stevens Point',
  },
  {
    institution: 'Casper College',
  },
  {
    institution: 'Central Wyoming College',
  },
  {
    institution: 'Eastern Wyoming College',
  },
  {
    institution: 'Laramie County Community College',
  },
  {
    institution: 'Northwest College',
  },
  {
    institution: 'Sheridan College',
  },
  {
    institution: 'Western Wyoming Community College',
  },
  {
    institution: 'Cheeks International Academy of Beauty Culture-Cheyenne',
  },
  {
    institution: 'Wyotech-Laramie',
  },
  {
    institution: 'University of Wyoming',
  },
  {
    institution: 'American Samoa Community College',
  },
  {
    institution: 'Guam Community College',
  },
  {
    institution: 'University of Guam',
  },
  {
    institution: 'Northern Marianas College',
  },
  {
    institution: 'Institute of Beauty Careers',
  },
  {
    institution: 'Educational Technical College-Recinto de Bayamon',
  },
  {
    institution: 'American University of Puerto Rico',
  },
  {
    institution: 'American University of Puerto Rico',
  },
  {
    institution: 'American Educational College',
  },
  {
    institution: 'Antilles School of Technical Careers',
  },
  {
    institution: 'Universidad Adventista de las Antillas',
  },
  {
    institution: 'Atlantic University College',
  },
  {
    institution: 'Bayamon Central University',
  },
  {
    institution: 'Columbia Centro Universitario-Caguas',
  },
  {
    institution: 'Carlos Albizu University-San Juan',
  },
  {
    institution: 'Caribbean University-Bayamon',
  },
  {
    institution: 'Caribbean University-Carolina',
  },
  {
    institution: 'Pontifical Catholic University of Puerto Rico-Arecibo',
  },
  {
    institution: 'Pontifical Catholic University of Puerto Rico-Ponce',
  },
  {
    institution: 'Centro de Estudios Multidisciplinarios-San Juan',
  },
  {
    institution: 'Universidad Teologica del Caribe',
  },
  {
    institution: 'Colegio Universitario de San Juan',
  },
  {
    institution: 'Universidad Metropolitana',
  },
  {
    institution: 'Puerto Rico Conservatory of Music',
  },
  {
    institution: 'Center for Advanced Studies On Puerto Rico and the Caribbean',
  },
  {
    institution: 'EDP University of Puerto Rico Inc-San Sebastian',
  },
  {
    institution: "Emma's Beauty Academy-Mayaguez",
  },
  {
    institution: 'Escuela de Artes Plasticas de Puerto Rico',
  },
  {
    institution: 'Instituto Tecnologico de Puerto Rico-Recinto de Manati',
  },
  {
    institution: 'Sistema Universitario Ana G Mendez',
  },
  {
    institution: 'Huertas College',
  },
  {
    institution: 'Humacao Community College',
  },
  {
    institution: 'ICPR Junior College-Arecibo',
  },
  {
    institution: 'ICPR Junior College-Mayaguez',
  },
  {
    institution: 'Instituto de Educacion Tecnica Ocupacional La Reine-Manati',
  },
  {
    institution: 'Hispanic American College',
  },
  {
    institution: 'Instituto de Banca y Comercio Inc',
  },
  {
    institution: 'Instituto Tecnologico de Puerto Rico-Recinto de Guayama',
  },
  {
    institution: 'Instituto Tecnologico de Puerto Rico-Recinto de Ponce',
  },
  {
    institution: 'Instituto Tecnologico de Puerto Rico-Recinto de San Juan',
  },
  {
    institution: 'Inter American University of Puerto Rico-San German',
  },
  {
    institution: 'Inter American University of Puerto Rico-Aguadilla',
  },
  {
    institution: 'Inter American University of Puerto Rico-Arecibo',
  },
  {
    institution: 'Inter American University of Puerto Rico-Barranquitas',
  },
  {
    institution: 'Inter American University of Puerto Rico-Metro',
  },
  {
    institution: 'Inter American University of Puerto Rico-Ponce',
  },
  {
    institution: 'Inter American University of Puerto Rico-Central Office',
  },
  {
    institution: 'Inter American University of Puerto Rico-Fajardo',
  },
  {
    institution: 'Inter American University of Puerto Rico-Guayama',
  },
  {
    institution: 'Inter American University of Puerto Rico-Bayamon',
  },
  {
    institution: 'Inter American University of Puerto Rico-School of Law',
  },
  {
    institution: 'International Technical College',
  },
  {
    institution: 'Liceo de Arte y Tecnologia',
  },
  {
    institution: 'MBTI Business Training Institute',
  },
  {
    institution: 'Modern Hairstyling Institute-Bayamon',
  },
  {
    institution: 'Modern Hairstyling Institute-Arecibo',
  },
  {
    institution: 'National University College-Bayamon',
  },
  {
    institution: 'National University College-Arecibo',
  },
  {
    institution: 'Ponce Paramedical College Inc',
  },
  {
    institution: 'Ponce School of Medicine and Health Sciences',
  },
  {
    institution: 'University of Puerto Rico-Aguadilla',
  },
  {
    institution: 'University of Puerto Rico-Arecibo',
  },
  {
    institution: 'University of Puerto Rico-Bayamon',
  },
  {
    institution: 'University of Puerto Rico-Carolina',
  },
  {
    institution: 'University of Puerto Rico-Cayey',
  },
  {
    institution: 'University of Puerto Rico-Central Administration',
  },
  {
    institution: 'University of Puerto Rico-Humacao',
  },
  {
    institution: 'University of Puerto Rico-Utuado',
  },
  {
    institution: 'University of Puerto Rico-Mayaguez',
  },
  {
    institution: 'University of Puerto Rico-Medical Sciences',
  },
  {
    institution: 'University of Puerto Rico-Ponce',
  },
  {
    institution: 'University of Puerto Rico-Rio Piedras',
  },
  {
    institution: 'Universidad Del Este',
  },
  {
    institution: 'Ryder Memorial School for Practical Nursing',
  },
  {
    institution: 'Rogies School of Beauty Culture',
  },
  {
    institution: 'Universidad del Sagrado Corazon',
  },
  {
    institution: 'Seminario Evangelico de Puerto Rico',
  },
  {
    institution: 'Universidad Central Del Caribe',
  },
  {
    institution: 'Universidad Politecnica de Puerto Rico',
  },
  {
    institution: 'Pontifical Catholic University of Puerto Rico-Mayaguez',
  },
  {
    institution: 'Universidad Del Turabo',
  },
  {
    institution: 'College of Micronesia-FSM',
  },
  {
    institution: 'Palau Community College',
  },
  {
    institution: 'University of the Virgin Islands',
  },
  {
    institution: 'Stanford University',
  },
  {
    institution: 'Purdue University-Main Campus',
  },
  {
    institution: 'Blue Hills Regional Technical School',
  },
  {
    institution: 'Parker University',
  },
  {
    institution: 'EDP Univeristy of Puerto Rico Inc-San Juan',
  },
  {
    institution: 'ICPR Junior College-General Institutional',
  },
  {
    institution: 'ITT Technical Institute-Sylmar',
  },
  {
    institution: 'ITT Technical Institute-Westminster',
  },
  {
    institution: 'Widener University-Delaware Campus',
  },
  {
    institution: 'City College-Fort Lauderdale',
  },
  {
    institution: 'Meridian College',
  },
  {
    institution: 'Georgia Perimeter College',
  },
  {
    institution: 'Georgia Piedmont Technical College',
  },
  {
    institution: 'Toni & Guy Hairdressing Academy-Coeur D Alene',
  },
  {
    institution: 'Cortiva Institute-Chicago',
  },
  {
    institution: 'ETI School of Skilled Trades',
  },
  {
    institution: 'Lincoln College of Technology-Florence',
  },
  {
    institution: "Trend Setters' Academy of Beauty Culture-Elizabethtown",
  },
  {
    institution: 'Traxlers School of Hair',
  },
  {
    institution: 'Infinity Career College',
  },
  {
    institution: 'Vatterott College-Berkeley',
  },
  {
    institution: 'Metro Business College-Rolla',
  },
  {
    institution: 'Metro Business College-Jefferson City',
  },
  {
    institution: 'Warren County Community College',
  },
  {
    institution: "St John's College",
  },
  {
    institution: 'Yeshiva and Kollel Harbotzas Torah',
  },
  {
    institution: 'Bais Medrash Elyon',
  },
  {
    institution: 'Antioch University-Los Angeles',
  },
  {
    institution: 'Antioch University-Santa Barbara',
  },
  {
    institution: 'Antioch University-New England',
  },
  {
    institution: 'Antioch University-Seattle',
  },
  {
    institution: 'Antioch University-Midwest',
  },
  {
    institution: 'Mid-America Christian University',
  },
  {
    institution: 'Platt College-Tulsa',
  },
  {
    institution: 'Ponca City Beauty College',
  },
  {
    institution: 'Francis Tuttle Technology Center',
  },
  {
    institution: 'Career Point College',
  },
  {
    institution: 'Central Technology Center',
  },
  {
    institution: 'Carrington College-Portland',
  },
  {
    institution: 'Career Technology Center of Lackawanna County',
  },
  {
    institution: 'Kaplan Career Institute-Nashville',
  },
  {
    institution: 'Kaplan College-El Paso',
  },
  {
    institution: 'Houston Graduate School of Theology',
  },
  {
    institution: 'Palo Alto College',
  },
  {
    institution: 'Sebring Career Schools-Huntsville',
  },
  {
    institution: 'University of Cosmetology Arts & Sciences-San Antonio Perrin',
  },
  {
    institution: 'Central Texas Beauty College-Round Rock',
  },
  {
    institution: 'Empire Beauty School-Manitowoc',
  },
  {
    institution: 'Unification Theological Seminary',
  },
  {
    institution: 'Athens Technical College',
  },
  {
    institution: 'Maricopa Skill Center',
  },
  {
    institution: 'Carrington College California-San Leandro',
  },
  {
    institution: 'Headmasters School of Hair Design',
  },
  {
    institution: 'Razzle Dazzle College of Hair Design Inc',
  },
  {
    institution: 'Beckfield College-Florence',
  },
  {
    institution: 'University of Louisiana-System Administration',
  },
  {
    institution: 'Dorsey Business Schools-Wayne',
  },
  {
    institution: 'Ecumenical Theological Seminary',
  },
  {
    institution: 'Ross Medical Education Center-Saginaw',
  },
  {
    institution: 'Sussex County Community College',
  },
  {
    institution: 'Landmark College',
  },
  {
    institution: 'National American University-Independence',
  },
  {
    institution: 'Mississippi Community College Board',
  },
  {
    institution: 'Yeshiva Gedolah of Greater Detroit',
  },
  {
    institution: 'Austin Graduate School of Theology',
  },
  {
    institution: 'Collin County Community College District',
  },
  {
    institution: 'Owensboro Community and Technical College',
  },
  {
    institution: 'Heads West Kentucky Beauty College',
  },
  {
    institution: 'Jenny Lea Academy of Cosmetology',
  },
  {
    institution: 'Empire Beauty School-Hurstborne',
  },
  {
    institution: 'Southeast School of Cosmetology',
  },
  {
    institution: "Cloyd's Beauty School 3 Inc",
  },
  {
    institution: 'Moore Norman Technology Center',
  },
  {
    institution:
      'Fayette County Career & Technical Institute Practical Nursing Program',
  },
  {
    institution: 'Franklin County Career and Technology Center',
  },
  {
    institution: 'Paul Mitchell the School-Knoxville',
  },
  {
    institution: 'Ogle School Hair Skin Nails-Hurst',
  },
  {
    institution: 'Okefenokee Technical College',
  },
  {
    institution: 'Fashion Institute of Design & Merchandising-San Diego',
  },
  {
    institution: 'National American University-Albuquerque',
  },
  {
    institution: 'Tennessee College of Applied Technology-Nashville',
  },
  {
    institution: 'ECPI University',
  },
  {
    institution: "Ms Robert's Academy of Beauty Culture-Villa Park",
  },
  {
    institution: 'Anthem Institute-Jersey City',
  },
  {
    institution: 'Paul Mitchell the School-Houston',
  },
  {
    institution: 'ATI Career Training Center-Dallas',
  },
  {
    institution: 'TDDS Technical Institute',
  },
  {
    institution: 'Fortis Institute-Forty Fort',
  },
  {
    institution: 'Laurel Business Institute',
  },
  {
    institution: 'Antonelli Medical and Professional Institute',
  },
  {
    institution: 'Aviation Institute of Maintenance-Philadelphia',
  },
  {
    institution: 'Sanford-Brown Institute-Trevose',
  },
  {
    institution: 'Fortis Institute-Mulberry',
  },
  {
    institution: 'Carrington College-Phoenix Westside',
  },
  {
    institution: 'Dorsey Business Schools-Roseville',
  },
  {
    institution: 'The Salon Professional Academy-Williston',
  },
  {
    institution: 'Moler-Pickens Beauty Academy',
  },
  {
    institution: 'Red River Technology Center',
  },
  {
    institution: 'Kaplan Career Institute-Harrisburg',
  },
  {
    institution: 'ITT Technical Institute-Newburgh',
  },
  {
    institution: 'Southern Union State Community College',
  },
  {
    institution: 'South University-Columbia',
  },
  {
    institution: 'Everest University-Brandon',
  },
  {
    institution: 'Clinton Technical School',
  },
  {
    institution: 'Consolidated School of Business-Lancaster',
  },
  {
    institution: 'Greene County Career and Technology Center',
  },
  {
    institution: 'Lac Courte Oreilles Ojibwa Community College',
  },
  {
    institution: 'University of Wisconsin Extension',
  },
  {
    institution: 'Diesel Driving Academy-Shreveport',
  },
  {
    institution: 'Pima Medical Institute-Mesa',
  },
  {
    institution: 'Platt College-Los Angeles',
  },
  {
    institution: 'Platt College-Aurora',
  },
  {
    institution: 'Kaplan University-Davenport Campus',
  },
  {
    institution: 'Kaplan University-Cedar Falls Campus',
  },
  {
    institution: 'Paul Mitchell the School-Boise',
  },
  {
    institution: 'Christian Life College',
  },
  {
    institution: 'Knowledge Systems Institute',
  },
  {
    institution: 'Educators of Beauty College of Cosmetology-Rockford',
  },
  {
    institution: 'ITT Technical Institute-Arlington Heights',
  },
  {
    institution: 'Northwestern College-Southwestern Campus',
  },
  {
    institution: 'Tulsa Technology Center-Lemley Campus',
  },
  {
    institution: 'Tulsa Technology Center-Riverside Campus',
  },
  {
    institution: 'Tulsa Technology Center-Broken Arrow Campus',
  },
  {
    institution: 'Douglas J Aveda Institute',
  },
  {
    institution: 'ITT Technical Institute-Troy',
  },
  {
    institution: 'Ross Medical Education Center-Brighton',
  },
  {
    institution: 'Ross Medical Education Center-Ann Arbor',
  },
  {
    institution: 'Taylortown School of Beauty Inc',
  },
  {
    institution: 'Pike-Lincoln Technical Center',
  },
  {
    institution: 'Pennsylvania Institute of Health and Technology',
  },
  {
    institution: 'Skyline College-Roanoke',
  },
  {
    institution: 'Bethel Seminary-San Diego',
  },
  {
    institution: 'St Charles Community College',
  },
  {
    institution: 'Brandman University',
  },
  {
    institution: 'New College of Florida',
  },
  {
    institution: 'Montana Bible College',
  },
  {
    institution: 'Arizona Academy of Beauty-North',
  },
  {
    institution: 'Empire Beauty School-Prescott',
  },
  {
    institution: 'Le Cordon Bleu College of Culinary Arts-Scottsdale',
  },
  {
    institution: 'Conservatory of Recording Arts and Sciences',
  },
  {
    institution: "Adrian's College of Beauty Turlock",
  },
  {
    institution: 'California Career School',
  },
  {
    institution: 'San Joaquin Valley College-Fresno',
  },
  {
    institution: 'Rolf Institute of Structural Integration',
  },
  {
    institution: 'Heritage College-Denver',
  },
  {
    institution: 'Empire Beauty School-Highland',
  },
  {
    institution: 'Sunstate Academy',
  },
  {
    institution: 'Elizabeth Grady School of Esthetics and Massage Therapy',
  },
  {
    institution: 'Ross Medical Education Center-Port Huron',
  },
  {
    institution: 'Capri Institute of Hair Design-Clifton',
  },
  {
    institution: "Raphael's School of Beauty Culture Inc-Alliance",
  },
  {
    institution: 'Metro Technology Centers',
  },
  {
    institution: 'Heald College-Roseville',
  },
  {
    institution: 'Daymar College-Bowling Green',
  },
  {
    institution: 'Everest College-Everett',
  },
  {
    institution: 'Luna Community College',
  },
  {
    institution: 'Grabber School of Hair Design',
  },
  {
    institution: 'Yeshivah Gedolah Rabbinical College',
  },
  {
    institution:
      'University of the District of Columbia-David A Clarke School of Law',
  },
  {
    institution: "Raphael's School of Beauty Culture Inc-Brunswick",
  },
  {
    institution: 'Caribbean University-Ponce',
  },
  {
    institution: 'Caribbean University-Vega Baja',
  },
  {
    institution: 'University of Advancing Technology',
  },
  {
    institution: 'Paradise Valley Community College',
  },
  {
    institution: 'Chandler-Gilbert Community College',
  },
  {
    institution: "St John's Hospital School of Clinical Lab Science",
  },
  {
    institution: 'Madison Media Institute',
  },
  {
    institution: 'Sanford-Brown Institute-Landover',
  },
  {
    institution: 'Sanford-Brown Institute-White Plains',
  },
  {
    institution: 'Sanford-Brown Institute-Garden City',
  },
  {
    institution: 'Great Plains Technology Center',
  },
  {
    institution: 'Montebello Beauty College',
  },
  {
    institution: 'Roane-Jackson Technical Center',
  },
  {
    institution: 'Total Cosmetology Training Center',
  },
  {
    institution: 'Iverson Business School',
  },
  {
    institution:
      'Lawrence County Career and Technical Center-Practical Nursing Program',
  },
  {
    institution: 'Pioneer Technology Center',
  },
  {
    institution: 'Portage Lakes Career Center',
  },
  {
    institution: 'Eastern College of Health Vocations-New Orleans',
  },
  {
    institution: 'Bell Mar Beauty College',
  },
  {
    institution: 'Cannella School of Hair Design-Chicago',
  },
  {
    institution: 'Caddo Kiowa Technology Center',
  },
  {
    institution: 'Kaplan College-San Antonio-San Pedro',
  },
  {
    institution: 'Oswego County BOCES',
  },
  {
    institution: 'Le Cordon Bleu College of Culinary Arts-Austin',
  },
  {
    institution: 'The Art Institute of New York City',
  },
  {
    institution: 'Paris II Educational Center',
  },
  {
    institution: 'International School of Skin Nailcare & Massage Therapy',
  },
  {
    institution: 'Palomar Institute of Cosmetology',
  },
  {
    institution: 'Southern Oklahoma Technology Center',
  },
  {
    institution: 'Vista College',
  },
  {
    institution: 'Autry Technology Center',
  },
  {
    institution: 'Greene County Vocational School District',
  },
  {
    institution: 'Canadian Valley Technology Center',
  },
  {
    institution:
      'Wilkes-Barre Area Career and Technical Center Practical Nursing',
  },
  {
    institution: 'Mayo Graduate School',
  },
  {
    institution: 'American Beauty Institute',
  },
  {
    institution: 'Meridian Technology Center',
  },
  {
    institution: 'Waynesville Career Center',
  },
  {
    institution: 'Connecticut Institute of Hair Design',
  },
  {
    institution: 'Capitol School of Hairstyling and Esthetics',
  },
  {
    institution: 'Morris County Vocational School District',
  },
  {
    institution: 'Ross Medical Education Center-Roosevelt Park',
  },
  {
    institution: 'Ross Medical Education Center-Kentwood',
  },
  {
    institution: 'South Florida Bible College and Theological Seminary',
  },
  {
    institution: "Cloyd's Barber School 2 Inc",
  },
  {
    institution: 'Universal Technical Institute of Illinois Inc',
  },
  {
    institution: 'Pennsylvania College of Technology',
  },
  {
    institution: 'Commonwealth Institute of Funeral Service',
  },
  {
    institution: 'Delta College of Arts & Technology',
  },
  {
    institution: 'InterCoast Colleges-Orange',
  },
  {
    institution: 'Stone Child College',
  },
  {
    institution: 'New York Academy of Art',
  },
  {
    institution: 'Suffolk County Community College',
  },
  {
    institution: 'Las Positas College',
  },
  {
    institution: 'Blades School of Hair Design',
  },
  {
    institution: 'Altamaha Technical College',
  },
  {
    institution: 'Ogeechee Technical College',
  },
  {
    institution: 'Remington College-Mobile Campus',
  },
  {
    institution: 'Everest College-Aurora',
  },
  {
    institution: 'Southwest Florida College',
  },
  {
    institution: "Mercy-St Luke's School of Radiologic Technology",
  },
  {
    institution: 'ITT Technical Institute-Norwood',
  },
  {
    institution: 'Southwest Acupuncture College-Santa Fe',
  },
  {
    institution: 'Northwest Technology Center-Alva',
  },
  {
    institution: 'Mercer County Career Center',
  },
  {
    institution: 'ITT Technical Institute-Knoxville',
  },
  {
    institution: 'ITT Technical Institute-Austin',
  },
  {
    institution: 'ITT Technical Institute-Houston North',
  },
  {
    institution:
      "DeVry University's Keller Graduate School of Management-Arizona",
  },
  {
    institution: 'California State University-San Marcos',
  },
  {
    institution: 'Argosy University-Hawaii',
  },
  {
    institution:
      "DeVry University's Keller Graduate School of Management-Missouri",
  },
  {
    institution: 'Baptist Theological Seminary at Richmond',
  },
  {
    institution: 'Kaplan College-Modesto',
  },
  {
    institution: 'Kaplan University-Des Moines Campus',
  },
  {
    institution: 'Hairmasters Institute of Cosmetology',
  },
  {
    institution: 'Hair Arts Academy',
  },
  {
    institution: 'Pinnacle Career Institute-Lawrence',
  },
  {
    institution: 'Interactive College of Technology-Newport',
  },
  {
    institution: 'Career Technical College-Monroe',
  },
  {
    institution: 'Empire Beauty School-Lowell',
  },
  {
    institution: 'Ross Medical Education Center-Taylor',
  },
  {
    institution: 'McNally Smith College of Music',
  },
  {
    institution: 'Allied Health Careers',
  },
  {
    institution: 'National Tractor Trailer School Inc-Liverpool',
  },
  {
    institution: 'Interactive Learning Systems-Houston',
  },
  {
    institution: 'Star Career Academy�Brick',
  },
  {
    institution: 'New Castle Beauty Academy',
  },
  {
    institution: 'NorthWest Arkansas Community College',
  },
  {
    institution: 'Madera Beauty College',
  },
  {
    institution: 'Colorado Heights University',
  },
  {
    institution: 'Brewster Technical Center',
  },
  {
    institution: 'Hodges University',
  },
  {
    institution: 'Everest University-Lakeland',
  },
  {
    institution: 'Argosy University-Atlanta',
  },
  {
    institution: 'University of St Augustine for Health Sciences',
  },
  {
    institution: 'Capital Area School of Practical Nursing',
  },
  {
    institution: 'Hair Professionals Career College',
  },
  {
    institution: 'Alexandria School of Scientific Therapeutics',
  },
  {
    institution: 'Empire Beauty School-Bloomfield',
  },
  {
    institution: 'Southwest Technology Center',
  },
  {
    institution: 'Western Area Career and Technology Center',
  },
  {
    institution: 'Daymar Institute-Clarksville',
  },
  {
    institution: 'Nossi College of Art',
  },
  {
    institution: 'Seguin Beauty School-New Braunfels',
  },
  {
    institution: 'ITT Technical Institute-Norfolk',
  },
  {
    institution: 'Pima Medical Institute-Seattle',
  },
  {
    institution: 'Mineral County Vocational Technical Center',
  },
  {
    institution: 'Hypnosis Motivation Institute',
  },
  {
    institution: 'Los Angeles ORT College-Los Angeles Campus',
  },
  {
    institution: 'Everest College-West Los Angeles',
  },
  {
    institution: 'Thanh Le College School of Cosmetology',
  },
  {
    institution: 'Southeastern Technical College',
  },
  {
    institution: 'Massachusetts School of Law',
  },
  {
    institution: 'ITT Technical Institute-Albuquerque',
  },
  {
    institution: 'Community Services Division-Alliance City',
  },
  {
    institution:
      'Universal Technical Institute-Auto Motorcycle & Marine Mechanics Institute Division-Orlando',
  },
  {
    institution: 'D G Erwin Technical Center',
  },
  {
    institution: 'Rosel School of Cosmetology',
  },
  {
    institution: 'Worsham College of Mortuary Science',
  },
  {
    institution: 'Bryan University',
  },
  {
    institution: 'Oregon College of Oriental Medicine',
  },
  {
    institution: 'Central Pennsylvania Institute of Science and Technology',
  },
  {
    institution: 'Northwest Educational Center',
  },
  {
    institution: 'Sebring Career Schools-Houston',
  },
  {
    institution: 'PCI Health Training Center',
  },
  {
    institution: 'Windham Memorial Hospital-Radiologic Technology Program',
  },
  {
    institution: 'Bethel Seminary of the East',
  },
  {
    institution: 'Miami-Jacobs Career College-Columbus',
  },
  {
    institution: 'Bryant & Stratton College-Eastlake',
  },
  {
    institution: 'Hamrick School',
  },
  {
    institution: 'Alabama State College of Barber Styling',
  },
  {
    institution: 'Fortis College-Mobile',
  },
  {
    institution: 'Arkansas Beauty School',
  },
  {
    institution: 'Everest College-Ontario',
  },
  {
    institution: 'Downey Adult School',
  },
  {
    institution: 'Heald College-Stockton',
  },
  {
    institution: 'Heald College-Hayward',
  },
  {
    institution: 'CRU Institute',
  },
  {
    institution: 'Modern Technology School',
  },
  {
    institution: 'National Career Education',
  },
  {
    institution: 'Everest College-Anaheim',
  },
  {
    institution: 'Thuy Princess Beauty College',
  },
  {
    institution: 'North-West College-Glendale',
  },
  {
    institution: 'Everest College-City of Industry',
  },
  {
    institution: 'Fremont College',
  },
  {
    institution: 'Pomona Unified School District Adult and Career Education',
  },
  {
    institution: 'University of Phoenix-Online Campus',
  },
  {
    institution: 'University of Phoenix-San Diego Campus',
  },
  {
    institution: 'Universal College of Beauty Inc-Los Angeles 2',
  },
  {
    institution: 'IBMC College',
  },
  {
    institution: 'Xenon International Academy-Denver',
  },
  {
    institution: 'Academy of Cosmetology',
  },
  {
    institution: 'ITT Technical Institute-Lake Mary',
  },
  {
    institution: 'Sunstate Academy',
  },
  {
    institution: 'Heritage Institute-Jacksonville',
  },
  {
    institution: 'Fortis College-Smyrna',
  },
  {
    institution: 'Remington College-Honolulu Campus',
  },
  {
    institution: 'Vatterott College-Des Moines',
  },
  {
    institution: 'Brown Mackie College-Quad Cities',
  },
  {
    institution: 'ETI School of Skilled Trades',
  },
  {
    institution: 'Pivot Point Academy-Bloomingdale',
  },
  {
    institution: 'Xenon International Academy-Kansas City',
  },
  {
    institution: 'Baton Rouge College',
  },
  {
    institution: 'Diesel Driving Academy-Baton Rouge',
  },
  {
    institution: 'Jonesville Beauty School',
  },
  {
    institution: 'New England Hair Academy',
  },
  {
    institution: 'Rob Roy Academy-Worcester',
  },
  {
    institution: 'Upper Cape Cod Regional Technical School',
  },
  {
    institution: 'All-State Career-Baltimore',
  },
  {
    institution: 'New England School of Communications',
  },
  {
    institution: 'Everest Institute-Kalamazoo',
  },
  {
    institution: 'Adler Graduate School',
  },
  {
    institution: 'Lebanon Technology and Career Center',
  },
  {
    institution: 'Academy of Hair Design-Jackson',
  },
  {
    institution: "KC's School of Hair Design",
  },
  {
    institution: 'Mitchells Hairstyling Academy-Goldsboro',
  },
  {
    institution: 'Lincoln Technical Institute-Moorestown',
  },
  {
    institution: 'Monmouth County Vocational School District',
  },
  {
    institution: 'Kaplan College-Las Vegas',
  },
  {
    institution: 'Beauty School of Middletown',
  },
  {
    institution: 'Bryant & Stratton College-Southtowns',
  },
  {
    institution: 'Continental School of Beauty Culture-Olean',
  },
  {
    institution: 'National Tractor Trailer School Inc-Buffalo',
  },
  {
    institution: "Yeshiva Gedolah Imrei Yosef D'spinka",
  },
  {
    institution: 'Cleveland Institute of Dental-Medical Assistants-Mentor',
  },
  {
    institution: 'Cleveland Institute of Dental-Medical Assistants-Lyndhurst',
  },
  {
    institution: 'Knox County Career Center',
  },
  {
    institution: 'Madison Adult Career Center',
  },
  {
    institution: 'Remington College-Cleveland Campus',
  },
  {
    institution: 'Brown Mackie College-Findlay',
  },
  {
    institution: 'Toledo Academy of Beauty Culture-East',
  },
  {
    institution: 'U S Grant Joint Vocational School',
  },
  {
    institution: "CC's Cosmetology College",
  },
  {
    institution: 'Chisholm Trail Technology Center',
  },
  {
    institution: 'Gordon Cooper Technology Center',
  },
  {
    institution: 'Kiamichi Technology Center-Atoka',
  },
  {
    institution: 'Kiamichi Technology Center-Durant',
  },
  {
    institution: 'Kiamichi Technology Center-Hugo',
  },
  {
    institution: 'Kiamichi Technology Center-McAlester',
  },
  {
    institution: 'Kiamichi Technology Center-Idabel',
  },
  {
    institution: 'Kiamichi Technology Center-Poteau',
  },
  {
    institution: 'Kiamichi Technology Center-Spiro',
  },
  {
    institution: 'Kiamichi Technology Center-Talihina',
  },
  {
    institution: 'Le Cordon Bleu College of Culinary Arts-Portland',
  },
  {
    institution: 'YTI Career Institute-Altoona',
  },
  {
    institution: 'Eastern Center for Arts and Technology',
  },
  {
    institution: 'Empire Beauty School-Center City Philadelphia',
  },
  {
    institution: 'Oakbridge Academy of Arts',
  },
  {
    institution: 'Sanford-Brown Institute-Wilkins Township',
  },
  {
    institution: 'Centro de Estudios Multidisciplinarios-Humacao',
  },
  {
    institution: 'Colegio Mayor de Tecnologia Inc',
  },
  {
    institution: 'Institucion Chaviano de Mayaguez',
  },
  {
    institution: 'EDIC College',
  },
  {
    institution: 'Liceo de Arte-Dise-O y Comercio',
  },
  {
    institution: 'Universal Technology College of Puerto Rico',
  },
  {
    institution: 'Academy of Hair Technology',
  },
  {
    institution: "Mr Wayne's School of Unisex Hair Design",
  },
  {
    institution: 'Southern Institute of Cosmetology',
  },
  {
    institution: 'Queen City College',
  },
  {
    institution: 'College of the Marshall Islands',
  },
  {
    institution: 'American National College',
  },
  {
    institution: 'ITT Technical Institute-San Antonio',
  },
  {
    institution: 'Remington College-Fort Worth Campus',
  },
  {
    institution: 'University of Cosmetology Arts & Sciences-San Antonio Jamar',
  },
  {
    institution: 'School of Automotive Machinists',
  },
  {
    institution: 'SW School of Business and Technical Careers-Cosmetology',
  },
  {
    institution: 'SW School of Business and Technical Careers',
  },
  {
    institution: 'Texas College of Cosmetology-Abilene',
  },
  {
    institution: 'Vista College-Online',
  },
  {
    institution: 'Centura College-Newport News',
  },
  {
    institution: 'Centura College-Norfolk',
  },
  {
    institution:
      'Virginia Beach City Public Schools School of Practical Nursing',
  },
  {
    institution: 'Northwest College of Art & Design',
  },
  {
    institution: 'University of Washington-Bothell Campus',
  },
  {
    institution: 'University of Washington-Tacoma Campus',
  },
  {
    institution: 'Huntington School of Beauty Culture-Main Campus',
  },
  {
    institution: 'Valley College-Beckley',
  },
  {
    institution: 'Valley College-Martinsburg',
  },
  {
    institution: 'Valley College-Princeton',
  },
  {
    institution: 'Bellus Academy-Poway',
  },
  {
    institution: 'Capstone College',
  },
  {
    institution: 'Central Coast College',
  },
  {
    institution: 'ITT Technical Institute-Torrance',
  },
  {
    institution: 'Pacific College of Oriental Medicine-San Diego',
  },
  {
    institution: 'Artistic Nails and Beauty Academy-Tampa',
  },
  {
    institution: 'National Aviation Academy of Tampa Bay',
  },
  {
    institution: 'Sanford-Brown Institute-Tampa',
  },
  {
    institution: "Cain's Barber College Inc",
  },
  {
    institution: 'Lawton Career Institute-Oak Park Campus',
  },
  {
    institution: 'Madison Oneida BOCES-Practical Nursing Program',
  },
  {
    institution: 'Astrodome Career Centers',
  },
  {
    institution: 'Center for Advanced Legal Studies',
  },
  {
    institution: 'Houston Training School-Main Campus',
  },
  {
    institution: 'Remington College-Houston Campus',
  },
  {
    institution: 'Bay Mills Community College',
  },
  {
    institution: 'Fond du Lac Tribal and Community College',
  },
  {
    institution: 'Northwest Indian College',
  },
  {
    institution: 'Marinello Schools of Beauty-Provo',
  },
  {
    institution: 'Provo College',
  },
  {
    institution: 'Paul Mitchell the School-Provo',
  },
  {
    institution: 'University of Phoenix-Utah Campus',
  },
  {
    institution: 'Utah College of Massage Therapy-Salt Lake City',
  },
  {
    institution: 'CET-Coachella',
  },
  {
    institution: 'CET-Oxnard',
  },
  {
    institution: 'CET-Santa Maria',
  },
  {
    institution: 'CET-Rancho Temecula',
  },
  {
    institution: 'Colorado School of Traditional Chinese Medicine',
  },
  {
    institution: 'Massage Therapy Institute of Colorado',
  },
  {
    institution: 'Michigan College of Beauty-Troy',
  },
  {
    institution: 'Ogle School Hair Skin Nails-Dallas',
  },
  {
    institution: 'Phoenix Seminary',
  },
  {
    institution: 'Faust Institute of Cosmetology-Spirit Lake',
  },
  {
    institution: 'Seattle Vocational Institute',
  },
  {
    institution: 'Baker College of Port Huron',
  },
  {
    institution: 'Empire Beauty School-Hyannis',
  },
  {
    institution: 'Brookstone College-Greensboro',
  },
  {
    institution: 'Cheeks International Academy of Beauty Culture-Fort Collins',
  },
  {
    institution: 'Cheeks International Academy of Beauty Culture-Greeley',
  },
  {
    institution: 'Colorado School of Healing Arts',
  },
  {
    institution: 'Remington College-Colorado Springs Campus',
  },
  {
    institution: 'Empire Beauty School-Arvada',
  },
  {
    institution: 'Westwood College-Denver South',
  },
  {
    institution: 'Kaplan College-Denver',
  },
  {
    institution: 'Healing Arts Institute',
  },
  {
    institution: 'ITT Technical Institute-San Bernardino',
  },
  {
    institution: 'Los Angeles ORT College-Van Nuys Campus',
  },
  {
    institution: 'Texas School of Business-Southwest',
  },
  {
    institution: 'University of Phoenix-Puerto Rico Campus',
  },
  {
    institution: 'Mt Diablo Adult Education',
  },
  {
    institution: 'Royale College of Beauty',
  },
  {
    institution: 'Westech College',
  },
  {
    institution: 'Greater Lowell Technical School',
  },
  {
    institution: 'Academy of Hair Design-Pearl',
  },
  {
    institution: "King's College",
  },
  {
    institution: 'Miller-Motte Technical College-Clarksville',
  },
  {
    institution: 'Genesis Career College-Lebanon',
  },
  {
    institution: 'Kaplan College-Dallas',
  },
  {
    institution: 'Star College of Cosmetology 2',
  },
  {
    institution: 'Southwest Collegiate Institute for the Deaf',
  },
  {
    institution: 'Fortis College-Richmond',
  },
  {
    institution: 'Midwest College of Oriental Medicine-Racine',
  },
  {
    institution: 'Hacienda La Puente Adult Education',
  },
  {
    institution: 'La Belle Beauty Academy',
  },
  {
    institution: 'Advanced Technical Centers',
  },
  {
    institution: 'Hawaii Community College',
  },
  {
    institution: 'Regency Beauty Institute-Winston-Salem',
  },
  {
    institution: 'Robert Fiance Beauty Schools-North Plainfield',
  },
  {
    institution: 'Long Island Beauty School-Hauppauge',
  },
  {
    institution: 'Buckeye Joint Vocational School',
  },
  {
    institution: 'Sandusky Career Center',
  },
  {
    institution: 'Greater Altoona Career & Technology Center',
  },
  {
    institution: 'Northeastern Hospital School of Nursing',
  },
  {
    institution: 'Central Texas Beauty College-Temple',
  },
  {
    institution: 'Dance Theatre of Harlem Inc',
  },
  {
    institution:
      'Erie 2 Chautauqua Cattaraugus BOCES-Practical Nursing Program',
  },
  {
    institution: 'Empire Beauty School-Brooklyn',
  },
  {
    institution: 'Houston Training School-Gulfgate',
  },
  {
    institution: 'Star Career Academy-Philadelphia',
  },
  {
    institution: 'Antonelli College-Hattiesburg',
  },
  {
    institution: 'Eastern New Mexico University-Ruidoso Campus',
  },
  {
    institution: 'Newbridge College-Santa Ana',
  },
  {
    institution: 'Kaplan College-Stockton',
  },
  {
    institution: 'The Bryman School of Arizona',
  },
  {
    institution: 'Beacon College',
  },
  {
    institution: 'Acupuncture and Integrative Medicine College-Berkeley',
  },
  {
    institution: 'Estrella Mountain Community College',
  },
  {
    institution: 'Heartland Community College',
  },
  {
    institution: 'University of the Potomac-Washington DC Campus',
  },
  {
    institution: 'Rabbi Jacob Joseph School',
  },
  {
    institution: 'Sanford-Brown Institute-Ft Lauderdale',
  },
  {
    institution: 'The Salon Professional Academy-Melbourne',
  },
  {
    institution: 'Fortis Institute-Scranton',
  },
  {
    institution: 'Milan Institute of Cosmetology-Amarillo',
  },
  {
    institution: 'Everglades University',
  },
  {
    institution: "Seminar L'moros Bais Yaakov",
  },
  {
    institution: 'Herzing University-Winter Park',
  },
  {
    institution: 'Coral Ridge Nurses Assistant Training School Inc',
  },
  {
    institution: 'Emil Fries Piano Hospital and Training Center',
  },
  {
    institution: 'Fairview Beauty Academy',
  },
  {
    institution: 'Florida College of Natural Health-Pompano Beach',
  },
  {
    institution: 'National College-Nashville',
  },
  {
    institution: 'College of Biblical Studies-Houston',
  },
  {
    institution: 'Inner State Beauty School',
  },
  {
    institution: 'Virginia College-Pensacola',
  },
  {
    institution: 'Mid-EastCTC-Adult Education',
  },
  {
    institution: 'Everest University-South Orlando',
  },
  {
    institution: 'Polaris Career Center',
  },
  {
    institution: 'P&A Scholars Beauty School',
  },
  {
    institution: 'Rensselaer BOCES-Practical Nursing Program',
  },
  {
    institution: 'ASM Beauty World Academy',
  },
  {
    institution: 'The Salter School-Tewksbury Campus',
  },
  {
    institution: 'Sanford-Brown College-Collinsville',
  },
  {
    institution: 'Sharps Academy of Hair Styling',
  },
  {
    institution: 'ICC Technical Institute',
  },
  {
    institution: 'College of Health Care Professions-Northwest',
  },
  {
    institution:
      'School of Professional Horticulture at the New York Botanical Garden',
  },
  {
    institution: 'Fortis College-Houston',
  },
  {
    institution: 'Volunteer Beauty Academy-Madison',
  },
  {
    institution: 'Watkins College of Art Design & Film',
  },
  {
    institution: 'Chicago ORT Technical Institute',
  },
  {
    institution: 'Alhambra Beauty College',
  },
  {
    institution: 'American Auto Institute',
  },
  {
    institution: 'Asian American International Beauty College',
  },
  {
    institution: 'Avance Beauty College',
  },
  {
    institution: 'Colton-Redlands-Yucaipa Regional Occupational Program',
  },
  {
    institution: 'Copper Mountain Community College',
  },
  {
    institution: 'Diversified Vocational College',
  },
  {
    institution: 'Bristol University',
  },
  {
    institution: 'UEI College-Fresno',
  },
  {
    institution: 'Mt Sierra College',
  },
  {
    institution: 'Wyotech-Long Beach',
  },
  {
    institution: 'Santiago Canyon College',
  },
  {
    institution: 'Southern California Institute of Technology',
  },
  {
    institution: 'Soka University of America',
  },
  {
    institution: 'Summit College',
  },
  {
    institution: 'Advance Beauty College',
  },
  {
    institution: 'Career Colleges of America',
  },
  {
    institution: 'World Mission University',
  },
  {
    institution: 'Yo San University of Traditional Chinese Medicine',
  },
  {
    institution: 'Coast Career Institute',
  },
  {
    institution: 'Cannella School of Hair Design-Blue Island',
  },
  {
    institution: 'Delta College Inc',
  },
  {
    institution: 'Colorado Technical University-Sioux Falls',
  },
  {
    institution: 'Southwest University of Visual Arts-Albuquerque',
  },
  {
    institution: 'Tri-State Cosmetology Institute',
  },
  {
    institution: 'Widener University-Harrisburg Campus',
  },
  {
    institution: 'Volunteer Beauty Academy-System Office',
  },
  {
    institution: 'Volunteer Beauty Academy-Nashville',
  },
  {
    institution: 'Frontier Community College',
  },
  {
    institution: 'Lincoln Trail College',
  },
  {
    institution: 'Wabash Valley College',
  },
  {
    institution: 'American Institute of Interior Design',
  },
  {
    institution: 'Brookline College-Tempe',
  },
  {
    institution: 'Baker College of Auburn Hills',
  },
  {
    institution: 'Baker College of Clinton Township',
  },
  {
    institution: 'Inter American University of Puerto Rico-School of Optometry',
  },
  {
    institution: 'Schiller International University',
  },
  {
    institution: 'Vatterott College-Springfield',
  },
  {
    institution: 'Vatterott College-Joplin',
  },
  {
    institution: 'Vatterott College-Kansas City',
  },
  {
    institution: 'Coconino Community College',
  },
  {
    institution: 'University System of Maryland-Research Centers',
  },
  {
    institution: 'Sanford-Brown College-Houston',
  },
  {
    institution: 'Sanford-Brown Institute-Jacksonville',
  },
  {
    institution: 'Sanford-Brown College-Dallas',
  },
  {
    institution: 'Baker College of Cadillac',
  },
  {
    institution: "Emma's Beauty Academy-Juana Diaz",
  },
  {
    institution: 'Franklin Academy',
  },
  {
    institution: 'Columbia Centro Universitario-Yauco',
  },
  {
    institution: 'Pima Medical Institute-Denver',
  },
  {
    institution: 'Fortis College-Miami',
  },
  {
    institution: 'ASA College',
  },
  {
    institution: 'Ohio State School of Cosmetology-Westerville',
  },
  {
    institution: 'Nationwide Beauty Academy',
  },
  {
    institution: 'Yeshivas Novominsk',
  },
  {
    institution: 'Southern Careers Institute-Corpus Christi',
  },
  {
    institution: 'Kiamichi Technology Center-Stigler',
  },
  {
    institution: 'Rabbinical College of Ohr Shimon Yisroel',
  },
  {
    institution: 'Carroll Community College',
  },
  {
    institution: 'University of Phoenix-Southern Arizona Campus',
  },
  {
    institution: 'University of Phoenix-Colorado Campus',
  },
  {
    institution: 'University of Phoenix-Albuquerque Campus',
  },
  {
    institution: 'University of Phoenix-Bay Area Campus',
  },
  {
    institution: 'University of Phoenix-Southern California Campus',
  },
  {
    institution: 'Galen College of Nursing-Tampa Bay',
  },
  {
    institution: 'Galen College of Nursing-San Antonio',
  },
  {
    institution: 'Southeastern Beauty School-Columbus North Lumpkin',
  },
  {
    institution: 'Southeastern Beauty School-Columbus Midtown',
  },
  {
    institution: 'Sarasota School of Massage Therapy',
  },
  {
    institution: 'Westwood College-Dupage',
  },
  {
    institution: 'Wright Career College',
  },
  {
    institution: 'Daymar College-Louisville',
  },
  {
    institution: 'Sanford-Brown College-Hazelwood',
  },
  {
    institution: 'Schuyler Steuben Chemung Tioga Allegany BOCES',
  },
  {
    institution:
      'Delaware Chenango Madison Otsego BOCES-Practical Nursing Program',
  },
  {
    institution: 'DCI Career Institute',
  },
  {
    institution: 'Central Susquehanna Intermediate Unit LPN Career',
  },
  {
    institution: 'Myotherapy College of Utah',
  },
  {
    institution: 'Graham Webb International Academy of Hair',
  },
  {
    institution: 'City College-Gainesville',
  },
  {
    institution: 'Arizona State University-West',
  },
  {
    institution: 'Connecticut Center for Massage Therapy-Westport',
  },
  {
    institution: 'ITT Technical Institute-Jacksonville',
  },
  {
    institution: 'G Skin & Beauty Institute',
  },
  {
    institution: 'Cosmetology Training Center',
  },
  {
    institution: 'Stage One-The Hair School',
  },
  {
    institution: 'Empire Beauty School-Malden',
  },
  {
    institution: 'Minnesota School of Business-Brooklyn Center',
  },
  {
    institution: 'ITT Technical Institute-Omaha',
  },
  {
    institution: 'Omaha School of Massage and Healthcare of Herzing University',
  },
  {
    institution: 'Xenon International Academy-Omaha',
  },
  {
    institution: 'Capri Institute of Hair Design-Brick',
  },
  {
    institution: 'Capri Institute of Hair Design-Kenilworth',
  },
  {
    institution: 'Capri Institute of Hair Design-Paramus',
  },
  {
    institution: 'Orlo School of Hair Design and Cosmetology',
  },
  {
    institution: 'Delaware Area Career Center',
  },
  {
    institution: 'Eastland-Fairfield Career and Technical Schools',
  },
  {
    institution: 'Empire Beauty School-Cincinnati',
  },
  {
    institution: 'Mahoning County Career and Technical Center',
  },
  {
    institution: 'Ohio Hi Point Joint Vocational School District',
  },
  {
    institution: 'Pickaway Ross Joint Vocational School District',
  },
  {
    institution: 'Pioneer Career and Technology Center',
  },
  {
    institution: "Raphael's School of Beauty Culture Inc-Boardman",
  },
  {
    institution: 'Scioto County Career Technical Center',
  },
  {
    institution: 'Trumbull Career & Technical Center',
  },
  {
    institution: 'Northwest Technology Center-Fairview',
  },
  {
    institution: 'Lake Erie College of Osteopathic Medicine',
  },
  {
    institution: 'University of Cosmetology Arts & Sciences-Harlingen',
  },
  {
    institution: 'Gene Juarez Academy of Beauty-Federal Way',
  },
  {
    institution: 'Empire Beauty School-Appleton',
  },
  {
    institution: 'Empire Beauty School-Green Bay',
  },
  {
    institution: 'Warren County Career Center',
  },
  {
    institution: 'Brown Mackie College-Fort Wayne',
  },
  {
    institution: 'Paul Mitchell the School-Wichita',
  },
  {
    institution: 'Pinnacle Institute of Cosmetology',
  },
  {
    institution: 'Career Training Academy-Monroeville',
  },
  {
    institution: 'Southern Careers Institute-Pharr',
  },
  {
    institution: 'Texas State Technical College-Marshall',
  },
  {
    institution: 'Florida National University-Main Campus',
  },
  {
    institution: 'Georgia Institute of Cosmetology',
  },
  {
    institution: 'Nouvelle Institute',
  },
  {
    institution: 'ITT Technical Institute-Fort Lauderdale',
  },
  {
    institution: 'Franklin Technology-MSSU',
  },
  {
    institution: 'Carson City Beauty Academy',
  },
  {
    institution: 'Professional Technical Institution Inc',
  },
  {
    institution: 'Erikson Institute',
  },
  {
    institution: 'South Texas College',
  },
  {
    institution: 'Tennessee Board of Regents',
  },
  {
    institution: 'Pike County Joint Vocational School District',
  },
  {
    institution: 'Lorain County Joint Vocational School District',
  },
  {
    institution: 'Roger Williams University School of Law',
  },
  {
    institution: 'Everest Institute-Kendall',
  },
  {
    institution: 'California State University-Monterey Bay',
  },
  {
    institution: 'Sanford-Brown College-St Peters',
  },
  {
    institution: 'Colorado Technical University-Kansas City',
  },
  {
    institution: 'Heald College-Salinas',
  },
  {
    institution: 'Academy of Somatic Healing Arts',
  },
  {
    institution: 'Heritage College-Oklahoma City',
  },
  {
    institution: 'Ann Arbor Institute of Massage Therapy',
  },
  {
    institution: 'Brown Mackie College-Atlanta',
  },
  {
    institution: 'Bryant & Stratton College-Henrietta',
  },
  {
    institution: 'The Art Institute of California-Argosy University Hollywood',
  },
  {
    institution: 'Sage College',
  },
  {
    institution: 'Anthem College-Denver',
  },
  {
    institution: 'Lincoln Technical Institute-Hartford',
  },
  {
    institution: 'Ohio Center for Broadcasting-Valley View',
  },
  {
    institution: 'EINE Inc',
  },
  {
    institution: 'Laurus Technical Institute',
  },
  {
    institution: 'Illinois School of Health Careers-Chicago Campus',
  },
  {
    institution: 'Marian Health Careers Center-Los Angeles Campus',
  },
  {
    institution: 'Cleveland Clinic Health System-School of Diagnostic Imaging',
  },
  {
    institution: 'National Institute of Massotherapy',
  },
  {
    institution: 'Academy for Nursing and Health Occupations',
  },
  {
    institution: 'Shawsheen Valley Regional Vocational Technical School',
  },
  {
    institution: 'Shear Ego International School of Hair Design',
  },
  {
    institution: 'Remington College-Memphis Campus',
  },
  {
    institution: 'Star Truck Driving School-Hickory Hills',
  },
  {
    institution: 'Fayette Institute of Technology',
  },
  {
    institution: 'Professional Beauty School',
  },
  {
    institution: 'Capella University',
  },
  {
    institution: 'College of Menominee Nation',
  },
  {
    institution: 'Leech Lake Tribal College',
  },
  {
    institution: 'Anthem College-Sacramento',
  },
  {
    institution: 'Southwest Acupuncture College-Albuquerque',
  },
  {
    institution: 'Crimson Technical College',
  },
  {
    institution: 'Stone Academy-Waterbury',
  },
  {
    institution: 'East San Gabriel Valley Regional Occupational Program',
  },
  {
    institution: 'Yukon Beauty College Inc',
  },
  {
    institution: 'ITT Technical Institute-Little Rock',
  },
  {
    institution: 'ITT Technical Institute-Oxnard',
  },
  {
    institution: 'ITT Technical Institute-Louisville',
  },
  {
    institution: 'ITT Technical Institute-Greenville',
  },
  {
    institution: 'ITT Technical Institute-Cordova',
  },
  {
    institution: 'Cairn University-Wisconsin Wilderness',
  },
  {
    institution: 'Toni & Guy Hairdressing Academy-Atlanta',
  },
  {
    institution: "Mitchell's Hair Styling Academy-Raleigh",
  },
  {
    institution: 'Salon Academy',
  },
  {
    institution: 'Artistic Academy of Hair Design',
  },
  {
    institution: 'Motoring Technical Training Institute',
  },
  {
    institution: 'Baker College of Jackson',
  },
  {
    institution: 'Phagans Newport Academy of Cosmetology Careers',
  },
  {
    institution: 'Mech-Tech College',
  },
  {
    institution: 'Baldwin Beauty School-North Austin',
  },
  {
    institution: 'ITT Technical Institute-Everett',
  },
  {
    institution: 'Jean Madeline Aveda Institute',
  },
  {
    institution: 'Cosmetology Schools Inc',
  },
  {
    institution: 'ITT Technical Institute-Bessemer',
  },
  {
    institution: 'ITT Technical Institute-Orland Park',
  },
  {
    institution: 'Pacific College of Oriental Medicine-New York',
  },
  {
    institution: 'Baker College Center for Graduate Studies',
  },
  {
    institution: 'Johnson & Wales University-North Miami',
  },
  {
    institution: 'ITT Technical Institute-Pittsburgh',
  },
  {
    institution: 'Tricoci University of Beauty Culture-Indianapolis',
  },
  {
    institution: 'Trine University-Regional/Non-Traditional Campuses',
  },
  {
    institution: 'Pennsylvania Highlands Community College',
  },
  {
    institution: 'Nevada Career Institute',
  },
  {
    institution: 'Phillips Theological Seminary',
  },
  {
    institution: 'Empire Beauty School-Hanover',
  },
  {
    institution: 'Industrial Management Training Institute',
  },
  {
    institution: 'Faris Technical Institute Inc',
  },
  {
    institution: 'Four-D College',
  },
  {
    institution: 'Allen School-Jamaica',
  },
  {
    institution: 'Premiere Career College',
  },
  {
    institution: 'Tennessee Academy of Cosmetology-Stage Road',
  },
  {
    institution: 'The University of Texas MD Anderson Cancer Center',
  },
  {
    institution: 'University of Phoenix-Las Vegas Campus',
  },
  {
    institution: 'Carousel Beauty College-Springfield',
  },
  {
    institution: 'Carousel Beauty College-Kettering',
  },
  {
    institution: 'Paul Mitchell the School-Huntsville',
  },
  {
    institution: 'Estes Institute of Cosmetology Arts and Science',
  },
  {
    institution: 'Baldwin Park Adult & Community Education',
  },
  {
    institution: 'Ventura Adult and Continuing Education',
  },
  {
    institution: 'Marinello Schools of Beauty-Sacramento',
  },
  {
    institution: 'Albert I Prince Technical High School',
  },
  {
    institution: 'Bullard-Havens Technical High School',
  },
  {
    institution: 'Howell Cheney Technical High School',
  },
  {
    institution: 'W F Kaynor Technical High School',
  },
  {
    institution: 'Platt Technical High School',
  },
  {
    institution: 'College of Business and Technology-Kendall',
  },
  {
    institution: 'City College-Altamonte Springs',
  },
  {
    institution: 'Augusta Area Dietetic Internship-University Hospital',
  },
  {
    institution: 'Empire Beauty School-Dunwoody',
  },
  {
    institution: "Michael's School of Beauty",
  },
  {
    institution: 'Cobb Beauty College Inc',
  },
  {
    institution: 'Career Center of Southern Illinois',
  },
  {
    institution: 'Xenon International Academy-Wichita',
  },
  {
    institution: 'Camelot College',
  },
  {
    institution: "Mr Bernard's School of Hair Fashion Inc",
  },
  {
    institution: 'Hastings Beauty School',
  },
  {
    institution: 'Kirksville Area Technical Center',
  },
  {
    institution: 'South Central Career Center',
  },
  {
    institution: 'Saline County Career Center',
  },
  {
    institution: 'Warrensburg Area Career Center',
  },
  {
    institution: 'Nevada Regional Technical Center',
  },
  {
    institution: 'Cass Career Center',
  },
  {
    institution: 'Applied Technology Services',
  },
  {
    institution: 'Central College of Cosmetology',
  },
  {
    institution:
      'Southeast Missouri Hospital College of Nursing and Health Sciences',
  },
  {
    institution: 'Dallas County Technical Center',
  },
  {
    institution: 'Messenger College',
  },
  {
    institution: 'Tennessee Academy of Cosmetology-Shelby Drive',
  },
  {
    institution: 'Cheveux School of Hair Design',
  },
  {
    institution: 'Durham Beauty Academy',
  },
  {
    institution: 'Union County Vocational Technical School',
  },
  {
    institution: 'Prism Career Institute-Philadelphia',
  },
  {
    institution:
      'Clinton Essex Warren Washington BOCES-Practical Nursing Program',
  },
  {
    institution: 'Jefferson Lewis BOCES-Practical Nursing Program',
  },
  {
    institution: 'Rochester General Hospital School of Medical Technology',
  },
  {
    institution: 'Veeb Nassau County School of Practical Nursing',
  },
  {
    institution: 'Syracuse City Schools Practical Nursing Program',
  },
  {
    institution: 'Cattaraugus Allegany BOCES-Practical Nursing Program',
  },
  {
    institution: 'Orange Ulster BOCES-Practical Nursing Program',
  },
  {
    institution: 'Wayne Finger Lakes BOCES-Practical Nursing Program',
  },
  {
    institution: 'Broome Delaware Tioga BOCES-Practical Nursing Program',
  },
  {
    institution:
      'Washington Saratoga Warren Hamilton Essex BOCES-Practical Nursing Program',
  },
  {
    institution: 'Albany BOCES-Adult Practical Nursing Program',
  },
  {
    institution: 'Ulster County BOCES-Practical Nursing Program',
  },
  {
    institution: 'Eastern Suffolk BOCES-Practical Nursing Program',
  },
  {
    institution: 'Rockland County BOCES-Practical Nursing Program',
  },
  {
    institution: 'New York College of Health Professions',
  },
  {
    institution: 'Career Institute of Health and Technology',
  },
  {
    institution: 'Charles Stuart School of Diamond Setting',
  },
  {
    institution: 'Penta County Joint Vocational School',
  },
  {
    institution: 'Career and Technology Education Centers of Licking County',
  },
  {
    institution: 'Willoughby-Eastlake School of Practical Nursing',
  },
  {
    institution: 'Washington County Career Center-Adult Technical Training',
  },
  {
    institution: 'Ohio Center for Broadcasting-Cincinnati',
  },
  {
    institution: 'Vantage Career Center',
  },
  {
    institution: 'Tri County Technology Center',
  },
  {
    institution: 'Indian Capital Technology Center-Muskogee',
  },
  {
    institution: 'Western Technology Center',
  },
  {
    institution: 'Mid-America Technology Center',
  },
  {
    institution: 'Northeast Technology Center-Pryor',
  },
  {
    institution: 'Eastern Oklahoma County Technology Center',
  },
  {
    institution: 'Wes Watkins Technology Center',
  },
  {
    institution: 'Technical Institute of Cosmetology Arts and Sciences',
  },
  {
    institution:
      'York County School of Technology-Adult & Continuing Education',
  },
  {
    institution: 'Chester County Intermediate Unit',
  },
  {
    institution:
      'Jefferson County Dubois Area Vocational Technical Practical Nursing Program',
  },
  {
    institution: 'Greater Johnstown Career and Technology Center',
  },
  {
    institution: 'Mifflin-Juniata Career and Technology Center',
  },
  {
    institution: 'Hanover Public School District Practical Nursing Program',
  },
  {
    institution: 'Lenape Technical School Practical Nursing Program',
  },
  {
    institution: 'Venango County Area Vocational Technical School',
  },
  {
    institution: 'Lancaster County Career and Technology Center',
  },
  {
    institution: 'Lebanon County Area Vocational Technical School',
  },
  {
    institution: 'Indiana County Technology Center',
  },
  {
    institution: 'Northern Tier Career Center',
  },
  {
    institution: 'Clarion County Career Center Practical Nursing Program',
  },
  {
    institution: 'Hazleton Area Career Center',
  },
  {
    institution: 'All-State Career School-Pittsburgh',
  },
  {
    institution: 'Carlos F Daniels Area Vocational School',
  },
  {
    institution: 'Serbias Technical College',
  },
  {
    institution: 'Colegio Tecnologico y Comercial de Puerto Rico',
  },
  {
    institution: 'Quality Technical and Beauty College',
  },
  {
    institution: "D'Mart Institute",
  },
  {
    institution: 'Automeca Technical College-Aguadilla',
  },
  {
    institution: 'Fortis Institute-Cookeville',
  },
  {
    institution: 'North Central Institute',
  },
  {
    institution: 'Pipo Academy of Hair Design',
  },
  {
    institution: 'Tint School of Makeup and Cosmetology-Dallas',
  },
  {
    institution: 'United Technical Center',
  },
  {
    institution: 'Charles H McCann Technical School',
  },
  {
    institution: 'Ailano School of Cosmetology',
  },
  {
    institution: 'Carsten Institute of Cosmetology',
  },
  {
    institution: 'Central California School',
  },
  {
    institution:
      'Mercy Hospital School of Practical Nursing-Plantation General Hospital',
  },
  {
    institution: 'Marion County Community Technical and Adult Education Center',
  },
  {
    institution: 'Rivertown School of Beauty Barber Skin Care and Nails',
  },
  {
    institution: 'Atlanta Institute of Music',
  },
  {
    institution: 'Illinois Center for Broadcasting',
  },
  {
    institution: 'Marion Community Schools-Tucker Career & Technology Center',
  },
  {
    institution: 'St Louis Hair Academy',
  },
  {
    institution: 'New Dimensions School of Hair Design',
  },
  {
    institution: 'Adirondack Beauty School',
  },
  {
    institution: 'Forbes Road Career and Technology Center',
  },
  {
    institution: 'Jna Institute of Culinary Arts',
  },
  {
    institution: 'Cortiva Institute-Seattle',
  },
  {
    institution: 'Putnam Career and Technical Center',
  },
  {
    institution: 'Argosy University-Washington DC',
  },
  {
    institution:
      'Crawford County Career and Technical Center Practical Nursing Program',
  },
  {
    institution: 'University of Phoenix-Sacramento Valley Campus',
  },
  {
    institution:
      "DeVry University's Keller Graduate School of Management-Georgia",
  },
  {
    institution: 'Texas College of Cosmetology-San Angelo',
  },
  {
    institution: 'Beauty Schools of America-Hialeah',
  },
  {
    institution: 'Automeca Technical College-Bayamon',
  },
  {
    institution: 'Automeca Technical College-Caguas',
  },
  {
    institution: 'Automeca Technical College-Ponce',
  },
  {
    institution: 'Everest University-Melbourne',
  },
  {
    institution: 'Centura College-Chesapeake',
  },
  {
    institution: 'University of Phoenix-Hawaii Campus',
  },
  {
    institution: 'Florida College of Natural Health-Miami',
  },
  {
    institution: 'Prism Career Institute-Cherry Hill',
  },
  {
    institution: 'Amerian National College',
  },
  {
    institution: 'Francois D College of Hair Skin and Nails',
  },
  {
    institution: 'Southwest College of Naturopathic Medicine & Health Sciences',
  },
  {
    institution: 'Escuela de Peritos Electricistas de Isabela Inc',
  },
  {
    institution: 'Virginia College-Birmingham',
  },
  {
    institution: 'Virginia College-Huntsville',
  },
  {
    institution: "Yeshiva D'monsey Rabbinical College",
  },
  {
    institution: 'Lincoln Technical Institute-Mahwah',
  },
  {
    institution: 'Career Technical Institute',
  },
  {
    institution: 'Northwest Vista College',
  },
  {
    institution: 'ITT Technical Institute-Getzville',
  },
  {
    institution: 'Oconee Fall Line Technical College',
  },
  {
    institution: 'York County Community College',
  },
  {
    institution: 'Northeast Technology Center-Afton',
  },
  {
    institution: 'Western Suffolk BOCES',
  },
  {
    institution: 'Sanford-Brown College-Atlanta',
  },
  {
    institution: 'Sanford-Brown College-Middleburg Heights',
  },
  {
    institution: 'Arkansas State University-Mountain Home',
  },
  {
    institution: 'Columbia Gorge Community College',
  },
  {
    institution: 'Arizona State University-Polytechnic',
  },
  {
    institution: "Colleen O'Haras Beauty Academy",
  },
  {
    institution: 'Bakke Graduate University',
  },
  {
    institution: 'Tillamook Bay Community College',
  },
  {
    institution: 'Arizona Board of Regents',
  },
  {
    institution: 'Argosy University-Schaumburg',
  },
  {
    institution:
      "DeVry University's Keller Graduate School of Management-California",
  },
  {
    institution: 'Manhattan School of Computer Technology',
  },
  {
    institution: 'University of Phoenix-Louisiana Campus',
  },
  {
    institution: 'University of Phoenix-Detroit Campus',
  },
  {
    institution: 'Brown Mackie College-Hopkinsville',
  },
  {
    institution: 'Indian Capital Technology Center-Sallisaw',
  },
  {
    institution: 'Indian Capital Technology Center-Stilwell',
  },
  {
    institution: 'Globelle Technical Institute',
  },
  {
    institution: 'Hairitage Hair Academy',
  },
  {
    institution: 'Houghton Lake Institute of Cosmetology',
  },
  {
    institution: 'Arizona College-Glendale',
  },
  {
    institution: 'Vanguard-Sentinel Adult Career and Technology Center',
  },
  {
    institution: 'House of Heavilin Beauty College-Raymore',
  },
  {
    institution: 'Living Arts College',
  },
  {
    institution: 'New York Conservatory for Dramatic Arts',
  },
  {
    institution: 'Eastern International College-Jersey City',
  },
  {
    institution: 'Academia Serrant Inc',
  },
  {
    institution: 'San Joaquin Valley College-Fresno Aviation',
  },
  {
    institution: 'Champion Institute of Cosmetology',
  },
  {
    institution: 'Pacific College',
  },
  {
    institution: 'American College of Healthcare',
  },
  {
    institution: 'Compu-Med Vocational Careers Corp',
  },
  {
    institution: 'Professional Electrical School Inc',
  },
  {
    institution: 'American Commercial College-Wichita Falls',
  },
  {
    institution: 'Midwestern University-Glendale',
  },
  {
    institution: 'Oregon Coast Community College',
  },
  {
    institution: 'Le Cordon Bleu College of Culinary Arts-Pasadena',
  },
  {
    institution: 'Gwinnett College-Sandy Springs',
  },
  {
    institution: 'Central Mass School of Massage and Therapy Inc',
  },
  {
    institution: 'Lincoln Technical Institute-Brockton',
  },
  {
    institution: 'Mildred Elley-Pittsfield Campus',
  },
  {
    institution: 'Embry-Riddle Aeronautical University-Worldwide',
  },
  {
    institution: 'Computer Systems Institute',
  },
  {
    institution: 'Merkaz Bnos-Business School',
  },
  {
    institution: 'Lia Schorr Institute of Cosmetic Skin Care Training',
  },
  {
    institution: 'ITT Technical Institute-Webster',
  },
  {
    institution: 'CET-Alexandria',
  },
  {
    institution: 'CET-Durham',
  },
  {
    institution: 'Aviation Institute of Maintenance-Chesapeake',
  },
  {
    institution: 'Centura College-Richmond Main',
  },
  {
    institution: 'Technical Education Center-Osceola',
  },
  {
    institution: 'Miami Ad School-Miami Beach',
  },
  {
    institution: 'Green Country Technology Center',
  },
  {
    institution: 'Nova College de Puerto Rico',
  },
  {
    institution: 'Styles and Profiles Beauty College',
  },
  {
    institution: 'John Wesley International Barber and Beauty College',
  },
  {
    institution: 'Platt College-Lawton',
  },
  {
    institution: 'Southeastern College-Greenacres',
  },
  {
    institution: 'Ottawa University-Milwaukee',
  },
  {
    institution: 'Argosy University-Tampa',
  },
  {
    institution: 'The Illinois Institute of Art-Schaumburg',
  },
  {
    institution: 'Star Truck Driving School-Bensenville',
  },
  {
    institution: 'Cambria-Rowe Business College-Indiana',
  },
  {
    institution: 'Facultad de Derecho Eugenio Maria de Hostos',
  },
  {
    institution: 'Klamath Community College',
  },
  {
    institution: 'San Bernardino Community College District',
  },
  {
    institution: 'The Art Institute of Phoenix',
  },
  {
    institution: 'Aerosim Flight Academy',
  },
  {
    institution: 'American College of Acupuncture and Oriental Med',
  },
  {
    institution: 'AOMA Graduate School of Integrative Medicine',
  },
  {
    institution: 'Arlington Medical Institute',
  },
  {
    institution: 'Urban College of Boston',
  },
  {
    institution: 'ITT Technical Institute-Henderson',
  },
  {
    institution: 'Anthem College-Minnesota',
  },
  {
    institution: 'Colorado Technical University-Greenwood Village',
  },
  {
    institution: 'Branford Hall Career Institute-Windsor Campus',
  },
  {
    institution: 'Heald College-Portland',
  },
  {
    institution: 'Golf Academy of America-Altamonte Springs',
  },
  {
    institution: 'Golf Academy of America-Phoenix',
  },
  {
    institution: 'Everest College-Merrillville',
  },
  {
    institution: 'Strayer University-Maryland',
  },
  {
    institution: 'Empire Beauty School-Bordentown',
  },
  {
    institution: 'Empire Beauty School-Monroeville',
  },
  {
    institution: 'Southern Careers Institute-San Antonio',
  },
  {
    institution: 'Virginia School of Massage',
  },
  {
    institution: 'ITT Technical Institute-Miami',
  },
  {
    institution: 'Cayce Reilly School of Massotherapy',
  },
  {
    institution: 'CET-Sacramento',
  },
  {
    institution: 'ITT Technical Institute-Harrisburg',
  },
  {
    institution: 'ITT Technical Institute-Tarentum',
  },
  {
    institution: 'ITT Technical Institute-Norwood',
  },
  {
    institution: 'ITT Technical Institute-Strongsville',
  },
  {
    institution: 'Prism Career Institute-West Atlantic City',
  },
  {
    institution: 'Everest Institute-Marietta',
  },
  {
    institution: 'The Creative Center',
  },
  {
    institution: 'Studio Jewelers',
  },
  {
    institution: 'Praxis Institute',
  },
  {
    institution: 'American College of Traditional Chinese Medicine',
  },
  {
    institution: 'Herndon Career Center',
  },
  {
    institution: 'San Juan Bautista School of Medicine',
  },
  {
    institution: 'Texas Health and Science University',
  },
  {
    institution: 'Carver Career Center',
  },
  {
    institution: 'New Hampshire Institute of Art',
  },
  {
    institution: 'StenoTech Career Institute-Fairfield',
  },
  {
    institution: 'Cayuga Onondaga BOCES-Practical Nursing Program',
  },
  {
    institution: 'Clearfield County Career and Technology Center',
  },
  {
    institution: 'Colegio de Cinematograf�a Artes y Television',
  },
  {
    institution: 'Delaware County Technical School-Practical Nursing Program',
  },
  {
    institution: 'Everest College-Torrance',
  },
  {
    institution: 'The Institute for Health Education',
  },
  {
    institution: 'Mid-Del Technology Center',
  },
  {
    institution: 'BIR Training Center',
  },
  {
    institution: 'New York Institute of Massage Inc',
  },
  {
    institution: 'Jolie Hair and Beauty Academy-Ludlow',
  },
  {
    institution: 'Dutchess BOCES-Practical Nursing Program',
  },
  {
    institution: 'Escuela Tecnica de Electricidad',
  },
  {
    institution: 'Institute of Technology Inc',
  },
  {
    institution: 'Garnet Career Center',
  },
  {
    institution: 'Bristol Technical Education Center',
  },
  {
    institution: 'Genesee Valley BOCES-Practical Nursing Program',
  },
  {
    institution: 'Hamilton Fulton Montgomery BOCES-Practical Nursing Program',
  },
  {
    institution:
      'Henrico County-Saint Marys Hospital School of Practical Nursing',
  },
  {
    institution: 'Herkimer County BOCES-Practical Nursing Program',
  },
  {
    institution: 'International Beauty College',
  },
  {
    institution: 'Dewey University-Hato Rey',
  },
  {
    institution: 'Cape Coral Institute of Technology',
  },
  {
    institution: 'Massachusetts General Hospital Dietetic Internship',
  },
  {
    institution: 'Mercy Hospital School of Nursing',
  },
  {
    institution: 'American Beauty Academy',
  },
  {
    institution: 'Northland Career Center',
  },
  {
    institution: 'Norwich Technical High School',
  },
  {
    institution: 'Ocean County Vocational-Technical School',
  },
  {
    institution: 'Putnam Westchester BOCES-Practical Nursing Program',
  },
  {
    institution: 'Sullivan County BOCES-Practical Nursing Program',
  },
  {
    institution: 'Kaplan College-San Antonio-Ingram',
  },
  {
    institution: 'Trinity College of Puerto Rico',
  },
  {
    institution: 'Virginia Sewing Machines and School Center',
  },
  {
    institution: 'Vogue Beauty and Barber School',
  },
  {
    institution: 'Yeshiva of the Telshe Alumni',
  },
  {
    institution: 'Northeast Technology Center-Kansas',
  },
  {
    institution: 'Kenneth Shuler School of Cosmetology-Rock Hill',
  },
  {
    institution: 'South Orange County Community College District',
  },
  {
    institution: 'DeVry College of New York',
  },
  {
    institution: 'DeVry University-Indiana',
  },
  {
    institution: 'University of Phoenix-Western Washington Campus',
  },
  {
    institution: 'University of Phoenix-Central Florida Campus',
  },
  {
    institution: 'University of Phoenix-Oregon Campus',
  },
  {
    institution: 'CET-El Paso',
  },
  {
    institution: 'Kaplan College-Riverside',
  },
  {
    institution: 'Appalachian School of Law',
  },
  {
    institution: 'LIU Hudson at Westchester',
  },
  {
    institution: 'Platt College-Ontario',
  },
  {
    institution: 'Delaware College of Art and Design',
  },
  {
    institution:
      'The Art Institute of California-Argosy University Los Angeles',
  },
  {
    institution: 'American University of Health Sciences',
  },
  {
    institution: 'Career Networks Institute',
  },
  {
    institution: 'Monty Tech',
  },
  {
    institution: 'CHOICE High School and Technical Center',
  },
  {
    institution: 'Lincoln Technical Institute-Lincoln',
  },
  {
    institution: 'Wayne County Schools Career Center',
  },
  {
    institution: 'Blessing Hospital School of Medical Laboratory Technology',
  },
  {
    institution: 'Carolinas College of Health Sciences',
  },
  {
    institution: 'Lester E Cox Medical Center-School of Medical Technology',
  },
  {
    institution: 'Western Governors University',
  },
  {
    institution: 'Santa Barbara Business College-Ventura',
  },
  {
    institution: 'Sullivan and Cogliano Training Center',
  },
  {
    institution: 'Herzing University-Kenner',
  },
  {
    institution: 'Spencerian College-Lexington',
  },
  {
    institution: 'Florida Gulf Coast University',
  },
  {
    institution: 'Seacoast Career Schools-Sanford Campus',
  },
  {
    institution: 'Little Priest Tribal College',
  },
  {
    institution: 'National Labor College',
  },
  {
    institution: 'ITT Technical Institute-Richardson',
  },
  {
    institution: 'South Louisiana Community College',
  },
  {
    institution: 'HRDE-Stanley Technical Institute-Parkersburg',
  },
  {
    institution: 'Pima Medical Institute-Chula Vista',
  },
  {
    institution: 'Lincoln College of Technology-Marietta',
  },
  {
    institution: 'Academy of Hair Design-Lufkin',
  },
  {
    institution: 'Academy of Hair Design-Beaumont',
  },
  {
    institution: 'Baltimore School of Massage-Linthicum',
  },
  {
    institution: 'Charter College-Canyon Country',
  },
  {
    institution: 'Toni & Guy Hairdressing Academy-Worcester',
  },
  {
    institution: 'Northwest HVAC/R Training Center',
  },
  {
    institution: 'L T International Beauty School',
  },
  {
    institution: 'Michigan Jewish Institute',
  },
  {
    institution: 'Myotherapy Institute',
  },
  {
    institution: 'Florida College of Integrative Medicine',
  },
  {
    institution: 'New Professions Technical Institute',
  },
  {
    institution: 'Strand College of Hair Design',
  },
  {
    institution: 'Cosmetology Career Institute',
  },
  {
    institution: 'Branford Hall Career Institute-Southington Campus',
  },
  {
    institution: 'City College-Miami',
  },
  {
    institution: 'ITT Technical Institute-Arnold',
  },
  {
    institution: 'ITT Technical Institute-Oak Brook',
  },
  {
    institution: 'ITT Technical Institute-Albany',
  },
  {
    institution: 'ITT Technical Institute-Liverpool',
  },
  {
    institution: 'Ilisagvik College',
  },
  {
    institution: 'The Community College of Baltimore County',
  },
  {
    institution: 'Golf Academy of America-Myrtle Beach',
  },
  {
    institution: 'Florida Coastal School of Law',
  },
  {
    institution: 'White Earth Tribal and Community College',
  },
  {
    institution: 'Centura College-North Charleston',
  },
  {
    institution: 'Blue Cliff College-Metairie',
  },
  {
    institution: 'Creative Images Institute of Cosmetology-North Dayton',
  },
  {
    institution: 'Dewey University-Carolina',
  },
  {
    institution: 'Yeshiva College of the Nations Capital',
  },
  {
    institution: 'University of Phoenix-South Florida Campus',
  },
  {
    institution: 'University of Phoenix-North Florida Campus',
  },
  {
    institution: 'University of Phoenix-West Florida Campus',
  },
  {
    institution: 'University of Phoenix-Maryland Campus',
  },
  {
    institution: 'University of Phoenix-Oklahoma City Campus',
  },
  {
    institution: 'University of Phoenix-Tulsa Campus',
  },
  {
    institution: 'Louisiana State University Health Sciences Center-Shreveport',
  },
  {
    institution: 'Central Career Institute LLC',
  },
  {
    institution: 'Franklin Career Institute',
  },
  {
    institution: 'Montessori Institute of Milwaukee',
  },
  {
    institution: 'Hair Academy II',
  },
  {
    institution: 'Pryor Beauty College',
  },
  {
    institution: 'Argosy University-Phoenix',
  },
  {
    institution: "Mr Leon's School of Hair Design-Lewiston",
  },
  {
    institution: 'Vatterott College-St Joseph',
  },
  {
    institution: 'Vatterott College-Sunset Hills',
  },
  {
    institution: 'Anthem College-Orlando',
  },
  {
    institution: 'Southwest Acupuncture College-Boulder',
  },
  {
    institution: 'Utah College of Massage Therapy-Utah Valley',
  },
  {
    institution: 'River Parishes Community College',
  },
  {
    institution:
      'Watson School of Biological Sciences at Cold Spring Harbor Laboratory',
  },
  {
    institution: 'Los Angeles Film School',
  },
  {
    institution: 'Argosy University-Orange County',
  },
  {
    institution: 'Dewey University-Bayamon',
  },
  {
    institution: 'National American University-Bloomington',
  },
  {
    institution: 'A.B.I. School of Barbering & Cosmetology of Chelsea Inc.',
  },
  {
    institution: 'Century College',
  },
  {
    institution: 'FINE Mortuary College',
  },
  {
    institution: 'International Baptist College and Seminary',
  },
  {
    institution: 'The Medical Arts School',
  },
  {
    institution: 'Fortis College-Foley',
  },
  {
    institution: 'Medical Training College',
  },
  {
    institution: 'Professional Training Centers',
  },
  {
    institution: 'Toni & Guy Hairdressing Academy-Carrollton',
  },
  {
    institution: 'University of Connecticut-Tri-Campus',
  },
  {
    institution: 'University of Connecticut-Avery Point',
  },
  {
    institution: 'University of Connecticut-Stamford',
  },
  {
    institution: 'ITT Technical Institute-Saint Rose',
  },
  {
    institution: 'ITT Technical Institute-Richmond',
  },
  {
    institution: 'Vatterott College-Oklahoma City',
  },
  {
    institution: 'University of Management and Technology',
  },
  {
    institution: 'Baton Rouge Community College',
  },
  {
    institution: 'ITT Technical Institute-Lathrop',
  },
  {
    institution: 'International Yacht Restoration School',
  },
  {
    institution: 'Linfield College-School of Nursing',
  },
  {
    institution: 'Linfield College-Adult Degree Program',
  },
  {
    institution:
      "DeVry University's Keller Graduate School of Management-Virginia",
  },
  {
    institution:
      "DeVry University's Keller Graduate School of Management-Wisconsin",
  },
  {
    institution: 'Academy of Massage Therapy',
  },
  {
    institution: 'Star Career Academy-Syosset',
  },
  {
    institution: 'Midwest Technical Institute-Springfield',
  },
  {
    institution: 'Advanced Barber College and Hair Design',
  },
  {
    institution: 'The College of Health Care Professions-Austin',
  },
  {
    institution: 'The English Center',
  },
  {
    institution: 'ICDC College',
  },
  {
    institution: 'Monteclaro Escuela de Hoteleria y Artes Culinarias',
  },
  {
    institution: 'New Community Workforce Development Center',
  },
  {
    institution: 'Dallas Nursing Institute',
  },
  {
    institution: 'Professional Golfers Career College',
  },
  {
    institution: 'Richmond School of Health and Technology',
  },
  {
    institution: 'Milan Institute-San Antonio Ingram',
  },
  {
    institution: 'Westwood College-Anaheim',
  },
  {
    institution: "Rudae's School of Beauty Culture-Ft Wayne",
  },
  {
    institution: 'InterCoast Colleges-Burbank',
  },
  {
    institution: 'Carrington College California-Antioch',
  },
  {
    institution: 'Carrington College California-San Jose',
  },
  {
    institution: 'Lincoln Technical Institute-Cromwell',
  },
  {
    institution: 'Stevens-Henager College-Murray',
  },
  {
    institution: 'Brookline College-Tucson',
  },
  {
    institution: 'Everest Institute-Jonesboro',
  },
  {
    institution: 'McCann School of Business & Technology',
  },
  {
    institution: 'Carrington College California-Pleasant Hill',
  },
  {
    institution: 'Ridley-Lowell Business & Technical Institute-Poughkeepsie',
  },
  {
    institution: 'Florida College of Natural Health-Maitland',
  },
  {
    institution: 'Florida College of Natural Health-Bradenton',
  },
  {
    institution: 'Modern Hairstyling Institute-Carolina',
  },
  {
    institution: 'University of Phoenix-Southern Colorado Campus',
  },
  {
    institution: 'University of Phoenix-West Michigan Campus',
  },
  {
    institution: 'Stratford University',
  },
  {
    institution: 'Universal Technical Institute of California Inc',
  },
  {
    institution: 'American InterContinental University-Atlanta',
  },
  {
    institution: 'American InterContinental University-South Florida',
  },
  {
    institution: 'University of Phoenix-Philadelphia Campus',
  },
  {
    institution: 'University of Phoenix-Pittsburgh Campus',
  },
  {
    institution: 'Everest Institute-Decatur',
  },
  {
    institution: 'Everest College-Chesapeake',
  },
  {
    institution: 'Everest Institute-Greenspoint',
  },
  {
    institution: 'Rancho Santiago Community College District Office',
  },
  {
    institution: 'The Academy of Hair Design Six',
  },
  {
    institution: 'Eastern West Virginia Community and Technical College',
  },
  {
    institution: 'Aviation Institute of Maintenance-Indianapolis',
  },
  {
    institution: 'Branford Hall Career Institute-Springfield Campus',
  },
  {
    institution: 'Remington College-Little Rock Campus',
  },
  {
    institution: 'Newbridge College-San Diego East',
  },
  {
    institution: 'Everest University-Jacksonville',
  },
  {
    institution: 'IVAEM College',
  },
  {
    institution: 'Midwest College of Oriental Medicine-Chicago',
  },
  {
    institution: 'Lincoln Technical Institute-Hamden',
  },
  {
    institution: 'Argosy University-Seattle',
  },
  {
    institution: 'Carrington College-Spokane',
  },
  {
    institution: 'Texas School of Business-Friendswood',
  },
  {
    institution: 'ITT Technical Institute-Wilmington',
  },
  {
    institution: 'Pierce College at Puyallup',
  },
  {
    institution: 'Texas Tech University System Administration',
  },
  {
    institution: 'DeVry University-Florida',
  },
  {
    institution: 'Cascadia Community College',
  },
  {
    institution: 'University of Phoenix-St Louis Campus',
  },
  {
    institution: 'Johnson & Wales University-Denver',
  },
  {
    institution: 'University of Phoenix-Cleveland Campus',
  },
  {
    institution: 'Du Bois Business College-Huntingdon',
  },
  {
    institution: 'Du Bois Business College-Oil City',
  },
  {
    institution: 'DeVry University-Maryland',
  },
  {
    institution: 'CBD College',
  },
  {
    institution: 'East West College of Natural Medicine',
  },
  {
    institution: 'Infinity Career College',
  },
  {
    institution: 'Lincoln Technical Institute-Fern Park',
  },
  {
    institution: 'Atlantic Institute of Oriental Medicine',
  },
  {
    institution: 'ATS Institute of Technology',
  },
  {
    institution: 'Bennett Career Institute',
  },
  {
    institution: 'Blue Cliff College-Shreveport',
  },
  {
    institution: 'Blue Cliff College-Lafayette',
  },
  {
    institution: 'Quest College',
  },
  {
    institution: 'Central Florida Institute',
  },
  {
    institution: 'Community Care College',
  },
  {
    institution: 'Marinello Schools of Beauty-Topeka',
  },
  {
    institution: 'Crossroads Bible College',
  },
  {
    institution: 'Erie 1 BOCES',
  },
  {
    institution: 'Fayette Beauty Academy',
  },
  {
    institution: 'HDS Truck Driving Institute',
  },
  {
    institution: 'International Training Careers',
  },
  {
    institution: "King's University",
  },
  {
    institution: 'Lacy Cosmetology School-Aiken',
  },
  {
    institution: 'Fortis College-Baton Rouge',
  },
  {
    institution: "My Le's Beauty College",
  },
  {
    institution: 'New Concept Massage and Beauty School',
  },
  {
    institution: 'New York College of Traditional Chinese Medicine',
  },
  {
    institution: 'Fortis College-Orange Park',
  },
  {
    institution: 'Northeastern Seminary',
  },
  {
    institution: 'Bryan College-Gold River',
  },
  {
    institution: 'Omega Institute of Cosmetology',
  },
  {
    institution: 'Pacific Islands University',
  },
  {
    institution: 'PCI College',
  },
  {
    institution: 'Rosedale Bible College',
  },
  {
    institution: 'American Career College at St Francis',
  },
  {
    institution: 'Seattle Institute of Oriental Medicine',
  },
  {
    institution: 'Shawnee Beauty College',
  },
  {
    institution: 'Acupuncture and Massage College',
  },
  {
    institution: 'Southeastern School of Cosmetology',
  },
  {
    institution: 'Universal Therapeutic Massage Institute',
  },
  {
    institution: 'Upper Valley Educators Institute',
  },
  {
    institution: 'Keck Graduate Institute',
  },
  {
    institution: 'Aviation Institute of Maintenance-Atlanta',
  },
  {
    institution: 'National Beauty College',
  },
  {
    institution: 'Star Career Academy-Egg Harbor',
  },
  {
    institution: 'Empire Beauty School-Kennesaw',
  },
  {
    institution: 'Anthem Career College-Nashville',
  },
  {
    institution: 'Anthem College-Irving',
  },
  {
    institution: 'Antioch University-System Administration',
  },
  {
    institution: 'Westwood College-River Oaks',
  },
  {
    institution: 'ITT Technical Institute-Green Bay',
  },
  {
    institution: 'Career Training Academy-Pittsburgh',
  },
  {
    institution: 'Huntingdon County Career and Technology Center',
  },
  {
    institution: 'Missouri College of Cosmetology North',
  },
  {
    institution: 'New York Automotive and Diesel Institute',
  },
  {
    institution: 'Remington College-Baton Rouge Campus',
  },
  {
    institution: 'Everest College-Ontario Metro',
  },
  {
    institution: 'Kaplan College-Beaumont',
  },
  {
    institution: 'Kaplan College-Laredo',
  },
  {
    institution: 'The Art Institute of Washington',
  },
  {
    institution: 'Everest Institute-Dearborn',
  },
  {
    institution: 'Centura College-Richmond Westend',
  },
  {
    institution: 'New Saint Andrews College',
  },
  {
    institution: 'Arkansas State University-Newport',
  },
  {
    institution: 'Marlboro College-Graduate School',
  },
  {
    institution: 'University of Phoenix-Boston Campus',
  },
  {
    institution: 'University of Phoenix-Dallas Campus',
  },
  {
    institution: 'University of Phoenix-Houston Campus',
  },
  {
    institution: 'University of Phoenix-Milwaukee Campus',
  },
  {
    institution: 'University of Phoenix-Idaho Campus',
  },
  {
    institution: 'Westwood College-Inland Empire',
  },
  {
    institution: 'DeVry University-Virginia',
  },
  {
    institution: 'DeVry University-Washington',
  },
  {
    institution: "DeVry University's Keller Graduate School of Management-Ohio",
  },
  {
    institution:
      "DeVry University's Keller Graduate School of Management-Texas",
  },
  {
    institution: 'DeVry University-Colorado',
  },
  {
    institution: 'Louisiana Delta Community College',
  },
  {
    institution: 'ITT Technical Institute-Levittown',
  },
  {
    institution: 'Atenas College',
  },
  {
    institution:
      'Southwest Skill Center-Campus of Estrella Mountain Community College',
  },
  {
    institution: 'Onondaga Cortland Madison BOCES',
  },
  {
    institution: 'National American University-Ellsworth AFB Extension',
  },
  {
    institution: 'National American University-Albuquerque West',
  },
  {
    institution: 'National American University-Brooklyn Center',
  },
  {
    institution: 'Interactive Learning Systems-Pasadena',
  },
  {
    institution: 'Pillar College',
  },
  {
    institution: 'Miami Ad School-Minneapolis',
  },
  {
    institution: 'Miami Ad School-San Francisco',
  },
  {
    institution: 'Oregon State University-Cascades Campus',
  },
  {
    institution: 'Milan Institute-Boise',
  },
  {
    institution: 'Milan Institute-Amarillo',
  },
  {
    institution: 'Vatterott College-Dividend',
  },
  {
    institution: 'Vatterott College-Tulsa',
  },
  {
    institution: 'Vatterott College-Wichita',
  },
  {
    institution: 'Star Career Academy-Newark',
  },
  {
    institution: 'Southern University Law Center',
  },
  {
    institution: 'International Academy of Design and Technology-Orlando',
  },
  {
    institution: 'Lincoln Technical Institute-Lowell',
  },
  {
    institution: 'Everest College-Vancouver Massage Therapy',
  },
  {
    institution: 'Texas Barber Colleges and Hairstyling Schools',
  },
  {
    institution: 'Everest Institute-Hobby',
  },
  {
    institution: 'Miller-Motte Technical College-North Charleston',
  },
  {
    institution: 'American Career College-Anaheim',
  },
  {
    institution: 'Saginaw Chippewa Tribal College',
  },
  {
    institution: 'Wyo Tech-Blairsville',
  },
  {
    institution: 'Richmont Graduate University',
  },
  {
    institution: 'The Seattle School of Theology & Psychology',
  },
  {
    institution:
      "DeVry University's Keller Graduate School of Management-Washington",
  },
  {
    institution: 'Northwest College-Hillsboro',
  },
  {
    institution: 'San Diego State University-Imperial Valley Campus',
  },
  {
    institution: 'American Broadcasting School-Arlington',
  },
  {
    institution: 'Miami-Jacobs Career College-Sharonville',
  },
  {
    institution: 'East Valley Institute of Technology',
  },
  {
    institution: 'West Coast Ultrasound Institute',
  },
  {
    institution:
      'Irell & Manella Graduate School of Biological Sciences at City of Hope',
  },
  {
    institution: "Design's School of Cosmetology",
  },
  {
    institution: 'Paul Mitchell the School-Santa Barbara',
  },
  {
    institution: 'University of the Rockies',
  },
  {
    institution: 'Eli Whitney Technical High School',
  },
  {
    institution: 'Vinal Technical High School',
  },
  {
    institution: 'Windham Technical High School',
  },
  {
    institution: 'Harris School of Business-Wilmington Campus',
  },
  {
    institution: 'Ultimate Medical Academy-Clearwater',
  },
  {
    institution: 'Florida Barber Academy',
  },
  {
    institution: 'Jacksonville Beauty Institute',
  },
  {
    institution: 'M-DCPS The English Center',
  },
  {
    institution: 'Florida Education Institute',
  },
  {
    institution: 'Superior Career Institute',
  },
  {
    institution: 'Institute of Technical Arts',
  },
  {
    institution: 'National Graduate School of Quality Management',
  },
  {
    institution: 'Texas County Technical College',
  },
  {
    institution: 'Aviation Institute of Maintenance-Kansas City',
  },
  {
    institution: 'Blue Cliff College-Gulfport',
  },
  {
    institution: 'Apex School of Theology',
  },
  {
    institution: 'Institute for Therapeutic Massage',
  },
  {
    institution: 'Metropolitan Learning Institute',
  },
  {
    institution: 'Orleans Niagara BOCES-Practical Nursing Program',
  },
  {
    institution: 'Yeshiva Shaarei Torah of Rockland',
  },
  {
    institution: 'Leon Studio One School of Hair Design',
  },
  {
    institution: "St Joseph's Medical Center School of Radiography",
  },
  {
    institution: 'American Institute of Alternative Medicine',
  },
  {
    institution: 'Brown Aveda Institute-Mentor',
  },
  {
    institution: 'Institute of Technology Inc',
  },
  {
    institution: 'Susquehanna County Career and Technology Center',
  },
  {
    institution: 'Centro de Capacitacion y Asesoramiento Vetelba',
  },
  {
    institution: 'Universidad Pentecostal Mizpa',
  },
  {
    institution: 'Caribbean Forensic and Technical College',
  },
  {
    institution: 'Concorde Career College-Grand Prairie',
  },
  {
    institution: 'Kussad Institute of Court Reporting',
  },
  {
    institution: 'Lamar Institute of Technology',
  },
  {
    institution: "MJ's Beauty Academy Inc",
  },
  {
    institution: 'Aviation Institute of Maintenance-Dallas',
  },
  {
    institution: 'Healing Mountain Massage School',
  },
  {
    institution: 'Career Training Solutions',
  },
  {
    institution: 'Rudy & Kelly Academy of Hair and Nails',
  },
  {
    institution: 'South Branch Career and Technical Center',
  },
  {
    institution: 'Nevada State College',
  },
  {
    institution: 'Virginia College-Jackson',
  },
  {
    institution: 'Virginia College-Austin',
  },
  {
    institution: 'California State University-Channel Islands',
  },
  {
    institution: 'ITT Technical Institute-Springfield',
  },
  {
    institution: 'ITT Technical Institute-Chantilly',
  },
  {
    institution:
      'The Art Institute of California-Argosy University Orange County',
  },
  {
    institution: 'Franklin W Olin College of Engineering',
  },
  {
    institution: 'Lincoln Technical Institute-Northeast Philadelphia',
  },
  {
    institution: 'Lincoln Technical Institute-Center City Philadelphia',
  },
  {
    institution: 'Won Institute of Graduate Studies',
  },
  {
    institution: 'Lincoln Technical Institute-Paramus',
  },
  {
    institution: 'Pima Medical Institute-Colorado Springs',
  },
  {
    institution: 'University of Phoenix-Kansas City Campus',
  },
  {
    institution: 'University of Phoenix-Atlanta Campus',
  },
  {
    institution: 'University of Phoenix-Chicago Campus',
  },
  {
    institution: 'University of Phoenix-Nashville Campus',
  },
  {
    institution: 'University of Phoenix-Northern Virginia Campus',
  },
  {
    institution: "David's Academy of Beauty",
  },
  {
    institution: 'Birthwise Midwifery School',
  },
  {
    institution: 'Argosy University-Dallas',
  },
  {
    institution: 'Le Cordon Bleu College of Culinary Arts-Orlando',
  },
  {
    institution: 'Paul Mitchell the School-Costa Mesa',
  },
  {
    institution: 'Ave Maria School of Law',
  },
  {
    institution: 'Everest College-Arlington',
  },
  {
    institution: 'Tulsa Welding School-Jacksonville',
  },
  {
    institution: 'ITT Technical Institute-Canton',
  },
  {
    institution: 'ITT Technical Institute-Plymouth Meeting',
  },
  {
    institution: 'Pennsylvania College of Health Sciences',
  },
  {
    institution: 'DeVry University-North Carolina',
  },
  {
    institution: 'West Virginia Junior College-Bridgeport',
  },
  {
    institution: 'Antioch University-PhD Program in Leadership and Change',
  },
  {
    institution: 'Vatterott College-Cleveland',
  },
  {
    institution: 'St Louis College of Health Careers-Fenton',
  },
  {
    institution: 'Interactive College of Technology-Gainesville',
  },
  {
    institution: 'San Joaquin Valley College-Ontario',
  },
  {
    institution: 'Anthem Institute-Cherry Hill',
  },
  {
    institution: 'Westwood College-Ft Worth',
  },
  {
    institution: 'Westwood College-Dallas',
  },
  {
    institution: 'Alaska Christian College',
  },
  {
    institution: 'Grantham University',
  },
  {
    institution: 'Minnesota School of Business-Plymouth',
  },
  {
    institution: 'Carrington College-Albuquerque',
  },
  {
    institution: 'NASCAR Technical Institute',
  },
  {
    institution: 'Everest Institute-Tigard',
  },
  {
    institution: 'University of the Potomac-VA Campus',
  },
  {
    institution: 'Anthem Institute-Las Vegas',
  },
  {
    institution: 'Milan Institute-Palm Desert',
  },
  {
    institution: 'Stenotype Institute of Jacksonville Inc-Orlando',
  },
  {
    institution: 'National American University-Overland Park',
  },
  {
    institution: 'Everest Institute-Austin',
  },
  {
    institution: 'Everest College-Burr Ridge',
  },
  {
    institution: 'Medvance Institute-West Palm',
  },
  {
    institution: "Tohono O'Odham Community College",
  },
  {
    institution: 'Everest College-Dallas',
  },
  {
    institution: 'Edward Via College of Osteopathic Medicine',
  },
  {
    institution: 'The Salter School-Malden Campus',
  },
  {
    institution: 'DeVry University-Pennsylvania',
  },
  {
    institution: 'Pacific College of Oriental Medicine-Chicago',
  },
  {
    institution: "Ronny J's Barber Styling",
  },
  {
    institution: 'Southwest Institute of Healing Arts',
  },
  {
    institution: 'InterCoast Colleges-Riverside',
  },
  {
    institution: 'Ottawa University-Jeffersonville',
  },
  {
    institution: 'University of Antelope Valley',
  },
  {
    institution: 'Birthingway College of Midwifery',
  },
  {
    institution: 'Blue Water College of Cosmetology Inc',
  },
  {
    institution: 'Pima Medical Institute-Albuquerque West',
  },
  {
    institution: "Brighton Center's Center for Employment Training",
  },
  {
    institution: 'California Healing Arts College',
  },
  {
    institution: 'Dallas Barber & Stylist College',
  },
  {
    institution:
      'NorthShore University HealthSystem School of Nurse Anesthesia',
  },
  {
    institution: 'Faith Evangelical College & Seminary',
  },
  {
    institution: 'Family of Faith College',
  },
  {
    institution: 'Hood Theological Seminary',
  },
  {
    institution: 'Colegio Educativo Tecnologico Industrial Inc',
  },
  {
    institution: 'International Technological University',
  },
  {
    institution: "Irene's Myomassology Institute",
  },
  {
    institution: "Jay's Technical Institute",
  },
  {
    institution:
      "Mr John's School of Cosmetology Esthetics & Nails-Jacksonville",
  },
  {
    institution: 'NTMA Training Centers of Southern California',
  },
  {
    institution: 'P C Age-Jersey City',
  },
  {
    institution: 'P C Age-Edison',
  },
  {
    institution: 'American Institute-Margate',
  },
  {
    institution: 'South Florida Institute of Technology',
  },
  {
    institution: 'Southeastern Institute-Charleston',
  },
  {
    institution: 'Southeastern College-Jacksonville',
  },
  {
    institution: 'Toni & Guy Hairdressing Academy-Colorado Springs',
  },
  {
    institution: 'Trend Barber College',
  },
  {
    institution: 'Illinois CareerPath Institute',
  },
  {
    institution: 'Valley Grande Institute for Academic Studies',
  },
  {
    institution: 'West Coast University-Los Angeles',
  },
  {
    institution: 'Williamson Christian College',
  },
  {
    institution: 'International Barber & Style College',
  },
  {
    institution: 'Illinois Eastern Community College-System Office',
  },
  {
    institution: 'Triangle Tech Inc-Sunbury',
  },
  {
    institution: 'DigiPen Institute of Technology',
  },
  {
    institution: 'California College of Vocational Careers',
  },
  {
    institution: 'Fortis Institute-Fort Lauderdale',
  },
  {
    institution: 'University of Phoenix-Cincinnati Campus',
  },
  {
    institution: 'University of Phoenix-Wichita Campus',
  },
  {
    institution: 'University of Phoenix-Charlotte Campus',
  },
  {
    institution: 'Connecticut Center for Massage Therapy-Groton',
  },
  {
    institution: 'Pierpont Community and Technical College',
  },
  {
    institution: 'ITT Technical Institute-Duluth',
  },
  {
    institution: 'ITT Technical Institute-Hilliard',
  },
  {
    institution: 'University of Phoenix-Indianapolis Campus',
  },
  {
    institution: 'Career Institute of Health and Technology',
  },
  {
    institution: 'Dewey University-Hato Rey',
  },
  {
    institution: 'InterCoast Colleges-West Covina',
  },
  {
    institution: 'American College of Healthcare Sciences',
  },
  {
    institution: 'Texas School of Business-East',
  },
  {
    institution: 'Le Cordon Bleu College of Culinary Arts-Atlanta',
  },
  {
    institution: 'Colorado Media School',
  },
  {
    institution: 'Paul Mitchell the School-Rhode Island',
  },
  {
    institution: 'Miller-Motte Technical College-Chattanooga',
  },
  {
    institution: 'Milan Institute-Sparks',
  },
  {
    institution: 'Empire Beauty School-Framingham',
  },
  {
    institution: 'Westwood College-Chicago Loop',
  },
  {
    institution: 'Interactive Learning Systems-North Houston',
  },
  {
    institution: 'Bexley Hall Episcopal Seminary',
  },
  {
    institution: 'University of North Texas System',
  },
  {
    institution: 'Anthem College-Fenton',
  },
  {
    institution: 'Everest Institute-Norcross',
  },
  {
    institution: 'Strayer University-Tennessee',
  },
  {
    institution: 'Strayer University-Pennsylvania',
  },
  {
    institution: 'Kaplan College-Brownsville',
  },
  {
    institution: 'Kaplan College-Corpus Christi',
  },
  {
    institution: 'School of Health',
  },
  {
    institution: 'Everest College-Arlington',
  },
  {
    institution: 'University of Phoenix-Columbus Georgia Campus',
  },
  {
    institution: 'University of Phoenix-Memphis Campus',
  },
  {
    institution: 'University of Phoenix-Columbus Ohio Campus',
  },
  {
    institution: 'University of Phoenix-Little Rock Campus',
  },
  {
    institution: 'Toni & Guy Hairdressing Academy-Boise',
  },
  {
    institution: "Charlie's Guard-Detective Bureau and Academy Inc",
  },
  {
    institution: 'PCI Academy-Plymouth',
  },
  {
    institution:
      "DeVry University's Keller Graduate School of Management-Pennsylvania",
  },
  {
    institution: 'DeVry University-Nevada',
  },
  {
    institution: 'Everest College-Tacoma',
  },
  {
    institution: 'DeVry University-Oregon',
  },
  {
    institution: 'National University College-Rio Grande',
  },
  {
    institution: 'Creative Images Institute of Cosmetology-South Dayton',
  },
  {
    institution: 'Westwood College-Houston South',
  },
  {
    institution: 'Fortis Institute-Jacksonville',
  },
  {
    institution: 'Brookline College-Albuquerque',
  },
  {
    institution: 'Byzantine Catholic Seminary of Saints Cyril and Methodius',
  },
  {
    institution: 'Remington College-Cleveland West Campus',
  },
  {
    institution: 'Northcentral University',
  },
  {
    institution: 'Colorado Technical University-Online',
  },
  {
    institution: 'Baker College of Allen Park',
  },
  {
    institution: 'Bayamon Community College',
  },
  {
    institution: 'Harris School of Business-Dover Campus',
  },
  {
    institution: 'Folsom Lake College',
  },
  {
    institution: 'LeGrand Institute of Cosmetology Inc',
  },
  {
    institution: 'Everest Institute-Detroit',
  },
  {
    institution: 'Daymar Institute-Murfreesboro',
  },
  {
    institution: 'Cambridge Technical Institute',
  },
  {
    institution: 'Empire Beauty School-Midlothian',
  },
  {
    institution: 'Empire Beauty School-Owings Mills',
  },
  {
    institution: 'Empire Beauty School-West Mifflin',
  },
  {
    institution: 'Bold Beauty Academy',
  },
  {
    institution: 'ATI College-Norwalk',
  },
  {
    institution: 'Advance Science Institute',
  },
  {
    institution: 'Advanced College',
  },
  {
    institution: 'Advance Tech College',
  },
  {
    institution: 'Advanced Training Associates',
  },
  {
    institution: 'American Advanced Technicians Institute',
  },
  {
    institution: 'Anamarc College-El Paso Central',
  },
  {
    institution: 'Baptist University of the Americas',
  },
  {
    institution: 'The Beauty Institute',
  },
  {
    institution: 'Beis Medrash Heichal Dovid',
  },
  {
    institution: 'Bellefonte Academy of Beauty',
  },
  {
    institution: 'Blue Cliff Career College',
  },
  {
    institution: 'Career Beauty College',
  },
  {
    institution: 'Paul Mitchell the School-Gastonia',
  },
  {
    institution: 'CES College',
  },
  {
    institution: 'Computer Tutor Business and Technical Institute',
  },
  {
    institution: 'Court Reporting Institute of Louisiana',
  },
  {
    institution: 'Auguste Escoffier School of Culinary Arts-Austin',
  },
  {
    institution: 'Culinary Institute Inc',
  },
  {
    institution: 'Dade Medical College-Miami',
  },
  {
    institution: 'Escuela Hotelera de San Juan',
  },
  {
    institution: 'Expertise Cosmetology Institute',
  },
  {
    institution: 'Paul Mitchell the School-Monroe',
  },
  {
    institution: 'Healing Arts Institute',
  },
  {
    institution: 'Health Works Institute',
  },
  {
    institution: 'In Session Arts of Cosmetology Beauty School',
  },
  {
    institution: 'Moore Career College',
  },
  {
    institution: 'Industrial Technical College',
  },
  {
    institution: 'Institute of Beauty Occupation and Technology Course',
  },
  {
    institution: 'Institute of Clinical Acupuncture & Oriental Med',
  },
  {
    institution: 'Institute of Hair Design',
  },
  {
    institution: 'MedTech Institute-Atlanta Campus',
  },
  {
    institution: 'Jones International University',
  },
  {
    institution: 'Leston College',
  },
  {
    institution: 'Lynndale Fundamentals of Beauty School',
  },
  {
    institution: 'Medical Professional Institute',
  },
  {
    institution: 'New Life Theological Seminary',
  },
  {
    institution: 'Nightingale College',
  },
  {
    institution: 'Omnitech Institute',
  },
  {
    institution: 'PITC Institute',
  },
  {
    institution: 'Skinworks School of Advanced Skincare',
  },
  {
    institution: 'Southeastern Institute-Columbia',
  },
  {
    institution: 'Technical Learning Centers Inc',
  },
  {
    institution: 'Total Look School of Cosmetology & Massage Therapy',
  },
  {
    institution: 'The Art Institute of Tucson',
  },
  {
    institution: 'Turning Point Beauty College',
  },
  {
    institution: 'Unitech Training Academy-Lafayette',
  },
  {
    institution: 'Mountwest Community and Technical College',
  },
  {
    institution: 'Centura College-Columbia',
  },
  {
    institution: 'Flagler College-Tallahassee',
  },
  {
    institution: 'Kanawha Valley Community and Technical College',
  },
  {
    institution: 'American InterContinental University-Online',
  },
  {
    institution: 'Westwood College-Atlanta Midtown',
  },
  {
    institution: 'ITT Technical Institute-Eden Prairie',
  },
  {
    institution: 'Virginia College-Mobile',
  },
  {
    institution: 'Le Cordon Bleu College of Culinary Arts-Las Vegas',
  },
  {
    institution: 'International Academy of Design and Technology-Troy',
  },
  {
    institution: 'American InterContinental University-Houston',
  },
  {
    institution: 'Sanford-Brown College-Houston North Loop',
  },
  {
    institution: 'University of California-Merced',
  },
  {
    institution: 'Everest College-Fort Worth',
  },
  {
    institution: 'Remington College-North Houston Campus',
  },
  {
    institution: 'Paul Mitchell the School-Orlando',
  },
  {
    institution: 'Regency Beauty Institute-Maplewood',
  },
  {
    institution: 'Pima Medical Institute-Las Vegas',
  },
  {
    institution: 'Remington College-Nashville Campus',
  },
  {
    institution: 'Platt College-North OKC',
  },
  {
    institution:
      'Central Methodist University-College of Graduate and Extended Studies',
  },
  {
    institution: 'Westwood College-Northlake',
  },
  {
    institution: 'University of Phoenix-Jersey City Campus',
  },
  {
    institution: 'University of Phoenix-Minneapolis St Paul Campus',
  },
  {
    institution: 'Kaplan College-Fort Worth',
  },
  {
    institution: 'Kaplan College-Midland',
  },
  {
    institution: 'Kaplan College-Lubbock',
  },
  {
    institution: 'Carrington College California-Emeryville',
  },
  {
    institution: 'North-West College-Riverside',
  },
  {
    institution: 'Everest Institute-Chelsea',
  },
  {
    institution: 'Everest Institute-Eagan',
  },
  {
    institution: 'University of Phoenix-Louisville Campus',
  },
  {
    institution: 'DeVry University-Minnesota',
  },
  {
    institution: 'Kaplan University-Council Bluffs Campus',
  },
  {
    institution: 'Everest University-Orange Park',
  },
  {
    institution: 'Wyotech-West Sacramento',
  },
  {
    institution: 'Everest Institute-Bissonnet',
  },
  {
    institution: 'Everest College-McLean',
  },
  {
    institution: 'Colegio Tecnico de Electricidad Galloza',
  },
  {
    institution: 'Empire Beauty School-St Paul',
  },
  {
    institution: "Arthur's Beauty School Inc-Pine Bluff",
  },
  {
    institution: "Arthur's Beauty School Inc-Conway",
  },
  {
    institution: 'Vatterott College-St Charles',
  },
  {
    institution: 'Anthem Institute-North Brunswick',
  },
  {
    institution: 'Anthem Career College-Memphis',
  },
  {
    institution: 'Anthem College-Kansas City',
  },
  {
    institution: 'Spa Tech Institute-Ipswich',
  },
  {
    institution: 'Spa Tech Institute-Westboro',
  },
  {
    institution: 'Spa Tech Institute-Plymouth',
  },
  {
    institution: 'Milan Institute of Cosmetology-San Antonio Walzem',
  },
  {
    institution: 'Bridgemont Community and Technical College',
  },
  {
    institution: 'Wards Corner Beauty Academy-Virginia Beach',
  },
  {
    institution: 'Neumont University',
  },
  {
    institution: 'Johnson & Wales University-Charlotte',
  },
  {
    institution: 'University of Phoenix-Central Valley Campus',
  },
  {
    institution: "L'Ecole Culinaire-St Louis",
  },
  {
    institution: 'Roseman University of Health Sciences',
  },
  {
    institution: 'Fortis Institute-Port Saint Lucie',
  },
  {
    institution: 'Fortis Institute-Miami',
  },
  {
    institution: 'Aviation Institute of Maintenance-Manassas',
  },
  {
    institution: 'Everest College-North Aurora',
  },
  {
    institution: 'Cosmetology School of Arts and Sciences',
  },
  {
    institution: 'Universal Technical Institute of Pennsylvania Inc',
  },
  {
    institution: 'Heritage College-Kansas City',
  },
  {
    institution: 'Kenneth Shuler School of Cosmetology-Spartanburg',
  },
  {
    institution: 'Kenneth Shuler School of Cosmetology-Columbia',
  },
  {
    institution: 'Institute for the Psychological Sciences',
  },
  {
    institution: 'Pivot Point Academy-Chicago',
  },
  {
    institution: 'Lincoln Technical Institute-Shelton',
  },
  {
    institution: 'Minnesota School of Business-Waite Park',
  },
  {
    institution: 'Minnesota School of Business-Shakopee',
  },
  {
    institution: 'Northwest Career College',
  },
  {
    institution:
      'Soma Institute-The National School of Clinical Massage Therapy',
  },
  {
    institution: 'Xtreme Career Institute',
  },
  {
    institution: 'Marinello Schools of Beauty-Niantic',
  },
  {
    institution: 'American Academy of Acupuncture and Oriental Medicine',
  },
  {
    institution: 'Arizona School of Acupuncture and Oriental Medicine',
  },
  {
    institution: 'Ave Maria University',
  },
  {
    institution:
      'Blue Sky School of Professional Massage and Therapeutic Bodywork',
  },
  {
    institution: 'Buchanan Beauty College',
  },
  {
    institution: 'CALC Institute of Technology',
  },
  {
    institution: 'Cambridge Junior College-Yuba City',
  },
  {
    institution: 'Cambridge Institute of Allied Health & Technology',
  },
  {
    institution: 'Career Care Institute',
  },
  {
    institution: 'ATA Career Education',
  },
  {
    institution: 'Career Quest Learning Centers-Lansing',
  },
  {
    institution: 'Central State Massage Academy',
  },
  {
    institution: 'Community Christian College',
  },
  {
    institution: 'Crossett School of Cosmetology',
  },
  {
    institution: 'Culpeper Cosmetology Training Center',
  },
  {
    institution: 'Day Spa Career College',
  },
  {
    institution: 'Eastern School of Acupuncture and Traditional Medicine',
  },
  {
    institution: 'Ecclesia College',
  },
  {
    institution: 'Employment Solutions-College for Technical Education',
  },
  {
    institution: 'European Massage Therapy School-Skokie',
  },
  {
    institution: 'Evergreen Beauty and Barber College-Everett',
  },
  {
    institution: 'Paul Mitchell the School-Great Lakes',
  },
  {
    institution: 'Healthcare Training Institute',
  },
  {
    institution: 'John D Rockefeller IV Career Center',
  },
  {
    institution: 'Ladera Career Paths Training Centers',
  },
  {
    institution: 'Los Angeles Music Academy',
  },
  {
    institution: 'Maple Springs Baptist Bible College and Seminary',
  },
  {
    institution: 'MCI Institute of Technology',
  },
  {
    institution: 'Oregon Career & Technology Center',
  },
  {
    institution: 'Performance Training Institute',
  },
  {
    institution: 'Pacific Coast Trade School',
  },
  {
    institution: 'Centura Institute',
  },
  {
    institution: 'Precision Manufacturing Institute',
  },
  {
    institution: 'Professional Massage Training Center',
  },
  {
    institution: 'Regency School of Hair Design',
  },
  {
    institution: 'Renaissance College-Massage Program',
  },
  {
    institution: 'Rosslyn Training Academy of Cosmetology',
  },
  {
    institution: 'SAE Institute of Technology-Nashville',
  },
  {
    institution: 'eClips School of Cosmetology and Barbering',
  },
  {
    institution: 'Shear Academy',
  },
  {
    institution: 'Southern Technical College',
  },
  {
    institution: 'Stanbridge College',
  },
  {
    institution: 'Styletrends Barber and Hairstyling Academy',
  },
  {
    institution: 'Universal Career School',
  },
  {
    institution: 'Universal College of Healing Arts',
  },
  {
    institution: 'Uta Mesivta of Kiryas Joel',
  },
  {
    institution: 'W L Bonner College',
  },
  {
    institution: 'Harrisburg University of Science and Technology',
  },
  {
    institution: 'The Art Institute of Ohio-Cincinnati',
  },
  {
    institution: 'Stevens-Henager College-Logan',
  },
  {
    institution: 'University of Phoenix-Cheyenne Campus',
  },
  {
    institution: 'University of Phoenix-Springfield  Campus',
  },
  {
    institution: 'University of Phoenix-Des Moines Campus',
  },
  {
    institution: 'University of Phoenix-San Antonio Campus',
  },
  {
    institution: 'University of Phoenix-Austin Campus',
  },
  {
    institution: 'University of Phoenix-Richmond-Virginia Beach Campus',
  },
  {
    institution: 'University of Phoenix-Raleigh Campus',
  },
  {
    institution: 'Blue Ridge Community and Technical College',
  },
  {
    institution: 'Lincoln Technical Institute-Suffield',
  },
  {
    institution: 'International Academy of Design and Technology-Henderson',
  },
  {
    institution: 'International Academy of Design and Technology-Seattle',
  },
  {
    institution: 'International Academy of Design and Technology-Nashville',
  },
  {
    institution: 'Le Cordon Bleu College of Culinary Arts-Miami',
  },
  {
    institution: 'Le Cordon Bleu College of Culinary Arts-Minneapolis',
  },
  {
    institution: 'Anthem College-Portland',
  },
  {
    institution: 'Career Technical College-Shreveport',
  },
  {
    institution: 'ITT Technical Institute-Kansas City',
  },
  {
    institution: 'ITT Technical Institute-Kennesaw',
  },
  {
    institution: 'ITT Technical Institute-Owings Mills',
  },
  {
    institution: 'ITT Technical Institute-Warrensville Heights',
  },
  {
    institution: 'Union Graduate College',
  },
  {
    institution: 'Colorado State University-System Office',
  },
  {
    institution: 'Chambersburg Beauty School',
  },
  {
    institution: 'Daytona College',
  },
  {
    institution: 'Delta Technical College',
  },
  {
    institution: 'United States University',
  },
  {
    institution: 'Westwood College-Arlington Ballston',
  },
  {
    institution: 'Kaplan College-Bakersfield',
  },
  {
    institution: 'Kaplan College-Fresno',
  },
  {
    institution: 'Empire Beauty School-Avondale',
  },
  {
    institution: 'Empire Beauty School-North Tucson',
  },
  {
    institution: 'Empire Beauty School-Littleton',
  },
  {
    institution: 'Empire Beauty School-Aurora',
  },
  {
    institution: 'Everest Institute-Gahanna',
  },
  {
    institution: 'Everest College-Merrionette Park',
  },
  {
    institution: 'Everest College-Earth City',
  },
  {
    institution: 'Everest College-Mesa',
  },
  {
    institution: 'Everest Institute-Silver Spring',
  },
  {
    institution: 'Alvareitas College of Cosmetology-Belleville',
  },
  {
    institution: 'AmeriTech College-Draper',
  },
  {
    institution: 'Argosy University-Los Angeles',
  },
  {
    institution: 'Brown Mackie College-Miami',
  },
  {
    institution: 'Regency Beauty Institute-Darien',
  },
  {
    institution: 'Regency Beauty Institute-Aurora',
  },
  {
    institution: 'Regency Beauty Institute-Madison',
  },
  {
    institution: 'Regency Beauty Institute-Ridgedale',
  },
  {
    institution: 'San Joaquin Valley College-Modesto',
  },
  {
    institution: 'Fortis College-Largo',
  },
  {
    institution: 'Platt College-Moore',
  },
  {
    institution: 'Platt College-Dallas',
  },
  {
    institution: 'Universal Technical Institute of Massachusetts Inc',
  },
  {
    institution: 'Eagle Gate College-Layton',
  },
  {
    institution: 'Carsten Institute of Cosmetology',
  },
  {
    institution: 'Academy of Hair Design-Jasper',
  },
  {
    institution: 'Expression College for Digital Arts',
  },
  {
    institution: 'Unitech Training Academy-West Monroe',
  },
  {
    institution: 'Daymar College-Bellevue',
  },
  {
    institution: 'DeVry University-Oklahoma',
  },
  {
    institution: 'Salter School-Fall River',
  },
  {
    institution: 'Seacoast Career School-Manchester Campus',
  },
  {
    institution:
      'Instituto de Educacion Tecnica Ocupacional La Reine-Aguadilla',
  },
  {
    institution: 'New River Community and Technical College',
  },
  {
    institution: 'The College of Health Care Professions-Southwest Houston',
  },
  {
    institution: 'Eagle Gate College-Salt Lake City',
  },
  {
    institution: 'Kaplan College-Palm Springs',
  },
  {
    institution: 'University of Phoenix-Savannah Campus',
  },
  {
    institution: 'University of Phoenix-Northern Nevada Campus',
  },
  {
    institution: 'Minnesota School of Business-Rochester',
  },
  {
    institution: 'Georgia Gwinnett College',
  },
  {
    institution: 'Phoenix Institute of Herbal Medicine & Acupuncture',
  },
  {
    institution: 'Tucson College of Beauty',
  },
  {
    institution: 'California Career College',
  },
  {
    institution: 'Palladium Technical Academy',
  },
  {
    institution: 'Palace Beauty College',
  },
  {
    institution: 'National Polytechnic College',
  },
  {
    institution: 'American Career College-Ontario',
  },
  {
    institution: 'Asher College',
  },
  {
    institution: 'American Institute of Massage Therapy',
  },
  {
    institution: 'University of East-West Medicine',
  },
  {
    institution: 'Blake Austin College',
  },
  {
    institution: 'Mojave Barber College',
  },
  {
    institution: 'Aviator College of Aeronautical Science and Technology',
  },
  {
    institution: 'Total International Career Institute',
  },
  {
    institution: 'Trendsetters School of Beauty & Barbering',
  },
  {
    institution: 'CDA Technical Institute',
  },
  {
    institution: 'American Institute of Beauty',
  },
  {
    institution: 'North Florida Cosmetology Institute Inc',
  },
  {
    institution: 'Florida Academy of Health & Beauty',
  },
  {
    institution: 'Augusta School of Massage',
  },
  {
    institution: 'ATA College',
  },
  {
    institution: 'SUM Bible College and Theological Seminary',
  },
  {
    institution: 'Compass Career College',
  },
  {
    institution: 'Hair Expressions Academy',
  },
  {
    institution: 'Nuvo College of Cosmetology',
  },
  {
    institution: 'WellSpring School of Allied Health-Kansas City',
  },
  {
    institution: 'Hair Academy 110',
  },
  {
    institution: 'Healing Touch Career College',
  },
  {
    institution: 'The Salon Professional Academy-Fargo',
  },
  {
    institution: 'Massage Therapy Trainining Institute',
  },
  {
    institution: 'Institute of Professional Careers',
  },
  {
    institution: 'Elite Academy of Beauty Arts',
  },
  {
    institution: 'Aveda Institute-New York',
  },
  {
    institution: 'Brookline College-Oklahoma City',
  },
  {
    institution: 'Northwest Regional Technology Institute',
  },
  {
    institution: 'National Massage Therapy Institute',
  },
  {
    institution: 'MyrAngel Beauty Institute',
  },
  {
    institution: 'Institute of Hair Design',
  },
  {
    institution: 'Tennessee Career Institute',
  },
  {
    institution: 'Texas Health School',
  },
  {
    institution: 'Mai-trix Beauty College',
  },
  {
    institution: 'North West Beauty School',
  },
  {
    institution: 'Maximum Style Tec School of Cosmetology',
  },
  {
    institution: 'Careers Unlimited',
  },
  {
    institution: 'Mountainland Applied Technology College',
  },
  {
    institution: 'Avi Career Training',
  },
  {
    institution: 'Yakima Beauty School',
  },
  {
    institution: 'Mountain State School of Massage',
  },
  {
    institution: 'Doane College-Lincoln Grand Island and Master',
  },
  {
    institution: 'Shorter University-College of Adult & Professional Programs',
  },
  {
    institution: 'Arkansas State University-System Office',
  },
  {
    institution: 'The Art Institute of Indianapolis',
  },
  {
    institution: 'Eastern International College-Belleville',
  },
  {
    institution: 'San Joaquin Valley College-Rancho Cordova',
  },
  {
    institution: 'San Joaquin Valley College-Central Administrative Office',
  },
  {
    institution: "Illinois School of Health Careers-O'Hare Campus",
  },
  {
    institution: 'MedTech College',
  },
  {
    institution: 'Broken Arrow Beauty College-Tulsa',
  },
  {
    institution: 'Universal Technical Institute of Northern California Inc',
  },
  {
    institution: 'ITT Technical Institute-Charlotte South',
  },
  {
    institution: 'ITT Technical Institute-Clovis',
  },
  {
    institution: 'ITT Technical Institute-Dunmore',
  },
  {
    institution: 'ITT Technical Institute-Swartz Creek',
  },
  {
    institution: 'ITT Technical Institute-Lexington',
  },
  {
    institution: 'ITT Technical Institute-Maumee',
  },
  {
    institution: 'ITT Technical Institute-Oklahoma City',
  },
  {
    institution: 'ITT Technical Institute-Tulsa',
  },
  {
    institution: 'University of Phoenix-Omaha Campus',
  },
  {
    institution: 'University of Phoenix-Northwest Arkansas Campus',
  },
  {
    institution: 'University of Phoenix-Madison Campus',
  },
  {
    institution: 'University of Phoenix-Columbia Campus',
  },
  {
    institution:
      'The Art Institute of California-Argosy University Inland Empire',
  },
  {
    institution: 'National American University-Zona Rosa',
  },
  {
    institution: 'West Hills College-Lemoore',
  },
  {
    institution: 'Stautzenberger College-Brecksville',
  },
  {
    institution: 'Stone Academy-East Hartford',
  },
  {
    institution: 'Westwood College-Annandale',
  },
  {
    institution: 'West Hills Community College District',
  },
  {
    institution: 'Unitech Training Academy-Houma',
  },
  {
    institution: 'Kaplan College-Milwaukee',
  },
  {
    institution: 'Miller-Motte Technical College-Madison',
  },
  {
    institution: 'Rasmussen College-Illinois',
  },
  {
    institution: 'International Institute for Restorative Practices',
  },
  {
    institution: 'Educational Technical College-Recinto de Coamo',
  },
  {
    institution: 'Educational Technical College-Recinto de san Sebastian',
  },
  {
    institution: 'Argosy University-Denver',
  },
  {
    institution: 'College America-Colorado Springs',
  },
  {
    institution: 'College America-Fort Collins',
  },
  {
    institution: 'Automotive Training Center-Warminster',
  },
  {
    institution: 'Sanford-Brown College-West Allis',
  },
  {
    institution: 'Empire Beauty School-Milwaukee',
  },
  {
    institution: 'The Robert B Miller College',
  },
  {
    institution: 'University of Phoenix-Fairfield County Campus',
  },
  {
    institution: 'University of Phoenix-Harrisburg Campus',
  },
  {
    institution: 'University of South Florida-St Petersburg',
  },
  {
    institution: 'Triangle Tech Inc-Bethlehem',
  },
  {
    institution: 'DeVry University-Utah',
  },
  {
    institution: 'Arizona State University-Downtown Phoenix',
  },
  {
    institution: 'Milan Institute-Clovis',
  },
  {
    institution: 'Branford Hall Career Institute-Albany Campus',
  },
  {
    institution: 'Harris School of Business-Linwood Campus',
  },
  {
    institution: 'The Art Institute of Tennessee-Nashville',
  },
  {
    institution: 'Strayer University-Florida',
  },
  {
    institution: 'Pima Medical Institute-Renton',
  },
  {
    institution: 'College of Business and Technology-Flagler',
  },
  {
    institution: 'College of Business and Technology-Hialeah',
  },
  {
    institution: 'Miller-Motte College-Cary',
  },
  {
    institution: 'Miami-Jacobs Career College-Springboro',
  },
  {
    institution: 'Dewey University-Juana D�az',
  },
  {
    institution: 'Dewey University-Fajardo',
  },
  {
    institution: 'Dewey University-Arroyo',
  },
  {
    institution: 'Regency Beauty Institute-Independence',
  },
  {
    institution: 'Regency Beauty Institute-St Peters',
  },
  {
    institution: 'Regency Beauty Institute-Peoria',
  },
  {
    institution: 'Regency Beauty Institute-Greenwood',
  },
  {
    institution: 'Regency Beauty Institute-Mehlville',
  },
  {
    institution: 'Regency Beauty Institute-Duluth',
  },
  {
    institution: 'Regency Beauty Institute-Fairview Heights',
  },
  {
    institution: 'Regency Beauty Institute-Champaign',
  },
  {
    institution: 'Regency Beauty Institute-Olathe',
  },
  {
    institution: 'The College of Health Care Professions-San Antonio',
  },
  {
    institution: 'Everest Institute-South Plainfield',
  },
  {
    institution: 'Kaplan Career Institute-Detroit',
  },
  {
    institution: 'Daymar College-Madisonville',
  },
  {
    institution: 'Brittany Beauty School',
  },
  {
    institution: 'Lincoln Technical Institute-Whitestone',
  },
  {
    institution: 'American Public University System',
  },
  {
    institution: 'Huntsville Bible College',
  },
  {
    institution: 'Beaumont Adult School',
  },
  {
    institution: 'Make-up Designory',
  },
  {
    institution: 'Video Symphony EnterTraining Inc',
  },
  {
    institution: 'Gnomon School of Visual Effects',
  },
  {
    institution: 'Coachella Valley Beauty College',
  },
  {
    institution: 'Career College of California',
  },
  {
    institution: 'Valley College of Medical Careers',
  },
  {
    institution: 'Academy of Natural Therapy Inc',
  },
  {
    institution: 'Academy Di Capelli-School of Cosmetology',
  },
  {
    institution: 'Delaware Learning Institute of Cosmetology',
  },
  {
    institution: 'Dragon Rises College of Oriental Medicine',
  },
  {
    institution: 'Palm Beach Academy of Health & Beauty',
  },
  {
    institution: 'SABER College',
  },
  {
    institution: 'Taylor College',
  },
  {
    institution: 'Pensacola School of Massage Therapy & Health Careers',
  },
  {
    institution: 'Hawaii College of Oriental Medicine',
  },
  {
    institution: 'Ideal Beauty Academy',
  },
  {
    institution: 'Success Schools',
  },
  {
    institution: 'Louisiana Culinary Institute',
  },
  {
    institution: 'Bais Medrash Toras Chesed',
  },
  {
    institution: 'Center for Natural Wellness School of Massage Therapy',
  },
  {
    institution: 'Ace Computer Training Center',
  },
  {
    institution: 'New Age Training',
  },
  {
    institution: 'Dayton School of Medical Massage',
  },
  {
    institution: 'Northcoast Medical Training Academy',
  },
  {
    institution: 'American Institute of Medical Technology',
  },
  {
    institution: 'Carib Technological Institute',
  },
  {
    institution: 'Visible Music College',
  },
  {
    institution: 'CCI Training Center-Arlington',
  },
  {
    institution: 'Champion Beauty College',
  },
  {
    institution: 'Professional Careers Institute',
  },
  {
    institution: 'Southeast Texas Career Institute',
  },
  {
    institution: 'Southwest Applied Technology Center',
  },
  {
    institution: 'Academy of Cosmetology',
  },
  {
    institution: 'Milwaukee Career College',
  },
  {
    institution: 'University of the West',
  },
  {
    institution: 'American College of Education',
  },
  {
    institution: 'South University-Tampa',
  },
  {
    institution: 'Milan Institute of Cosmetology-San Antonio Military',
  },
  {
    institution: 'Appalachian College of Pharmacy',
  },
  {
    institution: 'Averett University-Non-Traditional Programs',
  },
  {
    institution: 'Paul Mitchell the School-Tampa',
  },
  {
    institution: 'Paul Mitchell the School-San Diego',
  },
  {
    institution: 'Paul Mitchell the School-Michigan',
  },
  {
    institution: 'Paul Mitchell the School-Sherman Oaks',
  },
  {
    institution: 'Paul Mitchell the School-Mclean',
  },
  {
    institution: 'Miami-Jacobs Career College-Troy',
  },
  {
    institution: 'ATI College-Santa Ana',
  },
  {
    institution: 'Blue Cliff College-Houma',
  },
  {
    institution: 'The Art Institute of Salt Lake City',
  },
  {
    institution: 'Fortis College-Columbus',
  },
  {
    institution: 'Fortis College-Cincinnati',
  },
  {
    institution: 'Fortis Institute-Baltimore',
  },
  {
    institution: 'The Art Institute of Charleston',
  },
  {
    institution: 'The Art Institute of California-Argosy University Sacramento',
  },
  {
    institution: 'Blue Cliff College-Fayetteville',
  },
  {
    institution: 'Blue Cliff College-Alexandria',
  },
  {
    institution: 'StenoTech Career Institute-Piscataway',
  },
  {
    institution: 'Anthem College-Brookfield',
  },
  {
    institution: 'Ultimate Medical Academy-Tampa',
  },
  {
    institution: 'Beauty Schools of America-North Miami Beach',
  },
  {
    institution: 'Virginia College-Biloxi',
  },
  {
    institution: 'ITT Technical Institute-St Petersburg',
  },
  {
    institution: 'ITT Technical Institute-Baton Rouge',
  },
  {
    institution: 'ITT Technical Institute-Columbia',
  },
  {
    institution: 'ITT Technical Institute-Wichita',
  },
  {
    institution: 'ITT Technical Institute-Atlanta',
  },
  {
    institution: 'ITT Technical Institute-Mobile',
  },
  {
    institution: 'ITT Technical Institute-Chattanooga',
  },
  {
    institution: 'ITT Technical Institute-South Bend',
  },
  {
    institution: 'Virginia College-School of Business and Health-Chattanooga',
  },
  {
    institution: 'Strayer University-Delaware',
  },
  {
    institution: 'Brite Divinity School',
  },
  {
    institution: 'Strayer University-Alabama',
  },
  {
    institution: 'Brown Aveda Institute-Rocky River',
  },
  {
    institution: 'Clary Sage College',
  },
  {
    institution: 'Broadview University-Layton',
  },
  {
    institution: 'International Academy of Design and Technology-Sacramento',
  },
  {
    institution: 'University of Phoenix-Birmingham Campus',
  },
  {
    institution: 'International Academy of Design and Technology-San Antonio',
  },
  {
    institution: 'University of Phoenix-Augusta Campus',
  },
  {
    institution: 'University of Phoenix-Washington DC Campus',
  },
  {
    institution: 'University of Phoenix-Chattanooga Campus',
  },
  {
    institution: 'DeVry University-Michigan',
  },
  {
    institution: 'DeVry University-Tennessee',
  },
  {
    institution: 'Argosy University-Inland Empire',
  },
  {
    institution: 'Argosy University-Nashville',
  },
  {
    institution: 'Argosy University-San Diego',
  },
  {
    institution: 'Rasmussen College-Wisconsin',
  },
  {
    institution: 'Empire Beauty School-Lisle',
  },
  {
    institution: 'Empire Beauty School-Richmond',
  },
  {
    institution: 'Empire Beauty School-North Hills',
  },
  {
    institution: 'Empire Beauty School-Concord',
  },
  {
    institution: 'Empire Beauty School-Arlington Heights',
  },
  {
    institution: 'Lexington Healing Arts Academy',
  },
  {
    institution: 'Empire Beauty School-Hooksett',
  },
  {
    institution: 'The Institute of Beauty and Wellness',
  },
  {
    institution: 'InterCoast Colleges-Carson',
  },
  {
    institution: 'InterCoast Career Institute-South Portland',
  },
  {
    institution: 'Carrington College California-Stockton',
  },
  {
    institution: 'Carrington College California-Citrus Heights',
  },
  {
    institution: 'Euphoria Institute of Beauty Arts & Sciences-Summerlin',
  },
  {
    institution: 'Euphoria Institute of Beauty Arts & Sciences-Green Valley',
  },
  {
    institution: 'Empire Beauty School-Portsmouth',
  },
  {
    institution: 'LIU Riverhead',
  },
  {
    institution: 'Newbridge College-Long Beach',
  },
  {
    institution: 'Regency Beauty Institute-Lakewood',
  },
  {
    institution: 'Regency Beauty Institute-Westminster',
  },
  {
    institution: 'Regency Beauty Institute-Avon',
  },
  {
    institution: 'Regency Beauty Institute-Joliet',
  },
  {
    institution: 'Regency Beauty Institute-Rockford',
  },
  {
    institution: 'Regency Beauty Institute-Greenfield',
  },
  {
    institution: 'Regency Beauty Institute-Metro Center',
  },
  {
    institution: 'Regency Beauty Institute-East Tucson',
  },
  {
    institution: 'Regency Beauty Institute-Tri-County',
  },
  {
    institution: 'Regency Beauty Institute-Eastgate',
  },
  {
    institution: 'Regency Beauty Institute-Topeka',
  },
  {
    institution: 'Columbia Southern University',
  },
  {
    institution: 'Arizona Summit Law School',
  },
  {
    institution: 'Arizona Culinary Institute',
  },
  {
    institution: 'InfoTech Career College',
  },
  {
    institution: 'Trident University International',
  },
  {
    institution: 'Coastline Beauty College',
  },
  {
    institution: 'Career College Consultants',
  },
  {
    institution: 'San Diego College',
  },
  {
    institution: 'Montessori Casa International',
  },
  {
    institution: 'Branford Academy of Hair and Cosmetology',
  },
  {
    institution: 'Academy of Massage and Bodywork',
  },
  {
    institution: 'Digital Media Arts College',
  },
  {
    institution: 'Academy for Five Element Acupuncture',
  },
  {
    institution: 'Academy of Career Training',
  },
  {
    institution: 'Management Resources Institute',
  },
  {
    institution: 'Paul Mitchell the School-Miami',
  },
  {
    institution: 'Wolford College',
  },
  {
    institution: 'Aveda Institute-South Florida',
  },
  {
    institution: 'Aveda Institute-Tallahassee',
  },
  {
    institution: 'Shear Excellence Hair Academy',
  },
  {
    institution: 'Carlson College of Massage Therapy',
  },
  {
    institution: 'Bio-Chi Institute of Massage Therapy',
  },
  {
    institution: 'SOLEX Medical Academy',
  },
  {
    institution: 'MyComputerCareer.com-Indianapolis',
  },
  {
    institution: 'Z Hair Academy',
  },
  {
    institution: 'DiGrigoli School of Cosmetology',
  },
  {
    institution: 'Millennium Training Institute',
  },
  {
    institution: 'Gallery College of Beauty',
  },
  {
    institution: 'Lakewood School of Therapeutic Massage',
  },
  {
    institution: 'The Salon Spa Academy',
  },
  {
    institution: 'Trend Setters School of Cosmetology',
  },
  {
    institution: 'Academy of Hair Design-Springfield',
  },
  {
    institution: 'Corinth Academy of Cosmetology',
  },
  {
    institution: 'Southeastern Institute-Charlotte',
  },
  {
    institution: 'Gentle Healing School of Massage',
  },
  {
    institution: "Yeshivas Be'er Yitzchok",
  },
  {
    institution: 'Yeshiva Toras Chaim',
  },
  {
    institution: 'Talmudical Seminary of Bobov',
  },
  {
    institution:
      'New York Methodist Hospital Center for Allied Health Education',
  },
  {
    institution: 'Institute of Allied Medical Professions-New York',
  },
  {
    institution: 'Micropower Career Institute',
  },
  {
    institution: 'Monroe 2 Orleans BOCES-Center for Workforce Development',
  },
  {
    institution: "Aveda Fredric's Institute-Cincinnati",
  },
  {
    institution: 'MyComputerCareer.com-TechSkills',
  },
  {
    institution: 'CDE Career Institute',
  },
  {
    institution: 'Instituto Educativo Premier',
  },
  {
    institution: 'Charleston School of Law',
  },
  {
    institution: 'Elite College of Cosmetology',
  },
  {
    institution: 'Memphis Institute of Barbering',
  },
  {
    institution: "Manuel and Theresa's School of Hair Design-Bryan",
  },
  {
    institution: 'Southwest University at El Paso',
  },
  {
    institution: 'Paul Mitchell the School-Austin',
  },
  {
    institution: 'Aveda Institute-Provo',
  },
  {
    institution: 'Dixie Applied Technology College',
  },
  {
    institution: 'Centura College-Alexandria',
  },
  {
    institution: 'Southeast Culinary & Hospitality College',
  },
  {
    institution: 'Dominion School of Hair Design',
  },
  {
    institution: "Victoria's Academy of Cosmetology",
  },
  {
    institution: 'Inland Massage Institute',
  },
  {
    institution: 'Sunnyside Beauty Academy',
  },
  {
    institution: 'Wisconsin Academy',
  },
  {
    institution: 'The Art Institute of Pittsburgh-Online Division',
  },
  {
    institution: 'University of South Florida-Sarasota-Manatee',
  },
  {
    institution: 'Brown Mackie College-Indianapolis',
  },
  {
    institution: 'College America-Cheyenne',
  },
  {
    institution: 'Paul Mitchell the School-San Antonio',
  },
  {
    institution: 'Centro de Estudios Multidisciplinarios-Bayamon',
  },
  {
    institution: 'Bryant & Stratton College-Wauwatosa',
  },
  {
    institution: 'Minnesota School of Business-Blaine',
  },
  {
    institution: 'The Art Institute of Michigan',
  },
  {
    institution: 'Lacy Cosmetology School-Lexington',
  },
  {
    institution: 'Lacy Cosmetology School-Goose Creek',
  },
  {
    institution: 'The Art Institute of Austin',
  },
  {
    institution:
      'The Art Institute of California-Argosy University-Silicon Valley',
  },
  {
    institution: 'Remington College-Houston Southeast Campus',
  },
  {
    institution: 'Remington College-Shreveport Campus',
  },
  {
    institution: 'National American University-Austin',
  },
  {
    institution: 'National American University-Wichita',
  },
  {
    institution: 'Lawton Career Institute-Warren Main Campus',
  },
  {
    institution: 'Fortis College-Landover',
  },
  {
    institution: 'Jenny Lea Academy of Cosmetology and Aesthetics',
  },
  {
    institution: 'ITT Technical Institute-Cary',
  },
  {
    institution: 'ITT Technical Institute-Madison',
  },
  {
    institution: 'ITT Technical Institute-Clive',
  },
  {
    institution: 'ITT Technical Institute-Columbus',
  },
  {
    institution: 'ITT Technical Institute-Phoenix',
  },
  {
    institution: 'ITT Technical Institute-Madison',
  },
  {
    institution: 'ITT Technical Institute-High Point',
  },
  {
    institution: 'The Hair Academy',
  },
  {
    institution: 'The Art Institutes International�Kansas City',
  },
  {
    institution: 'The Art Institute of Raleigh-Durham',
  },
  {
    institution: 'Le Cordon Bleu College of Culinary Arts-Sacramento',
  },
  {
    institution: 'Le Cordon Bleu College of Culinary Arts-Seattle',
  },
  {
    institution: 'Le Cordon Bleu College of Culinary Arts-Cambridge',
  },
  {
    institution: 'Le Cordon Bleu College of Culinary Arts-Dallas',
  },
  {
    institution: 'Ross Medical Education Center-New Baltimore',
  },
  {
    institution: 'Ross College-Sylvania',
  },
  {
    institution: 'Argosy University-Salt Lake City',
  },
  {
    institution: 'Escuela De Troqueleria Y Herramentaje',
  },
  {
    institution: 'Virginia College-Montgomery',
  },
  {
    institution: 'Fortis Institute-Nashville',
  },
  {
    institution: 'Beckfield College-Tri-County',
  },
  {
    institution: 'Community Technology Learning Center of Portage',
  },
  {
    institution: 'David-Curtis School of Floral Design',
  },
  {
    institution: 'Dental Assistant Pro LLC-Columbus',
  },
  {
    institution: 'Dental Assistant Pro-Lebanon',
  },
  {
    institution: 'Iverson Institute',
  },
  {
    institution: 'Galen College of Nursing-Cincinnati',
  },
  {
    institution: 'Harmony Path School of Massage Therapy',
  },
  {
    institution: 'Institute of Therapeutic Massage',
  },
  {
    institution: 'Pinnacle Career Institute-North Kansas City',
  },
  {
    institution: 'Strayer University-Kentucky',
  },
  {
    institution: 'Strayer University-North Carolina',
  },
  {
    institution: 'Strayer University-New Jersey',
  },
  {
    institution: 'International Culinary Arts and Sciences Institute',
  },
  {
    institution: 'Kaplan College-Cincinnati',
  },
  {
    institution: 'National College-Dayton',
  },
  {
    institution: 'National College-Youngstown',
  },
  {
    institution: 'National College-Cincinnati',
  },
  {
    institution: 'National College-Stow',
  },
  {
    institution: 'Harris School of Business-Hamilton Campus',
  },
  {
    institution: 'Empire Beauty School-Eden Prairie',
  },
  {
    institution: 'Ohio Business College-Hilliard',
  },
  {
    institution: 'Ohio Center for Broadcasting-Columbus',
  },
  {
    institution: 'Ohio Medical Career Center',
  },
  {
    institution: 'Ohio Technical College-PowerSport Institute',
  },
  {
    institution: 'Reflexology Certification Institute',
  },
  {
    institution: 'Toledo Restaurant Training Center',
  },
  {
    institution: 'Dade Medical College-Miami Lakes',
  },
  {
    institution: 'The King�s College',
  },
  {
    institution: 'DeVry University-Kentucky',
  },
  {
    institution: 'Chamberlain College of Nursing-Administrative Office',
  },
  {
    institution: 'Chamberlain College of Nursing-Illinois',
  },
  {
    institution: 'Chamberlain College of Nursing-Ohio',
  },
  {
    institution: 'Chamberlain College of Nursing-Arizona',
  },
  {
    institution: 'Career Quest Learning Centers-Jackson',
  },
  {
    institution: 'Regency Beauty Institute-Fort Collins',
  },
  {
    institution: 'Regency Beauty Institute-Tolleson',
  },
  {
    institution: 'Regency Beauty Institute-Pasadena',
  },
  {
    institution: 'Regency Beauty Institute-Dayton',
  },
  {
    institution: 'Regency Beauty Institute-Akron',
  },
  {
    institution: 'Regency Beauty Institute-Detroit Southgate',
  },
  {
    institution: 'Regency Beauty Institute-Flint',
  },
  {
    institution: 'Regency Beauty Institute-Grand Rapids',
  },
  {
    institution: 'Regency Beauty Institute-Cypresswood',
  },
  {
    institution: 'Bryan University',
  },
  {
    institution: 'Regency Beauty Institute-Austin',
  },
  {
    institution: 'Regency Beauty Institute-Mesa',
  },
  {
    institution: 'Regency Beauty Institute-Nashville',
  },
  {
    institution: 'Regency Beauty Institute-Charlotte',
  },
  {
    institution: 'Regency Beauty Institute-North Olmsted',
  },
  {
    institution: 'Regency Beauty Institute-Columbus',
  },
  {
    institution: 'Regency Beauty Institute-Elgin',
  },
  {
    institution: 'The Salon Professional Academy-Appleton',
  },
  {
    institution: 'Milan Institute of Cosmetology-Fairfield',
  },
  {
    institution: 'Milan Institute of Cosmetology-Reno',
  },
  {
    institution: 'Milan Institute of Cosmetology-Visalia',
  },
  {
    institution: 'Ottawa University-Online',
  },
  {
    institution: 'Broadview University-Orem',
  },
  {
    institution: 'Paul Mitchell the School-Esani',
  },
  {
    institution: 'Institute of Production and Recording',
  },
  {
    institution: 'Virginia College-Greenville',
  },
  {
    institution: 'Paul Mitchell the School-Birmingham',
  },
  {
    institution: 'Taft University System',
  },
  {
    institution: 'Mayfield College',
  },
  {
    institution: 'International Polytechnic Institute',
  },
  {
    institution: 'Academy of Esthetics and Cosmetology',
  },
  {
    institution: 'BioHealth College',
  },
  {
    institution: 'International Professional School of Bodywork',
  },
  {
    institution: "Borner's Barber College",
  },
  {
    institution: 'Paul Mitchell the School-Pasadena',
  },
  {
    institution: 'San Francisco Institute of Esthetics and Cosmetology',
  },
  {
    institution: 'Laurus College',
  },
  {
    institution: 'Healing Hands School of Holistic Health',
  },
  {
    institution: 'Career Development Institute',
  },
  {
    institution: 'Auguste Escoffier School of Culinary Arts-Boulder',
  },
  {
    institution: 'Aspen University',
  },
  {
    institution: 'Institute of Taoist Education and Acupuncture',
  },
  {
    institution: 'National Beauty College',
  },
  {
    institution: 'Denver School of Nursing',
  },
  {
    institution: 'Cambridge Institute of Health & Technology',
  },
  {
    institution: 'Cozmo The School',
  },
  {
    institution: 'Allied Health Institute',
  },
  {
    institution: 'North Florida Academy',
  },
  {
    institution: 'Professional Hands Institute',
  },
  {
    institution: 'Celebrity School of Beauty',
  },
  {
    institution: 'Paul Mitchell the School-Atlanta',
  },
  {
    institution: 'Evans Hairstyling College-Rexburg',
  },
  {
    institution: 'Hair Academy Paul Mitchell Partner School',
  },
  {
    institution: 'University of Aesthetics-Chicago',
  },
  {
    institution: 'University of Aesthetics-Downers Grove',
  },
  {
    institution: 'Tricoci University of Beauty Culture-Chicago',
  },
  {
    institution: 'Tricoci University of Beauty Culture-Glendale Heights',
  },
  {
    institution: 'Tricoci University of Beauty Culture-Peoria',
  },
  {
    institution: 'Tricoci University of Beauty Culture-Rockford',
  },
  {
    institution: 'Frederick School of Cosmetology',
  },
  {
    institution: "Omega Studios' School of Applied Recording Arts & Sciences",
  },
  {
    institution: 'Focus-Hope Information Technologies Center',
  },
  {
    institution: 'International Cosmetology Academy',
  },
  {
    institution: 'French Academy of Cosmetology',
  },
  {
    institution: 'Healing Arts Center',
  },
  {
    institution: 'Paul Mitchell the School-Springfield',
  },
  {
    institution: 'Urshan Graduate School of Theology',
  },
  {
    institution: 'Mississippi Institute of Aesthetics Nails & Cosmetology',
  },
  {
    institution: 'College of Western Idaho',
  },
  {
    institution: 'Aveda Institute-Chapel Hill',
  },
  {
    institution: 'Charlotte School of Law',
  },
  {
    institution: 'Daoist Traditions College of Chinese Medical Arts',
  },
  {
    institution: 'Total Image Beauty Academy',
  },
  {
    institution: 'Jersey College',
  },
  {
    institution: 'American Institute of Medical Sciences & Education',
  },
  {
    institution: 'Casal Institute of Nevada',
  },
  {
    institution: 'Career School of NY',
  },
  {
    institution:
      "John Paolo's Xtreme Beauty Institute-Goldwell Product Artistry",
  },
  {
    institution: "Ann Marie's World of Beauty School",
  },
  {
    institution: 'Yeshiva of Machzikai Hadas',
  },
  {
    institution: 'Aveda Institute-Columbus',
  },
  {
    institution: 'Paul Mitchell the School-Cincinnati',
  },
  {
    institution: "Cutter's Edge School of Cosmetology",
  },
  {
    institution: 'Hands on Therapy',
  },
  {
    institution: 'MediaTech Institute-Dallas',
  },
  {
    institution: 'PCCenter',
  },
  {
    institution: 'Aveda Institute-San Antonio',
  },
  {
    institution: 'Skin Institute',
  },
  {
    institution: 'Skin Science Institute',
  },
  {
    institution: 'Global Health College',
  },
  {
    institution: 'Pacific Northwest University of Health Sciences',
  },
  {
    institution: 'Visions in Hair Design Institute of Cosmetology',
  },
  {
    institution: 'Brensten Education',
  },
  {
    institution: 'The Chicago School of Professional Psychology at Irvine',
  },
  {
    institution: 'California InterContinental University',
  },
  {
    institution: 'Fortis College-Dothan',
  },
  {
    institution: 'Fortis Institute-Pensacola',
  },
  {
    institution: 'Fortis College-Montgomery',
  },
  {
    institution: 'Fortis College School of Cosmetology',
  },
  {
    institution: 'Woodland Community College',
  },
  {
    institution: 'Dorsey Business Schools-Farmington Hills',
  },
  {
    institution: 'Dorsey Business Schools-Roseville Culinary Academy',
  },
  {
    institution: 'Vatterott College-Appling Farms',
  },
  {
    institution: 'Minnesota School of Business-Moorhead',
  },
  {
    institution: 'Brown Mackie College-Boise',
  },
  {
    institution: 'Brown Mackie College-Tulsa',
  },
  {
    institution: 'Fortis Institute-Birmingham',
  },
  {
    institution: 'Daymar College-Scottsville',
  },
  {
    institution: 'Miller-Motte College-Greenville',
  },
  {
    institution: 'The Chicago School of Professional Psychology at Los Angeles',
  },
  {
    institution: 'Metro Business College-Arnold',
  },
  {
    institution: 'The Chicago School of Professional Psychology at Westwood',
  },
  {
    institution: 'Cardiac and Vascular Institute of Ultrasound',
  },
  {
    institution: 'Virginia College-Jacksonville',
  },
  {
    institution: 'Studio Academy of Beauty',
  },
  {
    institution: 'California Nurses Educational Institute',
  },
  {
    institution: 'Central Nursing College',
  },
  {
    institution: 'RWM Fiber Optics',
  },
  {
    institution: 'Paul Mitchell the School-Sacramento',
  },
  {
    institution: 'Providence Christian College',
  },
  {
    institution: 'Summit Salon & Beauty School',
  },
  {
    institution: 'Oxford Academy of Hair Design',
  },
  {
    institution: 'Institute of World Politics',
  },
  {
    institution:
      'Pontifical John Paul II Institute for Studies on Marriage and Family',
  },
  {
    institution: 'Medical Institute of Palm Beach',
  },
  {
    institution: 'Lake Lanier School of Massage',
  },
  {
    institution: 'Oliver Finley Academy of Cosmetology',
  },
  {
    institution: 'National Career College',
  },
  {
    institution: 'Aveda Institute-Chicago',
  },
  {
    institution: 'Tricoci University of Beauty Culture-Libertyville',
  },
  {
    institution: 'Tricoci University of Beauty Culture-Bridgeview',
  },
  {
    institution: 'The Temple-A Paul Mitchell Partner School',
  },
  {
    institution: 'Center for Massage & Natural Health',
  },
  {
    institution: 'Academy of Hair Design-Oklahoma City',
  },
  {
    institution: 'Education and Technology Institute',
  },
  {
    institution: 'Pulse Beauty Academy-A Paul Mitchell Partner School',
  },
  {
    institution: 'Liberty Technical College',
  },
  {
    institution: 'Toni & Guy Hairdressing Academy-Cranston',
  },
  {
    institution: 'South Texas Training Center',
  },
  {
    institution: 'Columbia College',
  },
  {
    institution: 'Vermont College of Fine Arts',
  },
  {
    institution: 'Advanced Welding Institute',
  },
  {
    institution: 'The Art Institute of Washington-Dulles',
  },
  {
    institution: 'Paul Mitchell the School-Memphis',
  },
  {
    institution: 'Pima Medical Institute-East Valley',
  },
  {
    institution: 'Virginia College-Charleston',
  },
  {
    institution: 'The Ohio Academy Paul Mitchell Partner School-Cleveland',
  },
  {
    institution: 'The Ohio Academy Paul Mitchell Partner School-Columbus',
  },
  {
    institution: 'Georgia Beauty Academy',
  },
  {
    institution: 'Stautzenberger Institute-Allen Park',
  },
  {
    institution: 'Empire Beauty School-Lauderhill',
  },
  {
    institution: 'Empire Beauty School-Spring Lake Park',
  },
  {
    institution: 'MediaTech Institute-Austin',
  },
  {
    institution: 'MediaTech Institute-Houston',
  },
  {
    institution: 'Fortis College-Phoenix',
  },
  {
    institution: 'Miller-Motte College-Raleigh',
  },
  {
    institution: 'Lamson Institute',
  },
  {
    institution: 'Euphoria Institute of Beauty Arts & Sciences-Las Vegas',
  },
  {
    institution: 'Lincoln College of Technology-Toledo',
  },
  {
    institution: 'College of Hair Design-East Campus',
  },
  {
    institution: 'International Academy of Design and Technology-Online',
  },
  {
    institution: 'Le Cordon Bleu College of Culinary Arts-St Louis',
  },
  {
    institution: 'Sanford-Brown College-San Antonio',
  },
  {
    institution: 'Heritage College-Wichita',
  },
  {
    institution: 'Heritage College-Little Rock',
  },
  {
    institution: 'Argosy University-Phoenix Online Division',
  },
  {
    institution: 'MedTech College-Greenwood Campus',
  },
  {
    institution: 'MedTech College-Ft Wayne Campus',
  },
  {
    institution: 'MedTech College-Lexington Campus',
  },
  {
    institution: 'ITT Technical Institute-Madison',
  },
  {
    institution: 'ITT Technical Institute-Springfield',
  },
  {
    institution: 'ITT Technical Institute-Huntington',
  },
  {
    institution: 'ITT Technical Institute-Concord',
  },
  {
    institution: 'ITT Technical Institute-Fort Myers',
  },
  {
    institution: 'ITT Technical Institute-Charlotte North',
  },
  {
    institution: 'Fortis College-Salt Lake City',
  },
  {
    institution: 'Polytechnic University of Puerto Rico-Miami',
  },
  {
    institution: 'Polytechnic University of Puerto Rico-Orlando',
  },
  {
    institution: 'Ross Medical Education Center-Fort Wayne',
  },
  {
    institution: 'Ross Medical Education Center-Portage',
  },
  {
    institution: 'The Commonwealth Medical College',
  },
  {
    institution: "Josef's School of Hair Design Inc-Fargo West",
  },
  {
    institution: 'Northeast Technology Center-Claremore',
  },
  {
    institution: 'Salon Success Academy-Fontana',
  },
  {
    institution: 'Salon Success Academy-Redlands',
  },
  {
    institution: 'Brown Mackie College-Phoenix',
  },
  {
    institution: 'Strayer University-West Virginia',
  },
  {
    institution: 'Strayer University-Utah',
  },
  {
    institution: 'Strayer University-Ohio',
  },
  {
    institution: 'Regency Beauty Institute-Tinley Park',
  },
  {
    institution: 'Regency Beauty Institute-Columbia',
  },
  {
    institution: 'Regency Beauty Institute-South Bend',
  },
  {
    institution: 'Regency Beauty Institute-Detroit Lakeside',
  },
  {
    institution: 'Regency Beauty Institute-Fort Myers',
  },
  {
    institution: 'Regency Beauty Institute-Arlington',
  },
  {
    institution: 'Regency Beauty Institute-Durham',
  },
  {
    institution: 'Minnesota School of Business-Elk River',
  },
  {
    institution: 'Brown Mackie College-Greenville',
  },
  {
    institution: 'Globe University-Sioux Falls',
  },
  {
    institution: 'Globe University-Eau Claire',
  },
  {
    institution: 'Vanguard College of Cosmetology-Baton Rouge',
  },
  {
    institution: 'Globe University-Minneapolis',
  },
  {
    institution: 'San Joaquin Valley College-Hesperia',
  },
  {
    institution: 'Beauty Schools of America-Homestead',
  },
  {
    institution: 'ATI Career Training Center-Dallas',
  },
  {
    institution: 'South Texas Vocational Technical Institute-Brownsville',
  },
  {
    institution: 'South Texas Vocational Technical Institute-Corpus Christi',
  },
  {
    institution: "L'Ecole Culinaire-Memphis",
  },
  {
    institution: 'University of Minnesota-Rochester',
  },
  {
    institution: 'Health And Style Institute',
  },
  {
    institution: 'CBT College-Cutler Bay',
  },
  {
    institution: 'Provo College�American Fork',
  },
  {
    institution: 'National College-Columbus',
  },
  {
    institution: 'Kaplan Career Institute-Boston',
  },
  {
    institution: 'Herzing University-Toledo',
  },
  {
    institution: 'Capri Beauty College',
  },
  {
    institution: 'Milan Institute of Cosmetology-Concord',
  },
  {
    institution: 'Milan Institute-Bakersfield',
  },
  {
    institution: 'Homestead Schools',
  },
  {
    institution: 'Carrington College-Las Vegas',
  },
  {
    institution: 'Carrington College-Reno',
  },
  {
    institution: 'Chamberlain College of Nursing-Florida',
  },
  {
    institution: 'Remington College-Columbia Campus',
  },
  {
    institution: 'Remington College of Nursing Orlando',
  },
  {
    institution: 'Washington Barber College Inc',
  },
  {
    institution: 'InterCoast Colleges-Elk Grove',
  },
  {
    institution: 'Universal Barber College',
  },
  {
    institution: 'Horizon University',
  },
  {
    institution: 'San Diego Culinary Institute',
  },
  {
    institution: 'Beyond 21st Century Beauty Academy',
  },
  {
    institution: 'Franklin Career College',
  },
  {
    institution: 'Academy for Jewish Religion-California',
  },
  {
    institution: 'Hollywood Beauty College',
  },
  {
    institution: 'Angeles College',
  },
  {
    institution: 'Angeles Institute',
  },
  {
    institution: 'Paul Mitchell the School-Temecula',
  },
  {
    institution: 'European Academy of Cosmetology and Hairdressing',
  },
  {
    institution: 'International Institute of Cosmetology',
  },
  {
    institution: 'Florida School of Traditional Midwifery',
  },
  {
    institution: 'Walton Career Development Center',
  },
  {
    institution: 'Immokalee Technical Center',
  },
  {
    institution: 'American Academy of Cosmetology',
  },
  {
    institution: 'University of Fort Lauderdale',
  },
  {
    institution: 'Aviation Institute of Maintenance-Orlando',
  },
  {
    institution: 'The Salon Professional Academy-Tampa',
  },
  {
    institution: 'The Salon Professional Academy-Gainesville',
  },
  {
    institution: 'The Salon Professional Academy-The Villages',
  },
  {
    institution: 'The Hair Academy Inc',
  },
  {
    institution: 'Profile Institute of Barber-Styling',
  },
  {
    institution: 'The Process Institute of Cosmetology',
  },
  {
    institution: 'New Hope Christian College-Honolulu',
  },
  {
    institution: 'D & L Academy of Hair Design',
  },
  {
    institution: 'Master Educators Beauty School',
  },
  {
    institution: 'Ambria College of Nursing',
  },
  {
    institution: 'Midwestern Career College',
  },
  {
    institution: 'Paul Mitchell the School-Normal',
  },
  {
    institution: 'Innovations Design Academy',
  },
  {
    institution: "Aveda Fredric's Institute-Indianapolis",
  },
  {
    institution: 'The Salon Professional Academy-Anderson',
  },
  {
    institution: 'Eric Fisher Academy',
  },
  {
    institution: 'A & W Healthcare Educators',
  },
  {
    institution: "My Le's Beauty College",
  },
  {
    institution: 'Blackstone Valley Vocational Regional School District',
  },
  {
    institution: 'Southern Worcester County Regional Voc School District',
  },
  {
    institution: "L'esprit Academy",
  },
  {
    institution: 'Marketti Academy of Cosmetology',
  },
  {
    institution: 'CenterPoint Massage and Shiatsu Therapy School and Clinic',
  },
  {
    institution: 'Avalon School of Cosmetology',
  },
  {
    institution: 'American Business and Technology University',
  },
  {
    institution: 'City Vision College',
  },
  {
    institution: 'Transformed Barber and Cosmetology Academy',
  },
  {
    institution: 'Bitterroot School of Cosmetology',
  },
  {
    institution: 'Atlanta Beauty & Barber Academy',
  },
  {
    institution: 'The Salon Professional Academy-Great Falls',
  },
  {
    institution: 'Park West Barber School',
  },
  {
    institution: 'National Career Institute',
  },
  {
    institution: 'Toni & Guy Hairdressing Academy-Albuquerque',
  },
  {
    institution: 'International Academy of Style',
  },
  {
    institution: 'EDP School of Computer Programming',
  },
  {
    institution: 'New York Medical Career Training Center',
  },
  {
    institution: 'Institute of Culinary Education',
  },
  {
    institution: 'New Life Business Institute',
  },
  {
    institution: 'The Salon Professional Academy-Tonawanda',
  },
  {
    institution: 'Onondaga School of Therapeutic Massage-Syracuse',
  },
  {
    institution: 'Academy of Cosmetology and Esthetics NYC',
  },
  {
    institution: 'Finger Lakes School of Massage',
  },
  {
    institution: 'Four County Career Center',
  },
  {
    institution: 'Vanity School of Cosmetology',
  },
  {
    institution: 'Tolles Career and Technical Center',
  },
  {
    institution: 'The Salon Professional Academy-Perrysburg',
  },
  {
    institution: 'Portland Actors Conservatory',
  },
  {
    institution: 'Somerset County Technology Center',
  },
  {
    institution: 'Metro Beauty Academy',
  },
  {
    institution: 'Barone Beauty Academy',
  },
  {
    institution: 'Academy for Careers and Technology',
  },
  {
    institution: 'Aiken School of Cosmetology',
  },
  {
    institution: 'Love Beauty School Inc',
  },
  {
    institution: 'Professional Career Training Institute',
  },
  {
    institution: 'Cannon Institute of Higher Learning',
  },
  {
    institution: 'Cardiotech Ultrasound School',
  },
  {
    institution: "DuVall's School of Cosmetology",
  },
  {
    institution: 'Advanced Beauty College',
  },
  {
    institution: 'Texas Beauty College',
  },
  {
    institution: 'Avenue Five Institute',
  },
  {
    institution: 'Corpus Christi Beauty Academy',
  },
  {
    institution: 'The Salon Professional Academy-Lewisville',
  },
  {
    institution: 'Renaissance Academie',
  },
  {
    institution: 'Bethel College',
  },
  {
    institution: 'Institute of Advanced Medical Esthetics',
  },
  {
    institution: 'Gary Manuel Aveda Institute',
  },
  {
    institution: 'Northwest School of Wooden Boat Building',
  },
  {
    institution: 'Bainbridge Graduate Institute',
  },
  {
    institution: 'The Salon Professional Academy-Onalaska',
  },
  {
    institution: 'Regency Beauty Institute-North Tucson',
  },
  {
    institution: 'Kaplan College-Chula Vista',
  },
  {
    institution: 'InterCoast Colleges-Roseville',
  },
  {
    institution: 'West Coast University-Orange County',
  },
  {
    institution: 'West Coast University-Ontario',
  },
  {
    institution: 'Kaplan College-Pembroke Pines',
  },
  {
    institution: 'Dade Medical College-Homestead',
  },
  {
    institution: 'Virginia College-Augusta',
  },
  {
    institution: 'Midwest Technical Institute-East Peoria',
  },
  {
    institution: 'Paul Mitchell the School-Chicago',
  },
  {
    institution: 'Kaplan College-Indianapolis',
  },
  {
    institution: 'Regency Beauty Institute-Evansville',
  },
  {
    institution: 'Regency Beauty Institute-Wichita',
  },
  {
    institution: 'Regency Beauty Institute-Shreveport',
  },
  {
    institution: 'Regency Beauty Institute-Springfield',
  },
  {
    institution: 'Regency Beauty Institute-Canton',
  },
  {
    institution: 'Regency Beauty Institute-Spartanburg',
  },
  {
    institution: 'Regency Beauty Institute-Chattanooga',
  },
  {
    institution: 'Regency Beauty Institute-Mesquite',
  },
  {
    institution: 'Kaplan Career Institute-Dearborn',
  },
  {
    institution: 'Dorsey Business Schools-Waterford Pontiac',
  },
  {
    institution: "Regina's College of Beauty-High Point",
  },
  {
    institution: 'Miller-Motte College-Fayetteville',
  },
  {
    institution: 'Drake College of Business-Newark',
  },
  {
    institution: 'European Massage Therapy School-Las Vegas',
  },
  {
    institution: 'Harrison College-Grove City',
  },
  {
    institution: 'Miller-Motte Technical College-Columbus',
  },
  {
    institution: 'National University College-Ponce',
  },
  {
    institution: 'Fortis Institute-Grand Prairie',
  },
  {
    institution: 'Paul Mitchell the School-St George',
  },
  {
    institution: 'The Art Institute of Virginia Beach',
  },
  {
    institution: 'Bryant & Stratton College-Bayshore',
  },
  {
    institution:
      'Gerstner Sloan-Kettering Graduate School of Biomedical Sciences',
  },
  {
    institution: 'Daymar College-Online',
  },
  {
    institution: 'Virginia College-Columbia',
  },
  {
    institution: 'Sanford-Brown College-Dearborn',
  },
  {
    institution: 'Sanford-Brown College-Grand Rapids',
  },
  {
    institution: 'Sanford-Brown College-Indianapolis',
  },
  {
    institution: 'Sanford-Brown College-Hillside',
  },
  {
    institution: 'Sanford-Brown College-Tinley Park',
  },
  {
    institution: 'Sanford-Brown College-Phoenix',
  },
  {
    institution: 'Sanford-Brown Institute-Orlando',
  },
  {
    institution: 'Fortis College-Indianapolis',
  },
  {
    institution: 'Kaplan College-Arlington',
  },
  {
    institution: 'Kaplan College-Charlotte',
  },
  {
    institution: 'Kaplan College-Jacksonville',
  },
  {
    institution: 'Globe University-Madison East',
  },
  {
    institution: 'Minnesota School of Business-Lakeville',
  },
  {
    institution: 'Globe University�Green Bay',
  },
  {
    institution: 'Globe University�Madison West',
  },
  {
    institution: 'Globe University�Wausau',
  },
  {
    institution: 'Dade Medical College-Hollywood',
  },
  {
    institution: 'Empire Beauty School-E Memphis',
  },
  {
    institution: 'Empire Beauty School-Nashville',
  },
  {
    institution: 'Empire Beauty School-Jackson',
  },
  {
    institution: 'Empire Beauty School-Springfield',
  },
  {
    institution: 'Empire Beauty School-Paramus',
  },
  {
    institution: 'Empire Beauty School-Speedway',
  },
  {
    institution: 'Empire Beauty School-Morrow',
  },
  {
    institution: 'Strayer University-Arkansas',
  },
  {
    institution: 'Strayer University-Georgia',
  },
  {
    institution: 'Strayer University-Louisiana',
  },
  {
    institution: 'Strayer University-Mississippi',
  },
  {
    institution: 'Strayer University-South Carolina',
  },
  {
    institution: 'Strayer University-Texas',
  },
  {
    institution: 'The Art Institute of San Antonio',
  },
  {
    institution: 'Globe University-La Crosse',
  },
  {
    institution: 'The Hair Design School-S Memphis',
  },
  {
    institution: 'The Hair Design School-Charlotte',
  },
  {
    institution: 'The Hair Design School-Durham',
  },
  {
    institution: 'The Hair Design School-E Greensboro',
  },
  {
    institution: 'The Hair Design School-Winston-Salem',
  },
  {
    institution: 'Empire Beauty School-West Palm',
  },
  {
    institution: 'Empire Beauty School-Pineville',
  },
  {
    institution: 'The Hair Design School-N Memphis',
  },
  {
    institution: 'Broadview Entertainment Arts University',
  },
  {
    institution: 'Bryant & Stratton College-Hampton',
  },
  {
    institution: 'Paul Mitchell the School-Phoenix',
  },
  {
    institution: 'All Beauty College',
  },
  {
    institution: 'Northern California Institute of Cosmetology Inc',
  },
  {
    institution: 'Golden State College of Court Reporting',
  },
  {
    institution: 'Preferred College of Nursing-Los Angeles',
  },
  {
    institution: 'Trinity Vocational Center',
  },
  {
    institution: 'SICE Paul Mitchell Partner School',
  },
  {
    institution: 'Cosmo Beauty Academy',
  },
  {
    institution: 'Paul Mitchell the School-Fresno',
  },
  {
    institution: 'Unitek College',
  },
  {
    institution: 'Gurnick Academy of Medical Arts',
  },
  {
    institution: 'Southern California University SOMA',
  },
  {
    institution: 'Paul Mitchell the School-East Bay',
  },
  {
    institution: 'South University�Richmond',
  },
  {
    institution: 'South University�Virginia Beach',
  },
  {
    institution: 'Aveda Institute-Denver',
  },
  {
    institution: 'Paul Mitchell the School-Colorado Springs',
  },
  {
    institution: 'The Salon Professional Academy-Colorado Springs',
  },
  {
    institution: 'Paul Mitchell the School-Delaware',
  },
  {
    institution: 'Future-Tech Institute',
  },
  {
    institution: 'Marchman Technical Education Center',
  },
  {
    institution: 'Eureka Institute of Health and Beauty',
  },
  {
    institution: 'The Salon Professional Academy-Ft Myers',
  },
  {
    institution: 'SOLEX College',
  },
  {
    institution: 'Unity Cosmetology College',
  },
  {
    institution: 'Universal Spa Training Academy',
  },
  {
    institution: 'The Salon Professional Academy-Evansville',
  },
  {
    institution: 'Tri County Regional Vocational Technical High School',
  },
  {
    institution: 'Compass College of Cinematic Arts',
  },
  {
    institution: 'Nova Academy of Cosmetology',
  },
  {
    institution: 'Victory Trade School',
  },
  {
    institution: 'Starting Points Inc',
  },
  {
    institution: 'The Lab-Paul Mitchell Partner School',
  },
  {
    institution: 'SAE Institute of Technology-New York',
  },
  {
    institution:
      "John Paolo's Xtreme Beauty Institute-Goldwell Product Artistry",
  },
  {
    institution: 'The Artisan College of Cosmetology',
  },
  {
    institution: 'Sage School of Massage',
  },
  {
    institution: "Lawyer's Assistant School of Dallas",
  },
  {
    institution: 'ABC Beauty Academy',
  },
  {
    institution: 'Salon & Spa Institute',
  },
  {
    institution: 'American Beauty Academy',
  },
  {
    institution: 'The Salon Professional Academy-Tacoma',
  },
  {
    institution: 'Academy of Cosmetology',
  },
  {
    institution: 'The Academy Waukesha',
  },
  {
    institution: 'ITT Technical Institute-Merrillville',
  },
  {
    institution: 'ITT Technical Institute-Tallahassee',
  },
  {
    institution: 'ITT Technical Institute-Salem',
  },
  {
    institution: 'ITT Technical Institute-Akron',
  },
  {
    institution: 'ITT Technical Institute-Cedar Rapids',
  },
  {
    institution: 'ITT Technical Institute-Corona',
  },
  {
    institution: 'ITT Technical Institute-Johnson City',
  },
  {
    institution: 'ITT Technical Institute-DeSoto',
  },
  {
    institution: 'ITT Technical Institute-North Charleston',
  },
  {
    institution: 'ITT Technical Institute-Aurora',
  },
  {
    institution: 'ITT Technical Institute-West Covina',
  },
  {
    institution: 'ITT Technical Institute-Culver City',
  },
  {
    institution: 'ITT Technical Institute-Dearborn',
  },
  {
    institution: 'ITT Technical Institute-Las Vegas',
  },
  {
    institution: 'Touro University Worldwide',
  },
  {
    institution: 'Touro University California',
  },
  {
    institution:
      'The Chicago School of Professional Psychology at Washington DC',
  },
  {
    institution: 'Anamarc College-Santa Teresa',
  },
  {
    institution: 'Touro University Nevada',
  },
  {
    institution: 'National College-Willoughby Hills',
  },
  {
    institution: 'Herzing University-Kenosha',
  },
  {
    institution: 'Herzing University-Brookfield',
  },
  {
    institution: 'Heald College-Modesto',
  },
  {
    institution: 'Fortis Institute-Houston',
  },
  {
    institution: 'Southern Careers Institute-Brownsville',
  },
  {
    institution: 'Southern Careers Institute-Corpus Christi 2',
  },
  {
    institution: 'Southern Careers Institute-Harlingen',
  },
  {
    institution: 'Strayer University-Global Region',
  },
  {
    institution: "Regina's College of Beauty-Charlotte",
  },
  {
    institution: 'Kenneth Shuler School of Cosmetology-Florence',
  },
  {
    institution: 'Fortis College-Columbia',
  },
  {
    institution: 'Brown Mackie College-Albuquerque',
  },
  {
    institution: 'Brown Mackie College-St Louis',
  },
  {
    institution: 'Avant Gard The School',
  },
  {
    institution: 'Toni & Guy Hairdressing Academy-Bellingham',
  },
  {
    institution: 'California University of Management and Sciences',
  },
  {
    institution: 'Ross Medical Education Center-Davison',
  },
  {
    institution: 'Ross Medical Education Center-Granger',
  },
  {
    institution: 'Ross Medical Education Center-Niles',
  },
  {
    institution: 'Ross Medical Education Center-Canton',
  },
  {
    institution: 'Pima Medical Institute-Houston',
  },
  {
    institution: 'Illinois Center for Broadcasting�Chicago Campus',
  },
  {
    institution: 'South Texas Vocational Technical Institute-San Antonio',
  },
  {
    institution: 'Concorde Career Institute-Dallas',
  },
  {
    institution: 'Concorde Career Institute-Orlando',
  },
  {
    institution: 'Concorde Career Institute-San Antonio',
  },
  {
    institution: 'Ecotech Institute',
  },
  {
    institution: 'Johnson & Wales University-Online',
  },
  {
    institution: 'Everest College-Fort Worth South',
  },
  {
    institution: 'Everest College-Santa Ana',
  },
  {
    institution: 'Geisinger-Lewistown Hospital School of Nursing',
  },
  {
    institution: 'Moreno Valley College',
  },
  {
    institution: 'Hair Design Institute at Fifth Avenue-New York',
  },
  {
    institution: 'Onondaga School of Therapeutic Massage-Rochester',
  },
  {
    institution: 'Norco College',
  },
  {
    institution: 'Daymar College-Louisville',
  },
  {
    institution: 'Dorsey Business Schools-Saginaw',
  },
  {
    institution: 'Milan Institute of Cosmetology-La Quinta',
  },
  {
    institution: 'Milan Institute-Nampa',
  },
  {
    institution: 'Milan Institute of Cosmetology-El Paso',
  },
  {
    institution: 'Universal Technical Institute - Dallas Fort Worth',
  },
  {
    institution: 'Finger Lakes School of Massage',
  },
  {
    institution: 'Park Avenue School of Cosmetology',
  },
  {
    institution: 'Salter School of Nursing and Allied Health',
  },
  {
    institution: 'Salter School-New Bedford',
  },
  {
    institution: 'Harris School of Business-Upper Darby Campus',
  },
  {
    institution: 'Stratford School of Aviation Maintenance Technicians',
  },
  {
    institution: 'Brown Mackie College-San Antonio',
  },
  {
    institution: 'Hollywood Institute of Beauty Careers',
  },
  {
    institution: 'Cortiva Institute-New Jersey',
  },
  {
    institution: 'Cambridge Junior College-Woodland',
  },
  {
    institution: 'Anthem College-Atlanta',
  },
  {
    institution: 'ICPR Junior College-Manati',
  },
  {
    institution: 'Western Beauty Institute',
  },
  {
    institution: 'Ridley-Lowell Business & Technical Institute-Danbury',
  },
  {
    institution: 'Artistic Nails and Beauty Academy-Lakeland',
  },
  {
    institution: 'Paul Mitchell the School-Murfreesboro',
  },
  {
    institution: 'Allied American University',
  },
  {
    institution: 'American Sentinel University',
  },
  {
    institution: 'Tribeca Flashpoint Media Arts Academy',
  },
  {
    institution: 'Hawaii Medical College',
  },
  {
    institution: 'Carolina College of Hair Design',
  },
  {
    institution: 'Virginia College-Baton Rouge',
  },
  {
    institution: 'Remington College-Heathrow Campus',
  },
  {
    institution: 'College of Massage Therapy',
  },
  {
    institution: 'Miller-Motte College-Jacksonville',
  },
  {
    institution: 'Miller-Motte Technical College-Augusta',
  },
  {
    institution: 'Miller-Motte Technical College-Conway',
  },
  {
    institution: 'Virginia College-Macon',
  },
  {
    institution: 'Virginia College-Spartanburg',
  },
  {
    institution: 'Aveda Institute-Portland',
  },
  {
    institution: 'Chamberlain College of Nursing-Virginia',
  },
  {
    institution: 'Stevens-Henager College-Boise',
  },
  {
    institution: 'The Salon Professional Academy-Cincinnati',
  },
  {
    institution: 'Penrose Academy',
  },
  {
    institution: 'Virginia College-Richmond',
  },
  {
    institution: 'Skin Institute',
  },
  {
    institution: 'Northwest Institute of Literary Arts',
  },
  {
    institution: 'Body Therapy Institute',
  },
  {
    institution: 'Beauty Academy of South Florida',
  },
  {
    institution: 'Ogle School Hair Skin Nails-North Dallas',
  },
  {
    institution: 'The Salon Professional Academy-North Little Rock',
  },
  {
    institution: 'The Salon Professional Academy-St Charles',
  },
  {
    institution: 'American Institute',
  },
  {
    institution: 'Mildred Elley-New York Campus',
  },
  {
    institution: 'National Paralegal College',
  },
  {
    institution: 'Carolina College of Biblical Studies',
  },
  {
    institution: 'Star Career Academy-Audubon',
  },
  {
    institution: 'St Luke University',
  },
  {
    institution: 'Northeast Technology Center-System Office',
  },
  {
    institution: 'Allstate Hairstyling & Barber College',
  },
  {
    institution: 'Oxford Graduate School',
  },
  {
    institution: 'Jung Tao School of Classical Chinese Medicine',
  },
  {
    institution: 'New York Film Academy',
  },
  {
    institution: 'The Collective School Of Music',
  },
  {
    institution: 'International College of Cosmetology',
  },
  {
    institution: 'GP Institute of Cosmetology',
  },
  {
    institution: 'Bella Capelli Academy',
  },
  {
    institution: 'Institute of Medical Careers',
  },
  {
    institution: 'Toni & Guy Hairdressing Academy-Modesto',
  },
  {
    institution: 'Georgia Christian University',
  },
  {
    institution: 'Flagler Technical Institute',
  },
  {
    institution: 'Galaxy Medical College',
  },
  {
    institution: 'American Medical Sciences Center',
  },
  {
    institution: 'Mandalyn Academy',
  },
  {
    institution: 'Jose Maria Vargas University',
  },
  {
    institution: 'Laird Institute of Spa Therapy',
  },
  {
    institution: 'D A Dorsey Educational Center',
  },
  {
    institution: 'Keweenaw Bay Ojibwa Community College',
  },
  {
    institution: 'Meridian Institute of Surgical Assisting',
  },
  {
    institution: 'Mauna Loa Helicopters',
  },
  {
    institution: 'Acaydia School of Aesthetics',
  },
  {
    institution: 'Manhattan Institute',
  },
  {
    institution: 'Cosmetic Arts Institute',
  },
  {
    institution: 'Holistic Massage Training Institute',
  },
  {
    institution: 'Diamonds Cosmetology College',
  },
  {
    institution: 'Tramy Beauty School',
  },
  {
    institution: 'Scholars Cosmetology University',
  },
  {
    institution: 'Northern Virginia School of Therapeutic Massage',
  },
  {
    institution: 'Paul Mitchell the School-St Louis',
  },
  {
    institution: 'Advanced Training Institute',
  },
  {
    institution: 'Cosmopolitan Beauty and Tech School',
  },
  {
    institution: 'The Salon Professional Academy-Elgin',
  },
  {
    institution: 'CCIC Beauty College',
  },
  {
    institution: 'Salon 496 Barber Academy',
  },
  {
    institution: 'Shepherds Theological Seminary',
  },
  {
    institution: 'Taylor Andrews Academy of Hair Design-West Jordan',
  },
  {
    institution: 'Santa Ana Beauty Academy',
  },
  {
    institution: 'Grace College of Divinity',
  },
  {
    institution: 'Cosmetology College of Franklin County',
  },
  {
    institution: 'Rochester School of Hair Design',
  },
  {
    institution: 'Aveda Institute-New Mexico',
  },
  {
    institution: 'Nashville Barber and Style Academy',
  },
  {
    institution: 'American Trade School',
  },
  {
    institution: 'Estelle Skin Care and Spa Institute',
  },
  {
    institution: 'Paul Mitchell the School-Honolulu',
  },
  {
    institution: 'Elite Cosmetology School',
  },
  {
    institution: 'Academy of Aesthetic Arts',
  },
  {
    institution: 'American Technical Institute',
  },
  {
    institution: 'Twin Rivers Adult School',
  },
  {
    institution: 'Bergin University of Canine Studies',
  },
  {
    institution: 'Aveda Institute-Boise',
  },
  {
    institution: 'Progressive Training Centers',
  },
  {
    institution: 'American Health Institute',
  },
  {
    institution: 'Pima Medical Institute-South Denver',
  },
  {
    institution: 'Real Barbers College',
  },
  {
    institution: 'Capilo School of Hair Design',
  },
  {
    institution: 'Pure Aesthetics',
  },
  {
    institution: 'Lindsey Institute of Cosmetology',
  },
  {
    institution: 'The Salon Professional Academy-Iowa City',
  },
  {
    institution: 'Buckner Barber School',
  },
  {
    institution: 'Simmons College of Kentucky',
  },
  {
    institution: 'Atelier Esthetique Institute of Esthetics',
  },
  {
    institution: 'Ashdown College of Health Sciences',
  },
  {
    institution: 'Advanced College of Cosmetology',
  },
  {
    institution: 'North American University',
  },
  {
    institution: 'Entourage Institute of Beauty and Esthetics',
  },
  {
    institution: 'United Beauty College',
  },
  {
    institution: 'Cinta Aveda Institute',
  },
  {
    institution: 'Long Island Nail & Skin Care Institute',
  },
  {
    institution: 'Mesivta Keser Torah',
  },
  {
    institution: 'Southern California Health Institute',
  },
  {
    institution: 'Best Care Training Institute',
  },
  {
    institution: 'First Class Cosmetology School',
  },
  {
    institution: 'Millennia Atlantic University',
  },
  {
    institution: 'Abcott Institute',
  },
  {
    institution: 'The Salon Professional Academy-Kenosha',
  },
  {
    institution: 'Paul Mitchell the School-Indianapolis',
  },
  {
    institution: 'Taylor Andrews Academy-St George',
  },
  {
    institution: 'Seymour Beauty Academy',
  },
  {
    institution: 'Protege Academy',
  },
  {
    institution: 'Colorado Academy of Veterinary Technology',
  },
  {
    institution: 'Northeast Technical Institute',
  },
  {
    institution: 'Salinas Beauty College Inc',
  },
  {
    institution: 'Academy for Salon Professionals',
  },
  {
    institution: 'Elite School of Cosmetology',
  },
  {
    institution: 'Paul Mitchell the School-Las Vegas',
  },
  {
    institution: 'The Salon Professional Academy-Kokomo',
  },
  {
    institution: 'Hair Academy',
  },
  {
    institution: 'Florida Academy',
  },
  {
    institution: 'Institute for Doctoral Studies in the Visual Arts',
  },
  {
    institution: 'State Career College',
  },
  {
    institution: 'Academy for Salon Professionals',
  },
  {
    institution: 'Paul Mitchell The School-Spokane',
  },
  {
    institution:
      "DeVry University's Keller Graduate School of Management-Maryland",
  },
  {
    institution:
      "DeVry University's Keller Graduate School of Management-North Carolina",
  },
  {
    institution:
      "DeVry University's Keller Graduate School of Management-Florida",
  },
  {
    institution:
      "DeVry College of New York's Keller Graduate School of Management",
  },
  {
    institution:
      "DeVry University's Keller Graduate School of Management-Indiana",
  },
  {
    institution:
      "DeVry University's Keller Graduate School of Management-Nevada",
  },
  {
    institution:
      "DeVry University's Keller Graduate School of Management-Colorado",
  },
  {
    institution:
      "DeVry University's Keller Graduate School of Management-Minnesota",
  },
  {
    institution:
      "DeVry University's Keller Graduate School of Management-Oklahoma",
  },
  {
    institution: "DeVry University's Keller Graduate School of Management-Utah",
  },
  {
    institution:
      "DeVry University's Keller Graduate School of Management-Tennessee",
  },
  {
    institution:
      "DeVry University's Keller Graduate School of Management-Michigan",
  },
  {
    institution:
      "DeVry University's Keller Graduate School of Management-Kentucky",
  },
  {
    institution:
      "DeVry University's Keller Graduate School of Management-New Jersey",
  },
  {
    institution:
      "DeVry University's Keller Graduate School of Management-Oregon",
  },
  {
    institution: 'Lincoln College of New England-Hartford',
  },
  {
    institution: 'Lincoln College of Technology-Columbus',
  },
  {
    institution: 'Lincoln College of Technology-Cleveland',
  },
  {
    institution: 'Northwestern Institute of Health and Technology',
  },
  {
    institution: 'J Renee Career Facilitation',
  },
  {
    institution: 'National Personal Training Institute of Colorado',
  },
  {
    institution: 'National Personal Training Institute of Colorado',
  },
  {
    institution: 'International College of Beauty Arts & Sciences',
  },
  {
    institution: 'The Salon Professional Academy-Shorewood',
  },
  {
    institution: 'John Paul the Great Catholic University',
  },
  {
    institution: 'ITT Technical Institute-Durham',
  },
  {
    institution: 'ITT Technical Institute-Hanover',
  },
  {
    institution: 'University of Phoenix-Jackson Campus',
  },
  {
    institution: 'University of Phoenix-Delaware Campus',
  },
  {
    institution: 'Everest College-Milwaukee',
  },
  {
    institution: 'Independence University',
  },
  {
    institution: 'National American University-Tulsa',
  },
  {
    institution: 'National American University-Centennial',
  },
  {
    institution: "National American University-Lee's Summit",
  },
  {
    institution: 'National American University-Colorado Springs South',
  },
  {
    institution: 'Globe University-Appleton',
  },
  {
    institution: 'Salter College-Chicopee',
  },
  {
    institution: 'Chamberlain College of Nursing-Missouri',
  },
  {
    institution: 'Chamberlain College of Nursing-Texas',
  },
  {
    institution: 'ITT Technical Institute-Oakland',
  },
  {
    institution: 'Continental School of Beauty Culture-Mattydale',
  },
  {
    institution: 'Everest College-Bedford Park',
  },
  {
    institution: 'Fortis College-Akron',
  },
  {
    institution: 'Carrington College California-Pomona',
  },
  {
    institution: 'Regency Beauty Institute-Copperwood',
  },
  {
    institution: 'Regency Beauty Institute-Little Rock',
  },
  {
    institution: 'Regency Beauty Institute-Baltimore Golden Ring',
  },
  {
    institution: 'Regency Beauty Institute-El Paso',
  },
  {
    institution: 'Regency Beauty Institute-Jacksonville Regency',
  },
  {
    institution: 'Regency Beauty Institute-Knoxville',
  },
  {
    institution: 'Regency Beauty Institute-Fayetteville',
  },
  {
    institution: 'Regency Beauty Institute-Lansing',
  },
  {
    institution: 'Regency Beauty Institute-Jacksonville Orange Park',
  },
  {
    institution: 'Regency Beauty Institute-Crystal Lake',
  },
  {
    institution: 'Regency Beauty Institute-Manassas',
  },
  {
    institution: 'Regency Beauty Institute-Castleton',
  },
  {
    institution: 'Regency Beauty Institute-North Nashville',
  },
  {
    institution: 'Regency Beauty Institute-Round Rock',
  },
  {
    institution: 'Regency Beauty Institute-Merrillville',
  },
  {
    institution: 'Regency Beauty Institute-Roanoke',
  },
  {
    institution: 'Regency Beauty Institute-Toledo',
  },
  {
    institution: 'Regency Beauty Institute-SW Houston',
  },
  {
    institution: 'Everest College-Atlanta West',
  },
  {
    institution: 'Broadview University-Boise',
  },
  {
    institution: 'Advanced Institute of Hair Design-Madison',
  },
  {
    institution: 'Paul Mitchell The School-Columbia',
  },
  {
    institution: 'Paul Mitchell The School-Charleston',
  },
  {
    institution: 'Everest College-Melrose Park',
  },
  {
    institution: 'Kenneth Shuler School of Cosmetology-Greenville',
  },
  {
    institution: 'South University-Accelerated Graduate Programs',
  },
  {
    institution: 'South University-The Art Institute of Fort Worth',
  },
  {
    institution: 'South University-Novi',
  },
  {
    institution: 'Northwest College-Eugene',
  },
  {
    institution: 'Northwest College-Tualatin',
  },
  {
    institution: 'Harrison College-Morrisville',
  },
  {
    institution: "Trend Setters' Academy of Beauty Culture-Louisville",
  },
  {
    institution: 'National University College-Caguas',
  },
  {
    institution: 'Beauty Schools of America',
  },
  {
    institution: 'CET-Soledad',
  },
  {
    institution: 'MarJon School of Beauty ltd-Lockport',
  },
  {
    institution: 'Houston Training Schools-Southwest',
  },
  {
    institution: 'Vanguard College of Cosmetology-Metairie',
  },
  {
    institution: 'New England Tractor Trailer Training School of Massachusetts',
  },
  {
    institution: 'Heritage College-Columbus',
  },
  {
    institution: 'Utah College of Massage Therapy-Vegas',
  },
  {
    institution: 'Utah College of Massage Therapy-Tempe',
  },
  {
    institution: 'Utah College of Massage Therapy-Phoenix',
  },
  {
    institution: 'Utah College of Massage Therapy-Westminster',
  },
  {
    institution: 'Utah College of Massage Therapy-Aurora',
  },
  {
    institution: 'Sanford-Brown College-Portland',
  },
  {
    institution: 'Tint School of Makeup and Cosmetology-Grand Prairie',
  },
  {
    institution: 'Dewey University-Yabucoa',
  },
  {
    institution: 'Dewey University-Hatillo',
  },
  {
    institution: 'Dewey University-Manati',
  },
  {
    institution: 'Sanford-Brown College-Skokie',
  },
  {
    institution: 'Baltimore School of Massage-York',
  },
  {
    institution: 'Allen School-Phoenix',
  },
  {
    institution: 'Oklahoma Technical College',
  },
  {
    institution: 'Midwest Technical Institute-Moline',
  },
  {
    institution: 'Star Career Academy-Clifton',
  },
  {
    institution: 'Unitech Training Academy-Alexandria',
  },
  {
    institution: 'Unitech Training Academy-Lake Charles',
  },
  {
    institution: 'Aveda Institute-Los Angeles',
  },
  {
    institution: 'Career Quest Learning Center-Kalamazoo',
  },
  {
    institution: 'Buchanan Beauty College',
  },
  {
    institution: 'WellSpring School of Allied Health-Lawrence',
  },
  {
    institution: 'The Art Institute of Wisconsin',
  },
  {
    institution: 'Brown Mackie College-Birmingham',
  },
  {
    institution: "Regina's College of Beauty",
  },
  {
    institution: 'New York Film Academy',
  },
  {
    institution: "Manuel and Theresa's School of Hair Design-Victoria",
  },
  {
    institution: 'Coast Career Institute',
  },
  {
    institution: 'Tricoci University of Beauty Culture-Chicago NE',
  },
  {
    institution: 'ITT Technical Institute-Bradenton',
  },
  {
    institution: 'ITT Technical Institute-Phoenix West',
  },
  {
    institution: 'ITT Technical Institute-Brooklyn Center',
  },
  {
    institution: 'ITT Technical Institute-Orlando',
  },
  {
    institution: 'ITT Technical Institute-Waco',
  },
  {
    institution: 'ITT Technical Institute-Myrtle Beach',
  },
  {
    institution: 'University of Phoenix-Knoxville Campus',
  },
  {
    institution: 'Azusa Pacific Online University',
  },
  {
    institution: 'Minneapolis Media Institute',
  },
  {
    institution: 'College America-Phoenix',
  },
  {
    institution: 'CollegeAmerica-Stevens Henager College',
  },
  {
    institution: 'Avalon School of Cosmetology-Phoenix',
  },
  {
    institution: 'Avalon School of Cosmetology-Layton',
  },
  {
    institution: 'Hair Design Institute',
  },
  {
    institution: 'Hair Design Institute',
  },
  {
    institution: 'University of Phoenix-Baton Rouge Campus',
  },
  {
    institution: 'University of Phoenix-Lafayette Campus',
  },
  {
    institution: 'University of Phoenix-Shreveport-Bossier Campus',
  },
  {
    institution: 'All-State Career School-Allied Health Campus',
  },
  {
    institution: 'Aveda Institute-Des Moines',
  },
  {
    institution: 'Fortis Institute-Lawrenceville',
  },
  {
    institution: 'Relay Graduate School of Education',
  },
  {
    institution: 'Brown Mackie College-Oklahoma City',
  },
  {
    institution: 'Sanford-Brown College-Austin',
  },
  {
    institution: 'Miller-Motte Technical College-Macon',
  },
  {
    institution: 'Miller-Motte Technical College-Gulfport',
  },
  {
    institution: 'International Academy of Medical Reflexology',
  },
  {
    institution: 'International Culinary Center-California',
  },
  {
    institution: 'South University�Savannah Online',
  },
  {
    institution: 'Fortis College-Montgomery',
  },
  {
    institution: 'San Joaquin Valley College-Temecula',
  },
  {
    institution: 'Rio Grande Bible Institute',
  },
  {
    institution: 'Miller-Motte Technical College-Roanoke',
  },
  {
    institution: 'Whitworth University-Adult Degree Programs',
  },
  {
    institution: 'The Santa Barbara and Ventura Colleges of Law�Santa Barbara',
  },
  {
    institution: 'The University of America',
  },
  {
    institution: 'Kaplan College-Chesapeake',
  },
  {
    institution: 'American Broadcasting School-Online Program',
  },
  {
    institution: 'Springfield College-School of Human Services',
  },
  {
    institution: 'Marian Health Careers Center-Van Nuys Campus',
  },
  {
    institution: 'Brown College-Brooklyn Center',
  },
  {
    institution: 'Milan Institute-Las Vegas',
  },
  {
    institution: 'Williamson Free School of Mechanical Trades',
  },
  {
    institution: 'Virginia College-Savannah',
  },
  {
    institution: 'Virginia College-Columbus',
  },
  {
    institution: "Donna's Academy of Hair Design",
  },
  {
    institution: 'New York School of Esthetics & Day Spa',
  },
  {
    institution: 'Panache Academy of Beauty',
  },
  {
    institution: 'Finger Lakes Health College of Nursing',
  },
  {
    institution: 'ASI Career Institute',
  },
  {
    institution: 'Chicago School for Piano Technology',
  },
  {
    institution: 'Midwest Technical Institute-Ridgeland',
  },
  {
    institution:
      'Christine Valmy International School of Esthetics & Cosmetology',
  },
  {
    institution: 'City College-Hollywood',
  },
  {
    institution: 'Paul Mitchell the School-Arlington',
  },
  {
    institution: 'Rocky Mountain University of Health Professions',
  },
  {
    institution: 'MediaTech Institute-Oceanside',
  },
  {
    institution: "Christie's Education",
  },
  {
    institution: 'Prince Institute-Great Lakes',
  },
  {
    institution: 'Palmetto Beauty School',
  },
  {
    institution: 'Paul Mitchell the School-Fort Myers',
  },
  {
    institution: 'Paul Mitchell The School-Raleigh',
  },
  {
    institution: 'Stella and Charles Guttman Community College',
  },
  {
    institution: "Lil Lou's Barber College",
  },
  {
    institution: 'Bryan University',
  },
  {
    institution: 'Virginia College-Tulsa',
  },
  {
    institution: 'Criswell College',
  },
  {
    institution: 'Virginia College-Knoxville',
  },
  {
    institution: 'Columbia Academy of Cosmetology',
  },
  {
    institution: 'Academy of Couture Art',
  },
  {
    institution: 'Global Institute',
  },
  {
    institution: 'Shear Learning Academy of Cosmetology',
  },
  {
    institution: 'University of Cosmetology Arts & Sciences-La Joya',
  },
  {
    institution: 'Preferred College of Nursing-Van Nuys',
  },
  {
    institution: 'Bryant & Stratton College-Akron',
  },
  {
    institution: 'Annenberg School of Nursing',
  },
  {
    institution: 'American Medical Academy',
  },
  {
    institution: 'Tooele Applied Technology College',
  },
  {
    institution: 'Chamberlain College of Nursing-Georgia',
  },
  {
    institution: 'Chamberlain College of Nursing-Indiana',
  },
  {
    institution: 'Carrington College-Mesquite',
  },
  {
    institution: 'Universidad Internacional Iberoamericana',
  },
  {
    institution: 'Sessions College for Professional Design',
  },
  {
    institution: 'Southeastern Technical Careers Institute Inc',
  },
  {
    institution: 'Virginia College-Shreveport Bossier City',
  },
  {
    institution: 'Strayer University-Wisconsin',
  },
  {
    institution: 'Strayer University-Indiana',
  },
  {
    institution: 'Tulsa Welding School-Jacksonville',
  },
  {
    institution: 'Herzing University',
  },
  {
    institution: 'Regency Beauty Institute-Hoover',
  },
  {
    institution: 'Regency Beauty Institute-San Antonio',
  },
  {
    institution: 'Regency Beauty Institute-Carrollwood',
  },
  {
    institution: 'Regency Beauty Institute-Clearwater',
  },
  {
    institution: 'Regency Beauty Institute-Newport News',
  },
  {
    institution: 'Regency Beauty Institute-Lewisville',
  },
  {
    institution: 'Regency Beauty Institute-Dallas',
  },
  {
    institution: 'Regency Beauty Institute-Plano',
  },
  {
    institution: 'Empire Beauty School-Virginia Beach',
  },
  {
    institution: 'Sanford-Brown College-Columbus',
  },
  {
    institution: 'Ross Medical Education Center-Morgantown',
  },
  {
    institution: 'Paul Mitchell the School-Ogden',
  },
  {
    institution: 'CollegeAmerica-Colorado Springs South',
  },
  {
    institution: 'Everest Institute-Bensalem',
  },
  {
    institution: 'Golf Academy of America-Farmers Branch',
  },
  {
    institution: 'Utah College of Massage Therapy-Dallas',
  },
  {
    institution: 'Miami Ad School-New York',
  },
  {
    institution: 'Lacy Cosmetology School-West Ashley',
  },
  {
    institution: 'Dade Medical College-West Palm Beach',
  },
  {
    institution: 'Dade Medical College-Jacksonville',
  },
  {
    institution: 'WestMed College',
  },
  {
    institution: "Larry's Barber College",
  },
  {
    institution: 'Futura Career Institute',
  },
  {
    institution: 'Access Careers',
  },
  {
    institution: 'Flair Beauty College',
  },
  {
    institution: 'Eternity Cosmetology School',
  },
  {
    institution: 'Tri-State Institute of Hair Design',
  },
  {
    institution: 'Vogue College of Cosmetology-San Antonio Fredericksburg',
  },
  {
    institution: 'Vogue College of Cosmetology',
  },
  {
    institution: "Tomorrow's Image Barber Academy of Virginia",
  },
  {
    institution: 'Excel Learning Center',
  },
  {
    institution: 'College of International Esthetics Inc',
  },
  {
    institution: 'Medical Allied Career Center',
  },
  {
    institution: 'Bais HaMedrash and Mesivta of Baltimore',
  },
  {
    institution: 'Long Island Barber Institute',
  },
  {
    institution: 'Toni & Guy Hairdressing Academy-Toledo',
  },
  {
    institution: 'American Institute of Medical Sonography',
  },
  {
    institution: 'Destination Academy for Spa and Salon Professionals',
  },
  {
    institution: 'Classic Cooking Academy',
  },
  {
    institution: 'School of Court Reporting',
  },
  {
    institution: 'Curve Metric School of Hair Design',
  },
  {
    institution: 'Athena Career Academy',
  },
  {
    institution: 'Yeshiva Gedolah Zichron Leyma',
  },
  {
    institution: 'The Barber School',
  },
  {
    institution: "Be'er Yaakov Talmudic Seminary",
  },
  {
    institution: 'RGV Careers',
  },
  {
    institution: 'Alexander Academy',
  },
  {
    institution: 'LeMelange Academy of Hair',
  },
  {
    institution: 'Aveda Institute-Tucson',
  },
  {
    institution: 'Velvet Touch Academy of Cosmetology',
  },
  {
    institution: 'Ambiance Beauty & Barber Academy Inc',
  },
  {
    institution: 'Diamond Beauty College',
  },
  {
    institution: 'NCP College of Nursing-South San Francisco',
  },
  {
    institution: 'University Academy of Hair Design',
  },
  {
    institution: 'Florida Institute of Recording Sound and Technology',
  },
  {
    institution: 'New Dimensions Beauty Academy Inc',
  },
  {
    institution: "Jolei's Hair Institute",
  },
  {
    institution: 'Paul Mitchell the School-Reno',
  },
  {
    institution: 'Boise Barber College',
  },
  {
    institution: 'Austin Kade Academy',
  },
  {
    institution: 'Aesthetic Science Institute',
  },
  {
    institution: 'Elaine Sterling Institute',
  },
  {
    institution: 'Wade Gordon Hairdressing Academy',
  },
  {
    institution: 'Mitsu Sato Hair Academy',
  },
  {
    institution: 'SAE Institute of Technology-Los Angeles',
  },
  {
    institution: 'Florida Vocational Institute',
  },
  {
    institution: 'SAE Institute of Technology-Atlanta',
  },
  {
    institution: 'Academy di Firenze',
  },
  {
    institution: 'SAE Institute of Technology-Miami',
  },
  {
    institution: 'Colorado State University-Global Campus',
  },
  {
    institution: 'Paul Mitchell the School-Overland Park',
  },
  {
    institution: 'Medspa Academies',
  },
  {
    institution: 'Brighton Institute of Cosmetology',
  },
  {
    institution: 'Strayer University-Minnesota',
  },
  {
    institution: 'Strayer University-Illinois',
  },
  {
    institution: 'West Coast University-Dallas',
  },
  {
    institution: 'Stevens-Henager College-St George',
  },
  {
    institution: 'National American University-Bellevue',
  },
  {
    institution: 'National American University-Burnsville',
  },
  {
    institution: 'National American University-Mesquite',
  },
  {
    institution: 'Empire Beauty School-Savannah',
  },
  {
    institution: 'Ogle School Hair Skin Nails-Denton',
  },
  {
    institution: 'Empire Beauty School-West Greensboro',
  },
  {
    institution: 'California College San Diego',
  },
  {
    institution: 'California College San Diego',
  },
  {
    institution: 'Old Town Barber College-Topeka',
  },
  {
    institution: 'Jolie Hair and Beauty Academy-Northfield',
  },
  {
    institution: 'Hays Academy of Hair Design',
  },
  {
    institution: 'Bellus Academy',
  },
  {
    institution: 'Cortiva Institute-Seattle',
  },
  {
    institution: 'MotoRing Technical Training Institute',
  },
  {
    institution:
      'Leon Studio One School of Hair Design & Career Training Center',
  },
  {
    institution: 'Columbia College',
  },
  {
    institution: 'NCP College of Nursing-Hayward',
  },
  {
    institution: 'ITT Technical Institute-Philadelphia',
  },
  {
    institution: 'ITT Technical Institute-Marlton',
  },
  {
    institution: 'ITT Technical Institute-Germantown',
  },
  {
    institution: 'ITT Technical Institute-Grand Rapids',
  },
  {
    institution: 'ITT Technical Institute-Southfield',
  },
  {
    institution: 'ITT Technical Institute-West Palm Beach',
  },
  {
    institution: 'ITT Technical Institute-Salem',
  },
  {
    institution: 'ITT Technical Institute-Deerfield Beach',
  },
  {
    institution: 'ITT Technical Institute-Indianapolis East',
  },
  {
    institution: 'ITT Technical Institute-Douglasville',
  },
  {
    institution: 'ITT Technical Institute-Overland Park',
  },
  {
    institution: 'ITT Technical Institute-Springfield',
  },
  {
    institution: 'ITT Technical Institute-West Chester',
  },
  {
    institution: 'University of Phoenix-McAllen Campus',
  },
  {
    institution: 'Pennsylvania State University-World Campus',
  },
  {
    institution: 'Medical Career Institute',
  },
  {
    institution: 'Advance Beauty Techs Academy',
  },
  {
    institution: 'Longs Peak Academy',
  },
  {
    institution: 'Brand College',
  },
  {
    institution: 'Hinton Barber College',
  },
  {
    institution: 'Advanced Career Institute',
  },
  {
    institution: 'Digital Film Academy',
  },
  {
    institution: 'MyComputerCareer.com-Raleigh',
  },
  {
    institution: 'Kaplan University-Augusta Campus',
  },
  {
    institution: 'South University-Austin',
  },
  {
    institution: 'South University-Cleveland',
  },
  {
    institution: 'Bryant & Stratton College-Online',
  },
  {
    institution: 'Virginia College-Florence',
  },
  {
    institution: 'Ogle School Hair Skin Nails-San Antonio',
  },
  {
    institution:
      'Elizabethtown College School of Continuing and Professional Studies',
  },
  {
    institution: 'Fortis College-Cutler Bay',
  },
  {
    institution: 'Anamarc College-El Paso East',
  },
  {
    institution: 'Ideal Beauty Academy',
  },
  {
    institution: 'Minnesota School of Cosmetology-Plymouth Campus',
  },
  {
    institution: 'Mercyhurst University-North East Campus',
  },
  {
    institution: 'Warner Pacific College Adult Degree Program',
  },
  {
    institution: 'Platt College-Riverside',
  },
  {
    institution: 'Savannah Law School',
  },
  {
    institution: 'Empire Beauty School-Glen Burnie',
  },
  {
    institution: 'Everest College-Woodbridge',
  },
  {
    institution: 'Everest College-Kansas City',
  },
  {
    institution:
      'International School of Cosmetology-Toni & Guy Hairdressing Academy',
  },
  {
    institution: 'InterCoast Career Institute-Salem',
  },
  {
    institution: 'International Sports Sciences Association',
  },
  {
    institution: 'Abraham Lincoln University',
  },
  {
    institution: 'National College-Canton',
  },
  {
    institution: 'Shiloh University',
  },
  {
    institution: 'Court Reporting Institute of Arlington',
  },
  {
    institution: 'Vatterott College-Fairview Heights',
  },
  {
    institution: 'Ross Medical Education Center-Dayton',
  },
  {
    institution: 'Ross Medical Education Center-Ontario',
  },
  {
    institution: 'Ross Medical Education Center-Bowling Green',
  },
  {
    institution: 'Florida Institute of Technology-Online',
  },
  {
    institution: 'Rasmussen College-Kansas',
  },
  {
    institution: 'NewCourtland Education Center',
  },
  {
    institution: 'Columbia Institute',
  },
  {
    institution: 'Wadsworth Center-NY State Dept of Health',
  },
  {
    institution: 'EMS Training Institute',
  },
  {
    institution: 'School of Missionary Aviation Technology',
  },
  {
    institution: 'Ace Cosmetology and Barber Training Center',
  },
  {
    institution: 'Salt Lake Baptist College',
  },
  {
    institution: 'Manthano Christian College',
  },
  {
    institution: 'California Miramar University',
  },
  {
    institution: 'Rocky Vista University',
  },
  {
    institution: 'Virginia College-Greensboro',
  },
  {
    institution: 'The College of Health Care Professions-Dallas',
  },
  {
    institution: 'The College of Health Care Professions-Fort Worth',
  },
  {
    institution: 'Shear Finesse Hairstyling Academy',
  },
  {
    institution: 'Redstone College-Denver East',
  },
  {
    institution: 'Academy of Salon Professionals',
  },
  {
    institution: 'CyberTex Institute of Technology',
  },
  {
    institution: 'UEI College-Santa Cruz',
  },
  {
    institution: 'New England Tractor Trailer Training School of CT-Bridgeport',
  },
  {
    institution: 'Gemini School of Visual Arts & Communication',
  },
  {
    institution: 'Paul Mitchell the School-Woodbridge',
  },
  {
    institution: 'College of the Muscogee Nation',
  },
  {
    institution: 'The Salon Professional Academy-Huntsville',
  },
  {
    institution: 'Midwives College of Utah',
  },
  {
    institution: 'Aspen Beauty Academy of Laurel',
  },
  {
    institution: 'M T Training Center',
  },
  {
    institution: 'Berks Career & Technology Center',
  },
  {
    institution: 'Future Generations Graduate School',
  },
  {
    institution: 'Carthage R9 School District-Carthage Technical Center',
  },
  {
    institution: 'Grace Mission University',
  },
  {
    institution: 'World A Cuts Barber Institute',
  },
  {
    institution: 'Photographic Center Northwest',
  },
  {
    institution: 'PCCTI IT and Healthcare',
  },
  {
    institution: "Sotheby's Institute of Art-NY",
  },
  {
    institution: 'Top Nails & Hair Beauty School',
  },
  {
    institution: 'Radians College',
  },
  {
    institution: 'Santa Ana Beauty College',
  },
  {
    institution: 'Shepherds College',
  },
  {
    institution: 'Woodruff Medical Training and Testing',
  },
  {
    institution: 'Helms College',
  },
  {
    institution: 'American Academy of Personal Training',
  },
  {
    institution: 'Technology Center',
  },
  {
    institution: 'Ukiah Adult School',
  },
  {
    institution: 'Riverside County Office of Education',
  },
  {
    institution: 'Institute of Health Sciences',
  },
  {
    institution: 'Mid-America Baptist Theological Seminary',
  },
  {
    institution: 'Mid-South Christian College',
  },
  {
    institution: 'Advanced Computing Institute',
  },
  {
    institution: 'New York Institute of Beauty',
  },
  {
    institution: 'Ultrasound Medical Institute',
  },
  {
    institution: 'Beautiful You School of Nail Technology',
  },
  {
    institution: "Harmon's Beauty School",
  },
  {
    institution: 'Xavier College School of Nursing',
  },
  {
    institution: 'Lawrence & Company College of Cosmetology',
  },
  {
    institution: 'Cactus Academy',
  },
  {
    institution: 'Kaizen Beauty Academy',
  },
  {
    institution: 'Southern Texas Careers Academy',
  },
  {
    institution: 'Sharp Edgez Barber Institute',
  },
  {
    institution: 'Salon Professional Academy',
  },
  {
    institution: 'Cosmotech School of Cosmetology',
  },
  {
    institution: 'Prestige Health & Beauty Sciences Academy',
  },
  {
    institution: 'Grace College of Barbering',
  },
  {
    institution: 'California Career Institute',
  },
  {
    institution: 'Cosmo Factory Cosmetology Academy',
  },
  {
    institution: 'Grace School of Theology',
  },
  {
    institution: 'Yeshiva Gedolah Kesser Torah',
  },
  {
    institution: 'Universal Training Institute',
  },
  {
    institution: 'Yeshiva Yesodei Hatorah',
  },
  {
    institution: 'Rizzieri Institute',
  },
  {
    institution: 'Bonnie Joseph Academy of Cosmetology and Barbering',
  },
  {
    institution: 'Healthcare Training Institute',
  },
  {
    institution: 'W Academy of Salon and Spa',
  },
  {
    institution: 'Boca Beauty Academy',
  },
  {
    institution: 'United Medical and Business Institute',
  },
  {
    institution: 'Paul Mitchell the School-Jersey Shore',
  },
  {
    institution: 'City Pointe Beauty Academy',
  },
  {
    institution: 'Chrysm Institute of Esthetics',
  },
  {
    institution: 'Sanford-Burnham Medical Research Institute',
  },
  {
    institution: 'National Personal Training Institute of Columbus',
  },
  {
    institution: "Bly's School of Cosmetology",
  },
  {
    institution: 'Toni & Guy Hairdressing Academy-Manteca',
  },
  {
    institution: 'Belle Academy of Cosmetology',
  },
  {
    institution: 'Strayer University-Missouri',
  },
  {
    institution: 'Dorsey Business Schools-Lansing',
  },
  {
    institution: 'ITT Technical Institute-Pensacola',
  },
  {
    institution: 'ITT Technical Institute-San Antonio East',
  },
  {
    institution: 'MedTech Institute-Orlando Campus',
  },
  {
    institution: 'Tulsa Welding School-Tulsa',
  },
  {
    institution: 'Empire Beauty School-Augusta',
  },
  {
    institution: 'South University-High Point',
  },
  {
    institution: 'Empire Beauty School-Vernon Hills',
  },
  {
    institution: 'Empire Beauty School-Stone Park',
  },
  {
    institution: 'Court Reporting Institute of St Louis',
  },
  {
    institution: 'San Joaquin Valley College-Lancaster',
  },
  {
    institution: 'San Joaquin Valley College-San Diego',
  },
  {
    institution: 'Ohio Business College-Dayton',
  },
  {
    institution: 'Empire Beauty School-Rochester',
  },
  {
    institution: 'American Career College-Long Beach',
  },
  {
    institution: 'Ross Medical Education Center-Kokomo',
  },
  {
    institution: 'Ross Medical Education Center-Erlanger',
  },
  {
    institution: 'Ross Medical Education Center-Charleston',
  },
  {
    institution: "L'Ecole Culinaire-Kansas City",
  },
  {
    institution: 'Utah College of Massage Therapy-Houston',
  },
  {
    institution: 'InterCoast Colleges-Fairfield',
  },
  {
    institution: "Vatterott College-ex'treme Institute by Nelly-St Louis",
  },
  {
    institution: 'Personal Fitness & Nutrition Center',
  },
  {
    institution: 'Southern Careers Institute-San Antonio',
  },
  {
    institution: 'College of Business and Technology-Miami Gardens',
  },
  {
    institution: 'Milan Institute of Cosmetology-Nampa',
  },
  {
    institution: 'Milan Institute-Merced',
  },
  {
    institution: 'Midwest Technical Institute-Springfield',
  },
  {
    institution: 'Finger Lakes School of Massage',
  },
  {
    institution: 'Georgia Regents University',
  },
  {
    institution: 'Middle Georgia State College',
  },
  {
    institution: 'Trenz Beauty Academy',
  },
  {
    institution: 'Paul Mitchell the School-Denver',
  },
  {
    institution: 'Academy of Interactive Entertainment',
  },
  {
    institution: 'Arrojo Cosmetology School',
  },
  {
    institution: 'Cosmetology Academy of Texarkana',
  },
  {
    institution: 'Virginia Baptist College',
  },
  {
    institution: 'Lynnes Welding Training',
  },
  {
    institution: 'Aviation Institute of Maintenance-Las Vegas',
  },
  {
    institution: 'Tulsa Technology Center-Owasso Campus',
  },
  {
    institution: 'Tulsa Technology Center-Sand Springs Campus',
  },
  {
    institution: 'National American University-Austin South',
  },
  {
    institution: 'National American University-Lewisville',
  },
  {
    institution: 'National American University-Georgetown',
  },
  {
    institution: 'National American University-Richardson',
  },
  {
    institution: 'National American University-Rochester',
  },
  {
    institution: 'National American University-Weldon Spring',
  },
  {
    institution: 'National American University-Wichita West',
  },
  {
    institution: 'National American University-Indianapolis',
  },
  {
    institution: 'National American University-Tigard',
  },
  {
    institution: 'The Art Institute of St Louis',
  },
  {
    institution: 'DeVry College of New York',
  },
  {
    institution: 'DeVry University-Arizona',
  },
  {
    institution: 'DeVry University-California',
  },
  {
    institution: 'DeVry University-Colorado',
  },
  {
    institution: 'DeVry University-Florida',
  },
  {
    institution: 'DeVry University-Georgia',
  },
  {
    institution: 'DeVry University-Illinois',
  },
  {
    institution: 'DeVry University-Indiana',
  },
  {
    institution: 'DeVry University-Kentucky',
  },
  {
    institution: 'DeVry University-Maryland',
  },
  {
    institution: 'DeVry University-Michigan',
  },
  {
    institution: 'DeVry University-Minnesota',
  },
  {
    institution: 'DeVry University-Missouri',
  },
  {
    institution: 'DeVry University-Nevada',
  },
  {
    institution: 'DeVry University-New Jersey',
  },
  {
    institution: 'DeVry University-North Carolina',
  },
  {
    institution: 'DeVry University-Ohio',
  },
  {
    institution: 'DeVry University-Oklahoma',
  },
  {
    institution: 'DeVry University-Oregon',
  },
  {
    institution: 'DeVry University-Pennsylvania',
  },
  {
    institution: 'DeVry University-Tennessee',
  },
  {
    institution: 'DeVry University-Texas',
  },
  {
    institution: 'DeVry University-Utah',
  },
  {
    institution: 'DeVry University-Virginia',
  },
  {
    institution: 'DeVry University-Washington',
  },
  {
    institution: 'DeVry University-Wisconsin',
  },
  {
    institution: 'University of North Georgia',
  },
  {
    institution: 'South Georgia State College',
  },
  {
    institution: 'Northeastern University Global Network',
  },
  {
    institution: 'Georgia Military College-Distance Learning Campuses',
  },
  {
    institution: 'Florida Polytechnic University',
  },
  {
    institution: 'Brown Mackie College-Dallas',
  },
  {
    institution: 'American Institute-Toms River',
  },
  {
    institution: 'Texas Covenant Education',
  },
  {
    institution: 'Arizona College-Mesa',
  },
  {
    institution: 'American College of Healthcare',
  },
  {
    institution: 'Antioch College',
  },
  {
    institution: 'Texas A & M University-Central Texas',
  },
  {
    institution: 'Central Georgia Technical College',
  },
  {
    institution: 'Barber School of Pittsburgh',
  },
  {
    institution: 'EDMC Central Administrative Office',
  },
  {
    institution: 'The Salon Professional Academy-South Plainfield',
  },
  {
    institution: 'Arizona State University-Skysong',
  },
  {
    institution: 'Louisiana Delta Community College',
  },
]
